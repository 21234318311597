<section class="page">
  <section>
    <h1>Switch</h1>

    <div class="form-group">
      <div class="custom-control custom-switch primary">
        <input type="checkbox" class="custom-control-input" id="switch1">
        <label class="custom-control-label" for="switch1">Toggle me</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-switch primary">
        <input type="checkbox" class="custom-control-input is-invalid" id="switch12">
        <label class="custom-control-label" for="switch12">Toggle me</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-switch primary">
        <input type="checkbox" class="custom-control-input" id="switch13" checked>
        <label class="custom-control-label" for="switch13">Toggle me</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-switch primary">
        <input type="checkbox" class="custom-control-input" id="switch14" checked disabled>
        <label class="custom-control-label" for="switch14">Toggle me</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-switch primary">
        <input type="checkbox" class="custom-control-input" id="switch15" disabled>
        <label class="custom-control-label" for="switch15">Toggle me</label>
      </div>
    </div>
  </section>
  <section>
    <h1>Radio button</h1>

    <div class="form-group">
      <div class="custom-control custom-radio primary">
        <input type="radio" class="custom-control-input" id="anonymous2" name="anonymous2">
        <label class="custom-control-label" for="anonymous2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Ea laborum, veniam. Libero, recusandae voluptate! Aperiam autem consectetur consequuntur delectus eum fugit
          illo itaque necessitatibus, nemo odit quia, quibusdam recusandae, repellendus?</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio primary">
        <input type="radio" class="custom-control-input" id="anonymous3" name="anonymous2">
        <label class="custom-control-label" for="anonymous3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Animi eius, eos eveniet fugit ipsum molestiae nam nobis placeat provident sunt tempore vel veniam vero. Beatae
          dolore incidunt nulla possimus veritatis.</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio primary">
        <input type="radio" class="custom-control-input is-invalid" id="anonymous3-invalid" name="anonymous2">
        <label class="custom-control-label" for="anonymous3-invalid">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit.
          Animi eius, eos eveniet fugit ipsum molestiae nam nobis placeat provident sunt tempore vel veniam vero. Beatae
          dolore incidunt nulla possimus veritatis.</label>

        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-radio primary">
        <input type="radio" class="custom-control-input" id="anonymous4" name="anonymous2" disabled>
        <label class="custom-control-label" for="anonymous4">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Delectus ea, fugit iste laboriosam nam odit quia repudiandae sapiente? Dolorum illo incidunt iusto magni
          nihil, nisi pariatur sapiente sint vitae voluptatem.</label>
      </div>
    </div>
  </section>

  <section>
    <h1>Checkbox</h1>

    <div class="form-group">
      <div class="custom-control custom-checkbox primary">
        <input type="checkbox" class="custom-control-input" id="checkbox1" name="checkbox1">
        <label class="custom-control-label" for="checkbox1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Amet at consequatur cum iusto, libero nisi quam saepe ullam? Aut debitis deleniti earum eius error fugiat iure
          odit sunt suscipit voluptatem?</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-checkbox primary">
        <input type="checkbox" class="custom-control-input is-invalid" id="checkbox1invalid" name="checkbox1invalid">
        <label class="custom-control-label" for="checkbox1invalid">Lorem ipsum dolor sit amet, consectetur adipisicing
          elit.
          Amet at consequatur cum iusto, libero nisi quam saepe ullam? Aut debitis deleniti earum eius error fugiat iure
          odit sunt suscipit voluptatem?</label>

        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-checkbox primary">
        <input type="checkbox" class="custom-control-input" id="checkbox1d" name="checkbox1d" disabled>
        <label class="custom-control-label" for="checkbox1d">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Amet at consequatur cum iusto, libero nisi quam saepe ullam? Aut debitis deleniti earum eius error fugiat iure
          odit sunt suscipit voluptatem?</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-checkbox primary">
        <input type="checkbox" class="custom-control-input" id="checkbox2" name="checkbox2" [checked]="true">
        <label class="custom-control-label" for="checkbox2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Amet at consequatur cum iusto, libero nisi quam saepe ullam? Aut debitis deleniti earum eius error fugiat iure
          odit sunt suscipit voluptatem?</label>
      </div>
    </div>

    <div class="form-group">
      <div class="custom-control custom-checkbox primary">
        <input type="checkbox" class="custom-control-input" id="checkbox2d" name="checkbox2d" [checked]="true" disabled>
        <label class="custom-control-label" for="checkbox2d">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Amet at consequatur cum iusto, libero nisi quam saepe ullam? Aut debitis deleniti earum eius error fugiat iure
          odit sunt suscipit voluptatem?</label>
      </div>
    </div>
  </section>

  <section>
    <h1>Input</h1>
    <form>
      <div class="form-group">
        <label for="input-test-1">Label (normal size)</label>
        <input id="input-test-1" type="text" class="form-control" placeholder="Placeholder">
      </div>

      <div class="form-group">
        <label for="input-test-1-sm">Label (sm)</label>
        <input id="input-test-1-sm" type="text" class="form-control form-control-sm" placeholder="Placeholder">
      </div>

      <div class="form-group">
        <label for="input-test-2">Label</label>
        <input id="input-test-2" type="text" class="form-control" placeholder="Placeholder" value="Some Value">
      </div>

      <div class="form-group">
        <label for="input-test-3">Label</label>
        <input id="input-test-3" type="text" class="form-control is-invalid" placeholder="Placeholder" value="Error">
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="input-test-4">Label</label>
        <input id="input-test-4" type="text" class="form-control" placeholder="Placeholder" value="Disabled"
               [disabled]="true">
      </div>

      <div class="form-group">
        <label for="input-test-41">Label</label>
        <input id="input-test-41" type="text" class="form-control is-invalid" placeholder="Placeholder"
               value="Disabled Error"
               [disabled]="true">
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="input-test-42">Label</label>
        <input id="input-test-42" type="text" class="form-control is-valid" placeholder="Placeholder"
               value="Disabled Success"
               [disabled]="true">
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="input-test-5">Label</label>
        <input id="input-test-5" type="text" class="form-control" placeholder="Placeholder" [disabled]="true">
      </div>
    </form>
  </section>

  <section>
    <h1>Select</h1>

    <form>
      <div class="form-group">
        <label for="select-test-1">Label</label>
        <ng-select id="select-test-1" class="" [items]="options" placeholder="Placeholder"></ng-select>
      </div>

      <div class="form-group">
        <label for="select-test-2">Label</label>
        <ng-select id="select-test-2" name="select-test-2" class="" [items]="options" placeholder="Placeholder"
                   [clearable]="false"
                   [(ngModel)]="selectedValue"></ng-select>
      </div>

      <div class="form-group">
        <label for="select-test-3">Label</label>
        <ng-select id="select-test-3" name="select-test-3" class="is-invalid" [items]="options"
                   placeholder="Placeholder"
                   [(ngModel)]="selectedValue"></ng-select>
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="select-test-4">Label</label>
        <ng-select id="select-test-4" class="" [items]="options" placeholder="Placeholder"
                   [clearable]="false" disabled></ng-select>
      </div>

      <div class="form-group">
        <label for="select-test-31">Label</label>
        <ng-select id="select-test-31" name="select-test-31" class="is-invalid" [items]="options"
                   placeholder="Placeholder"
                   disabled
                   [(ngModel)]="selectedValue"></ng-select>
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="select-test-32">Label</label>
        <ng-select id="select-test-32" name="select-test-32" class="is-valid" [items]="options"
                   placeholder="Placeholder"
                   disabled
                   [(ngModel)]="selectedValue"></ng-select>
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="select-test-33">Label</label>
        <ng-select id="select-test-33" name="select-test-33" class="" [items]="options"
                   placeholder="Placeholder"
                   disabled
                   [(ngModel)]="selectedValue"></ng-select>
        <div class="invalid-feedback">
          <div>{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>
    </form>
  </section>

  <section>
    <h1>Button</h1>

    <div class="py-2">
      <button class="btn btn-primary">Primary button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-primary">Primary outline button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-secondary">Secondary button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-secondary">Secondary outline button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-danger">Danger button</button>
    </div>

    <!--    with icons-->
    <div class="py-2">
      <button class="btn btn-primary">
        <div class="d-flex align-items-center">
          <i-tabler name="camera" class="mr-1"></i-tabler>
          <span>Primary button</span>
        </div>
      </button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-primary">
        <div class="d-flex align-items-center">
          <i-tabler name="camera" class="mr-1"></i-tabler>
          <span>Primary outline button</span>
        </div>
      </button>
    </div>

    <div class="py-2">
      <button class="btn btn-secondary">
        <div class="d-flex align-items-center">
          <i-tabler name="camera" class="mr-1"></i-tabler>
          <span>Secondary button</span>
        </div>
      </button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-secondary">
        <div class="d-flex align-items-center">
          <i-tabler name="camera" class="mr-1"></i-tabler>
          <span>Secondary outline button</span>
        </div>
      </button>
    </div>

    <div class="py-2">
      <button class="btn btn-danger">
        <div class="d-flex align-items-center">
          <i-tabler name="camera" class="mr-1"></i-tabler>
          <span>Danger button</span>
        </div>
      </button>
    </div>

    <h4 class="mt-2">Button size</h4>
    <div class="py-2">
      <button class="btn btn-primary">Primary button normal size</button>
    </div>

    <div class="py-2">
      <button class="btn btn-primary btn-lg">Primary button large</button>
    </div>

    <div class="py-2">
      <button class="btn btn-primary btn-sm">Primary button small</button>
    </div>

    <h4 class="mt-2">Disabled</h4>
    <div class="py-2">
      <button class="btn btn-primary" disabled>Primary button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-primary" disabled>Primary outline button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-secondary" disabled>Secondary button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-outline-secondary" disabled>Secondary outline button</button>
    </div>

    <div class="py-2">
      <button class="btn btn-danger" disabled>Danger button</button>
    </div>

  </section>

  <section>
    <h1>Icons (tabler)</h1>
    <div class="d-flex flex-column">
      <i-tabler name="user"></i-tabler>
      <i-tabler name="camera" class="i-sm"></i-tabler>
      <i-tabler name="camera" class="i-md"></i-tabler>
      <i-tabler name="abacus" class="i-lg"></i-tabler>
      <i-tabler name="abacus" class="i-xl"></i-tabler>
    </div>
  </section>
</section>