import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-status-popup',
  templateUrl: './NetworkStatusPopupComponent.html',
  styleUrls: [ './NetworkStatusPopupComponent.scss' ]
})
export class NetworkStatusPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
