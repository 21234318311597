import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {

  public transform(value: string): string {

    if (value.length > 0) {
        let spaceIndex = value.indexOf('T');
        let truncated: string = value.substring(0, spaceIndex);
        return truncated;
    }
    else 
       return "-";
  }
}