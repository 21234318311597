import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import * as moment from 'moment';

@Directive({
  selector: '[appTimeLeft]'
})
export class TimeLeftDirective implements OnInit, OnDestroy {
  @Input() public appTimeLeft: Date;

  @Input() public duration: number;

  public interval: ReturnType<typeof setInterval>;

  constructor(private el: ElementRef,
              private renderer: Renderer2) {
  }

  public ngOnInit(): void {
    const startTime = moment(this.appTimeLeft).add(this.duration, 'minutes');

    if (moment().isBefore(startTime)) {
      this.calculate(startTime);
      this.interval = setInterval(() => this.calculate(startTime), 1000);
    } else {
      this.setValue('00:00');
    }
  }

  public ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private calculate(startTime: moment.Moment): void {
    const diff = startTime.diff(moment());
    const value = moment(diff).format('mm:ss');
    this.setValue(value);

    if (value === '00:00') {
      clearInterval(this.interval);
    }
  }

  private setValue(value: string): void {
    this.renderer.setProperty(this.el.nativeElement, 'textContent', value);
  }

}
