import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-not-found',
  templateUrl: 'ErrorNotFoundComponent.html',
  styleUrls: [ 'ErrorNotFoundComponent.scss' ]
})
export class ErrorNotFoundComponent implements OnInit, OnDestroy {

  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public backToHome(): void {
    history.back();
  }

}
