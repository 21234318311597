import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';
import { Observable } from 'rxjs';
import { EventPatientService } from '@service/event/EventPatientService';

@Component({
  selector: 'app-event-upload-files',
  templateUrl: './EventUploadFilesComponent.html',
  styleUrls: [ './EventUploadFilesComponent.scss' ]
})
export class EventUploadFilesComponent implements OnInit {
  private eventId: number = this.transition.params().eventId;

  public files$: Observable<string[]> = this.eventPatientService.getUploadedFiles(this.eventId);

  constructor(private eventPatientService: EventPatientService,
              private transition: Transition) {
  }

  public ngOnInit(): void {
  }
}
