<div class="container px-0">
  <div *ngIf="practice; else noDataTemplate">
    <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.PRACTICE.PRACTICE_INFO' | translate }}</div>

    <div class="row mt-4">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.PRACTICE.PRACTICE_NAME' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{practice?.practiceName | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.PRACTICE.ADDRESS' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">
            <div>{{practice?.address?.streetWithNumber | replaceEmpty}}</div>
            <div>{{ practice?.address?.cityCode }} {{ practice?.address?.city }}</div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Portal-->
<app-page-action-portal name="profile" *ngIf="isEditable$ | async">
  <button class="btn btn-danger" type="button" *ngIf="viewReady && practice"
          (click)="delete()">{{'COMMON.DELETE' | translate}}</button>
  <button class="btn btn-primary" type="button" *ngIf="viewReady && practice"
          (click)="edit()">{{'COMMON.EDIT' | translate}}</button>
  <button class="btn btn-primary" type="button" *ngIf="viewReady && !practice"
          (click)="create()">{{'COMMON.CREATE' | translate}}</button>
</app-page-action-portal>

<ng-template #noDataTemplate>
  <span *ngIf="viewReady">{{'VIEW.MAIN.DOCTOR.PRACTICE.NO_DATA' | translate}}</span>
</ng-template>