<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 my-5 text-center">
      <h1 class="display-1">404</h1>
      <div class="display-4 mb-3">{{ 'VIEW.PRELIMINARY.ERROR.PAGE_NOT_FOUND' | translate }}</div>
      <div class="lead">{{ 'VIEW.PRELIMINARY.ERROR.PAGE_NOT_FOUND_MESSAGE' | translate }}</div>

      <button type="button" class="btn btn-link" (click)="backToHome()">
        <span>{{ 'VIEW.PRELIMINARY.ERROR.BACK' | translate }}</span>
      </button>
    </div>
  </div>
</div>
