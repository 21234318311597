import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { UserDecision } from '@service/UserDecisionService';
import { Transition } from '@uirouter/angular';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { ViewUtil } from '@util/ViewUtil';
import { ChildDecisionDTO } from '@dto/migratingAccount/ChildDecisionDTO';
import { LocaleService } from '@service/LocaleService';
import { MigratingAccountDecision } from '@enum/account/MigratingAccountDecision';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { ApplicationModel } from '@model/ApplicationModel';

@Component({
  selector: 'app-migrating-account',
  templateUrl: './MigratingAccountComponent.html',
  styleUrls: [ './MigratingAccountComponent.scss' ]
})
export class MigratingAccountComponent implements OnInit {
  @ViewChild('baseForm') private baseForm: NgForm;

  public form: FormGroup = this.fb.group({
    acceptTermsAndConditions: [ false, [ Validators.requiredTrue ] ],
    confirmProcessingData: [ false, [ Validators.requiredTrue ] ]
  });

  public linkExpired: boolean = false;

  public patient: PatientDTO;

  public viewReady: boolean = false;

  public userDecision: MigratingAccountDecision;

  public MigratingAccountDecision: typeof MigratingAccountDecision = MigratingAccountDecision;

  private token: string = this.transition.params().token;

  constructor(public applicationModel: ApplicationModel,
              private fb: FormBuilder,
              private transition: Transition,
              private localeService: LocaleService,
              private viewUtil: ViewUtil,
              private modalService: BsModalService,
              private accountMigrationService: UserDecision) {
    const language = transition.params()?.lang;
    this.localeService.setLanguage(language);

    this.getData();
  }

  public ngOnInit(): void {
  }

  public reject(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.ACCOUNT_MIGRATION.MESSAGE.DELETE.TITLE',
        okText: 'COMMON.CONFIRM'
      }
    }).content.close$.toPromise()
      .then(() => {
        const decision: ChildDecisionDTO = { deleteAccount: true, token: this.token };
        this.makeDecision(decision);
      });
  }

  public save(createAccount: boolean): void {
    this.baseForm.onSubmit(null);

    if (this.baseForm.valid) {
      const { confirmProcessingData, acceptTermsAndConditions } = this.baseForm.value;
      const decision: ChildDecisionDTO = {
        migrateData: createAccount,
        processData: confirmProcessingData,
        termsCondition: acceptTermsAndConditions,
        token: this.token
      };

      this.makeDecision(decision);
    }
  }

  private makeDecision(decision: ChildDecisionDTO): void {
    this.accountMigrationService.makeChildDecision(decision).toPromise()
      .then(() => {
        this.userDecision = decision.deleteAccount ?
          MigratingAccountDecision.DELETE_ACCOUNT :
          (decision.migrateData ? MigratingAccountDecision.CREATE_ACCOUNT : MigratingAccountDecision.USE_PARENT_ACCOUNT);
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private getData(): void {
    this.accountMigrationService.getAccountByToken(this.token).toPromise()
      .then((patient: PatientDTO) => {
        this.patient = patient;
      })
      .catch(() => {
        this.linkExpired = true;
      })
      .finally(() => {
        this.viewReady = true;
      });
  }

}
