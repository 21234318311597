import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '@common/Constant';

@Injectable({
  providedIn: 'root'
})
export class EventPrescriptionService {

  constructor(private http: HttpClient) {
  }

  public getList(eventId: number): Observable<PrescriptionDTO[]> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/e-prescriptions`, { headers })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(PrescriptionDTO, response))
      );
  }

  public create(eventId: number, prescription: PrescriptionDTO): Observable<PrescriptionDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/events/${ eventId }/e-prescriptions`, ObjectUtil.classToPlain(prescription))
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(PrescriptionDTO, response))
      );
  }

  public remove(eventId: number, prescriptionId: number): Observable<unknown> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/events/${ eventId }/e-prescriptions/${ prescriptionId }`);
  }

  public edit(eventId: number, prescription: PrescriptionDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/events/${ eventId }/e-prescriptions/${ prescription.id }`, ObjectUtil.classToPlain(prescription));
  }
}
