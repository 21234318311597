import { Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input, OnDestroy, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-responsive-data-grid-dynamic-element',
  template: '<ng-container #container></ng-container>'
})
export class ResponsiveDataGridDynamicElementComponent implements OnInit, OnDestroy {

  @ViewChild('container', { static: true, read: ViewContainerRef })
  private container: ViewContainerRef;

  private componentRef: ComponentRef<any>;

  @Input()
  public cellRenderer: Type<ICellRendererAngularComp>;

  @Input()
  public params: ICellRendererParams;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  public ngOnInit():void {
    const factory: ComponentFactory<ICellRendererAngularComp> = this.componentFactoryResolver.resolveComponentFactory(this.cellRenderer);
    this.componentRef = this.container.createComponent(factory);
    const instance: ICellRendererAngularComp = this.componentRef.instance as ICellRendererAngularComp;
    instance.agInit(this.params);
  }

  public ngOnDestroy():void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

}

