import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateUtil } from '@util/TranslateUtil';
import { StateService } from '@uirouter/core';
import { State } from '@common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';

@Component({
  selector: 'app-workdays-settings',
  templateUrl: './WorkDaysSettingsComponent.html'
})
export class WorkDaysSettingsComponent implements OnInit, OnDestroy {
  public State: typeof State = State;

  public tabs: ITab[] = [
    {
      title: 'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.WORKING_HOURS',
      state: State.MAIN.WORKDAYS.WORKING_HOURS
    },
    {
      title: 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.VACATION',
      state: State.MAIN.WORKDAYS.VACATIONS
    }
  ];

  constructor(private stateService: StateService,
              private translateUtil: TranslateUtil,
              private applicationModel: ApplicationModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PRACTICE.CATEGORY);

    if (this.stateService.current.name === State.MAIN.WORKDAYS.WORKDAYS) {
      this.stateService.go(State.MAIN.WORKDAYS.WORKING_HOURS, null, { location: 'replace' });
    }
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}
