<div class="form-group w-100">
  <label> {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_REQUIREMENTS' | translate}}  </label>

  <div *ngFor="let requirement of requirements | keyvalue"
       class="d-flex flex-nowrap align-items-center mb-1">
    <div class="icon mr-1">
      <ng-container
        *ngIf="passwordControl?.errors?.required || passwordControl?.errors?.passwordRequirements?.[requirement.value]">
        <i-tabler name="x"></i-tabler>
      </ng-container>

      <ng-container
        *ngIf="!passwordControl?.errors?.required && !passwordControl?.errors?.passwordRequirements?.[requirement.value]">
        <i-tabler name="check" class="valid"></i-tabler>
      </ng-container>
    </div>

    <span class="font-size-sm">{{'VALIDATION.PASSWORD_REQUIREMENTS.' + requirement.key | translate}}</span>
  </div>
</div>
