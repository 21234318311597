import { LocaleService } from '@service/LocaleService';

export class LocaleId extends String {
  constructor(private localeService: LocaleService) {
    super();
  }

  public toString(): string {
    return this.localeService.currentLocale;
  }

  public valueOf(): string {
    return this.toString();
  }
}