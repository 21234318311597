<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.MAIN.STATISTICS' | translate}}</div>

    <div class="actions">
<!--     <button type="button" class="btn btn-primary"-->
<!--             (click)="export()"> {{ 'COMMON.EXPORT' | translate}} </button>-->
    </div>
  </div>

  <div class="content">
    <app-data-grid *ngIf="data" [data]="data.content"
                   [criteria]="criteria"
                   [context]="{ host: this }"
                   [selectionEnabled]="false"
                   [showFilters]="true"
                   [showSearch]="true"
                   [showPagination]="true"
                   [pageTotal]="data.totalPages" [itemTotal]="data.totalElements"
                   (criteriaChanged)="onCriteriaChanged($event)"
                   [columns]="$any([
                    { colId: '1', headerName: ('VIEW.MAIN.STATISTIC.DATA.CUSTOMER_ID' | translate), maxWidth: 120, field: 'customerId', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '2', headerName: ('VIEW.MAIN.STATISTIC.DATA.DOCTOR_NAME' | translate), maxWidth: 150, field: 'doctorName', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '3', headerName: ('VIEW.MAIN.STATISTIC.DATA.DOCTOR_LAST_NAME' | translate), maxWidth: 150, field: 'doctorLastName', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '4', headerName: ('VIEW.MAIN.STATISTIC.DATA.SPECIALITY' | translate), maxWidth: 140, field: 'speciality', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '5', headerName: ('VIEW.MAIN.STATISTIC.DATA.VIDEO_CALLS' | translate), maxWidth: 110, field: 'videoCalls', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '6', headerName: ('VIEW.MAIN.STATISTIC.DATA.PRESCRIPTIONS' | translate), maxWidth: 140, field: 'ePrescriptions', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                    { colId: '7', headerName: ('VIEW.MAIN.STATISTIC.DATA.SICK_LEAVES' | translate), minWidth: 110, field: 'sickLeaves', flex: 1, suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: valueTemplate, preventSelection: false } },
                  ])">

      <app-data-grid-filters>
        <div class="filter">
          <ng-select name="filter-year"
                     class="sm"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="criteria.year" (ngModelChange)="onYearFilterChanged($event)">
            <ng-option *ngFor="let year of yearOptions"
                       [value]="year">{{year}}</ng-option>
          </ng-select>
        </div>

        <div class="filter">
          <ng-select name="filter-month"
                     class="sm"
                     [searchable]="false"
                     [clearable]="false"
                     [(ngModel)]="criteria.month" (ngModelChange)="onMonthFilterChanged($event)">
            <ng-option *ngFor="let month of monthOptions"
                       [value]="month">{{'ENUM.MONTH.' + month | translate}}</ng-option>
          </ng-select>
        </div>
      </app-data-grid-filters>
    </app-data-grid>
  </div>
</section>

<!--Templates-->
<ng-template #valueTemplate let-value="value">
  <span>{{ value | replaceEmpty }}</span>
</ng-template>

