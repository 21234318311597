<section class="page">
  <div class="content">
    <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.EVENT_HISTORY.TITLE' | translate}}</div>

    <app-data-grid *ngIf="data" [data]="data.content"
                   [criteria]="criteria"
                   [context]="{ host: this }"
                   [selectionEnabled]="false"
                   [showFilters]="true"
                   [showSearch]="false"
                   [showPagination]="true"
                   [pageTotal]="data.totalPages" [itemTotal]="data.totalElements"
                   (criteriaChanged)="onCriteriaChanged($event)"
                   [columns]="$any([
                    { colId: '1', headerName: ('VIEW.MAIN.PATIENT.EVENT_HISTORY.DATA.DATE_TIME' | translate), maxWidth: 150, field: 'dateTime', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTimeTemplate, preventSelection: false } },
                    { colId: '2', headerName: ('VIEW.MAIN.PATIENT.EVENT_HISTORY.DATA.VISIT_TYPES' | translate), maxWidth: 150, field: 'visitTypes', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: visitTypesTemplate, preventSelection: false } },
                    { colId: '3', headerName: ('VIEW.MAIN.PATIENT.EVENT_HISTORY.DATA.STATUS' | translate), maxWidth: 135, field: 'status', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: statusTemplate, preventSelection: false } },
                    { colId: '4', headerName: ('VIEW.MAIN.PATIENT.EVENT_HISTORY.DATA.DURATION' | translate), minWidth: 150, flex: 1, field: 'duration', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: durationTemplate, preventSelection: false } },
                    { colId: '5', headerName: ('COMMON.ACTIONS' | translate), headerClass: 'header-right', cellClass: 'cell-right', sortable: false, minWidth: 150, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: menuTemplate, preventSelection: false } },
                  ])">

      <app-data-grid-filters>
        <div class="filter">
          <ng-select name="filter-status"
                     class="sm"
                     [placeholder]="'VIEW.MAIN.PATIENT.EVENT_HISTORY.FILTER.STATUS.PLACEHOLDER' | translate"
                     [searchable]="false"
                     [(ngModel)]="criteria.eventStatus" (ngModelChange)="onStatusFilterChanged($event)">
            <ng-option *ngFor="let status of eventStatuses"
                       [value]="status">{{ status | prefix:'ENUM.EVENT_STATUS.' | translate }}</ng-option>
          </ng-select>
        </div>
      </app-data-grid-filters>
    </app-data-grid>
  </div>
</section>

<!--Templates-->
<ng-template #statusTemplate let-value="value">
  <span class="status" [ngClass]="{
  'status-past': EventStatus.PAST === value,
  'status-upcoming': EventStatus.UPCOMING === value,
  'status-cancelled': EventStatus.CANCELLED === value}">{{value | prefix:'ENUM.EVENT_STATUS.' | translate}}</span>
</ng-template>

<ng-template #dateTimeTemplate let-value="value">
  <span>{{value | date:Constant.DATE_TIME_FORMAT}}</span>
</ng-template>

<ng-template #visitTypesTemplate let-value="value">
  <div class="visit-types">
    <ng-container *ngFor="let visitType of value">
      <div class="visit-type">{{visitType?.name}}</div>
    </ng-container>
  </div>
</ng-template>

<ng-template #durationTemplate let-value="value">
  <span>{{value | humanDuration:'seconds'}}</span>
</ng-template>

<ng-template #menuTemplate let-data>
  <button type="button"
          class="btn btn-link text-normal btn-link--black font-size-sm"
          (click)="goDetails(data)">{{'COMMON.SHOW_DETAILS' | translate}}</button>
</ng-template>

