import { Component, OnInit } from '@angular/core';
import { PageDTO } from '@dto/PageDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { PatientEventsHistoryPageCriteriaDTO } from '@dto/patient/PatientEventsHistoryPageCriteriaDTO';
import { PatientEventHistoryDTO } from '@dto/patient/PatientEventHistoryDTO';
import { Constant } from '@common/Constant';
import { EventStatus } from '@enum/event/EventStatus';
import { PatientModel } from '@model/PatientModel';
import { SortDirection } from '@enum/SortDirection';
import { PageUtil } from '@util/PageUtil';

@Component({
  selector: 'app-patient-history',
  templateUrl: './PatientHistoryComponent.html',
  styleUrls: [ './PatientHistoryComponent.scss' ]
})
export class PatientHistoryComponent implements OnInit {
  public data: PageDTO<PatientEventHistoryDTO>;

  public criteria: PatientEventsHistoryPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  public EventStatus: typeof EventStatus = EventStatus;

  public eventStatuses: EventStatus[] = Object.keys(EventStatus) as EventStatus[];

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private patientModel: PatientModel) {
    this.criteria = PageUtil.prepareCriteria<PatientEventsHistoryPageCriteriaDTO>(PatientEventsHistoryPageCriteriaDTO, transition.params()?.criteria, { sortOrders: [ `dateTime ${ SortDirection.DESC.toLowerCase() }` ] });
    this.applicationModel.selectSideBarItemWithState(State.MAIN.MY_PATIENT.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): Promise<void> {
    return this.patientModel.getEventsHistoryPage(this.criteria).toPromise()
      .then((result: PageDTO<PatientEventHistoryDTO>) => {
        this.data = result;
      });
  }

  public goDetails(data: PatientEventHistoryDTO): void {
    this.stateService.go(State.MAIN.EVENT.DETAILS, { eventId: data.eventId });
  }

  public onStatusFilterChanged(eventStatus: EventStatus): void {
    this.onCriteriaChanged({ ...this.criteria, eventStatus } as PageCriteriaDTO);
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<PatientEventsHistoryPageCriteriaDTO>(PatientEventsHistoryPageCriteriaDTO, this.criteria, changedCriteria as PatientEventsHistoryPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }
}
