<div class="container py-3">
  <section class="page">
    <div class="header-container separator align-items-center flex-row px-2 justify-content-between">
      <button type="button"
              class="back" (click)="close()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.TITLE' | translate: {number: conflicts?.length} }}</div>
      </button>

      <div class="actions mt-0">
        <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
          <i-tabler name="x"></i-tabler>
        </button>
      </div>
    </div>

    <div class="content">
      <ng-container *ngIf="conflicts">
        <app-scheduler-conflicts
          [(conflicts)]="conflicts"
          (conflictsChange)="conflictsChanged($event)"></app-scheduler-conflicts>
      </ng-container>
    </div>
  </section>
</div>
