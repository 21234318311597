import { AddressDTO } from '@dto/doctor/AddressDTO';
import { Gender } from '@enum/common/Gender';
import { SpecialityDTO } from 'app/data/dto/doctor/SpecialityDTO';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { DateUtil } from '@util/DateUtil';
import { TransformFnParams } from 'class-transformer/types/interfaces';
import { DoctorTitle } from '@enum/doctor/DoctorTitle';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { DoctorAvatarHelper } from 'app/data/helper/DoctorAvatarHelper';
import { DoctorSpecialitiesHelper } from 'app/data/helper/DoctorSpecialitiesHelper';
import { AvatarUrl } from '@local/interface/AvatarUrl';
import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';

export class DoctorDTO extends IdentifiableObjectDTO<number> implements AvatarUrl {
  public customerId?: number;

  public title?: DoctorTitle;

  public firstName: string;

  public lastName?: string;

  public gender?: Gender;

  @Exclude({ toPlainOnly: true })
  @Transform((params: TransformFnParams) => DoctorAvatarHelper.getUrl(params.value, params.obj?.gender))
  public avatarUrl?: string;

  public email?: string;

  public phoneNumber?: string;

  @Expose({ name: 'addressDto' })
  @Type(() => AddressDTO)
  @Transform((params: TransformFnParams) => params.value || new AddressDTO())
  public address?: AddressDTO;

  @Transform(DateUtil.dateTimeConversion)
  public dateApplied?: Date;

  @Transform(DateUtil.dateTimeConversion)
  public joinDate?: Date;

  @Expose({ name: 'specialities' })
  @Type(() => SpecialityDTO)
  @Transform(DoctorSpecialitiesHelper.transform)
  public speciality: SpecialityDTO;

  public bio?: string;

  public rating: number;

  public status: DoctorStatus;

  public businessOwner?: boolean;

  public ustId?: string;

  public billingName?: string;

  public bic?: string;

  @Transform((transformFn: TransformFnParams) => transformFn.value?.replace(/[^a-zA-Z0-9]+/g, '') ?? null)
  public iban?: string;

  public billingEmail?: string;

  public paymentAcceptation?: boolean;

  public resendConfirmationLinkAvailable?: boolean;

  public emailVerified?: boolean;

  @Expose({ toClassOnly: true })
  @Transform((params: TransformFnParams) => params.obj.avatarUrl ? true : false)
  public isAvatar: boolean;
}