import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { ViewUtil } from '@util/ViewUtil';
import { StateUtil } from '@util/StateUtil';
import { State } from '@common/State';
import { DoctorService } from '@service/DoctorService';
import { Transition } from '@uirouter/core';

@Component({
  selector: 'app-doctor-practice-edit',
  templateUrl: './DoctorPracticeEditComponent.html',
  styleUrls: [ './DoctorPracticeEditComponent.scss' ]
})
export class DoctorPracticeEditComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public practice: PracticeDTO;

  public id: number = this.transition.params().doctorId;

  constructor(private doctorService: DoctorService,
              private applicationModel: ApplicationModel,
              private viewUtil: ViewUtil,
              private transition: Transition,
              private stateUtil: StateUtil) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public ngOnInit(): void {
    this.doctorService.getPractice(this.id).toPromise()
      .then((practice: PracticeDTO) => {
        this.practice = practice;
      });
  }

  public save(): void {
    if (this.form.valid) {
      this.doctorService.updatePractice(this.id, this.practice).toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.UPDATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public back(): void {
    history.back();
  }
}
