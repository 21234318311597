import { Injectable, NgZone } from '@angular/core';
import { Ng2StateDeclaration, Transition, TransitionService, UrlService } from '@uirouter/angular';
import { StateService } from '@uirouter/core';
import { HttpErrorResponse } from '@angular/common/http';
import dedent from 'dedent';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { LocalMessage } from 'app/data/local/LocalMessage';
import { Event } from 'app/common/Event';
import { Token } from 'app/data/local/auth/Token';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { ViewUtil } from 'app/util/ViewUtil';
import * as _ from 'lodash';
import { State } from 'app/common/State';
import { HtmlUtil } from 'app/util/HtmlUtil';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { EventManager } from 'app/util/other/EventManager';
import { LocalMessageType } from 'app/data/local/LocalMessageType';
import { LayoutType } from 'app/common/Layout';
import { StateUtil } from 'app/util/StateUtil';
import { CurrentUserDTO } from '@dto/user/CurrentUserDTO';
import { UserType } from '@enum/user/UserType';
import { LocaleService } from '@service/LocaleService';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';

@Injectable({ providedIn: 'root' })
export class Application {

  // states that are available for not logged in user
  private publicStates: string[] = [
    State.PRELIMINARY.LOGIN,
    State.PRELIMINARY.STYLEGUIDE,
    State.PRELIMINARY.RESET_PASSWORD_START,
    State.PRELIMINARY.RESET_PASSWORD_COMPLETE,
    State.PRELIMINARY.REGISTER,
    State.PRELIMINARY.REGISTER_COMPLETE,
    State.PRELIMINARY.REGISTER_COMPLETE_UNSUCCESSFUL,
    State.PRELIMINARY.ERROR.NOT_FOUND,
    State.PRELIMINARY.ERROR.ACCESS_DENIED,
    State.RAW.ACCOUNT_MIGRATION.ACCOUNT_MIGRATION,
    State.RAW.ACCOUNT_MIGRATION.PROCESSING_DATA_CONSENT,
    State.RAW.GUEST_VIDEO_CALL.GUEST_VIDEO_CALL,
    State.RAW.EVENT.CANCEL
  ];

  // states that should redirect "into" main application, if user is logged in
  private transientStates: string[] = [
    State.PRELIMINARY.LOGIN
  ];

  // Angular application can get stable "too quick" for the first time, which the messes with the HMR code
  // (html input restoration doesn't work properly, because html inputs aren't created yet at that stage)
  // so we intentionally destabilize it by introducing a fake timeout,
  // that will be released as soon as the first initial navigation will complete successfully
  // This guarantees that html inputs will be there by that time and HMR restoration can proceed
  private angularApplicationRefStabilityTimeoutId: ReturnType<typeof setTimeout>;

  constructor(private eventManager: EventManager,
              private urlService: UrlService,
              private transitionService: TransitionService,
              private stateService: StateService,
              private localeService: LocaleService,
              private viewUtil: ViewUtil,
              private stateUtil: StateUtil,
              private zone: NgZone,
              private applicationModel: ApplicationModel,
              private authModel: AuthModel,
              private userModel: UserModel) {
    this.localeService.setLanguage(ApplicationConfig.defaultLanguage);

    this.angularApplicationRefStabilityTimeoutId = setTimeout(() => {
    }, 9999);

    this.configureTransitions();
    this.configureListeners();
    this.configureTranslations();
    this.configureInterface();
    this.introduce();
  }


  private configureTransitions(): void {

    this.transitionService.onBefore({}, (transition: Transition) => {
      const toState: Ng2StateDeclaration = transition.to();
      const toStateParams: { [paramName: string]: any } = transition.params('entering');

      if (!this.authModel.isLoggedIn && _.includes(this.transientStates, toState.name) && !this.applicationModel.goingToTransientState) {
        this.applicationModel.goingToTransientState = true;

        return this.authModel.recoverToken()
          .then((token: Token) => {
            this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
            return false;
          })
          .catch((error) => {
            if (this.applicationModel.resolvingUnauthorizedState) {
              // we might be resolving unauthorized, but still another transition could sneak in, so we need to hold it
              if (toState.name !== State.PRELIMINARY.LOGIN) {
                return false;
              } else {
                return true; // proceed with transition
              }
            } else {
              return this.stateService.target(toState.name, toStateParams);
            }
          });
      } else if (!this.authModel.isLoggedIn && !_.includes(this.publicStates, toState.name)) {
        this.applicationModel.stateBeforeLogin = new ApplicationState(toState, toStateParams);

        return this.authModel.recoverToken()
          .then((token: Token) => {
            //     this.authModel.token = token;
            this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
            return false;
          })
          .catch((error) => {
            if (this.applicationModel.resolvingUnauthorizedState) {
              // we might be resolving unauthorized, but still another transition could sneak in, so we need to hold it
              if (toState.name !== State.PRELIMINARY.LOGIN) {
                return false;
              } else {
                return true; // proceed with transition
              }
            } else {
              return this.stateService.target(State.PRELIMINARY.LOGIN);
            }
          });
      } else if (toState?.data && toState?.data?.type) {
        if (this.userModel?.currentUser?.type === toState.data.type) {
          return true;
        } else {
          return this.stateService.target(State.PRELIMINARY.ERROR.ACCESS_DENIED);
        }
      } else {
        return true;
      }
    });

    this.transitionService.onSuccess({}, (transition: Transition) => {
      const toState = transition.to();
      const toStateParams = transition.params('entering');
      const fromState = transition.from();
      const fromStateParams = transition.params('exiting');

      if (this.applicationModel.goingToPreviousState) {
        this.applicationModel.goingToPreviousState = false;
      } else {
        if (fromState.name && !_.includes(this.publicStates, fromState.name)) {
          this.applicationModel.stateHistory.push(new ApplicationState(fromState, fromStateParams));
        }
      }

      if (this.applicationModel.goingToTransientState) {
        this.applicationModel.goingToTransientState = false;
      }

      if (this.applicationModel.resolvingUnauthorizedState && (toState.name === State.PRELIMINARY.LOGIN)) {
        this.viewUtil.showToastError('ERROR.UNAUTHORIZED', false);
        this.applicationModel.resolvingUnauthorizedState = false;
      }

      this.applicationModel.currentState = new ApplicationState(toState, toStateParams);
      this.applicationModel.currentUrl = this.stateService.href(
        this.applicationModel.currentState.state,
        this.applicationModel.currentState.params,
        { absolute: true }
      );

      // run outside of zone, since it contains setTimeout, which would delay stability of ApplicationRef
      // (important for HMR among other things and no need for change detection here anyway)
      this.zone.runOutsideAngular(() => {
        HtmlUtil.scrollToTop(null, true);
      });

      const layout: LayoutType = toState.name.split('.')[0] as LayoutType;
      if (layout) {
        HtmlUtil.addLayoutClassToBody(layout);
      }

      if (this.angularApplicationRefStabilityTimeoutId) {
        setTimeout(() => {
          clearTimeout(this.angularApplicationRefStabilityTimeoutId);
          this.angularApplicationRefStabilityTimeoutId = null;
        });
      }
      return true;
    });
  }

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  private configureListeners(): void {
    this.eventManager.on(Event.AUTH.LOGIN.SUCCESS, () => {
      this.userModel.getCurrentUser()
        .then((user: CurrentUserDTO) => {
          if (user) {
            this.localeService.setLanguage(user?.locale || ApplicationConfig.defaultLanguage);

            if (![ UserType.ADMIN, UserType.DOCTOR ].includes(user.type)) {
              this.authModel.logout();
              this.viewUtil.showToastWarning('VIEW.PRELIMINARY.LOGIN.ACCOUNT_TYPE_FORBIDDEN');
            } else if ([ DoctorStatus.INCOMPLETE_REGISTRATION, DoctorStatus.NOT_ACCEPTED ].includes(user.status)) {
              this.stateUtil.goToState(State.MAIN.PROFILE.BASIC_DATA);
            } else if (this.applicationModel.goingToTransientState) {
              this.stateUtil.goToState(State.MAIN.DASHBOARD.DASHBOARD);
            } else if (this.applicationModel.stateBeforeLogin) {
              this.stateUtil.goToState(this.applicationModel.stateBeforeLogin.state.name, this.applicationModel.stateBeforeLogin.params);
              this.applicationModel.stateBeforeLogin = null;
            } else {
              this.stateUtil.goToState(State.MAIN.DASHBOARD.DASHBOARD);
            }

          } else {
            // login successful, but the user returned is empty (even with 200)
            // a corner case that should not ever happen, but we cover it for safety
            this.authModel.recoverToken()
              .then(() => {
                this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
                return false;
              })
              .catch((error) => {
                this.authModel.logout();
              });
          }
        })
        .catch((error) => {
          // login successful, but user retrieval not
          // again, a corner case that should not ever happen, but we cover it for safety
          this.authModel.recoverToken()
            .then((token: Token) => {
              this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS);
              return false;
            })
            .catch((error) => {
              this.authModel.logout();
            });
        });
    });

    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
    });

    this.eventManager.on(Event.AUTH.LOGOUT.ERROR, () => {
      // if we actually are resolving unauthorized state, it means we're going to PRELIMINARY.LOGIN anyway, no need to retrigger that
      if (!this.applicationModel.resolvingUnauthorizedState) {
        this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
      }
    });

    this.eventManager.on(Event.AUTH.ERROR.FORBIDDEN, () => {
      this.viewUtil.showToastError('ERROR.FORBIDDEN', false);
    });

    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, () => {
      if (!this.applicationModel.resolvingUnauthorizedState) {
        this.applicationModel.resolvingUnauthorizedState = true;

        if (this.applicationModel.currentState?.state.name !== State.PRELIMINARY.LOGIN) {
          // the rest will be handled on transition success
          this.stateUtil.goToState(State.PRELIMINARY.LOGIN);
        } else {
          this.viewUtil.showToastError('ERROR.UNAUTHORIZED', false);
          this.applicationModel.resolvingUnauthorizedState = false;
        }
      }
    });

    this.eventManager.on(Event.SYSTEM.GENERAL_ERROR, (error) => {
      if (this.applicationModel.devMode) {
        console.error('--- ERROR ---');

        if (_.isObject(error)) {
          console.error(JSON.stringify(error));
        } else if (typeof error === 'string') {
          console.error(error);
        } else {
          console.error(error.toString());
        }
      }

      let errorText: string;

      if (error instanceof HttpErrorResponse) {
        if (error.message) {
          errorText = error.message;
          this.viewUtil.showToastError(errorText, false);
        } else {
          this.viewUtil.translate([ 'ERROR.SERVER', 'COMMON.DETAILS' ])
            .then((translations: string | any) => {

              if (error.status && error.statusText && error.url) {
                errorText = translations['ERROR.SERVER'] + ' ' + translations['COMMON.DETAILS'] + ': ' + error.status + ' ' + error.statusText + ' - ' + error.url;
              } else if (error.url) {
                errorText = translations['ERROR.SERVER'] + ' URL: ' + error.url;
              } else {
                errorText = translations['ERROR.SERVER'];
              }

              this.viewUtil.showToastError(errorText, false);
            });
        }
      } else if (error instanceof LocalMessage) {
        errorText = `${ error.message } (${ error.details })`;
      } else {
        let errorString: string;
        let errorTranslateAttempt: boolean = false;

        if (_.isObject(error)) {
          errorString = JSON.stringify(error).replace(/({|:|,)/g, '$1 ').replace(/}/g, ' }'); // json with some whitespacing format
        } else if (typeof error === 'string') {
          errorString = error;
          errorTranslateAttempt = true;
        } else {
          errorString = error.toString();
        }

        this.viewUtil.translate(errorTranslateAttempt ? [ 'ERROR.SOMETHING_WENT_WRONG', 'COMMON.DETAILS', errorString ] : [ 'ERROR.SOMETHING_WENT_WRONG', 'COMMON.DETAILS' ])
          .then((translations: string | any) => {
            errorText = translations['ERROR.SOMETHING_WENT_WRONG'] + ' ' + translations['COMMON.DETAILS'] + ': ' + translations[errorString];

            this.viewUtil.showToastError(errorText, false);
          });
      }
    });

    this.eventManager.on(Event.SYSTEM.GENERAL_MESSAGE, (message) => {
      if (message instanceof LocalMessage) {
        let messageText: string;
        let messageTitle: string;

        if (message.message) {
          messageText = message.message;

          if (message.details) {
            messageText += '(' + message.details + ')';
          }
        }

        if (message.title) {
          messageTitle = message.title;
        }

        if (messageText) {
          if (message.type === LocalMessageType.INFO) {
            this.viewUtil.showToastInfo(messageText, false);

            if (this.applicationModel.devMode) {
              console.info(messageText);
            }
          } else if (message.type === LocalMessageType.WARNING) {
            this.viewUtil.showToastWarning(messageText, false);

            if (this.applicationModel.devMode) {
              console.warn(messageText);
            }
          } else if (message.type === LocalMessageType.ERROR) {
            this.viewUtil.showToastError(messageText, false);

            if (this.applicationModel.devMode) {
              console.error(messageText);
            }
          } else if (message.type === LocalMessageType.SUCCESS) {
            this.viewUtil.showToastSuccess(messageText, false);

            if (this.applicationModel.devMode) {
              console.info(messageText);
            }
          }
        }
      }
    });
  }

  private configureTranslations(): void {
  }

  private configureInterface(): void {
    // run outside of zone, since it is setTimeout, which would delay stability of ApplicationRef
    // (important for HMR among other things and no need for change detection here anyway)
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        document.documentElement.style.setProperty('--scrollbar-width', `${ HtmlUtil.getScrollbarWidth() }px`);
      }, 500);
    });
  }

  private introduce(): void {
    if (this.applicationModel.devMode) {
      console.info(dedent`
          ---------------------------------------------------------------------------
          Application "${ ApplicationConfig.applicationOwner } ${ ApplicationConfig.applicationName }" initialized.
          ---------------------------------------------------------------------------
           UI Version: ${ ApplicationConfig.version }
                  API: ${ ApplicationConfig.apiUrl }
          ---------------------------------------------------------------------------`);
    }
  }

}