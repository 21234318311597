<ng-container *ngIf="data$ | async as data">
  <div class="container px-0">
    <div class="font-weight-bold mb-4">{{'VIEW.MAIN.DOCTOR.QUALIFICATIONS.QUALIFICATIONS' | translate }}</div>

    <ng-container *ngFor="let qualification of data?.qualifications">
      <div class="mb-1">{{qualification?.name | replaceEmpty}}</div>
    </ng-container>

  </div>
</ng-container>

<!--Portal-->
<app-page-action-portal name="doctor">
  <button class="btn btn-primary" type="button" (click)="edit()"
          *ngIf="!doctorModel.needVerification">{{'COMMON.EDIT' | translate}}</button>
</app-page-action-portal>