import { Component, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { NgForm } from '@angular/forms';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { UserRegistrationDTO } from 'app/data/dto/user/UserRegistrationDTO';
import { Constant } from 'app/common/Constant';
import { UserRegistrationService } from '@service/UserRegistrationService';

@Component({
  selector: 'app-registration',
  templateUrl: 'RegistrationComponent.html',
  styleUrls: [ 'RegistrationComponent.scss' ]
})
export class RegistrationComponent {

  @ViewChild('registrationForm')
  private registrationForm: NgForm;

  public userRegistration: UserRegistrationDTO = new UserRegistrationDTO();

  public formData: {
    passwordRepeat: string;
  } = {
    passwordRepeat: null
  };

  public Constant: typeof Constant = Constant;

  public termsAndConditions: boolean = false;

  public privacyPolicy: boolean = false;

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              public userRegistrationService: UserRegistrationService) {
  }

  public register(): void {
    if (this.registrationForm.form.valid) {
      this.registerRequest(this.userRegistration)
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.REGISTRATION.MESSAGE.REGISTRATION_START_SUCCESS');
          this.stateService.go(State.PRELIMINARY.LOGIN);
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    }
  }

  private registerRequest(userRegistration: UserRegistrationDTO): Promise<UserRegistrationDTO> {
    return this.userRegistrationService.register(userRegistration).toPromise()
      .then((registeredUser: UserRegistrationDTO) => {
        return registeredUser;
      });
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
