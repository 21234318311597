import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { State } from 'app/common/State';
import { UIRouter } from '@uirouter/angular';

@Injectable({ providedIn: 'root' })
export class StateUtil {

  constructor(private applicationModel: ApplicationModel,
              private router: UIRouter,
              private stateService: StateService) {
  }

  public goBack(): void {
    const previousState = this.applicationModel.stateHistory.pop();

    if (previousState) {
      this.applicationModel.goingToPreviousState = true;
      this.goToState(previousState.state.name, previousState.params);
    } else {
      this.applicationModel.goingToPreviousState = true;
      this.goToHome();
    }
  }

  public reloadApplication(): void {
    window.location.reload();
  }

  public goToUrl(url: string, newWindow: boolean = false): void {
    window.open(url, newWindow ? '_blank' : '_self');
  }

  public goToState(state: string, stateParameters: { [key: string]: any } = undefined, forceSynchronousTransition: boolean = true): void {
    if (forceSynchronousTransition) {
      // if transition is currently in progress, don't interrupt and check again in 100ms
      if (this.router?.globals?.transition) {
        setTimeout(this.goToState, 100, state, stateParameters, forceSynchronousTransition);
      } else {
        this.stateService.go(state, stateParameters);
      }
    } else {
      this.stateService.go(state, stateParameters);
    }
  }

  public goToHome(): void {
    this.goToState(State.MAIN.DASHBOARD.DASHBOARD);
  }

  public goToPageNotFound(): void {
    this.goToState(State.PRELIMINARY.ERROR.NOT_FOUND);
  }

  public goToPageAccessDenied(): void {
    this.goToState(State.PRELIMINARY.ERROR.ACCESS_DENIED);
  }

}
