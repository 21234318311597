import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ITab } from 'app/component/ui/tab/interface/ITab';
import { State } from '@common/State';
import { Transition } from '@uirouter/angular';
import { ApplicationModel } from '@model/ApplicationModel';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { DoctorModel } from '@model/DoctorModel';
import { tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { PopupConfirmationWithAnswerComponent } from 'app/component/ui/popup/PopupConfirmationWithAnswerComponent';

@Component({
  selector: 'app-doctor-details',
  templateUrl: './DoctorDetailsComponent.html',
  styleUrls: [ './DoctorDetailsComponent.scss' ],
  providers: [ DoctorModel ]
})
export class DoctorDetailsComponent implements OnInit {
  public tabs: ITab[];

  public doctor$: Observable<DoctorDTO> = this.doctorModel.doctor$
    .pipe(
      tap(() => {
        if (this.doctorModel.needVerification) {
          this.applicationModel.selectSideBarItemWithState(State.MAIN.DASHBOARD.DASHBOARD);
        } else {
          this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
        }

        this.setTab();
      })
    );

  constructor(public doctorModel: DoctorModel,
              private transition: Transition,
              private modalService: BsModalService,
              private applicationModel: ApplicationModel) {
  }

  public ngOnInit(): void {
  }

  public resendConfirmationLink(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.ACTION.RESEND_CONFIRMATION_LINK.TITLE',
        okText: 'COMMON.CONFIRM'
      }
    }).content.close$.toPromise()
      .then(() => this.doctorModel.resendVerificationEmail());
  }

  public accept(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.ACTION.ACCEPT.TITLE',
        message: 'VIEW.MAIN.DOCTOR.ACTION.ACCEPT.DESCRIPTION',
        okText: 'COMMON.ACCEPT'
      }
    }).content.close$.toPromise()
      .then(() => this.doctorModel.accept());
  }

  public decline(): void {
    this.modalService.show(PopupConfirmationWithAnswerComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.ACTION.DECLINE.TITLE',
        message: 'VIEW.MAIN.DOCTOR.ACTION.DECLINE.DESCRIPTION',
        okText: 'COMMON.DECLINE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then((answer: string) => this.doctorModel.decline(answer));
  }

  public back(): void {
    history.back();
  }

  private setTab(): void {
    this.tabs = [
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.BASIC_DATA',
        state: State.MAIN.DOCTOR.DETAILS.BASIC_DATA
      },
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.CATEGORIES',
        state: State.MAIN.DOCTOR.DETAILS.CATEGORY,
        hidden: this.doctorModel.needVerification
      },
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.ADDITIONAL_QUALIFICATIONS',
        state: State.MAIN.DOCTOR.DETAILS.QUALIFICATION
      },
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.LICENSE',
        state: State.MAIN.DOCTOR.DETAILS.LICENSE
      },
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.PRACTICE',
        state: State.MAIN.DOCTOR.DETAILS.PRACTICE
      },
      {
        title: 'VIEW.MAIN.DOCTOR.TAB.SCHEDULER',
        state: State.MAIN.DOCTOR.DETAILS.SCHEDULER,
        hidden: this.doctorModel.needVerification
      }
    ];
  }
}
