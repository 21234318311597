import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { Gender } from '@enum/common/Gender';
import { DoctorTitle } from '@enum/doctor/DoctorTitle';
import { StateUtil } from '@util/StateUtil';
import { ApplicationModel } from '@model/ApplicationModel';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewUtil } from '@util/ViewUtil';
import { State } from '@common/State';
import { Constant } from '@common/Constant';
import { DoctorService } from '@service/DoctorService';
import { Transition } from '@uirouter/core';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { Observable } from 'rxjs';
import { SpecialityDTO } from '@dto/doctor/SpecialityDTO';
import { DictionaryModel } from '@model/DictionaryModel';
import { DoctorAvatarHelper } from 'app/data/helper/DoctorAvatarHelper';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { FileUtil } from '@util/FileUtil';

@Component({
  selector: 'app-doctor-basic-data-edit',
  templateUrl: './DoctorBasicDataEditComponent.html',
  styleUrls: [ './DoctorBasicDataEditComponent.scss' ]
})
export class DoctorBasicDataEditComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public id: number = this.transition.params().doctorId;

  public doctor: DoctorDTO;

  public Constant: typeof Constant = Constant;

  public genderOptions: string[] = Object.keys(Gender);

  public statusOptions: string[] = Object.keys(DoctorStatus).filter(s => s !== DoctorStatus.NOT_ACCEPTED);

  public titleOptions: string[] = Object.keys(DoctorTitle);

  public specialities$: Observable<SpecialityDTO[]> = this.dictionaryModel.specialities$;

  public disabledSubmit: boolean = false;

  constructor(private stateUtil: StateUtil,
              private doctorService: DoctorService,
              private transition: Transition,
              private applicationModel: ApplicationModel,
              private fileUtil: FileUtil,
              private dictionaryModel: DictionaryModel,
              private modalService: BsModalService,
              private viewUtil: ViewUtil) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public ngOnInit(): void {
    this.doctorService.getById(this.id)
      .subscribe((doctor: DoctorDTO) => {
          this.doctor = doctor;
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  public save(): void {
    if (this.form.valid) {
      this.disabledSubmit = true;
      this.doctorService.update(this.id, this.doctor)
        .toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.UPDATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        })
        .finally(() => {
          this.disabledSubmit = false;
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }


  public deleteAvatar(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.DELETE_AVATAR.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then(() => {
        this.doctorService.deleteAvatar(this.id)
          .toPromise()
          .then(() => {
            this.doctor.isAvatar = false;
            this.doctor.avatarUrl = DoctorAvatarHelper.getUrl(null, this.doctor?.gender);
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.DELETE_AVATAR.SUCCESS');
          })
          .catch((error) => {
            this.viewUtil.handleServerError(error);
          });
      });
  }

  public uploadAvatar(avatar: Blob): void {
    const file = this.fileUtil.blobToFile(avatar, 'avatar.jpg');

    this.doctorService.uploadAvatar(this.id, avatar).toPromise()
      .then(() => {
        const url = URL.createObjectURL(file);

        this.doctor.avatarUrl = url;
        this.doctor.isAvatar = true;
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.UPLOAD_AVATAR_SUCCESS');
      })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public back(): void {
    history.back();
  }
}
