import { Component, OnInit } from '@angular/core';
import { PageDTO } from '@dto/PageDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { Constant } from '@common/Constant';
import { EventPageEntityDTO } from '@dto/events/EventPageEntityDTO';
import { EventPageCriteriaDTO } from '@dto/events/EventPageCriteriaDTO';
import { EventStatus } from '@enum/event/EventStatus';
import { InsuranceType } from '@enum/doctor/InsuranceType';
import { EventService } from '@service/EventService';
import { PageUtil } from '@util/PageUtil';
import { ViewUtil } from '@util/ViewUtil';
import { SortDirection } from '@enum/SortDirection';
import { PatientInsuranceType } from '@enum/patient/PatientInsuranceType';
import { EventExportCriteriaDTO } from '@dto/events/EventExportCriteriaDTO';
import { FileUtil } from '@util/FileUtil';

@Component({
  selector: 'app-event-list',
  templateUrl: './EventListComponent.html'
})
export class EventListComponent implements OnInit {
  public data: PageDTO<EventPageEntityDTO>;

  public criteria: EventPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  public EventStatus: typeof EventStatus = EventStatus;

  public patientInsuranceTypes: PatientInsuranceType[] = Object.keys(PatientInsuranceType) as PatientInsuranceType[];

  public statuses: string[] = [ EventStatus.UPCOMING, EventStatus.PAST, EventStatus.CANCELLED, 'NO_SHOW' ];

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private fileUtil: FileUtil,
              private viewUtil: ViewUtil,
              private eventService: EventService) {
    this.criteria = PageUtil.prepareCriteria<EventPageCriteriaDTO>(EventPageCriteriaDTO, transition.params()?.criteria, { sortOrders: [ `dateTimeFrom ${ SortDirection.DESC.toLowerCase() }` ] });
    this.applicationModel.selectSideBarItemWithState(State.MAIN.EVENT.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): Promise<void> {
    return this.eventService.getPage(this.criteria).toPromise()
      .then((result: PageDTO<EventPageEntityDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public onInsuranceTypeFilterChanged(insuranceType: InsuranceType): void {
    this.onCriteriaChanged({ ...this.criteria, insuranceType } as PageCriteriaDTO);
  }

  public onStatusFilterChanged(status: EventStatus | string): void {
    this.onCriteriaChanged({ ...this.criteria, status } as PageCriteriaDTO);
  }

  public export(): void {
    const exportCriteria = new EventExportCriteriaDTO();

    this.eventService.getExcel(exportCriteria).toPromise()
      .then((file: File) => this.fileUtil.saveFile(file))
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<EventPageCriteriaDTO>(EventPageCriteriaDTO, this.criteria, changedCriteria as EventPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }
}
