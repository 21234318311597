import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export const fileMaxSizeValidator = (value: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    const size: number = control.value ? (control.value as File).size : 0;
    return size > value ? { fileMaxSize: { requiredSize: value, actualSize: size } } : null;
  };
};

@Directive({
  selector: '[appFileMaxSize][formControlName],[appFileMaxSize][formControl],[appFileMaxSize][ngModel]',
  providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: FileMaxSizeValidator,
        multi: true
      }
    ]
})
export class FileMaxSizeValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appFileMaxSize: number;

  private validator: ValidatorFn;

  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = fileMaxSizeValidator(this.appFileMaxSize);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appFileMaxSize') {
        this.validator = fileMaxSizeValidator(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appFileMaxSize !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}

