<div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.PATIENT_UPLOADS.TITLE' | translate}}</div>

<ng-container *ngIf="files$ | async as files">
  <div class="row">
    <div class="col-12 col-sm-8">
      <ng-container *ngFor="let fileUrl of files">
        <div class="image-container">
          <a [href]="fileUrl" target="_blank">
            <img [src]="fileUrl"/>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>