import { Component, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { NgForm } from '@angular/forms';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { Constant } from 'app/common/Constant';
import { PasswordRequirementParams } from 'app/util/validator/PasswordRequirementsValidator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupAlertComponent } from 'app/component/ui/popup/PopupAlertComponent';
import { LocaleService } from '@service/LocaleService';

@Component({
  selector: 'app-reset-password-complete',
  templateUrl: 'ResetPasswordCompleteComponent.html',
  styleUrls: [ 'ResetPasswordCompleteComponent.scss' ]
})
export class ResetPasswordCompleteComponent {
  @ViewChild('form')
  private resetPasswordForm: NgForm;

  public passwordValidatorParams: PasswordRequirementParams = {
    upperCase: true,
    lowerCase: false,
    numberChars: true,
    specialChars: true,
    minLength: Constant.PASSWORD_MIN_LENGTH
  };

  public formData: {
    email: string;
    password: string;
    passwordRepeat: string;
    token: string;
  } = {
    email: null,
    password: null,
    passwordRepeat: null,
    token: null
  };

  public Constant: typeof Constant = Constant;

  constructor(private stateService: StateService,
              private transition: Transition,
              private modalService: BsModalService,
              private localeService: LocaleService,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
    const language = transition.params()?.lang;
    this.localeService.setLanguage(language);

    this.formData.email = transition.params().email ? decodeURI(transition.params().email) : null;
    this.formData.token = transition.params().token;
  }

  public submit(): void {
    if (this.resetPasswordForm.valid) {
      this.authModel
        .completePasswordReset(this.formData.email, this.formData.token, this.formData.password)
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_COMPLETE_SUCCESS');
          const modal: BsModalRef = this.modalService.show(PopupAlertComponent, {
            initialState: {
              title: 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_MODAL_TITLE',
              message: 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_MODAL_MESSAGE',
              okText: 'COMMON.OK'
            }
          });

          modal.content.close$
            .subscribe(() => {
              this.backToLogin();
            });
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    }
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
