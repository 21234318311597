import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { EventSlot } from '@local/scheduler/EventSlot';

@Component({
  selector: 'app-scheduler-slots-grid',
  templateUrl: 'SchedulerSlotsGridComponent.html',
  styleUrls: [ 'SchedulerSlotsGridComponent.scss' ]
})
export class SchedulerSlotsGridComponent implements OnInit {
  @Input() public data: EventSlot[] = [];

  @Input() public slotTemplate: TemplateRef<any>;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
