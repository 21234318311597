import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';

export class PageUtil {
  public static updateURL(criteria: any): void {
    history.replaceState(null, null, `${ window.location.origin }${ window.location.pathname }?criteria=${ btoa(JSON.stringify(criteria)) }`);
  }

  public static prepareCriteria<T>(type: new() => T, criteria: string, defaultCriteria: any = {}): T {
    const parsedRawCriteria = criteria ? JSON.parse(atob(criteria)) : defaultCriteria;
    return Object.assign(new type(), parsedRawCriteria);
  }

  public static onCriteriaChanged<T extends PageCriteriaDTO>(type: new() => T, currentCriteria: T, changedCriteria: T): T {
    changedCriteria.pageNumber = currentCriteria.pageNumber === changedCriteria.pageNumber ? 0 : changedCriteria.pageNumber;
    const newCriteria = Object.assign(new type(), changedCriteria);

    newCriteria.pageNumber = currentCriteria.pageNumber === changedCriteria.pageNumber ? 0 : changedCriteria.pageNumber;

    return newCriteria;
  }
}