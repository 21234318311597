import { Component, OnInit } from '@angular/core';
import { PatientModel } from '@model/PatientModel';
import { Observable } from 'rxjs';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { ViewUtil } from '@util/ViewUtil';
import { Constant } from '@common/Constant';
import { PatientStatus } from '@enum/patient/PatientStatus';

@Component({
  selector: 'app-patient-basic-data',
  templateUrl: './PatientBasicDataComponent.html',
  styleUrls: [ './PatientBasicDataComponent.scss' ]
})
export class PatientBasicDataComponent implements OnInit {
  public isEditing: boolean = false;

  public patient$: Observable<PatientDTO> = this.patientModel.patient$;

  public Constant: typeof Constant = Constant;

  public PatientStatus: typeof PatientStatus = PatientStatus;

  constructor(private patientModel: PatientModel,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
  }

  public save(patient: PatientDTO): void {
    this.patientModel.update(patient)
      .subscribe(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.PATIENT.MESSAGE.UPDATE_EXTERNAL_ID_NUMBER_SUCCESS');
        this.isEditing = false;
      }, (err) => {
        this.viewUtil.handleServerError(err);
      });
  }

}
