<div class="video-call h-100">
  <div class="h-100" [hidden]="videoCallStorage?.roomDetails">
    <div class="d-flex flex-column justify-content-center m-2 h-100">
      <app-video-call-waiting-room></app-video-call-waiting-room>
    </div>
  </div>

  <ng-container *ngIf="videoCallStorage?.roomDetails">
    <app-video-call-room></app-video-call-room>
  </ng-container>
</div>