<div class="modal-header">
  <h4 class="modal-title">{{ 'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.WORKING_HOURS' | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
      <i-tabler name="x"></i-tabler>
    </button>
  </div>
</div>

<div class="modal-body">
  <div class="weeks">
    <ng-container *ngFor="let week of data">
      <div class="week">
        <div class="week-wrapper">
          <div class="name">{{week.dayOfWeek | prefix: 'ENUM.DAY_OF_WEEK.' | translate}}</div>
          <div class="slot">
            <div class="hours-container">
              <div class="hour" *ngFor="let hour of week.hours">{{hour.timeFrom | toDate | date: 'HH:mm'}}
                - {{hour.timeTo | toDate | date: 'HH:mm'}}</div>
            </div>
            <div
              class="not-available">{{'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.NOT_AVAILABLE' | translate}}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="cancel()">{{ 'COMMON.CLOSE' | translate }}</button>
</div>
