import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { Transition } from '@uirouter/core';
import { SickLeaveDTO } from '@dto/sickLeave/SickLeaveDTO';
import { EventSickLeaveService } from '@service/event/EventSickLeaveService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { Constant } from '@common/Constant';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sick-leaves',
  templateUrl: './EventSickLeavesComponent.html',
  styleUrls: [ './EventSickLeavesComponent.scss' ]
})
export class EventSickLeavesComponent implements OnInit {
  @ViewChild('form') private form: NgForm;

  public isProcessing: boolean = false;

  public Constant: typeof Constant = Constant;

  private eventId: number = this.transition.params().eventId;

  public data: SickLeaveDTO = new SickLeaveDTO();

  public list: SickLeaveDTO[] = [];

  constructor(private transition: Transition,
              private modalService: BsModalService,
              private eventSickLeaveService: EventSickLeaveService,
              public viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
    this.getList();
  }

  public add(): void {
    this.isProcessing = true;

    this.eventSickLeaveService.create(this.eventId, this.data).toPromise()
      .then((createdSickLeave: SickLeaveDTO) => {
        this.list.push(createdSickLeave);
        this.viewUtil.showToastSuccess('VIEW.VIDEO_CALL.SICK_LEAVES.MESSAGE.CREATED_SUCCESS');
        this.form.resetForm();
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  public remove(sickLeave: SickLeaveDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.VIDEO_CALL.SICK_LEAVES.ACTION.DELETE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => {
      this.eventSickLeaveService.remove(this.eventId, sickLeave.id).toPromise()
        .then(() => {
          this.list = this.list.filter(s => s.id !== sickLeave.id);
          this.viewUtil.showToastSuccess('VIEW.VIDEO_CALL.SICK_LEAVES.MESSAGE.DELETED_SUCCESS');
        })
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    });
  }

  private getList(): void {
    this.eventSickLeaveService.getList(this.eventId).toPromise()
      .then((sickLeaves: SickLeaveDTO[]) => {
        this.list = sickLeaves;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }
}
