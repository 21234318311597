import { Expose, Transform } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';

export class PatientAddressDTO {
  public placeId: string;

  public city: string;

  public cityCode: string;

  public street: string;

  public buildingNumber: string;

  public flatNumber: string;

  @Expose({ toClassOnly: true })
  @Transform((params: TransformFnParams) => PatientAddressDTO.FullStreetNameTransform(params.obj))
  public fullStreetName: string;

  public static FullStreetNameTransform(obj: PatientAddressDTO): string {
    let fullStreetName = obj?.street?.trim();

    if (obj.buildingNumber) {
      fullStreetName += ' ' + obj.buildingNumber;

      if (obj.flatNumber) {
        fullStreetName += '/' + obj.flatNumber;
      }
    }

    return fullStreetName;
  }
}

