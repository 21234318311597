import { HttpContextToken } from '@angular/common/http';

export class Constant {

  public static readonly HTTP_TIMEZONE_HEADER: string = 'X-Timezone';

  public static readonly HTTP_UTC_OFFSET_HEADER: string = 'X-UTC-Offset';

  public static readonly HTTP_APP_LANGUAGE_HEADER: string = 'X-AppLanguage';

  public static readonly HTTP_APP_ACCEPT_LANGUAGE_HEADER: string = 'Accept-Language';

  public static readonly HTTP_APP_TYPE_HEADER: string = 'X-AppType';

  public static readonly HTTP_APP_VERSION_HEADER: string = 'X-AppVersion';

  public static readonly HTTP_CONTENT_DISPOSITION_HEADER: string = 'Content-Disposition';

  public static readonly HTTP_SILENT_MODE: string = 'X-Silent-Mode';

  public static readonly HTTP_LOADING_MODE: string = 'X-Loading-Mode';

  public static readonly HTTP_IGNORE_ERROR: string = 'X-Ignore-Error';

  public static readonly HTTP_SUPPRESS_ERRORS_TOKEN = new HttpContextToken<boolean>(() => false);

  public static readonly HTTP_SUPPRESS_LOADING_TOKEN = new HttpContextToken<boolean>(() => false);

  public static readonly PASSWORD_MIN_LENGTH: number = 12;

  public static readonly PASSWORD_PATTERN: RegExp = new RegExp(`^(?=.*?[A-ZÄÖÜß])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_+]).{${Constant.PASSWORD_MIN_LENGTH},}$`);

  public static readonly TEXT_MIN_LENGTH: number = 3;

  public static readonly TEXT_MAX_LENGTH: number = 255;

  public static readonly TEXT_MAX_LENGTH_LG: number = 500;

  public static readonly TEXT_MAX_LENGTH_XL: number = 4000;

  public static readonly MAX_MEDICATION_NAME_LENGTH: number = 80;

  public static readonly MAX_GRAMMAGE_LENGTH: number = 80;

  public static readonly MAX_DOZE_LENGTH: number = 255;

  public static readonly DATE_FORMAT: string = 'dd.MM.YYYY';

  public static readonly DATE_TIME_FORMAT: string = 'dd.MM.YYYY, HH:mm';

  public static readonly TIME_FORMAT: string = 'HH:mm';

  public static readonly PHONE_PATTERN: RegExp = /^[+]([ ]*?[0-9]){11}[ ]*?$/;

  public static readonly POSTAL_CODE_PATTERN: RegExp = /^(\s*\d){5}\s*$/;

  public static readonly UST_ID_PATTERN: RegExp = /^(\s*[a-zA-Z]){2}(\s*\d){9}\s*$/;

  public static readonly BIC_PATTERN: RegExp = /^(\s*[a-zA-Z]){8}((\s*[a-zA-Z]){3})?\s*$/;

  public static readonly BAN_PATTERN: RegExp = /^(\s*\d){9}\s*$/;

  public static readonly NUMBER_PATTERN: RegExp = /^\d*$/;

  public static readonly LANR_NUMBER_PATTERN: RegExp = /^(\s*\d){9}\s*$/;

  public static readonly DOCTOR_OFFICE_NUMBER_PATTERN: RegExp = /^(\d){9}$/;

  public static readonly STRING_NO_NUMBER_NO_SPECIAL_CHARACTERS: RegExp = /^[_A-zÀ-ž\s-]*$/;

  public static readonly STRING_NO_SPECIAL_CHARACTERS: RegExp = /^[_A-zÀ-ž0-9]*((-|\s)*[_A-zÀ-ž0-9])*$/;
}