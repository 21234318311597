import { Component, OnInit } from '@angular/core';
import { UserModel } from '@model/UserModel';
import { StateService } from '@uirouter/core';
import { State } from '@common/State';
import { UserType } from '@enum/user/UserType';

@Component({
  selector: 'app-dashboard',
  template: '<ui-view></ui-view>'
})
export class DashboardComponent implements OnInit {

  constructor(private stateService: StateService,
              private userModel: UserModel) {
    if (this.stateService.current.name === State.MAIN.DASHBOARD.DASHBOARD) {
      switch (this.userModel.currentUser.type) {
        case UserType.ADMIN:
          this.stateService.go(State.MAIN.DASHBOARD.NEW_DOCTORS, null, { location: 'replace' });
          break;
        case UserType.DOCTOR:
          this.stateService.go(State.MAIN.DASHBOARD.MY_SCHEDULER, null, { location: 'replace' });
          break;
      }
    }
  }

  public ngOnInit(): void {
  }
}
