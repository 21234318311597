<div id="register-container" class="d-flex align-items-center justify-content-center min-height-100-dvh">
  <div id="register">
    <form novalidate>

      <img class="logo d-block img-fluid mx-auto mb-5 mt-3" src="asset/image/logo.png">

      <div class="text-center mb-4 font-weight-bold title">{{
        'VIEW.PRELIMINARY.REGISTRATION.MESSAGE.REGISTRATION_COMPLETE' | translate | uppercase }}</div>

      <div class="d-flex justify-content-center">
        <button type="button" (click)="backToLogin()" class="btn btn-custom-1">{{ 'VIEW.PRELIMINARY.REGISTRATION.LOG_IN' |
          translate |
          uppercase
          }}</button>
      </div>

    </form>
  </div>
</div>
