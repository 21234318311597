import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationModel } from '@model/ApplicationModel';
import { LanguageVersion } from '@local/LanguageVersion';
import { State } from '@common/State';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { DoctorSettingsDTO } from '@dto/doctor/DoctorSettingsDTO';
import { NgForm } from '@angular/forms';
import { ViewUtil } from '@util/ViewUtil';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { LocaleService } from '@service/LocaleService';

@Component({
  selector: 'app-doctor-settings',
  templateUrl: './DoctorSettingsComponent.html',
  styleUrls: [ './DoctorSettingsComponent.scss' ]
})
export class DoctorSettingsComponent implements OnInit {
  @ViewChild('form') private form: NgForm;

  public settings: DoctorSettingsDTO;

  public languageOptions: string[] = Object.keys(LanguageVersion);

  constructor(private modalService: BsModalService,
              private currentDoctorService: CurrentDoctorService,
              private viewUtil: ViewUtil,
              private localeService: LocaleService,
              private applicationModel: ApplicationModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.SETTINGS.SETTINGS);
  }

  public ngOnInit(): void {
    this.currentDoctorService.getSettings().toPromise()
      .then((settings: DoctorSettingsDTO) => {
        this.settings = settings;
      });
  }

  public onSmsNotificationChanged(event: any): void {
    this.settings.smsNotificationEnabled = !this.settings.smsNotificationEnabled;

    if (!this.settings.smsNotificationEnabled) {
      this.modalService.show(PopupConfirmationComponent, {
        initialState: {
          title: 'VIEW.MAIN.SETTINGS.MESSAGE.CHANGE_SMS_NOTIFICATION',
          okText: 'COMMON.CONFIRM'
        }
      }).content.close$.toPromise()
        .catch(() => {
          this.settings.smsNotificationEnabled = true;
        });
    }
  }

  public onEmailNotificationChanged(event: any): void {
    this.settings.emailNotificationEnabled = !this.settings.emailNotificationEnabled;

    if (!this.settings.emailNotificationEnabled) {
      this.modalService.show(PopupConfirmationComponent, {
        initialState: {
          title: 'VIEW.MAIN.SETTINGS.MESSAGE.CHANGE_EMAIL_NOTIFICATION',
          okText: 'COMMON.CONFIRM'
        }
      }).content.close$.toPromise()
        .catch(() => {
          this.settings.emailNotificationEnabled = true;
        });
    }
  }

  public save(): void {
    if (this.form.valid) {
      this.currentDoctorService.updateSettings(this.settings).toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.SETTINGS.MESSAGE.SAVED_SUCCESS');
          this.localeService.setLanguage(this.settings.locale);
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    }
  }
}
