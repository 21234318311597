import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from 'app/common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';
import { UserRegistrationService } from '@service/UserRegistrationService';
import { UserRegistrationStatusDTO } from '@dto/user/UserRegistrationStatusDTO';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { ViewUtil } from '@util/ViewUtil';
import { DoctorTabName } from '@enum/doctor/DoctorTabName';
import { switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserModel } from '@model/UserModel';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';

@Component({
  selector: 'app-profile',
  templateUrl: './ProfileComponent.html',
  styleUrls: [ './ProfileComponent.scss' ]
})
export class ProfileComponent implements OnInit, OnDestroy {
  public tabs: ITab[] = [
    {
      title: 'VIEW.MAIN.DOCTOR.TAB.BASIC_DATA',
      state: State.MAIN.PROFILE.BASIC_DATA,
      name: DoctorTabName.BASIC_DATA
    },
    {
      title: 'VIEW.MAIN.DOCTOR.TAB.ADDITIONAL_QUALIFICATIONS',
      state: State.MAIN.PROFILE.QUALIFICATION,
      name: DoctorTabName.ADDITIONAL_QUALIFICATIONS
    },
    {
      title: 'VIEW.MAIN.DOCTOR.TAB.LICENSE',
      state: State.MAIN.PROFILE.LICENSE,
      name: DoctorTabName.LICENSE
    },
    {
      title: 'VIEW.MAIN.DOCTOR.TAB.PRACTICE',
      state: State.MAIN.PROFILE.PRACTICE,
      name: DoctorTabName.PRACTICE
    }
  ];

  public registrationStatus: UserRegistrationStatusDTO;

  public DoctorStatus: typeof DoctorStatus = DoctorStatus;

  public doctorIsIncompleteRegistration$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.INCOMPLETE_REGISTRATION);

  private subscription: Subscription = new Subscription();

  constructor(private userModel: UserModel,
              private viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              public userRegistrationService: UserRegistrationService,
              private modalService: BsModalService) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PROFILE.BASIC_DATA);
  }

  public ngOnInit(): void {
    this.subscription.add(this.userModel.isStatus$(DoctorStatus.INCOMPLETE_REGISTRATION)
      .subscribe((incompleteRegistration: boolean) => incompleteRegistration ? this.getRegistrationStatusAndSetStatus() : this.setDefaultTabValidationStatus())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getRegistrationStatusAndSetStatus(): void {
    this.userRegistrationService.getRegistrationStatus().pipe(
      switchMap(status => {
        this.registrationStatus = status;
        const duplicates = this.getDuplicates();

        return of(this.tabs.map(tab => {
          if (duplicates.includes(tab.name)) {
            tab.validationStatus = true;
          } else if (this.registrationStatus.requiredProfileCategories.includes(tab.name as DoctorTabName)) {
            tab.validationStatus = false;
          }
          return tab;
        }));
      })
    ).subscribe(tabs => {
      this.tabs = tabs;
    });
  }

  private setDefaultTabValidationStatus(): void {
    this.tabs.forEach((tab: ITab) => tab.validationStatus = undefined);
  }

  public verifyRegistration(): void {
    if (this.registrationStatus.completed) {
      this.userRegistrationService.sendForVerification().subscribe({
        next: () => {
          this.userModel.getCurrentUser().then(() => {
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.ACTION.VERIFICATION.VERIFICATION_SENT_SUCCESSFUL');
          });
        },
        error: error => this.viewUtil.handleServerError(error)
      });
    }
  }

  private getDuplicates(): string[] {
    return this.registrationStatus.completedProfileCategories.filter(item =>
      this.registrationStatus.requiredProfileCategories.includes(item));
  }

  public openConfirmationModal(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.ACTION.VERIFICATION.VERIFY_REGISTRATION_CONFIRMATION_TITLE',
        message: 'VIEW.MAIN.DOCTOR.ACTION.VERIFICATION.VERIFY_REGISTRATION_CONFIRMATION_TITLE_MESSAGE'
      }
    });

    modal.content.close$.toPromise()
      .then(() => {
        this.verifyRegistration();
      })
      .catch(() => {
      });
  }
}
