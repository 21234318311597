import { Component, Input, OnInit } from '@angular/core';
import { Constant } from '@common/Constant';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-doctor-practice-form',
  templateUrl: './DoctorPracticeFormComponent.html',
  styleUrls: [ './DoctorPracticeFormComponent.scss' ],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class DoctorPracticeFormComponent implements OnInit {
  @Input() public practice: PracticeDTO;

  @Input() public submitted: boolean;

  public Constant: typeof Constant = Constant;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
