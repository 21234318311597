<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.DASHBOARD.SCHEDULER.TITLE' | translate}}</div>
  </div>

  <div class="content">
    <div>
      <app-calendar-selector
        [startDate]="startDate"
        (changeDateRange)="onDateRangeChanged($event)"></app-calendar-selector>
    </div>

    <div class="scheduler-container">
      <section class="weeks-container">
        <div *ngFor="let dayItem of data">
          <app-scheduler-day-header [data]="dayItem"></app-scheduler-day-header>
        </div>
      </section>

      <section class="slots-container">
        <div *ngFor="let dayItem of data">
          <app-scheduler-slots-grid [data]=dayItem.slots></app-scheduler-slots-grid>
        </div>
      </section>
    </div>
  </div>
</section>







