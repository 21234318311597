import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NetworkStatusPopupComponent } from 'app/component/ui/popup/networkStatus/NetworkStatusPopupComponent';

@Injectable({
  providedIn: 'root'
})
export class NetworkService implements OnDestroy {
  private isOnline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);

  public isOnline$: Observable<boolean> = this.isOnline.asObservable();

  private subscription: Subscription = new Subscription();

  private modalRef: BsModalRef;

  constructor(private bsModalService: BsModalService) {
    this.subscription.add(
      fromEvent(window, 'offline')
        .subscribe(() => this.isOnline.next(false))
    );

    this.subscription.add(
      fromEvent(window, 'online')
        .subscribe(() => this.isOnline.next(true))
    );

    this.subscription.add(
      this.isOnline$
        .subscribe((isOnline: boolean) => {
          if (!isOnline) {
            this.modalRef = this.bsModalService.show(NetworkStatusPopupComponent, {
              ignoreBackdropClick: true,
              keyboard: false,
              backdrop: true,
              class: 'full-screen'
            });
          } else {
            if (this.modalRef) {
              this.modalRef.hide();
            }
          }
        })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getOnlineStatus(): boolean {
    return this.isOnline.value;
  }
}
