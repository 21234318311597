import { EventTimesDTO } from '@dto/events/EventTimesDTO';
import { Type } from 'class-transformer';
import { AverageTimeDTO } from '@dto/doctor/AverageTimeDTO';

export class DoctorCategoryDTO {
  @Type(() => AverageTimeDTO)
  public averageTimes: AverageTimeDTO[];

  @Type(() => EventTimesDTO)
  public eventTimes: EventTimesDTO;
}