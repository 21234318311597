import { Component, Input, OnInit } from '@angular/core';
import { FileDTO, FileType } from '@dto/file/FileDTO';

@Component({
  selector: 'app-file-preview',
  templateUrl: './FilePreviewComponent.html',
  styleUrls: [ './FilePreviewComponent.scss' ]
})
export class FilePreviewComponent implements OnInit {
  @Input() public file: FileDTO;

  public FileType: typeof FileType = FileType;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
