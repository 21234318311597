import { ChangeDetectionStrategy, Component } from '@angular/core';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { Observable } from 'rxjs';
import { State } from '@common/State';
import { StateUtil } from '@util/StateUtil';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { map } from 'rxjs/operators';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-profile-qualification',
  templateUrl: './ProfileQualificationComponent.html',
  styleUrls: [ './ProfileQualificationComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileQualificationComponent {

  public data$: Observable<QualificationListDTO> = this.currentDoctorService.getQualifications();

  public isEditable$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.NOT_ACCEPTED)
    .pipe(
      map(status => !status)
    );

  constructor(private currentDoctorService: CurrentDoctorService,
              private stateUtil: StateUtil,
              private userModel: UserModel) {
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE_EDIT.QUALIFICATION);
  }
}
