import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { Constant } from 'app/common/Constant';
import { PasswordRequirementParams } from 'app/util/validator/PasswordRequirementsValidator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '@service/UserService';
import { UpdatePasswordDTO } from '@dto/user/UpdatePasswordDTO';
import { ObjectUtil } from '@util/ObjectUtil';
import { PopupAlertComponent } from 'app/component/ui/popup/PopupAlertComponent';
import { StateUtil } from '@util/StateUtil';

@Component({
  selector: 'app-account-change-password',
  templateUrl: 'AccountChangePasswordComponent.html',
  styleUrls: [ 'AccountChangePasswordComponent.scss' ]
})

export class AccountChangePasswordComponent implements OnInit, OnDestroy {
  @ViewChild('form')
  public form: NgForm;

  public disabledSubmit: boolean = false;

  public formData: {
    currentPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  } = {
    currentPassword: null,
    newPassword: null,
    newPasswordRepeat: null
  };

  public passwordValidatorParams: PasswordRequirementParams = {
    upperCase: true,
    lowerCase: false,
    numberChars: true,
    specialChars: true,
    minLength: Constant.PASSWORD_MIN_LENGTH
  };

  public Constant: typeof Constant = Constant;

  constructor(private stateUtil: StateUtil,
              public viewUtil: ViewUtil,
              private modalService: BsModalService,
              private appModel: ApplicationModel,
              private userService: UserService) {

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public save(): void {
    if (this.form.valid) {
      this.disabledSubmit = true;
      const updatePassword = ObjectUtil.plainToClass(UpdatePasswordDTO, {
        oldPassword: this.formData.currentPassword,
        password: this.formData.newPassword
      } as UpdatePasswordDTO);

      this.userService.updateUserPassword(updatePassword).toPromise()
        .then(() => {
          const modal: BsModalRef = this.modalService.show(PopupAlertComponent, {
            initialState: {
              title: 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_MODAL_TITLE',
              message: 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CHANGE_PASSWORD_MODAL_MESSAGE'
            }
          });

          modal.content.close$.toPromise()
            .finally(() => {
              this.goBack();
            });
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        })
        .finally(() => {
          this.disabledSubmit = false;
        });
    }
  }

  public goBack(): void {
    this.stateUtil.goBack();
  }
}
