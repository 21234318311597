import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { SchedulerModel } from '@model/SchedulerModel';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import { ApplicationModel } from '@model/ApplicationModel';
import { ICalendarDateRange } from '@local/scheduler/ICalendarDateRange';

@Component({
  selector: 'app-scheduler',
  templateUrl: './SchedulerComponent.html',
  styleUrls: [ './SchedulerComponent.scss' ]
})

export class SchedulerComponent implements OnInit, OnDestroy {
  public data: EventSlotList[];

  private dateRange: ICalendarDateRange;

  public State: typeof State = State;

  public startDate: Date = new Date();

  private schedulerRefreshingInterval: ReturnType<typeof setInterval>;

  constructor(private stateService: StateService,
              private applicationModel: ApplicationModel,
              private eventModel: SchedulerModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DASHBOARD.MY_SCHEDULER);
  }

  public ngOnInit(): void {
    this.setSchedulerTimeout();
  }

  public ngOnDestroy(): void {
    this.cancelSchedulerTimeout();
  }

  public onDateRangeChanged(dateRange: ICalendarDateRange): void {
    this.getScheduler(dateRange);
  }

  private getScheduler(dateRange: ICalendarDateRange): void {
    this.eventModel.getWeeklySelectedScheduler(dateRange.startDate, dateRange.endDate)
      .then((result: EventSlotList[]) => {
        this.data = result;
        this.dateRange = dateRange;
      });
  }

  private setSchedulerTimeout(): void {
    this.cancelSchedulerTimeout();

    this.schedulerRefreshingInterval = setInterval(() => {
      this.getScheduler(this.dateRange);
    }, 30000);
  }

  private cancelSchedulerTimeout(): void {
    if (this.schedulerRefreshingInterval) {
      clearInterval(this.schedulerRefreshingInterval);
      this.schedulerRefreshingInterval = null;
    }
  }
}


