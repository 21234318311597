import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';
import { VIDEO_CALL_SERVICE } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { GuestVideoCallService } from '@service/event/GuestVideoCallService';

@Component({
  selector: 'app-guest-video-call',
  templateUrl: './GuestVideoCallComponent.html',
  styleUrls: [ './GuestVideoCallComponent.scss' ],
  providers: [ {
    provide: VIDEO_CALL_SERVICE,
    useExisting: GuestVideoCallService
  } ]
})
export class GuestVideoCallComponent implements OnInit {
  public roomName: string = this.transition.params().roomName;

  public roomToken: string = this.transition.params().roomToken;

  public eventId: string = this.transition.params().eventId;

  constructor(private transition: Transition,
              private videoCallService: GuestVideoCallService) {
    this.videoCallService.roomName = this.roomName;
    this.videoCallService.roomToken = this.roomToken;
  }

  public ngOnInit(): void {
  }

}
