import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { EventStatus } from '@enum/event/EventStatus';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from '@util/DateUtil';

export class PublicEventDetailsDTO {
  @Type(() => DoctorDTO)
  public doctor: DoctorDTO;

  public status: EventStatus;

  @Transform(DateUtil.dateTimeConversion)
  public dateTime: Date;
}