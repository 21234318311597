import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageVersion } from '@local/LanguageVersion';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { localeParser } from '@util/parser/LocaleParser';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  public language$: BehaviorSubject<LanguageVersion> = new BehaviorSubject<LanguageVersion>(null);

  private initialized = false;

  public get currentLocale(): string {
    return this.getLanguageTranslation(this.translate?.currentLang);
  }

  constructor(private translate: TranslateService,
              private bsLocaleService: BsLocaleService) {
  }

  public setLanguage(language: LanguageVersion = ApplicationConfig.defaultLanguage): void {
    const lang = localeParser(language);

    if (!this.language$.value || this.language$.value !== lang) {
      this.setLocale(lang);
    }
  }

  private setLocale(language: LanguageVersion): void {
    const lang = localeParser(language);
    const languageTranslated = this.getLanguageTranslation(lang);

    this.translate.use(lang);
    this.bsLocaleService.use(languageTranslated);
    moment.locale(languageTranslated);

    this.language$.next(lang);
    this.initialized = true;
  }

  private getLanguageTranslation(language: string): string {
    const lang = localeParser(language);

    switch (lang) {
      case LanguageVersion.en:
        return 'en-GB';
      case LanguageVersion.de:
        return 'de-DE';
    }

    return 'en-GB';
  }
}
