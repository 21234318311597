import { Component } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-popup-confirmation-with-answer',
  templateUrl: './PopupConfirmationWithAnswerComponent.html'
})
export class PopupConfirmationWithAnswerComponent extends BaseModalComponent<string> {

  public title: string = 'COMPONENT.POPUP.CONFIRMATION.TITLE';

  public message: string;

  public messageVariables: { [key: string]: any };

  public okText: string = 'COMMON.OK';

  public cancelText: string = 'COMMON.CANCEL';

  public okButtonClass: string = 'btn-primary';

  public answer: string;

  public answerIsOptional: boolean = false;

  constructor(public bsModalRef: BsModalRef) {
    super();
  }
}
