import { Injectable } from '@angular/core';
import { EventService } from 'app/service/EventService';
import { EventManager } from 'app/util/other/EventManager';
import { EventsTimetableDTO } from '@dto/events/EventsTimetableDTO';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import { EventSlotMapper } from 'app/data/mapper/EventSlotMapper';

@Injectable({ providedIn: 'root' })
export class SchedulerModel {
  constructor(private eventManager: EventManager,
              private eventService: EventService) {
  }

  public getWeeklySelectedScheduler(dateFrom: Date, dateTo: Date): Promise<EventSlotList[]> {
    return this.eventService.getUpcomingVisits(dateFrom, dateTo).toPromise()
      .then((scheduler: EventsTimetableDTO) => {
        const transformed = EventSlotMapper.timeTableToEventSlots(scheduler);
        return EventSlotMapper.eventSlotsToList(dateFrom, transformed);
      });
  }

  public getWeeklySelectedSchedulerForDoctor(dateFrom: Date, dateTo: Date, doctorId: number): Promise<EventSlotList[]> {
    return this.eventService.getUpcomingVisitsForDoctor(dateFrom, dateTo, doctorId).toPromise()
      .then((scheduler: EventsTimetableDTO) => {
        const transformed = EventSlotMapper.timeTableToEventSlots(scheduler);
        return EventSlotMapper.eventSlotsToList(dateFrom, transformed);
      });
  }
}
