import { InsuranceBranchProviderDTO } from '@dto/insurance/InsuranceBranchProviderDTO';
import { MemberType } from '@enum/patient/MemberType';
import { Transform, Type } from 'class-transformer';
import { DateUtil } from '@util/DateUtil';

export class InsuranceDTO {
  @Type(() => InsuranceBranchProviderDTO)
  public branchProvider: InsuranceBranchProviderDTO;

  public cardBackFileUrl: string;

  public cardFrontFileUrl: string;

  public companyNumber: number;

  public memberNumber: string;

  public memberType: MemberType;

  @Transform(DateUtil.dateConversion)
  public validUntil: Date;
}