export class SideBarItem {

  public label: string;

  public cssClass?: string;

  public icon?: string;

  public stateName?: string;

  public stateParameters?: { [key: string]: any };

  public callback?: () => any;

  public selected: boolean;

  public visible: boolean;

  public badge?: number;

  public badge$?: Function;
}
