import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-page-action-portal',
  template: '<ng-template cdk-portal><ng-content></ng-content></ng-template>'
})
export class PageActionPortalComponent implements AfterViewInit {
  @ViewChild(CdkPortal)
  private portal: CdkPortal;

  private host: DomPortalOutlet;

  @Input() public name: string = 'page';

  constructor(private cfr: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  public ngAfterViewInit(): void {
    const element = document.querySelector(`#${ this.name }-action-portal`);
    this.host = new DomPortalOutlet(
      element,
      this.cfr,
      this.appRef,
      this.injector
    );

    this.host.attach(this.portal);
  }

  public ngOnDestroy(): void {
    this.host.detach();
  }
}
