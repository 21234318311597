import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageDTO } from '@dto/PageDTO';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ObjectUtil } from '@util/ObjectUtil';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { StatisticPageCriteriaDTO } from '@dto/statistic/StatisticPageCriteriaDTO';
import { StatisticPageEntityDTO } from '@dto/statistic/StatisticPageEntityDTO';
import { StatisticExportCriteriaDTO } from '@dto/statistic/StatisticExportCriteriaDTO';
import { HttpUtil } from '@util/HttpUtil';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(private http: HttpClient) {
  }

  public getPage(criteria: StatisticPageCriteriaDTO): Observable<PageDTO<StatisticPageEntityDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/invoices/all`, { params })
      .pipe(
        map((response: PageDTO<StatisticPageEntityDTO>) => ObjectUtil.plainToClassFromExist(new PageDTO<StatisticPageEntityDTO>(StatisticPageEntityDTO), response))
      );
  };

  public getExcelReport(criteria: StatisticExportCriteriaDTO): Observable<File> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/invoices/export`,
      {
        params: params,
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => new File([ response.body ], `invoice-${ criteria.month.toLowerCase() }-${ criteria.year }.xlsx`))
      );
  }
}
