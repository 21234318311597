<div class="search-and-filter mb-4" *ngIf="showFilters || showSearch">
  <div id="filters" *ngIf="showFilters">
    <ng-content select="app-data-grid-filters"></ng-content>
  </div>

  <div class="search">
    <div id="searchbox" class="form-group mb-0" *ngIf="showSearch">
      <div class="icon">
        <button type="button" class="btn btn-link btn-link--black p-0 m-0" (click)="onSearchCommitted()" *ngIf="!criteria.searchPhrase">
          <i-tabler class="i-md" name="search"></i-tabler>
        </button>

        <button type="button" class="btn btn-link btn-link--black btn-sm p-0 m-0" (click)="clearSearch()" *ngIf="criteria.searchPhrase">
          <i-tabler class="i-sm" name="x"></i-tabler>
        </button>
      </div>
      <input class="form-control form-control-sm"
             type="text" name="search" id="search"
             placeholder="{{ 'COMPONENT.DATA_GRID.SEARCH' | translate }}"
             [(ngModel)]="internalSearchPhrase"
             (keyup.enter)="onSearchCommitted()">
    </div>
  </div>
</div>

<ng-container *ngIf="data?.length === 0">
  <span class="no-data-text">{{noDataText | translate}}</span>
</ng-container>
<ng-container *ngIf="data?.length > 0">
  <ag-grid-angular *ngIf="gridOptions && gridOptionsReady" [style.visibility]="gridVisible ? 'visible' : 'hidden'"
                   class="ag-theme-alpine"
                   [ngClass]="{ 'with-row-click-selection': (gridOptions.rowSelection !== 'null') && !gridOptions.suppressRowClickSelection }"
                   [gridOptions]="gridOptions" [rowData]="data" [columnDefs]="columns"
                   (gridReady)="onGridReady($event)" (rowSelected)="onRowSelected($event)"
                   (rowDoubleClicked)="onRowDoubleClicked($event)" (selectionChanged)="onSelectionChanged($event)"
                   (sortChanged)="onSortChanged($event)">
  </ag-grid-angular>
</ng-container>

<div class="mt-1 mt-sm-2 mt-lg-4" *ngIf="showPagination || showTotalItems"
     [style.visibility]="gridVisible ? 'visible' : 'hidden'">
  <div class="row">
    <div class="col-6">
      <div class="d-flex align-items-center h-100">
        <div id="pagination-info" *ngIf="showTotalItems">
        <span *ngIf="showTotalItems && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)">
          {{ 'COMPONENT.DATA_GRID.ITEMS_SHOWING' | translate: {
          itemLow: (criteria.pageNumber * criteria.pageSize) + 1,
          itemHigh: Math.min((criteria.pageNumber * criteria.pageSize) + criteria.pageSize, itemTotal),
          itemLabel: (itemLabel | translate),
          itemTotal: itemTotal,
          pageTotal: pageTotal
        } }}
        </span>
        </div>
      </div>
    </div>

    <div class="col-6">
      <nav *ngIf="showPagination && (itemTotal > 0) && (internalItemsPerPage !== itemsPerPageAllValue)">
        <pagination class="justify-content-end mb-0"
                    [totalItems]="itemTotal" [itemsPerPage]="criteria.pageSize"
                    [maxSize]="3" [rotate]="true" [boundaryLinks]="false"
                    previousText="{{'COMMON.PREVIOUS' | translate}}"
                    nextText="{{'COMMON.NEXT' | translate}}"
                    [(ngModel)]="internalPage" (pageChanged)="onPageChanged($event)"></pagination>
      </nav>
    </div>
  </div>
</div>


