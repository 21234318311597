import { Component, OnInit } from '@angular/core';
import { PageDTO } from '@dto/PageDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { Constant } from '@common/Constant';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { DoctorService } from '@service/DoctorService';
import { DoctorPageCriteriaDTO } from '@dto/doctor/DoctorPageCriteriaDTO';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { SortDirection } from '@enum/SortDirection';
import { DoctorExportCriteriaDTO } from '@dto/doctor/DoctorExportCriteriaDTO';
import { FileUtil } from '@util/FileUtil';
import { ViewUtil } from '@util/ViewUtil';
import { PageUtil } from '@util/PageUtil';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './DoctorListComponent.html'
})
export class DoctorListComponent implements OnInit {
  public data: PageDTO<DoctorDTO>;

  public criteria: DoctorPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  public DoctorStatus: typeof DoctorStatus = DoctorStatus;

  public statuses: DoctorStatus[] = [ DoctorStatus.ACTIVE, DoctorStatus.INACTIVE ];

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private fileUtil: FileUtil,
              private viewUtil: ViewUtil,
              private doctorService: DoctorService) {
    this.criteria = PageUtil.prepareCriteria<DoctorPageCriteriaDTO>(DoctorPageCriteriaDTO, transition.params()?.criteria, { sortOrders: [ `joinDate ${ SortDirection.DESC.toLowerCase() }` ] });
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): Promise<void> {
    return this.doctorService.getPage(this.criteria).toPromise()
      .then((result: PageDTO<DoctorDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public export(): void {
    const exportCriteria = new DoctorExportCriteriaDTO();

    this.doctorService.getExcelReport(exportCriteria).toPromise()
      .then((file: File) => this.fileUtil.saveFile(file))
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public goDetails(data: DoctorDTO): void {
    this.stateService.go(State.MAIN.DOCTOR.DETAILS.BASIC_DATA, { doctorId: data.id });
  }

  public onStatusFilterChanged(status: DoctorStatus): void {
    this.onCriteriaChanged({ ...this.criteria, status } as PageCriteriaDTO);
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<DoctorPageCriteriaDTO>(DoctorPageCriteriaDTO, this.criteria, changedCriteria as DoctorPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }
}
