import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-raw-layout',
  templateUrl: 'RawLayoutComponent.html',
  styleUrls: [ 'RawLayoutComponent.scss' ]
})
export class RawLayoutComponent implements OnInit, OnDestroy {

  constructor(public viewUtil: ViewUtil) {
  }

  public ngOnInit():void {
  }

  public ngOnDestroy():void {
  }

}
