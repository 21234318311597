import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { AuthModel } from '@model/AuthModel';

@Component({
  selector: 'app-settings-global-logout',
  templateUrl: './SettingsGlobalLogoutComponent.html',
  styleUrls: [ './SettingsGlobalLogoutComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsGlobalLogoutComponent {

  constructor(private modalService: BsModalService, private authModel: AuthModel) {
  }

  public logout(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        message: 'VIEW.MAIN.SETTINGS.LOGOUT.CONFIRMATION.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then(() => this.authModel.globalLogout());
  }

}
