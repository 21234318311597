import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { PrescriptionRefillPageCriteriaDTO } from '@dto/prescription/PrescriptionRefillPageCriteriaDTO';
import { PrescriptionRefillDTO } from '@dto/prescription/PrescriptionRefillDTO';
import { PageDTO } from '@dto/PageDTO';
import { PrescriptionRefillPageEntityDTO } from '@dto/prescription/PrescriptionRefillPageEntityDTO';
import { Constant } from '@common/Constant';
import { PrescriptionRefillConfirmationDTO } from '@dto/prescription/PrescriptionRefillConfirmationDTO';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { HttpUtil } from '@util/HttpUtil';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionService {

  constructor(private http: HttpClient) {
  }

  public getPrescriptionRefillPage(criteria: PrescriptionRefillPageCriteriaDTO): Observable<PageDTO<PrescriptionRefillPageEntityDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/e-prescriptions/refill`, { params })
      .pipe(
        map((response: PageDTO<PrescriptionRefillPageEntityDTO>) => ObjectUtil.plainToClassFromExist(new PageDTO<PrescriptionRefillPageEntityDTO>(PrescriptionRefillPageEntityDTO), response))
      );
  };

  public getNumberOfNewPrescriptions(criteria: PrescriptionRefillPageCriteriaDTO): Observable<number> {
    const params = HttpUtil.createHttpParams(criteria);

    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/e-prescriptions/refill`, { params, headers })
      .pipe(
        map((response: PageDTO<PrescriptionRefillPageEntityDTO>) => response.totalElements)
      );
  };

  public getPrescriptionRefillDetails(id: number): Observable<PrescriptionRefillDTO> {
    return this.http.get<PrescriptionRefillDTO>(`${ ApplicationConfig.apiUrl }/e-prescriptions/refill/${ id }`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(PrescriptionRefillDTO, response))
      );
  };

  public confirmPatientPrescription(id: number, data: PrescriptionRefillConfirmationDTO[]): Observable<any> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/e-prescriptions/refill/${ id }`, { requestDetails: ObjectUtil.classToPlainArray(data) });
  }

  public updatePatientPrescription(id: number, data: PrescriptionDTO): Observable<any> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/e-prescriptions/refill/${ id }/requests/${ data.id }`, ObjectUtil.classToPlain(data));
  }

}

