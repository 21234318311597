<nav>
  <a [href]="websiteUrl">
    <img class="logo d-block" src="asset/image/logo.png" alt="Logo"/>
  </a>

  <div class="desktop-menu">
    <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
  </div>

  <div class="mobile-menu">
    <button type="button" class="btn btn-link sidebar-button p-0 border-0" (click)="toggle()" *ngIf="collapsed">
      <div class="d-flex justify-content-center align-items-center">
        <i-tabler name="menu-2"></i-tabler>
      </div>
    </button>

    <div class="menu-container" cdkTrapFocus>
      <button type="button" class="btn btn-link sidebar-button p-0 border-0" (click)="toggle()" *ngIf="!collapsed">
        <div class="d-flex justify-content-center align-items-center">
          <i-tabler name="x"></i-tabler>
        </div>
      </button>

      <div class="menu-position">
        <div class="menu-collapse" [collapse]="collapsed" [isAnimated]="true"
             (collapsed)="onCollapsed(true)"
             (expanded)="onCollapsed(false)">
          <div class="px-3 pt-3">
            <div class="menu-collapse-scrollable-view pr-2">
              <ng-container [ngTemplateOutlet]="menuTemplate"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--Templates-->
<ng-template #menuTemplate>
  <ul class="menu">
    <li *ngFor="let menuItem of menuItems">
      <a class="btn btn-link" [href]="websiteUrl + '/' + menuItem.link">{{ menuItem.label | translate }}</a>
    </li>

    <li class="login">
      <a class="btn btn-outline-secondary btn-rounded text-normal btn-block" [href]="'/login'">
        {{ 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.LOG_IN' | translate }}
      </a>
    </li>
  </ul>
</ng-template>
