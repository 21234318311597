<div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.HEALTH_CONDITION.TITLE' | translate}}</div>

<ng-container *ngIf="answers$ | async as answers">
  <ng-container *ngFor="let answer of answers">
    <div class="row mb-2">
      <div class="col-12 col-sm-6 font-weight-bold font-size-sm">{{answer.question}}</div>
      <div class="col-12 col-sm-6 font-size-sm mt-1 mt-sm-0">{{answer.answers}}</div>
    </div>
  </ng-container>
</ng-container>



