import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { PublicEventDetailsDTO } from '@dto/events/PublicEventDetailsDTO';

@Injectable({
  providedIn: 'root'
})
export class PublicEventService {

  constructor(private http: HttpClient) {
  }

  public getById(token: string): Observable<PublicEventDetailsDTO> {
    return this.http.get<PublicEventDetailsDTO>(`${ ApplicationConfig.apiUrl }/events/public/${ token }`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(PublicEventDetailsDTO, response))
      );
  }

  public cancel(token: string): Observable<any> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/events/public/${ token }`);
  }
}
