<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 my-5 text-center">
      <svg-icon src="asset/image/icon/point.svg"></svg-icon>
      <div class="display-4 mb-3">{{ 'VIEW.PRELIMINARY.ERROR.ACCESS_DENIED' | translate }}</div>
      <div class="lead">{{ 'VIEW.PRELIMINARY.ERROR.ACCESS_DENIED_MESSAGE' | translate }}</div>

      <button id="back-button" type="button" class="btn btn-link btn-block mt-3 mb-4" (click)="backToHome()">
        <span>{{ 'VIEW.PRELIMINARY.ERROR.BACK' | translate }}</span>
      </button>
    </div>
  </div>
</div>
