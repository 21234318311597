import { StatesModule, UIRouter } from '@uirouter/angular';
import { Injector } from '@angular/core';

export function RouterConfig(uiRouter: UIRouter, injector: Injector, module: StatesModule): any {
  uiRouter.urlService.rules.when('/', '/dashboard');
  uiRouter.urlService.rules.otherwise('/error/not-found');
  // trace.enable(Category.TRANSITION);

  const transitionService = uiRouter.transitionService;
  // requiresAuthHook(transitionService);
}
