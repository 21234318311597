<form #form="ngForm" novalidate (ngSubmit)="save()">
  <section class="page">
    <div class="header-container separator">
      <div class="header">{{'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.PASSWORD_CHANGE' | translate}}</div>

      <div class="actions">
        <button type="button" class="btn btn-outline-primary"
                [disabled]="disabledSubmit" (click)="goBack()"> {{ 'COMMON.CANCEL' | translate}} </button>
        <button type="submit" class="btn btn-primary"
                [disabled]="disabledSubmit"> {{ 'COMMON.SAVE' | translate}} </button>

      </div>
    </div>

    <div class="content">
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
          <div class="form-group mb-4">
            <label for="currentPassword"> {{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.CURRENT_PASSWORD' | translate }}</label>
            <input id="currentPassword" type="password"
                   name="currentPassword"
                   #currentPassword="ngModel" appInputPasswordToggle
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': form.submitted && currentPassword.invalid}"
                   [(ngModel)]="formData.currentPassword" required>

            <div class="invalid-feedback" *ngIf="form.submitted && currentPassword.invalid">
              <div *ngIf="currentPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>

          <div class="form-group mb-4">
            <label for="newPassword"> {{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</label>
            <input id="newPassword" type="password"
                   name="newPassword"
                   #newPassword="ngModel" appInputPasswordToggle
                   [appPasswordRequirements]="passwordValidatorParams"
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': form.submitted && newPassword.invalid}"
                   [pattern]="Constant.PASSWORD_PATTERN"
                   [(ngModel)]="formData.newPassword" required>

            <div class="invalid-feedback" *ngIf="form.submitted && newPassword.invalid">
              <div *ngIf="newPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="newPassword.errors['pattern']">{{
                  'VALIDATION.PASSWORD' | translate: {
                    passwordLength:
                    Constant.PASSWORD_MIN_LENGTH
                  }
                }}
              </div>
            </div>
          </div>

          <div class="mb-3 w-100">
            <app-password-requirements [passwordControl]="newPassword.control"></app-password-requirements>
          </div>

          <div class="form-group mb-4">
            <label
              for="repeatNewPassword"> {{ 'VIEW.MAIN.ACCOUNT.CHANGE_PASSWORD.REPEAT_NEW_PASSWORD' | translate }}</label>
            <input id="repeatNewPassword" type="password"
                   name="repeatNewPassword"
                   #repeatNewPassword="ngModel" appInputPasswordToggle
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': form.submitted && repeatNewPassword.invalid}"
                   [equalTo]="newPassword.control"
                   [(ngModel)]="formData.newPasswordRepeat" required>

            <div class="invalid-feedback" *ngIf="form.submitted && repeatNewPassword.invalid">
              <div *ngIf="repeatNewPassword.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="repeatNewPassword.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </section>
</form>
