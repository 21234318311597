import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DoctorModel } from '@model/DoctorModel';
import { DoctorCategoryDTO } from '@dto/doctor/DoctorCategoryDTO';

@Component({
  selector: 'app-doctor-category',
  templateUrl: './DoctorCategoryComponent.html',
  styleUrls: [ './DoctorCategoryComponent.scss' ]
})
export class DoctorCategoryComponent implements OnInit {
  public data$: Observable<DoctorCategoryDTO> = this.doctorModel.categories$;

  constructor(public doctorModel: DoctorModel) {
  }

  public ngOnInit(): void {
  }
}
