import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { InsuranceDTO } from '@dto/insurance/InsuranceDTO';
import { PatientPageCriteriaDTO } from '@dto/patient/PatientPageCriteriaDTO';
import { PageDTO } from '@dto/PageDTO';
import { IInsuranceService } from '@local/interface/IInsuranceService';
import { PatientEventsHistoryPageCriteriaDTO } from '@dto/patient/PatientEventsHistoryPageCriteriaDTO';
import { PatientEventHistoryDTO } from '@dto/patient/PatientEventHistoryDTO';
import { HttpUtil } from '@util/HttpUtil';
import { PatientStatus } from '@enum/patient/PatientStatus';

@Injectable({ providedIn: 'root' })
export class PatientService implements IInsuranceService {

  constructor(private http: HttpClient) {
  }

  public getPage(criteria: PatientPageCriteriaDTO): Observable<PageDTO<PatientDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/patients`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassFromExist(new PageDTO<PatientDTO>(PatientDTO), response))
      );
  }

  public getById(patientId: number): Observable<PatientDTO> {
    return this.http.get<PatientDTO>(`${ ApplicationConfig.apiUrl }/doctors/patients/${ patientId }/basic-data`)
      .pipe(
        map((response: any) => {
          return ObjectUtil.plainToClass(PatientDTO, response);
        })
      );
  }

  public getInsurance(patientId: number): Observable<InsuranceDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/patients/${ patientId }/insurance`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(InsuranceDTO, response))
      );
  }

  public reportInsuranceProblem(patientId: number, message: string): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/patients/${ patientId }/insurance-issue`, { message });
  }

  public update(data: PatientDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/patients/${ data.id }/basic-data`, ObjectUtil.classToPlain(data));
  }

  public getEventsHistoryPage(patientId: number, criteria: PatientEventsHistoryPageCriteriaDTO): Observable<PageDTO<PatientEventHistoryDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/patients/${ patientId }/history`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassFromExist(new PageDTO<PatientEventHistoryDTO>(PatientEventHistoryDTO), response))
      );
  }
}
