import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { PublicEventDetailsDTO } from '@dto/events/PublicEventDetailsDTO';
import { PublicEventService } from '@service/PublicEventService';
import { Transition } from '@uirouter/angular';
import { EventStatus } from '@enum/event/EventStatus';
import { ViewUtil } from '@util/ViewUtil';
import { catchError } from 'rxjs/operators';
import { LocaleService } from '@service/LocaleService';


@Component({
  selector: 'app-event-cancel',
  templateUrl: './EventCancelComponent.html',
  styleUrls: [ './EventCancelComponent.scss' ]
})
export class EventCancelComponent implements OnInit {

  public event$: Observable<PublicEventDetailsDTO>;

  public token: string = this.transition.params().token;

  public EventStatus: typeof EventStatus = EventStatus;

  constructor(private publicEventService: PublicEventService,
              private viewUtil: ViewUtil,
              private localeService: LocaleService,
              private transition: Transition) {
    const language = transition.params()?.lang;
    this.localeService.setLanguage(language);

    this.setData();
  }

  public ngOnInit(): void {
  }

  public cancel(): void {
    this.publicEventService.cancel(this.token)
      .subscribe(() => this.setData(), (error) => this.viewUtil.handleServerError(error));
  }

  private setData(): void {
    this.event$ = this.publicEventService.getById(this.token)
      .pipe(
        catchError((error) => {
          this.viewUtil.handleServerError(error);

          return EMPTY;
        })
      );
  }

}
