import { Component, OnInit } from '@angular/core';
import { State } from '@common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';

@Component({
  selector: 'app-practice',
  templateUrl: './PracticeComponent.html',
  styleUrls: [ './PracticeComponent.scss' ]
})
export class PracticeComponent implements OnInit {

  public State: typeof State = State;

  public tabs: ITab[] = [
    {
      title: 'VIEW.MAIN.PRACTICE.TAB.CATEGORIES',
      state: State.MAIN.PRACTICE.CATEGORY,
    }
  ];

  constructor(private applicationModel: ApplicationModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PRACTICE.CATEGORY);
  }

  ngOnInit(): void {
  }

}
