<div class="modal-header">
    <h4 class="modal-title">{{'COMPONENT.AVATAR.TITLE' | translate}}</h4>
    <div class="modal-control">
        <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
            <i-tabler name="x"></i-tabler>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="image-editor">
        <image-cropper
            [imageFile]="image"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            [containWithinAspectRatio]="false"
            [onlyScaleDown]="true"
            format="jpeg"
            [resizeToWidth]="450"
            [alignImage]="'center'"
            [backgroundColor]="'white'"
            [transform]="transform"
            [autoCrop]="true"
            (imageCropped)="imageCropped($event)">
        </image-cropper>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">{{ 'COMMON.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="ok(croppedFile)">{{ 'COMMON.CONFIRM' | translate }}</button>
</div>
