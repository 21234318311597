import { Component, OnInit } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { StateService } from '@uirouter/core';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DoctorWorkingDayService } from '@service/DoctorWorkingDayService';
import { VacationDTO } from '@dto/workingDay/VacationDTO';
import { Constant } from '@common/Constant';
import { SchedulerConflictsPopupComponent } from 'app/component/ui/scheduler/popup/SchedulerConflictsPopupComponent';
import { ServerErrorCode } from '@enum/ServerErrorCode';
import { HttpErrorResponse } from '@angular/common/http';
import { EventSlot } from '@local/scheduler/EventSlot';

@Component({
  selector: 'app-vacation',
  templateUrl: './VacationComponent.html',
  styleUrls: [ './VacationComponent.scss' ]
})
export class VacationComponent implements OnInit {
  public Constant: typeof Constant = Constant;

  public nowDate: Date = new Date();

  public vacations: VacationDTO[] = [];

  constructor(private workingDayService: DoctorWorkingDayService, private viewUtil: ViewUtil,
              private stateService: StateService, private modalService: BsModalService) {
  }

  public ngOnInit(): void {
    this.getHolidays();
  }

  private getHolidays(): void {
    this.workingDayService.getVacations().toPromise()
      .then((vacations: VacationDTO[]) => {
        this.vacations = vacations;
      })
      .catch((err) => {
        this.viewUtil.showToastError(err);
      });
  }

  public save(vacation: VacationDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.UPDATE.TITLE',
        okText: 'COMMON.SAVE'
      }
    });

    modal.content.close$.subscribe(() => {
      if (vacation?.id) {
        this.update(vacation);
      } else {
        this.createNew(vacation);
      }
    });
  }

  public remove(vacation: VacationDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.DELETE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => {
      if (vacation.id) {
        this.delete(vacation.id);
      } else {
        this.vacations = this.vacations.filter(item => item.id);
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.DELETE.SUCCESS');
      }
    });
  }

  public addNew(): void {
    const newVacation = new VacationDTO();
    newVacation.isEditing = true;

    this.vacations.push(newVacation);
  }

  public createNew(vacation: VacationDTO): void {
    this.workingDayService.createVacation(vacation).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.UPDATE.SUCCESS');
        this.getHolidays();
      })
      .catch((ex: HttpErrorResponse) => {
        if (ex?.error?.errorCode === ServerErrorCode.FOUND_CONFLICTING_EVENTS) {
          this.detectedConflicts(vacation)
            .then(() => this.createNew(vacation));
        } else {
          this.viewUtil.handleServerError(ex);
        }
      });
  }

  public update(vacation: VacationDTO): void {
    this.workingDayService.updateVacation(vacation).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.UPDATE.SUCCESS');
        this.getHolidays();
      })
      .catch((ex: HttpErrorResponse) => {
        if (ex?.error?.errorCode === ServerErrorCode.FOUND_CONFLICTING_EVENTS) {
          this.detectedConflicts(vacation)
            .then(() => this.update(vacation));
        } else {
          this.viewUtil.handleServerError(ex);
        }
      });
  }

  public delete(id: number): void {
    this.workingDayService.deleteVacation(id).toPromise()
      .then(() => {
        this.getHolidays();
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.VACATION.MESSAGE.DELETE.SUCCESS');
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private detectedConflicts(vacation: VacationDTO): Promise<boolean> {
    return this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.TITLE',
        message: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.DESCRIPTION',
        okText: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.BUTTON_OK'
      }
    }).content.close$
      .toPromise()
      .then(() => this.showEventConflicts(vacation.dateFrom, vacation.dateTo, vacation.wholeDay));
  }

  private showEventConflicts(startDate: Date, endDate: Date, wholeDay: boolean): Promise<boolean> {
    return this.workingDayService.getVacationConflicts(startDate, endDate, wholeDay).toPromise()
      .then((conflicts: EventSlot[]) => this.modalService.show(SchedulerConflictsPopupComponent, {
        class: 'full-screen',
        initialState: {
          conflicts
        }
      }).content.close$.toPromise());
  }

}
