import { Exclude, Type } from 'class-transformer';

export class PageDTO<T> {

  // eslint-disable-next-line id-blacklist
  public number: number;

  public numberOfElements: number;

  public totalPages: number;

  public totalElements: number;

  // eslint-disable-next-line no-underscore-dangle
  @Type(options => (options.newObject as PageDTO<T>)._genericType)
  public content: T[];

  @Exclude()
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private readonly _genericType: Function;

  // class-transformer / TS workaround - https://github.com/typestack/class-transformer/issues/14
  constructor(genericType: any = null) {
    // eslint-disable-next-line no-underscore-dangle
    this._genericType = genericType;
  }

}
