import { Component, OnDestroy, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { State } from 'app/common/State';
import { PrescriptionRefillDTO } from '@dto/prescription/PrescriptionRefillDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewUtil } from 'app/util/ViewUtil';
import _, { cloneDeep } from 'lodash';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateUtil } from '@util/StateUtil';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrescriptionService } from '@service/PrescriptionService';
import { PrescriptionStatus } from '@enum/prescription/PrescriptionStatus';
import { Subscription } from 'rxjs';
import { PrescriptionRefillConfirmationDTO } from '@dto/prescription/PrescriptionRefillConfirmationDTO';
import { PrescriptionRefillStatus } from '@enum/prescription/PrescriptionRefillStatus';
import { PrescriptionEditPopupComponent } from './popup/PrescriptionEditPopupComponent';
import { Constant } from '@common/Constant';

@Component({
  selector: 'app-prescription-request',
  templateUrl: './PrescriptionRequestComponent.html',
  styleUrls: [ './PrescriptionRequestComponent.scss' ]
})
export class PrescriptionRequestComponent implements OnInit, OnDestroy {
  public isDone: boolean = true;

  public prescriptionRequestlId: number;

  public prescriptionRefill: PrescriptionRefillDTO;

  public form: FormGroup = this.fb.group({
    status: this.fb.control(undefined),
    requests: this.fb.array([])
  });

  public PrescriptionStatus: typeof PrescriptionStatus = PrescriptionStatus;

  private subscription: Subscription = new Subscription();

  constructor(public viewUtil: ViewUtil,
              private prescriptionService: PrescriptionService,
              private stateUtil: StateUtil,
              private transition: Transition,
              private fb: FormBuilder,
              private applicationModel: ApplicationModel,
              private modalService: BsModalService) {
    this.prescriptionRequestlId = transition.params().id;
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PRESCRIPTION_REQUEST.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public save(): void {
    if (this.form.valid) {
      const requestConfirmations = this.form.value.requests.map((s: PrescriptionDTO) => {
        return {
          requestDetailId: s.id,
          confirmed: s.status === PrescriptionStatus.CONFIRMED,
          justification: s.justification
        } as PrescriptionRefillConfirmationDTO;
      });

      this.prescriptionService.confirmPatientPrescription(this.prescriptionRequestlId, requestConfirmations).toPromise()
        .then(() => {
          this.setIsDone(true);
          this.viewUtil.showToastSuccess('VIEW.MAIN.PRESCRIPTION.REQUEST.MESSAGE.SUCCESS');
        })
        .catch((err) => {
          this.viewUtil.handleServerError(err);
        });
    }
  }

  public get requests(): FormArray {
    return this.form?.controls?.requests as FormArray;
  }

  public editRequest(request: PrescriptionDTO, index: number): void {
    const modal: BsModalRef = this.modalService.show(PrescriptionEditPopupComponent, {
      initialState: {
        prescription: cloneDeep(request),
        prescriptionRequestId: this.prescriptionRequestlId
      }
    });

    modal.content.close$.subscribe((prescription: PrescriptionDTO) => {
      this.requests.controls[index].patchValue({
        medicamentName: prescription.medicamentName,
        medicamentGrammage: prescription.medicamentGrammage,
        doze: prescription.doze
      } as PrescriptionDTO);
    }, () => {
      //
    });
  }

  public back(): void {
    history.back();
  }

  private getData(): void {
    this.requests.controls = [];

    this.prescriptionService.getPrescriptionRefillDetails(this.prescriptionRequestlId).toPromise()
      .then((result: PrescriptionRefillDTO) => {
        this.prescriptionRefill = result;
        this.setForm(result?.requests);
        this.setIsDone(result.status === PrescriptionRefillStatus.DONE);
      })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public setForm(requests: PrescriptionDTO[]): void {
    requests.forEach((request: PrescriptionDTO) => {
        const group = this.fb.group({
          ...request,
          status: request.status !== PrescriptionStatus.PENDING ? request.status : undefined
        });

        const status = group.get('status');
        const justification = group.get('justification');

        status.setValidators([ Validators.required ]);
        status.updateValueAndValidity();

        justification.setValidators([ Validators.maxLength(Constant.TEXT_MAX_LENGTH) ]);
        justification.updateValueAndValidity();

        this.subscription.add(status.valueChanges.subscribe(() => this.acceptationStatusChanged()));

        this.requests.push(group);
      }
    );

    this.acceptationStatusChanged();
  }

  public changeAllStatus(status: PrescriptionStatus): void {
    this.requests.controls.forEach((s: FormGroup) => {
      s.controls.status.patchValue(status, {
        onlySelf: false,
        emitEvent: false
      });
    });
  }

  private acceptationStatusChanged(): void {
    const statuses = this.requests.controls.map((s: FormGroup) => s.controls['status'].value);

    const allConfirmed = _.every(statuses, (s) => s === PrescriptionStatus.CONFIRMED);
    const allDeclined = _.every(statuses, (s) => s === PrescriptionStatus.DECLINED);

    const statusValue = allConfirmed ? PrescriptionStatus.CONFIRMED : (allDeclined ? PrescriptionStatus.DECLINED : null);

    this.form.controls.status.patchValue(statusValue);
  }

  private setIsDone(value: boolean): void {
    this.isDone = value;

    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
