<div class="container px-0">
  <div class="d-flex align-items-center justify-content-center min-height-100-dvh">
    <div class="content">
      <form #form="ngForm" (ngSubmit)="submit()" novalidate>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="mb-5">
            <img class="logo d-block img-fluid" src="asset/image/logo.png" alt="Logo"/>
          </div>

          <h5 class="mb-3"> {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.SET_NEW_PASSWORD' | translate }}</h5>

          <div class="form-group">
            <label for="password"> {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.NEW_PASSWORD' | translate }}</label>
            <input id="password" type="password" name="password" #password="ngModel" appInputPasswordToggle
                   [appPasswordRequirements]="passwordValidatorParams" class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': form.submitted && password.invalid}" [(ngModel)]="formData.password"
                   required>

            <div class="invalid-feedback" *ngIf="form.submitted && password.invalid">
              <div *ngIf="password.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="passwordRepeat"> {{
                'VIEW.PRELIMINARY.RESET_PASSWORD.REPEAT_NEW_PASSWORD' | translate
              }}</label>
            <input id="passwordRepeat" type="password" name="passwordRepeat" #passwordRepeat="ngModel"
                   appInputPasswordToggle class="form-control form-control-sm" [equalTo]="password.control"
                   [ngClass]="{'is-invalid': form.submitted && passwordRepeat.invalid}"
                   [(ngModel)]="formData.passwordRepeat" required>

            <div class="invalid-feedback" *ngIf="form.submitted && passwordRepeat.invalid">
              <div *ngIf="passwordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              <div *ngIf="passwordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
            </div>
          </div>

          <div class="mb-3 w-100">
            <app-password-requirements [passwordControl]="password.control"></app-password-requirements>
          </div>

          <button type="submit" class="btn btn-custom-1">{{
              'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_COMPLETE_ACTION' | translate
            }}
          </button>


          <button type="button" class="btn btn-link btn-sm text-normal mt-4" (click)="backToLogin()">
            <span>{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.BACK_TO_LOGIN' | translate }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>