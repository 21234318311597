export class StatisticPageEntityDTO {
  public customerId: number;

  public doctorLastName: string;

  public doctorName: string;

  public ePrescriptions: number;

  public invoiceId: number;

  public sickLeaves: number;

  public videoCalls: number;

  public speciality: string;
}