import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

export abstract class BaseModalComponent<T> {
  private onClose: Subject<T> = new Subject();

  public close$: Observable<T> = this.onClose.asObservable();

  public abstract bsModalRef: BsModalRef;

  public ok(value: T): void {
    this.onClose.next(value);
    this.exit();
  }

  public cancel(): void {
    this.onClose.error(null);
    this.exit();
  }

  public close(): void {
    this.onClose.error(null);
    this.exit();
  }

  protected exit(): void {
    this.onClose.complete();
    this.bsModalRef.hide();
  }
}
