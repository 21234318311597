import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { VideoCallRoomDTO } from '@dto/videocall/VideoCallRoomDTO';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';

export const VIDEO_CALL_SERVICE: InjectionToken<IVideoCallService> = new InjectionToken<IVideoCallService>('IVideoCallService');

export interface IVideoCallService {
  getRoom(id: number | string): Observable<VideoCallRoomDTO>;

  closeRoom(id: number | string): Observable<unknown>;

  createRoom(id: number | string): Observable<VideoCallRoomDTO>;

  get canJoin(): boolean;

  get participantIsWaiting(): boolean;

  get roomExists(): boolean;

  get me(): IVideoCallUser;

  get participant(): IVideoCallUser;

  close(): void;
}