import { Injectable } from '@angular/core';
import { IVideoCallService } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map, tap } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VideoCallSimpleInfoDTO } from '@dto/videocall/VideoCallSimpleInfoDTO';
import { VideoCallRoomDTO } from '@dto/videocall/VideoCallRoomDTO';
import { StateService } from '@uirouter/core';
import { State } from '@common/State';
import { EventNoteDTO } from '@dto/events/EventNoteDTO';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';
import { Constant } from '@common/Constant';

@Injectable({
  providedIn: 'root'
})
export class DoctorVideoCallService implements IVideoCallService {
  private eventInfo: VideoCallSimpleInfoDTO;

  public isInstantCall: boolean = false;

  constructor(private http: HttpClient,
              private stateService: StateService) {
  }

  public get canJoin(): boolean {
    return this.eventInfo?.couldJoin;
  }

  public get participant(): IVideoCallUser {
    return this.eventInfo?.patient;
  }

  public get participantIsWaiting(): boolean {
    return this.eventInfo?.isPatientJoined;
  }

  public get roomExists(): boolean {
    return this.eventInfo?.isRoomExist;
  }

  public get me(): IVideoCallUser {
    return this.eventInfo?.doctor;
  }

  public getDetails(id: number): Observable<VideoCallSimpleInfoDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ id }/simple-info`, { headers })
      .pipe(
        map((response: VideoCallSimpleInfoDTO) => {
          this.isInstantCall = response.isUnregisteredPatient;
          return ObjectUtil.plainToClass(VideoCallSimpleInfoDTO, response);
        }),
        tap((eventInfo: VideoCallSimpleInfoDTO) => {
          this.eventInfo = eventInfo;
        })
      );
  }

  public createRoom(eventId: number): Observable<VideoCallRoomDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/doctors/event/${ eventId }/room`, null)
      .pipe(
        map((response: VideoCallRoomDTO) => ObjectUtil.plainToClass(VideoCallRoomDTO, response))
      );
  }

  public getRoom(eventId: number): Observable<VideoCallRoomDTO> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/event/${ eventId }/room`)
      .pipe(
        map((response: VideoCallRoomDTO) => ObjectUtil.plainToClass(VideoCallRoomDTO, response))
      );
  }

  public closeRoom(eventId: number): Observable<unknown> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/doctors/event/${ eventId }/room`);
  }

  public getNote(eventId: number): Observable<EventNoteDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/note`, { headers })
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(EventNoteDTO, response))
      );
  }

  public updateNote(eventId: number, note: EventNoteDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/events/${ eventId }/note`, ObjectUtil.classToPlain(note));
  }

  public close(): void {
    this.stateService.go(State.MAIN.DASHBOARD.MY_SCHEDULER);
  }
}
