<div class="d-flex align-items-center weekNrb">
  <button type="button" class="d-flex align-items-center btn btn-link btn-link--black mr-2" (click)="previous()">
    <i-tabler name="chevron-left" class="i-lg"></i-tabler>
  </button>

  <div>
    <span class="font-weight-bold">{{'VIEW.MAIN.DASHBOARD.SCHEDULER.WEEK' | translate}} {{ weekNumber}}:</span>
    <span>&nbsp;{{ start | date: 'dd MMMM' }} - {{ end | date: 'dd MMMM YYYY' }}</span>
  </div>

  <button type="button" class="d-flex align-items-center btn btn-link btn-link--black ml-2" (click)="next()">
    <i-tabler name="chevron-right" class="i-lg"></i-tabler>
  </button>
</div>
