import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { AvatarUrl } from '@local/interface/AvatarUrl';

@Injectable({
  providedIn: 'root'
})
export class AvatarManagerService {

  constructor(private http: HttpClient) {
  }

  public getAvatar(data: AvatarUrl): Observable<Blob> {
    const avatarUrl = data.avatarUrl.includes('blob:') ? data.avatarUrl : `${ ApplicationConfig.apiUrl }/doctors/${ data.id }/avatar`;

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'image/jpeg');

    return this.http.get<Blob>(avatarUrl, {
      responseType: 'blob' as 'json',
      observe: 'response',
      headers
    }).pipe(
      map((response: HttpResponse<Blob>) => response.body)
    );
  }
}
