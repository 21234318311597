import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICalendarDateRange } from '@local/scheduler/ICalendarDateRange';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import { SchedulerModel } from '@model/SchedulerModel';
import { Transition } from '@uirouter/core';
import { WorkingDaysTimetableDTO } from '@dto/workingDay/WorkingDaysTimetableDTO';
import { DoctorWorkingDayService } from '@service/DoctorWorkingDayService';
import { ViewUtil } from '@util/ViewUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { DoctorWorkingHoursPopupComponent } from 'app/component/view/main/doctor/details/scheduler/popup/DoctorWorkingHoursPopupComponent';
import { VacationDTO } from '@dto/workingDay/VacationDTO';
import { DoctorVacationsPopupComponent } from 'app/component/view/main/doctor/details/scheduler/popup/DoctorVacationsPopupComponent';

@Component({
  selector: 'app-doctor-scheduler',
  templateUrl: './DoctorSchedulerComponent.html',
  styleUrls: [ './DoctorSchedulerComponent.scss' ]
})
export class DoctorSchedulerComponent implements OnInit, OnDestroy {
  public id: number = this.transition.params().doctorId;

  public data: EventSlotList[];

  public startDate: Date = new Date();

  private dateRange: ICalendarDateRange;

  private schedulerRefreshingInterval: ReturnType<typeof setInterval>;


  constructor(private schedulerModel: SchedulerModel,
              private viewUtil: ViewUtil,
              private doctorWorkingDayService: DoctorWorkingDayService,
              private modalService: BsModalService,
              private transition: Transition) {
  }

  public ngOnInit(): void {
    this.setSchedulerTimeout();
  }

  public ngOnDestroy(): void {
    this.cancelSchedulerTimeout();
  }

  public onDateRangeChanged(dateRange: ICalendarDateRange): void {
    this.getScheduler(dateRange);
  }

  public openVacationsAndAbsences(): void {
    this.doctorWorkingDayService.getVacationsForDoctor(this.id).toPromise()
      .then((vacations: VacationDTO[]) =>
        this.modalService.show(DoctorVacationsPopupComponent, {
          initialState: {
            data: vacations
          }
        }))
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public openWorkingHours(): void {
    this.doctorWorkingDayService.getWorkingHoursForDoctor(this.id).toPromise()
      .then((scheduler: WorkingDaysTimetableDTO[]) =>
        this.modalService.show(DoctorWorkingHoursPopupComponent, {
          initialState: {
            data: scheduler
          }
        }))
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  private getScheduler(dateRange: ICalendarDateRange): void {
    this.schedulerModel.getWeeklySelectedSchedulerForDoctor(dateRange.startDate, dateRange.endDate, this.id)
      .then((result: EventSlotList[]) => {
        this.data = result;
        this.dateRange = dateRange;
      });
  }

  private setSchedulerTimeout(): void {
    this.cancelSchedulerTimeout();

    this.schedulerRefreshingInterval = setInterval(() => {
      this.getScheduler(this.dateRange);
    }, 30000);
  }

  private cancelSchedulerTimeout(): void {
    if (this.schedulerRefreshingInterval) {
      clearInterval(this.schedulerRefreshingInterval);
      this.schedulerRefreshingInterval = null;
    }
  }
}
