import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { PageDTO } from '@dto/PageDTO';
import { Transition } from '@uirouter/angular';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { Constant } from '@common/Constant';
import { PatientService } from '@service/PatientService';
import { PatientPageCriteriaDTO } from '@dto/patient/PatientPageCriteriaDTO';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { PatientStatus } from '@enum/patient/PatientStatus';
import { PatientInsuranceType } from '@enum/patient/PatientInsuranceType';
import { ViewUtil } from '@util/ViewUtil';
import { PageUtil } from '@util/PageUtil';

@Component({
  selector: 'app-patient-list',
  templateUrl: './PatientListComponent.html'
})
export class PatientListComponent implements OnInit {
  public data: PageDTO<PatientDTO>;

  public criteria: PatientPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  public PatientStatus: typeof PatientStatus = PatientStatus;

  public insuranceTypes: PatientInsuranceType[] = Object.keys(PatientInsuranceType) as PatientInsuranceType[];

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private viewUtil: ViewUtil,
              private patientService: PatientService) {
    this.criteria = PageUtil.prepareCriteria<PatientPageCriteriaDTO>(PatientPageCriteriaDTO, transition.params()?.criteria);
    this.applicationModel.selectSideBarItemWithState(State.MAIN.MY_PATIENT.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): Promise<void> {
    return this.patientService.getPage(this.criteria).toPromise()
      .then((result: PageDTO<PatientDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public goDetails(data: PatientDTO): void {
    this.stateService.go(State.MAIN.MY_PATIENT.DETAILS.BASIC_DATA, { patientId: data.id });
  }

  public onInsuranceTypeFilterChanged(insuranceType: PatientInsuranceType): void {
    this.onCriteriaChanged({ ...this.criteria, insuranceType } as PageCriteriaDTO);
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<PatientPageCriteriaDTO>(PatientPageCriteriaDTO, this.criteria, changedCriteria as PatientPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }
}
