import { Component, OnInit } from '@angular/core';
import { VideoCallStorage } from 'app/component/ui/videoCall/VideoCallStorage';

@Component({
  selector: 'app-video-call-setting',
  templateUrl: './VideoCallSettingComponent.html',
  styleUrls: [ './VideoCallSettingComponent.scss' ]
})
export class VideoCallSettingComponent implements OnInit {

  constructor(public videoCallStorage: VideoCallStorage) {
  }

  public ngOnInit(): void {
  }

}
