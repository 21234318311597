import { TransformFnParams } from 'class-transformer/types/interfaces';
import { TransformationType } from 'class-transformer';

export class DoctorSpecialitiesHelper {
  public static transform(params: TransformFnParams): any {
    if (params.type === TransformationType.CLASS_TO_PLAIN) {
      return params.value ? [ params.value ] : [];
    } else if (params.type === TransformationType.PLAIN_TO_CLASS) {
      return params.value && params.value?.length > 0 ? params.value[0] : undefined;
    }
  }
}