import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-preview',
  templateUrl: './AvatarPreviewComponent.html',
  styleUrls: [ './AvatarPreviewComponent.scss' ]
})
export class AvatarPreviewComponent implements OnInit {
  @Input() public url: string;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
