import { Injectable } from '@angular/core';
import { IVideoCallService } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { Observable, of } from 'rxjs';
import { ObjectUtil } from '@util/ObjectUtil';
import { VideoCallSimpleInfoDTO } from '@dto/videocall/VideoCallSimpleInfoDTO';
import { VideoCallRoomDTO } from '@dto/videocall/VideoCallRoomDTO';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';
import { VideoCallDoctorDTO } from '@dto/videocall/VideoCallDoctorDTO';
import { Gender } from '@enum/common/Gender';
import { PatientGender } from '@enum/common/PatientGender';
import { VideoCallPatientDTO } from '@dto/videocall/VideoCallPatientDTO';

@Injectable({
  providedIn: 'root'
})
export class GuestVideoCallService implements IVideoCallService {
  public roomName: string;

  public roomToken: string;

  private eventInfo: VideoCallSimpleInfoDTO = ObjectUtil.plainToClass(VideoCallSimpleInfoDTO, {
    couldJoin: true,
    doctorIconDetails: { firstName: 'Erik', lastName: 'Hulse', gender: Gender.MALE } as VideoCallDoctorDTO,
    patientIconDetails: {
      firstName: 'Elizabeth',
      lastName: 'Jones',
      gender: PatientGender.FEMALE
    } as VideoCallPatientDTO,
    visitTypes: [],
    dateTime: new Date(),
    isRoomExist: true,
    isPatientJoined: true,
    specialities: [],
    duration: 30
  });

  constructor() {
  }

  public get canJoin(): boolean {
    return this.eventInfo?.couldJoin;
  }

  public get participant(): IVideoCallUser {
    return this.eventInfo?.patient;
  }

  public get participantIsWaiting(): boolean {
    return this.eventInfo?.isPatientJoined;
  }

  public get roomExists(): boolean {
    return this.eventInfo?.isRoomExist;
  }

  public get me(): IVideoCallUser {
    return this.eventInfo?.doctor;
  }

  public createRoom(eventId: number): Observable<VideoCallRoomDTO> {
    return of(ObjectUtil.plainToClass(VideoCallRoomDTO, { roomName: this.roomName, token: this.roomToken }));
  }

  public getRoom(eventId: number): Observable<VideoCallRoomDTO> {
    return of(ObjectUtil.plainToClass(VideoCallRoomDTO, { roomName: this.roomName, token: this.roomToken }));
  }

  public closeRoom(eventId: number): Observable<unknown> {
    return of();
  }

  public close(): void {
    console.log('close');
  }
}
