import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InvitationRequestDTO } from '@dto/doctor/InvitationRequestDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { InviteService } from '@service/InviteService';
import { Constant } from '@common/Constant';

@Component({
  selector: 'app-invite-patient',
  templateUrl: './InvitePatientComponent.html',
  styleUrls: [ './InvitePatientComponent.scss' ]
})
export class InvitePatientComponent implements OnInit {
  @ViewChild('form') private form: NgForm;

  public data: InvitationRequestDTO = new InvitationRequestDTO();

  public Constant: typeof Constant = Constant;

  constructor(private viewUtil: ViewUtil,
              private inviteService: InviteService) {
  }

  public ngOnInit(): void {
  }

  public invite(): void {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.inviteService.createPatientInvitation(this.data).toPromise()
        .then(() => {
          this.form.resetForm();
          this.viewUtil.showToastSuccess('VIEW.MAIN.INVITE.MESSAGE.SUCCESS');
        })
        .catch((err) => {
          this.viewUtil.handleServerError(err);
        });
    }
  }
}
