import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/core';
import { EventPatientService } from '@service/event/EventPatientService';
import { Observable } from 'rxjs';
import { HealthConditionAnswerDTO } from '@dto/events/HealthConditionAnswerDTO';

@Component({
  selector: 'app-health-condition',
  templateUrl: './EventHealthConditionComponent.html',
  styleUrls: [ './EventHealthConditionComponent.scss' ]
})
export class EventHealthConditionComponent implements OnInit {
  private eventId: number = this.transition.params().eventId;

  public answers$: Observable<HealthConditionAnswerDTO[]> = this.eventPatientService.getHealthConditionAnswers(this.eventId);

  constructor(private eventPatientService: EventPatientService,
              private transition: Transition) {
  }

  public ngOnInit(): void {
  }

}
