import { Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';

@Component({
  selector: 'app-error-access-denied',
  templateUrl: 'ErrorAccessDeniedComponent.html',
  styleUrls: [ 'ErrorAccessDeniedComponent.scss' ]
})
export class ErrorAccessDeniedComponent implements OnInit, OnDestroy {

  constructor(private stateService: StateService) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public backToHome(): void {
  //  this.stateService.go(State.MAIN.HOME);
    this.stateService.go(State.MAIN.DASHBOARD.DASHBOARD);
  }
}
