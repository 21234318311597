import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { empty, Observable } from 'rxjs';
import { NetworkService } from '@service/NetworkService';

@Injectable()
export class NetworkStatusInterceptor implements HttpInterceptor {
  constructor(private networkService: NetworkService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.networkService.getOnlineStatus()) {
      return Observable.create(empty);
    }

    return next.handle(request);
  }
}
