import { AddressDTO } from '@dto/doctor/AddressDTO';
import { Expose, Transform, Type } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';

export class PracticeDTO {
  public practiceName?: string;

  @Type(() => AddressDTO)
  @Transform((params: TransformFnParams) => params.value || new AddressDTO())
  @Expose({ name: 'addressDto' })
  public address: AddressDTO = new AddressDTO();
}