<div id="layout-event">
  <div class="container-fluid px-0 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <img id="logo" class="mb-8" src="asset/image/logo.svg" alt="Logo"/>

          <ng-container *ngIf="event$ | async as event">
            <p id="status">{{event.status | prefix: 'VIEW.EVENT.CANCEL.STATUS.' | translate}}</p>

            <ng-container *ngIf="event.status === EventStatus.UPCOMING">
              <hr>

              <p class="mb-0">{{event.doctor.title}} {{event.doctor.firstName}} {{event.doctor.lastName}}</p>
              <p>{{event.dateTime | date:'EEEE, MMM. dd \'at\' h:mm a.'}}</p>

              <div>
                <button type="button" class="btn btn-primary" (click)="cancel()">
                  <div class="mx-3">{{'VIEW.EVENT.CANCEL.ACTION.CANCEL' | translate}}</div>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
