import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=checkbox][formControlName][array],input[type=checkbox][formControl][array],input[type=checkbox][ngModel][array])',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCheckBoxArrayValueAccessorDirective,
      multi: true
    }
  ]
})
export class InputCheckBoxArrayValueAccessorDirective extends CheckboxControlValueAccessor {

  @Input()
  public value: any;

  private modelValue: any[] = [];

  public onChange = (__: any): void => {};  // avoid shadowing lodash _

  public onTouched = (): void => {};

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super(renderer, elementRef);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public registerOnChange(fn: (__: any) => {}): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('change', [ '$event.target.checked' ])
  public onInputChange(value: boolean): void {
    if (value) {
      if (!this.modelValue) {
        this.modelValue = [];
      }

      this.modelValue.push(this.value);
    }
    else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      _.isObject(this.value) ? _.remove(this.modelValue, this.value) : _.pull(this.modelValue, this.value);
    }

    this.onChange(_.cloneDeep(this.modelValue.sort()));
  }

  @HostListener('blur')
  public onInputBlur(): void {
    this.onTouched();
  }

  public writeValue(value: any[]): void {
    this.modelValue = value;

    if (_.isObject(this.value) ? _.find(this.modelValue, this.value) : _.includes(this.modelValue, this.value)) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', true);
    }
    else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', false);
    }
  }
}
