import { DoctorTabName } from '@enum/doctor/DoctorTabName';

export class UserRegistrationStatusDTO {

  public completedProfileCategories: DoctorTabName[];

  public completed: boolean;

  public visibleProfileCategories: DoctorTabName[];

  public requiredProfileCategories: DoctorTabName[];
}

