import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VideoCallStorage } from 'app/component/ui/videoCall/VideoCallStorage';
import { Subscription } from 'rxjs';
import { VideoCallRoomDTO } from '@dto/videocall/VideoCallRoomDTO';

@Component({
  selector: 'app-video-call',
  templateUrl: './VideoCallComponent.html',
  styleUrls: [ './VideoCallComponent.scss' ],
  providers: [ VideoCallStorage ]
})
export class VideoCallComponent implements OnInit, OnDestroy {
  @Input() public identifier: string | number;

  @Output() public joinedToRoom: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscription: Subscription = new Subscription();

  constructor(public videoCallStorage: VideoCallStorage) {
  }

  public ngOnInit(): void {
    this.videoCallStorage.id = this.identifier;

    this.subscription.add(
      this.videoCallStorage.roomDetails$.subscribe((value: VideoCallRoomDTO) => {
        this.joinedToRoom.emit(value !== null);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
