import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { Exclude } from 'class-transformer';

export class WorkingDaysTimeRangeDTO extends IdentifiableObjectDTO<number> {
  public id: number;

  public timeFrom: string;

  public timeTo: string;

  public zone: string;

  @Exclude({ toPlainOnly: true })
  public isEditing: boolean;
}