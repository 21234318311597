import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkingDaysTimetableDTO } from '@dto/workingDay/WorkingDaysTimetableDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { HttpClient } from '@angular/common/http';
import { DayOfWeek } from '@enum/common/DayOfWeek';
import { WorkingDaysCreateDTO } from '@dto/workingDay/WorkingDaysCreateDTO';
import { WorkingDaysTimeRangeDTO } from '@dto/workingDay/WorkingDaysTimeRangeDTO';
import { VacationDTO } from '@dto/workingDay/VacationDTO';
import { EventSlot } from '@local/scheduler/EventSlot';
import { EventsSummaryDTO } from '@dto/events/EventsSummaryDTO';
import { EventSlotMapper } from 'app/data/mapper/EventSlotMapper';
import { HttpUtil } from '@util/HttpUtil';

@Injectable({
  providedIn: 'root'
})
export class DoctorWorkingDayService {

  constructor(private http: HttpClient) {
  }

  public getWorkingHours(): Observable<WorkingDaysTimetableDTO[]> {
    return this.http.get<WorkingDaysTimetableDTO[]>(`${ ApplicationConfig.apiUrl }/timetables`)
      .pipe(
        map((response: WorkingDaysTimetableDTO[]) => ObjectUtil.plainToClassArray(WorkingDaysTimetableDTO, response)),
        map((workingDays: WorkingDaysTimetableDTO[]) => this.groupDays(workingDays))
      );
  }

  public getWorkingHoursForDoctor(doctorId: number): Observable<WorkingDaysTimetableDTO[]> {
    return this.http.get<WorkingDaysTimetableDTO[]>(`${ ApplicationConfig.apiUrl }/timetables/doctors/${ doctorId }`)
      .pipe(
        map((response: WorkingDaysTimetableDTO[]) => ObjectUtil.plainToClassArray(WorkingDaysTimetableDTO, response)),
        map((workingDays: WorkingDaysTimetableDTO[]) => this.groupDays(workingDays))
      );
  }

  public createWorkingHour(data: WorkingDaysCreateDTO): Observable<any> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/timetables`, ObjectUtil.classToPlain(data));
  }

  public deleteWorkingHour(timetableId: number): Observable<any> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/timetables/${ timetableId }`);
  }

  public updateWorkingHour(data: WorkingDaysTimeRangeDTO): Observable<any> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/timetables/${ data.id }`, { timeRange: ObjectUtil.classToPlain(data) });
  }

  public getVacationsForDoctor(doctorId: number): Observable<VacationDTO[]> {
    return this.http.get<VacationDTO[]>(`${ ApplicationConfig.apiUrl }/timetables/doctors/${ doctorId }/absences`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(VacationDTO, response?.absences))
      );
  }

  public getVacations(): Observable<VacationDTO[]> {
    return this.http.get<VacationDTO[]>(`${ ApplicationConfig.apiUrl }/timetables/absences`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(VacationDTO, response?.absences))
      );
  }

  public createVacation(data: VacationDTO): Observable<any> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/timetables/absences`, ObjectUtil.classToPlain(data));
  }

  public deleteVacation(id: number): Observable<any> {
    return this.http.delete(`${ ApplicationConfig.apiUrl }/timetables/absences/${ id }`);
  }

  public updateVacation(vacation: VacationDTO): Observable<any> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/timetables/absences/${ vacation.id }`, ObjectUtil.classToPlain(vacation));
  }

  public getVacationConflicts(dateFrom: Date, dateTo: Date, wholeDay: boolean): Observable<EventSlot[]> {
    const params = HttpUtil.createHttpParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      wholeDay
    });

    return this.http.get<EventSlot[]>(`${ ApplicationConfig.apiUrl }/timetables/absences/conflicts`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(EventsSummaryDTO, response)),
        map((events: EventsSummaryDTO[]) => events.map((item: EventsSummaryDTO) => EventSlotMapper.eventFromTimetableToEventSlot(item)))
      );
  }

  public getWorkingHoursConflicts(id: number, timeFrom?: string, timeTo?: string): Observable<EventSlot[]> {
    const params = HttpUtil.createHttpParams({ timeFrom, timeTo });

    return this.http.get<EventSlot[]>(`${ ApplicationConfig.apiUrl }/timetables/${ id }/conflicts`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(EventsSummaryDTO, response)),
        map((events: EventsSummaryDTO[]) => events.map((item: EventsSummaryDTO) => EventSlotMapper.eventFromTimetableToEventSlot(item)))
      );
  }

  private groupDays(workingDays: WorkingDaysTimetableDTO[]): WorkingDaysTimetableDTO[] {
    {
      let result = [];
      const availableDays: DayOfWeek[] = workingDays.map(item => item.dayOfWeek);

      Object.keys(DayOfWeek).forEach((key: string) => {
        const index = availableDays.indexOf(key as DayOfWeek);
        result.push(index > -1 ? workingDays[index] : ObjectUtil.plainToClass(WorkingDaysTimetableDTO, { dayOfWeek: key }));
      });

      return result;
    }
  }
}
