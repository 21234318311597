<form #form="ngForm" novalidate>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'VIEW.MAIN.PATIENT.INSURANCE.REPORT_PROBLEM.TITLE' | translate }}</h4>
    <div class="modal-control">
      <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
        <i-tabler name="x"></i-tabler>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <p class="lead mb-2 font-size-sm">{{ 'VIEW.MAIN.PATIENT.INSURANCE.REPORT_PROBLEM.DESCRIPTION' | translate }}</p>

    <div class="form-group">
      <label for="reportText">{{'VIEW.MAIN.PATIENT.INSURANCE.REPORT_PROBLEM.LABEL' | translate}}</label>
      <textarea id="reportText"
                rows="3"
                class="form-control form-control-sm"
                name="reportText"
                #reportTextModel="ngModel"
                [ngClass]="{'is-invalid': form.touched && reportTextModel.invalid }"
                [maxlength]="Constant.TEXT_MAX_LENGTH"
                [minlength]="Constant.TEXT_MIN_LENGTH"
                [(ngModel)]="reportText" required></textarea>
      <div class="invalid-feedback" *ngIf="form.touched && reportTextModel.invalid">
        <div *ngIf="reportTextModel.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div *ngIf="reportTextModel.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate: { requiredLength: reportTextModel.errors['maxlength'].requiredLength } }}</div>
        <div *ngIf="reportTextModel.errors['minlength']">{{ 'VALIDATION.MINLENGTH' | translate:{ requiredLength: reportTextModel.errors['minlength'].requiredLength } }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()"
            [disabled]="isProcessing">{{ 'COMMON.CANCEL' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="send()"
            [disabled]="isProcessing || form.invalid">{{ 'COMMON.REPORT' | translate }}</button>
  </div>
</form>