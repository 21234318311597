import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { VideoCallStorage } from 'app/component/ui/videoCall/VideoCallStorage';
import {
  connect,
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteAudioTrack,
  RemoteTrack,
  RemoteVideoTrack,
  Room
} from 'twilio-video';
import { Subscription } from 'rxjs';
import { RemoteParticipant } from 'twilio-video/tsdef/RemoteParticipant';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { ViewUtil } from '@util/ViewUtil';

@Component({
  selector: 'app-video-call-room',
  templateUrl: './VideoCallRoomComponent.html',
  styleUrls: [ './VideoCallRoomComponent.scss' ]
})
export class VideoCallRoomComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cameraPreview') private cameraPreview: ElementRef;

  @ViewChild('participants') private participantContainer: ElementRef;

  public currentRoom: Room;

  public isRemoteVideo: boolean = false;

  public ignoreNotifications: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(public videoCallStorage: VideoCallStorage,
              private viewUtil: ViewUtil,
              private modalService: BsModalService,
              private renderer: Renderer2) {
  }

  public ngOnInit(): void {
    this.establishConnection();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    if (this.currentRoom) {
      this.ignoreNotifications = true;
      this.currentRoom.disconnect();
    }
  }

  public ngAfterViewInit(): void {
    this.videoCallStorage.videoLocalTrack$.subscribe((value: LocalVideoTrack) => {
      if (value) {
        const video = value.attach();

        this.renderer.setStyle(video, 'width', '100%');
        this.renderer.setStyle(video, 'height', '100%');

        this.renderer.appendChild(this.cameraPreview.nativeElement, video);
      } else {
        const videoContainer = this.cameraPreview.nativeElement;

        while (videoContainer.lastElementChild) {
          videoContainer.removeChild(videoContainer.lastElementChild);
        }
      }
    });
  }

  private async establishConnection(): Promise<void> {
    const tracks = [];

    const roomDetails = this.videoCallStorage.roomDetails;

    const participantConnected = this.participantConnected.bind(this);
    const participantDisconnected = this.participantDisconnected.bind(this);

    const videoLocalTrack = this.videoCallStorage.getLocalVideoTrack();
    const audioLocalTrack = this.videoCallStorage.getLocalAudioTrack();

    if (videoLocalTrack) {
      tracks.push(videoLocalTrack);
    }

    if (audioLocalTrack) {
      tracks.push(audioLocalTrack);
    }

    try {
      const currentRoom: Room = await connect(roomDetails.token, {
        name: roomDetails.roomName,
        tracks
      });

      currentRoom.participants.forEach(participantConnected);
      currentRoom.on('participantConnected', participantConnected);
      currentRoom.on('participantDisconnected', participantDisconnected);
      currentRoom.once('disconnected', error => currentRoom.participants.forEach(participantDisconnected));

      this.currentRoom = currentRoom;
    } catch (ex) {
      console.log('ex', ex);
    }
  }

  public startMicrophone(): void {
    this.videoCallStorage.generateLocalAudioTrack(true)
      .then((value: LocalAudioTrack) => {
        if (this.currentRoom) {
          this.currentRoom.localParticipant.publishTrack(value);
        }
      });
  }

  public startCamera(): void {
    this.videoCallStorage.generateLocalVideoTrack(true)
      .then((value: LocalVideoTrack) => {
        if (this.currentRoom) {
          this.currentRoom.localParticipant.publishTrack(value);
        }
      });
  }

  public stopMicrophone(): void {
    if (this.currentRoom) {
      this.currentRoom.localParticipant.audioTracks.forEach((publication: LocalAudioTrackPublication) => {
        publication.track.stop();
        publication.unpublish();
      });
    }

    this.videoCallStorage.stopLocalAudioTrack();
  }

  public stopCamera(): void {
    if (this.currentRoom) {
      this.currentRoom.localParticipant.videoTracks.forEach((publication: LocalVideoTrackPublication) => {
        publication.track.stop();
        publication.unpublish();
      });
    }

    this.videoCallStorage.stopLocalVideoTrack();
  }

  public disconnect(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.VIDEO_CALL.VIDEO_ROOM.MESSAGE.CLOSE_ROOM.TITLE',
        message: 'VIEW.VIDEO_CALL.VIDEO_ROOM.MESSAGE.CLOSE_ROOM.DESCRIPTION',
        okText: 'VIEW.VIDEO_CALL.VIDEO_ROOM.MESSAGE.CLOSE_ROOM.OK_BUTTON_TEXT',
        cancelText: 'VIEW.VIDEO_CALL.VIDEO_ROOM.MESSAGE.CLOSE_ROOM.CANCEL_BUTTON_TEXT',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => {
      this.ignoreNotifications = true;

      this.videoCallStorage.closeRoom().toPromise()
        .then(() => {
          if (this.currentRoom) {
            this.currentRoom.disconnect();
          }

          this.videoCallStorage.stopLocalVideoTrack();
          this.videoCallStorage.stopLocalAudioTrack();

          this.back();
        })
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });

    });
  }

  private back(): void {
    this.videoCallStorage.close();
  }

  private participantConnected(participant: RemoteParticipant): void {
    console.log('Participant "%s" connected', participant.identity);

    const div = document.createElement('div');

    div.id = participant.sid;

    div.style.width = '100%';
    div.style.height = '100%';

    const trackSubscribed = this.trackSubscribed.bind(this);
    const trackUnsubscribed = this.trackUnsubscribed.bind(this);

    participant.on('trackSubscribed', (track: RemoteTrack) => trackSubscribed(div, track));
    participant.on('trackSubscribed', (track: RemoteTrack) => {
      if (track.kind === 'video') {
        this.isRemoteVideo = track.isEnabled;

        track.on('disabled', (a) => {
          this.isRemoteVideo = false;
        });

        track.on('enabled', () => {
          this.isRemoteVideo = true;
        });
      }
    });

    participant.on('trackUnsubscribed', (track: RemoteTrack) => trackUnsubscribed(track));

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });

    this.renderer.appendChild(this.participantContainer.nativeElement, div);
  }

  private participantDisconnected(participant: RemoteParticipant): void {
    console.log('Participant "%s" disconnected', participant.identity);

    if (!this.ignoreNotifications) {
      this.viewUtil.showToastWarning('VIEW.VIDEO_CALL.VIDEO_ROOM.MESSAGE.PATIENT_LEFT');
    }

    document.getElementById(participant.sid).remove();
  }

  private trackSubscribed(div: HTMLDivElement, track: RemoteVideoTrack | RemoteAudioTrack): void {
    const trackElement = track.attach();

    if (trackElement instanceof HTMLVideoElement) {
      this.renderer.setStyle(trackElement, 'width', '100%');
      this.renderer.setStyle(trackElement, 'height', '100%');
    }

    this.renderer.appendChild(div, trackElement);
  }

  private trackUnsubscribed(track: RemoteVideoTrack | RemoteAudioTrack): void {
    if (track.kind === 'video') {
      this.isRemoteVideo = false;
    }

    track.detach().forEach(element => element.remove());
  }
}
