import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { State } from 'app/common/State';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { Constant } from 'app/common/Constant';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { Gender } from '@enum/common/Gender';
import { DoctorTitle } from '@enum/doctor/DoctorTitle';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateUtil } from '@util/StateUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DoctorAvatarHelper } from 'app/data/helper/DoctorAvatarHelper';
import { FileUtil } from '@util/FileUtil';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { SpecialityDTO } from '@dto/doctor/SpecialityDTO';
import { Observable } from 'rxjs/internal/Observable';
import { DictionaryModel } from '@model/DictionaryModel';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-update-doctor-info',
  templateUrl: './ProfileBasicDataEditComponent.html',
  styleUrls: [ './ProfileBasicDataEditComponent.scss' ]
})

export class ProfileBasicDataEditComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public doctor: DoctorDTO;

  public Constant: typeof Constant = Constant;

  public genderOptions: string[] = Object.keys(Gender);

  public titleOptions: string[] = Object.keys(DoctorTitle);

  public disabledSubmit: boolean = false;

  public specialities$: Observable<SpecialityDTO[]> = this.dictionaryModel.specialities$;

  public DoctorStatus: typeof DoctorStatus = DoctorStatus;

  public doctorIsActive$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.ACTIVE);

  constructor(private stateUtil: StateUtil,
              private currentDoctorService: CurrentDoctorService,
              private applicationModel: ApplicationModel,
              private fileUtil: FileUtil,
              private modalService: BsModalService,
              private userModel: UserModel,
              private viewUtil: ViewUtil,
              private dictionaryModel: DictionaryModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PROFILE.BASIC_DATA);
  }

  public ngOnInit(): void {
    this.currentDoctorService.getBasicData()
      .subscribe((doctor: DoctorDTO) => {
          this.doctor = doctor;
        },
        (error) => {
          this.viewUtil.handleServerError(error);
        });
  }

  public save(): void {
    if (this.form.valid) {
      if (!this.doctor.businessOwner) {
        this.doctor.ustId = null;
      }
      this.disabledSubmit = true;
      this.currentDoctorService.update(this.doctor)
        .toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.UPDATE_SUCCESS');
          this.backToProfile();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        })
        .finally(() => {
          this.disabledSubmit = false;
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public deleteAvatar(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.PROFILE_DELETE_AVATAR.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then(() => {
        this.currentDoctorService.deleteAvatar()
          .toPromise()
          .then(() => {
            this.doctor.isAvatar = false;
            this.doctor.avatarUrl = DoctorAvatarHelper.getUrl(null, this.doctor?.gender);
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.PROFILE_DELETE_AVATAR.SUCCESS');
          })
          .catch((error) => {
            this.viewUtil.handleServerError(error);
          });
      });
  }

  public uploadAvatar(avatar: Blob): void {
    const file = this.fileUtil.blobToFile(avatar, 'avatar.jpg');

    this.currentDoctorService.uploadAvatar(file).toPromise()
      .then(() => {
        const url = URL.createObjectURL(file);

        this.doctor.avatarUrl = url;
        this.doctor.isAvatar = true;
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.BASIC_DATA.MESSAGE.UPLOAD_AVATAR_SUCCESS');
      })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public backToProfile(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE.BASIC_DATA);
  }
}

