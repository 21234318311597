import { Injectable } from '@angular/core';
import { DictionaryService } from '@service/DictionaryService';
import { QualificationDTO } from '@dto/doctor/QualificationDTO';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SpecialityDTO } from '@dto/doctor/SpecialityDTO';

@Injectable({
  providedIn: 'root'
})
export class DictionaryModel {
  private _qualifications$: Observable<QualificationDTO[]>;

  private _specialities$: Observable<SpecialityDTO[]>;

  constructor(private dictionaryService: DictionaryService) {
  }

  public get qualifications$(): Observable<QualificationDTO[]> {
    return this._qualifications$ || this.getQualifications();
  }

  public get specialities$(): Observable<SpecialityDTO[]> {
    return this._specialities$ || this.getSpecialities();
  }

  private getQualifications(): Observable<QualificationDTO[]> {
    this._qualifications$ = this.dictionaryService.getQualifications()
      .pipe(
        shareReplay()
      );

    return this._qualifications$;
  }

  private getSpecialities(): Observable<QualificationDTO[]> {
    this._specialities$ = this.dictionaryService.getSpecialities()
      .pipe(
        shareReplay()
      );

    return this._specialities$;
  }
}
