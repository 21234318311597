<ng-container *ngIf="patient$ | async as patient">
  <section class="page">
    <div class="header-container">
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{patient.firstName}} {{patient.lastName}}</div>
      </button>

      <app-page-action-container></app-page-action-container>
    </div>

    <div class="content">
      <app-tab [tabs]="tabs"></app-tab>

      <div class="pt-4">
        <ui-view></ui-view>
      </div>
    </div>
  </section>
</ng-container>
