import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { Transform } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';
import { PatientGender } from '@enum/common/PatientGender';
import { PatientAvatarHelper } from 'app/data/helper/PatientAvatarHelper';

export class VideoCallPatientDTO extends IdentifiableObjectDTO<number> implements IVideoCallUser {
  @Transform((params: TransformFnParams) => PatientAvatarHelper.getUrl(params.value, params.obj?.gender, params.obj?.isAdult))
  public avatarUrl: string;

  public firstName: string;

  public lastName: string;

  public gender: PatientGender;

  public isAdult: boolean;
}