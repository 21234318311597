import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { DateUtil } from '@util/DateUtil';
import { Transform } from 'class-transformer';

export class SickLeaveDTO extends IdentifiableObjectDTO<number> {
  @Transform(DateUtil.dateConversion)
  public dateFrom: Date;

  @Transform(DateUtil.dateConversion)
  public dateTo: Date;
}