import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceDTO } from '@dto/insurance/InsuranceDTO';
import { PatientModel } from '@model/PatientModel';
import { Constant } from '@common/Constant';
import { PatientInsuranceReportProblemPopupComponent } from 'app/component/view/main/patient/details/insurance/popup/PatientInsuranceReportProblemPopupComponent';
import { INSURANCE_SERVICE } from '@local/interface/IInsuranceService';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PatientService } from '@service/PatientService';

@Component({
  selector: 'app-patient-insurance',
  templateUrl: './PatientInsuranceComponent.html',
  styleUrls: [ './PatientInsuranceComponent.scss' ]
})
export class PatientInsuranceComponent implements OnInit {
  public insurance$: Observable<InsuranceDTO> = this.patientModel.getInsurance();

  public Constant: typeof Constant = Constant;

  constructor(public patientModel: PatientModel,
              private modalService: BsModalService) {
  }

  public ngOnInit(): void {
  }

  public reportProblem(): void {
    this.modalService.show(PatientInsuranceReportProblemPopupComponent, {
      providers: [
        {
          provide: INSURANCE_SERVICE,
          useExisting: PatientService
        }
      ],
      initialState: {
        id: this.patientModel.id
      }
    });
  }
}
