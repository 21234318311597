import { Component } from '@angular/core';
import { ApplicationConfig } from '@config/ApplicationConfig';

@Component({
  selector: 'app-navbar',
  templateUrl: './NavbarComponent.html',
  styleUrls: [ './NavbarComponent.scss' ]
})
export class NavbarComponent {
  public websiteUrl: string = ApplicationConfig.websiteUrl;

  public collapsed: boolean = true;

  public menuItems: { label: string, link: string }[] = [
    { label: 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.DOCTORS', link: 'doctors' },
    { label: 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.PATIENTS', link: 'patients' },
    { label: 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.HEALTH_APPLICATIONS', link: 'health-apps' },
    { label: 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.ABOUT_US', link: 'about-us' },
    { label: 'VIEW.PRELIMINARY.PRELIMINARY.HEADER.CONTACT', link: 'contact-us' }
  ];

  public toggle(): void {
    this.collapsed = !this.collapsed;
  }

  public onCollapsed(value: boolean): void {
    this.collapsed = value;
  }
}
