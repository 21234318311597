import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-requirements',
  templateUrl: './PasswordRequirementsComponent.html',
  styleUrls: [ './PasswordRequirementsComponent.scss' ]
})
export class PasswordRequirementsComponent implements OnInit {
  @Input() public passwordControl: FormControl;

  public requirements: { [key: string]: string } = {
    'AT_LEAST_CHARACTERS': 'minLength',
    'AT_LEAST_ONE_SPECIAL_CHARACTER': 'specialChars',
    'AT_LEAST_ONE_CAPITAL_LETTER': 'upperCase',
    'AT_LEAST_ONE_DIGIT': 'numberChars'
  };

  constructor() {
  }

  public ngOnInit(): void {
  }
}

