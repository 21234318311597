import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { Gender } from '@enum/common/Gender';
import { DoctorTitle } from '@enum/doctor/DoctorTitle';
import { Transform } from 'class-transformer';
import { TransformFnParams } from 'class-transformer/types/interfaces';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';
import { DoctorAvatarHelper } from 'app/data/helper/DoctorAvatarHelper';

export class VideoCallDoctorDTO extends IdentifiableObjectDTO<number> implements IVideoCallUser {
  @Transform((params: TransformFnParams) => DoctorAvatarHelper.getUrl(params.value, params.obj?.gender))
  public avatarUrl: string;

  public firstName: string;

  public lastName: string;

  public gender: Gender;

  public title: DoctorTitle;
}