export class Layout {

  public static readonly RAW: LayoutType          = 'raw';
  public static readonly PRELIMINARY: LayoutType  = 'preliminary';
  public static readonly MAIN: LayoutType         = 'main';
  public static readonly VIDEOCALL: LayoutType    = 'videocall';

}

export type LayoutType = ('raw' | 'preliminary' | 'main' | 'videocall');
