import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable } from 'rxjs';
import { AuthModel } from 'app/model/AuthModel';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class OAuth2Interceptor implements HttpInterceptor {

  constructor(private authModel: AuthModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ( this.authModel.token && request.method 
         && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const authorizedRequest: HttpRequest<any> = request.clone(
      { 
          headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.authModel.token.accessToken) 
      });
      
      return next.handle(authorizedRequest);
    }
    else {
      return next.handle(request);
    }
  }
}
