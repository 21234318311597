import { Component, Inject, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewUtil } from '@util/ViewUtil';
import { Transition } from '@uirouter/core';
import { InsuranceDTO } from '@dto/insurance/InsuranceDTO';
import { Observable } from 'rxjs';
import { Constant } from '@common/Constant';
import { PatientInsuranceReportProblemPopupComponent } from 'app/component/view/main/patient/details/insurance/popup/PatientInsuranceReportProblemPopupComponent';
import { EventPatientService } from '@service/event/EventPatientService';
import { INSURANCE_SERVICE } from '@local/interface/IInsuranceService';
import { VIDEO_CALL_SERVICE } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { DoctorVideoCallService } from '@service/event/DoctorVideoCallService';

@Component({
  selector: 'app-event-insurance',
  templateUrl: './EventInsuranceComponent.html',
  styleUrls: [ './EventInsuranceComponent.scss' ]
})
export class EventInsuranceComponent implements OnInit {
  private eventId: number = this.transition.params().eventId;

  public insurance$: Observable<InsuranceDTO> = this.eventPatientService.getInsurance(this.eventId);

  public Constant: typeof Constant = Constant;

  constructor(private modalService: BsModalService,
              private eventPatientService: EventPatientService,
              private transition: Transition,
              @Inject(VIDEO_CALL_SERVICE) public videoCallService: DoctorVideoCallService,
              public viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
  }

  public reportProblem(): void {
    this.modalService.show(PatientInsuranceReportProblemPopupComponent, {
      providers: [
        {
          provide: INSURANCE_SERVICE,
          useExisting: EventPatientService
        }
      ],
      initialState: {
        id: this.eventId
      }
    });
  }
}
