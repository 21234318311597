<div class="content">
  <div>
    <app-calendar-selector
      [startDate]="startDate"
      (changeDateRange)="onDateRangeChanged($event)"></app-calendar-selector>
  </div>

  <div class="scheduler-container">
    <section class="weeks-container">
      <div *ngFor="let dayItem of data">
        <app-scheduler-day-header [data]="dayItem"></app-scheduler-day-header>
      </div>
    </section>

    <section class="slots-container">
      <div *ngFor="let dayItem of data">
        <app-scheduler-slots-grid [data]="dayItem.slots" [slotTemplate]="slotTemplate"></app-scheduler-slots-grid>
      </div>
    </section>
  </div>
</div>

<!--Templates-->
<ng-template #slotTemplate let-slot>
  <app-doctor-scheduler-slot [slot]="slot"></app-doctor-scheduler-slot>
</ng-template>

<!--Portal-->

<app-page-action-portal name="doctor">
  <button type="button"
          class="btn btn-primary"
          (click)="openWorkingHours()">{{ 'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.WORKING_HOURS' | translate}} </button>

  <button type="button"
          class="btn btn-primary"
          (click)="openVacationsAndAbsences()">{{ 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.VACATION' | translate}} </button>
</app-page-action-portal>