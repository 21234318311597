import { Component } from '@angular/core';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-settings',
  templateUrl: './SettingsComponent.html',
  styleUrls: [ './SettingsComponent.scss' ]
})
export class SettingsComponent {

  constructor(public userModel: UserModel) {
  }

}
