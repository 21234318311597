<div class="container px-0">
  <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.PRACTICE.PRACTICE_INFO' | translate }}</div>

  <div class="row mt-4">
    <div class="col-12 col-sm-6 col-md-4">
      <div class="form-group">
        <label for="practiceName">{{ 'VIEW.MAIN.DOCTOR.PRACTICE.PRACTICE_NAME' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="practiceName" id="practiceName"
               [ngClass]="{'is-invalid': submitted && practiceName.invalid }" #practiceName="ngModel"
               [(ngModel)]="practice.practiceName"
               [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

        <div class="invalid-feedback" *ngIf="submitted && practiceName.invalid">
          <div *ngIf="practiceName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="practiceName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: practiceName.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="streetWithNumber">{{ 'VIEW.MAIN.DOCTOR.PRACTICE.STREET' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="streetWithNumber" id="streetWithNumber"
               [ngClass]="{'is-invalid': submitted && streetWithNumber.invalid }"
               #streetWithNumber="ngModel"
               [(ngModel)]="practice.address.streetWithNumber"
               [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

        <div class="invalid-feedback" *ngIf="submitted && streetWithNumber.invalid">
          <div *ngIf="streetWithNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="streetWithNumber.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: streetWithNumber.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="cityCode">{{ 'VIEW.MAIN.DOCTOR.PRACTICE.POST_CODE' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="cityCode" id="cityCode"
               [ngClass]="{'is-invalid': submitted && cityCode.invalid }" #cityCode="ngModel"
               [(ngModel)]="practice.address.cityCode"
               [pattern]="Constant.POSTAL_CODE_PATTERN"
               [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

        <div class="invalid-feedback" *ngIf="submitted && cityCode.invalid">
          <div *ngIf="cityCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="cityCode.errors['pattern']">{{ 'VALIDATION.POSTAL_CODE' | translate }}</div>
          <div
            *ngIf="cityCode.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: cityCode.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="city">{{ 'VIEW.MAIN.DOCTOR.PRACTICE.CITY' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="city" id="city"
               [ngClass]="{'is-invalid': submitted && city.invalid }" #city="ngModel"
               [(ngModel)]="practice.address.city"
               [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

        <div class="invalid-feedback" *ngIf="submitted && city.invalid">
          <div *ngIf="city.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="city.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: city.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
