import { VisitTypeDTO } from '@dto/doctor/VisitTypeDTO';
import { DateUtil } from '@util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { SlotStatus } from '@local/scheduler/SlotStatus';

export class EventSlot {
  @Transform(DateUtil.dateTimeConversion)
  public startDate: Date;

  @Transform(DateUtil.dateTimeConversion)
  public endDate: Date;

  public duration: number;

  public isVideoCall: boolean;

  public eventId: number;

  public patientId: number;

  public patientFirstName: string;

  public patientLastName: string;

  public newPatient: boolean;

  public isUnregisteredPatient: boolean;

  public noShow: boolean;

  @Type(() => VisitTypeDTO)
  public visitType: VisitTypeDTO[];

  public activeStatus: SlotStatus;
}