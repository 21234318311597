<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.DASHBOARD.NEW_DOCTORS.TITLE' | translate}}</div>
  </div>

  <div class="content">
    <app-data-grid *ngIf="data" [data]="data.content"
                   [criteria]="criteria"
                   [context]="{ host: this }"
                   [selectionEnabled]="false"
                   [showFilters]="false"
                   [showSearch]="false"
                   [showPagination]="true"
                   [pageTotal]="data.totalPages" [itemTotal]="data.totalElements"
                   (criteriaChanged)="onCriteriaChanged($event)"
                   [columns]="$any([
                    { colId: '1', headerName: ('VIEW.MAIN.DOCTOR.DATA.DATE_APPLIED' | translate), maxWidth: 140, field: 'dateApplied', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTemplate, preventSelection: false } },
                    { colId: '2', headerName: ('VIEW.MAIN.DOCTOR.DATA.STATUS' | translate), maxWidth: 110, field: 'status', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: statusTemplate, preventSelection: false } },
                    { colId: '3', headerName: ('VIEW.MAIN.DOCTOR.DATA.TITLE' | translate), maxWidth: 120, field: 'title', suppressSizeToFit: true },
                    { colId: '4', headerName: ('VIEW.MAIN.DOCTOR.DATA.FIRST_NAME' | translate), maxWidth: 100, field: 'firstName', suppressSizeToFit: true },
                    { colId: '5', headerName: ('VIEW.MAIN.DOCTOR.DATA.LAST_NAME' | translate), maxWidth: 130, field: 'lastName', suppressSizeToFit: true },
                    { colId: '6', headerName: ('VIEW.MAIN.DOCTOR.DATA.SPECIALITY' | translate), maxWidth: 150, field: 'speciality', overrideSortField: 'specialities', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: specialityTemplate, preventSelection: false } },
                    { colId: '7', headerName: ('VIEW.MAIN.DOCTOR.DATA.PHONE_NUMBER' | translate), maxWidth: 135, field: 'phoneNumber', suppressSizeToFit: true },
                    { colId: '8', headerName: ('VIEW.MAIN.DOCTOR.DATA.EMAIL' | translate), minWidth: 150, flex: 1, field: 'email', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: emailTemplate, preventSelection: false } },
                    { colId: '9', headerName: ('COMMON.ACTIONS' | translate), headerClass: 'header-right', cellClass: 'cell-right', sortable: false, minWidth: 150, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: menuTemplate, preventSelection: false } },
                  ])">
    </app-data-grid>
  </div>
</section>

<!--Templates-->
<ng-template #statusTemplate let-value="value">
  <span>{{value | prefix: 'ENUM.DOCTOR_STATUS.' | translate}}</span>
</ng-template>

<ng-template #dateTemplate let-value="value">
  <span>{{value | date:Constant.DATE_FORMAT}}</span>
</ng-template>

<ng-template #specialityTemplate let-value="value">
  <span>{{value?.name}}</span>
</ng-template>

<ng-template #emailTemplate let-value="value">
  <a href="mailto:{{value}}">{{value}}</a>
</ng-template>

<ng-template #menuTemplate let-data>
  <button type="button"
          class="btn btn-link text-normal btn-link--black font-size-sm"
          (click)="goDetails(data)">{{'COMMON.SHOW_DETAILS' | translate}}</button>
</ng-template>