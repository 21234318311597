<div id="sidebar">
  <div class="nav">
    <ng-container *ngFor="let sideBarItem of sideBarItems$ | async;trackBy:trackBy;">
      <button type="button" role="button" class="nav-item" [ngClass]="{ 'active': sideBarItem.selected }"
              (click)="goToSideBarItem(sideBarItem)">
        <div class="d-flex align-items-center">
          <div class="icon mr-1" *ngIf="sideBarItem?.icon">
            <i-tabler name="{{sideBarItem.icon}}"></i-tabler>
            <div class="icon-badge" *ngIf="sideBarItem?.badge">
              {{sideBarItem.badge}}
            </div>
            <div class="icon-badge" *ngIf="sideBarItem?.badge$ && sideBarItem.badge$() | async as badge">
              {{badge > 99 ? 99 : badge}}
            </div>
          </div>
          <span class="label d-inline d-md-none d-lg-inline">{{ sideBarItem.label | translate }}</span>
        </div>
      </button>
    </ng-container>
  </div>

  <div id="version" class="d-flex d-md-none d-lg-flex justify-content-center p-2" (mouseenter)="versionMouseOver = true"
       (mouseleave)="versionMouseOver = false">
    <span [style.visibility]="versionMouseOver ? 'visible' : 'hidden'">{{ applicationVersion }}</span>
    <span [style.visibility]="versionMouseOver ? 'visible' : 'hidden'" class="ml-1">[{{ environmentName }}]</span>
  </div>

</div>