import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

export const fileAllowedExtensionsValidator = (value: string[]): ValidatorFn => {

  return (control: AbstractControl): ValidationErrors => {
    const filenameLowercase: string = (control.value as File)?.name?.toLowerCase();
    const extension: string = filenameLowercase ? filenameLowercase.substring(_.lastIndexOf(filenameLowercase, '.'), filenameLowercase.length) : null;
    return (value.length > 0 && extension && !_.includes(value, extension)) ? { fileAllowedExtensions: { allowedExtensions: value.join(', '), extension: extension } } : null;
  };
};

@Directive({
  selector: '[appFileAllowedExtensions][formControlName],[appFileAllowedExtensions][formControl],[appFileAllowedExtensions][ngModel]',
  providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: FileAllowedExtensionsValidator,
        multi: true
      }
    ]
})
export class FileAllowedExtensionsValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appFileAllowedExtensions: string[];

  private validator: ValidatorFn;

  private onChange: () => void;

  public ngOnInit(): void {
    this.validator = fileAllowedExtensionsValidator(this.appFileAllowedExtensions);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appFileAllowedExtensions') {
        this.validator = fileAllowedExtensionsValidator(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appFileAllowedExtensions !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}

