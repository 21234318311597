<ng-container *ngIf="doctor$ | async as doctor">
  <section class="page">
    <div class="header-container">
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div *ngIf="doctorModel.needVerification">{{'VIEW.MAIN.DOCTOR.VERIFY_NEW_DOCTOR' | translate}}</div>
        <div *ngIf="!doctorModel.needVerification">{{doctor.firstName}} {{doctor.lastName}}</div>
      </button>

      <div class="actions">
        <ng-container *ngIf="doctorModel.needVerification && !doctorModel.wasDeclined">
          <button type="button"
                  *ngIf="doctor.resendConfirmationLinkAvailable"
                  class="btn btn-outline-primary"
                  (click)="resendConfirmationLink()">{{'VIEW.MAIN.DOCTOR.ACTION.RESEND_CONFIRMATION_LINK.RESEND_CONFIRMATION_LINK' | translate}}</button>

          <ng-container *ngIf="doctor.emailVerified">
            <button type="button"
                    class="btn btn-danger"
                    (click)="decline()">{{'VIEW.MAIN.DOCTOR.ACTION.DECLINE.DECLINE' | translate}}</button>
            <button type="button" class="btn btn-primary"
                    (click)="accept()">{{'VIEW.MAIN.DOCTOR.ACTION.ACCEPT.ACCEPT' | translate}}</button>
          </ng-container>
        </ng-container>

        <app-page-action-container name="doctor"></app-page-action-container>
      </div>
    </div>

    <div class="content">
      <app-tab [tabs]="tabs"></app-tab>

      <div class="pt-4">
        <ui-view></ui-view>
      </div>
    </div>
  </section>
</ng-container>
