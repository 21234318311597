import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/common/Constant';
import FileSaver from 'file-saver';
import * as URI from 'urijs';
import * as blueImpLoadImage from 'blueimp-load-image';
import { Base64 } from 'js-base64';

@Injectable({ providedIn: 'root' })
export class FileUtil {

  public static readonly IMAGE_EXTENSIONS: string[] = [ 'png', 'jpg', 'jpeg', 'jfif', 'bmp', 'gif', 'tiff', 'exif' ];

  public static readonly AUDIO_EXTENSIONS: string[] = [ 'mp3', 'wav', 'aac', 'ogg', 'm4a', 'ac3', 'flac', 'ape' ];

  public static readonly VIDEO_EXTENSIONS: string[] = [ 'avi', 'flv', 'wmv', 'mov', 'mp4', 'mkv' ];

  public static readonly DOC_EXTENSIONS: string[] = [ 'doc', 'docx', 'xls', 'xlsx', 'odt', 'ods', 'odp', 'txt', 'rtf' ];

  public static readonly PDF_EXTENSIONS: string[] = [ 'pdf' ];

  constructor(private http: HttpClient) {
  }

  public isBase64Data(input: any): boolean {
    try {
      return Base64.btoa(Base64.atob(input)) === input;
    } catch (err) {
      return false;
    }
  }

  public base64DataToBlob(data: string): Blob {
    const split = data.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ ab ], { type });
  }

  public blobToBase64Data(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {

      const reader: FileReader = new FileReader();

      reader.onload = (event: ProgressEvent): void => {
        resolve((event.target as FileReader).result as string);
      };

      reader.onerror = (event: ProgressEvent): void => {
        reject();
      };

      reader.onabort = (event: ProgressEvent): void => {
        reject();
      };

      reader.readAsDataURL(blob);
    });
  }

  public arrayBufferToBase64Data(arrayBuffer): string {
    let binaryString: string = '';
    const byteArray: Uint8Array = new Uint8Array(arrayBuffer);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < byteArray.length; i++) {
      binaryString += String.fromCharCode(byteArray[i]);
    }

    return Base64.btoa(binaryString);
  }

  public urlToBlob(url: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'blob' })
        .subscribe((response: Blob) => {
            resolve(response);
          },
          (errorResponse: HttpErrorResponse) => {
            reject(errorResponse);
          });
    });
  }

  public urlToBase64Data(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: 'arraybuffer' })
        .subscribe((response: ArrayBuffer) => {
            resolve(this.arrayBufferToBase64Data(response));
          },
          (errorResponse: HttpErrorResponse) => {
            reject(errorResponse);
          });
    });
  }

  public blobToFile(blob: Blob, fileName: string): File {
    return new File([ blob ], fileName, { lastModified: new Date().getTime(), type: blob.type });
  }

  public fileAsImage(imageFile: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (image, metaData) => {
        resolve(image as HTMLImageElement);
      }, { orientation: true, noRevoke: true });
    });
  }

  public fileAsCanvas(imageFile: File): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (canvas, metaData) => {
        resolve(canvas as HTMLCanvasElement);
      }, { canvas: true, orientation: true });
    });
  }

  public fileAsBase64(imageFile: File): Promise<string> {
    return new Promise((resolve, reject) => {
      blueImpLoadImage(imageFile, (canvas, metaData) => {
        resolve((canvas as HTMLCanvasElement).toDataURL());
      }, { canvas: true, orientation: true });
    });
  }

  public getFileName(blob: Blob, prefix: string = 'file'): string {
    let fileName: string = `${ prefix }.ext`;

    if (blob.type === 'image/png') {
      fileName = fileName.replace('ext', 'png');
    } else if (blob.type === 'image/jpeg') {
      fileName = fileName.replace('ext', 'jpg');
    }

    return fileName;
  }

  public getFileNameFromContentDisposition(contentDisposition: string): string {
    const pattern = /filename="([^;]+)"/ig;
    const matches = pattern.exec(contentDisposition);

    if (matches?.[1]) {
      return matches[1].trim();
    } else {
      return 'untitled';
    }
  }

  public downloadFile(url: string, forceDownload: boolean = false): void {
    if (forceDownload) {
      const forcedUrl: URI = URI(url);
      forcedUrl.addQuery('forceDownload', true);
      window.open(forcedUrl.href(), '_blank');
    } else {
      window.open(url, '_blank');
    }
  }

  public saveFileFromResponse(response: HttpResponse<Blob>): void {
    console.log('response', response);
    const contentDisposition: string = response.headers.get(Constant.HTTP_CONTENT_DISPOSITION_HEADER);
    console.log('contentDisposition', contentDisposition);
    const fileName: string = this.getFileNameFromContentDisposition(contentDisposition);
    FileSaver.saveAs(response.body, fileName);
  }

  public saveFile(file: File): void {
    FileSaver.saveAs(file);
  }

  public saveFileFromBlob(blob: Blob, name: string = null): void {
    let blobFileName: string = name;
    if (!blobFileName) {
      blobFileName = this.getFileName(blob);
    }
    FileSaver.saveAs(blob, blobFileName);
  }
}
