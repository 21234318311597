import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { Token } from 'app/data/local/auth/Token';
import { Exclude } from 'class-transformer';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Duration, Moment } from 'moment';

export class CognitoTokenDTO extends Token {

  public cognitoIdToken: CognitoIdToken;

  public cognitoAccessToken: CognitoAccessToken;

  public cognitoRefreshToken: CognitoRefreshToken;

  constructor() {
    super();
  }

  public static fromCognitoSession(session: CognitoUserSession): CognitoTokenDTO {
    let token: CognitoTokenDTO = new CognitoTokenDTO();

    token.cognitoIdToken = session.getIdToken();
    token.cognitoAccessToken = session.getAccessToken();
    token.cognitoRefreshToken = session.getRefreshToken();

    return token;
  }

  @Exclude()
  public get accessToken(): string {
    // we're using CognitoIdToken for this (why not CognitoAccessToken?)
    return this.cognitoIdToken?.getJwtToken();
  }

  @Exclude()
  public get refreshToken(): string {
    return this.cognitoRefreshToken?.getToken();
  }

  @Exclude()
  public get expiryDate(): Date {
    if (!_.isNil(this.cognitoIdToken) && !_.isNil(this.cognitoIdToken?.getExpiration())) {
      return moment(this.cognitoIdToken.getExpiration() * 1000).toDate();
    } else {
      return null;
    }
  }

  @Exclude()
  public get issuedAt(): Date {
    if (!_.isNil(this.cognitoIdToken) && !_.isNil(this.cognitoIdToken?.getIssuedAt())) {
      return moment(this.cognitoIdToken.getIssuedAt() * 1000).toDate();
    } else {
      return null;
    }
  }

  @Exclude()
  public get lifetime(): Duration {
    if (!_.isNil(this.cognitoIdToken) && !_.isNil(this.cognitoIdToken?.getIssuedAt()) && !_.isNil(this.cognitoIdToken?.getExpiration())) {
      const issuedAt: Moment = moment(this.cognitoIdToken.getIssuedAt() * 1000);
      const expiryDate: Moment = moment(this.cognitoIdToken.getExpiration() * 1000);
      return moment.duration(expiryDate.diff(issuedAt, 'seconds'), 'seconds');
    } else {
      return null;
    }
  }

}
