import { LanguageVersion } from '@local/LanguageVersion';

export function localeParser(value: string): LanguageVersion {
  try {
    const parsedValue = value?.toLowerCase();

    return parsedValue.includes('de') ? LanguageVersion.de : LanguageVersion.en;
  } catch (ex) {
    return LanguageVersion.en;
  }
}
