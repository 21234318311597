import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { InsuranceType } from '@enum/doctor/InsuranceType';
import { ViewUtil } from '@util/ViewUtil';
import { ApplicationModel } from '@model/ApplicationModel';
import { State } from '@common/State';
import { DoctorService } from '@service/DoctorService';
import { Transition } from '@uirouter/core';
import { LicenseType } from '@enum/doctor/LicenseType';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Constant } from '@common/Constant';

@Component({
  selector: 'app-doctor-license-edit',
  templateUrl: './DoctorLicenseEditComponent.html',
  styleUrls: [ './DoctorLicenseEditComponent.scss' ]
})
export class DoctorLicenseEditComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public id: number = this.transition.params().doctorId;

  public disabledSubmit: boolean = false;

  public license: LicenseDTO;

  public insuranceTypeOptions: InsuranceType[] = Object.keys(InsuranceType) as InsuranceType[];

  public LicensePhotoType: typeof LicenseType = LicenseType;

  public Constant: typeof Constant = Constant;

  constructor(
    private viewUtil: ViewUtil,
    private transition: Transition,
    private modalService: BsModalService,
    private applicationModel: ApplicationModel,
    private doctorService: DoctorService) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public save(): void {
    if (this.form.valid) {
      this.disabledSubmit = true;

      this.doctorService.updateLicense(this.license, this.id)
        .toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.UPDATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        })
        .finally(() => {
          this.disabledSubmit = false;
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public changeCertificate(event: Event, certificateType: LicenseType, currentFileUrl: string = null): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files.length > 0) {
      const file = files[0];

      if (currentFileUrl) {
        this.modalService.show(PopupConfirmationComponent, {
          initialState: {
            title: 'VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.REPLACE_CERTIFICATE.TITLE',
            okText: 'COMMON.REPLACE'
          }
        }).content.close$.toPromise()
          .then(() => this.uploadCertificate(file, certificateType))
          .then(() => this.getLicense())
          .then(() => {
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.REPLACE_CERTIFICATE.SUCCESS');
          });
      } else {
        this.uploadCertificate(file, certificateType)
          .then(() => this.getLicense())
          .then(() => {
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.UPLOAD_CERTIFICATE.SUCCESS');
          });
      }
    }
  }

  public deleteCertificate(certificateType: LicenseType): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.DELETE_CERTIFICATE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then(() => this.doctorService.deleteCertificate(this.id, certificateType).toPromise())
      .then(() => this.getLicense())
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.DELETE_CERTIFICATE.SUCCESS');
      });
  }

  public ngOnInit(): void {
    this.getLicense();
  }

  public back(): void {
    history.back();
  }

  private uploadCertificate(file: File, type: LicenseType): Promise<void> {
    return this.doctorService.uploadCertificate(this.id, file, type).toPromise();
  }

  private getLicense(): Promise<LicenseDTO> {
    return this.doctorService.getLicense(this.id).toPromise()
      .then((license: LicenseDTO) => {
        this.license = license;

        return license;
      });
  }
}
