<ng-container *ngIf="data$ | async as data">
  <div class="container px-0">
    <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.CATEGORIES.VIDEO_CALL_CATEGORIES' | translate }}</div>

    <div class="row mt-4">
      <div class="col-12 col-md-8">

        <div class="row mb-2">
          <div class="col-6">{{'VIEW.MAIN.DOCTOR.CATEGORIES.CATEGORY' | translate }}</div>
          <div class="col-6">{{'VIEW.MAIN.DOCTOR.CATEGORIES.MAX_DURATION_TIME' | translate  }}</div>
        </div>

        <ng-container *ngFor="let visitType of data.visitTypes">
          <div class="row value">
            <div class="col-6 font-size-sm">{{visitType?.name}}</div>
            <div
              class="col-6 font-size-sm">{{visitType?.time | durationFormat:'minutes':'m'}} {{'COMMON.TIME.MIN' | translate}}</div>
          </div>
        </ng-container>

        <div class="row mt-4 mb-2">
          <div class="col-6">{{'VIEW.MAIN.DOCTOR.CATEGORIES.EXTRA_TIME_INFO' | translate }}</div>
          <div class="col-6">{{'VIEW.MAIN.DOCTOR.CATEGORIES.MAX_DURATION_TIME' | translate  }}</div>
        </div>

        <div class="row value">
          <div class="col-6 font-size-sm">
            {{'VIEW.MAIN.DOCTOR.CATEGORIES.NEW_PATIENT' | translate }}
          </div>
          <div class="col-6 font-size-sm">
            + {{ data?.newPatientAdditionalTime | durationFormat:'minutes':'m' }} {{'COMMON.TIME.MIN' | translate}}
          </div>
        </div>

        <div class="row value">
          <div class="col-6 font-size-sm">
            {{'VIEW.MAIN.DOCTOR.CATEGORIES.MAX_VC_TIME' | translate }}
          </div>
          <div class="col-6 font-size-sm">
            {{data?.maxVideoCallTime | durationFormat:'minutes':'m'}} {{'COMMON.TIME.MIN' | translate}}
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 col-md-7">
            <span class="font-size-sm" innerHTML="{{'MESSAGE.FEEDBACK' | translate}}"></span>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>
