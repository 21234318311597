<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.SETTINGS.SETTINGS' | translate}}</div>
  </div>

  <div class="content">
    <div class="row mt-2">
      <div class="col-12">
        <app-settings-global-logout></app-settings-global-logout>
      </div>
    </div>
  </div>
</section>
