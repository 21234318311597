<div
  class="d-flex flex-column flex-sm-row justify-content-center align-items-center justify-content-sm-start avatar-manager">
  <div>
    <app-avatar-preview [url]="data?.avatarUrl"></app-avatar-preview>
  </div>

  <div class="avatar-actions mt-2 mt-sm-0 ml-sm-2">
    <button type="button" class="btn btn-outline-primary" (click)="avatarUploader.click()"
            *ngIf="!data.isAvatar">{{ 'COMMON.UPLOAD' | translate}}</button>
    <button type="button" class="btn btn-outline-primary" (click)="avatarUploader.click()"
            *ngIf="data.isAvatar">{{ 'COMMON.REPLACE' | translate}}</button>
    <button type="button" class="btn btn-outline-primary" (click)="edit()"
            *ngIf="data.isAvatar">{{ 'COMMON.EDIT' | translate}}</button>
    <button type="button" class="btn btn-danger" (click)="deleteEvent.emit()"
            *ngIf="data.isAvatar">{{ 'COMMON.DELETE' | translate}}</button>
  </div>

  <input type="file" #avatarUploader name="avatarFile"
         accept="image/*"
         class="d-none"
         (change)="uploadAvatar($event)">
</div>