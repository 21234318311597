import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { Transition } from '@uirouter/angular';
import { LocaleService } from '@service/LocaleService';
import { ViewUtil } from '@util/ViewUtil';
import { UserDecision } from '@service/UserDecisionService';
import { YoungChildDecisionDTO } from '@dto/migratingAccount/YoungChildDecisionDTO';

type ProcessingDataChildDecision = 'ALLOWED' | 'DISALLOWED';

@Component({
  selector: 'app-processing-data-consent',
  templateUrl: './ProcessingDataConsentComponent.html',
  styleUrls: [ './ProcessingDataConsentComponent.scss' ]
})
export class ProcessingDataConsentComponent implements OnInit {
  @ViewChild('baseForm') private baseForm: NgForm;

  public form: FormGroup = this.fb.group({
    personalDataUsage: [ undefined, Validators.required ]
  });

  public linkExpired: boolean = false;

  public patient: PatientDTO;

  public viewReady: boolean = false;

  public decision: ProcessingDataChildDecision;

  private token: string = this.transition.params().token;

  constructor(private fb: FormBuilder,
              private transition: Transition,
              private localeService: LocaleService,
              private viewUtil: ViewUtil,
              private accountMigrationService: UserDecision) {
    const language = transition.params()?.lang;
    this.localeService.setLanguage(language);

    this.getData();
  }

  public ngOnInit(): void {
  }

  public save(): void {
    this.baseForm.onSubmit(null);

    if (this.baseForm.valid) {
      const { personalDataUsage } = this.baseForm.value;
      const decision: YoungChildDecisionDTO = {
        personalDataUsage,
        token: this.token
      };

      this.makeDecision(decision);
    }
  }

  private makeDecision(decision: YoungChildDecisionDTO): void {
    this.accountMigrationService.makeYoungChildDecision(decision).toPromise()
      .then(() => {
        this.decision = decision.personalDataUsage ? 'ALLOWED' : 'DISALLOWED';
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private getData(): void {
    this.accountMigrationService.getAccountByToken(this.token).toPromise()
      .then((patient: PatientDTO) => {
        this.patient = patient;
      })
      .catch(() => {
        this.linkExpired = true;
      })
      .finally(() => {
        this.viewReady = true;
      });
  }
}