import { FreeTimeSlotDTO } from './FreeTimeSlotDTO';
import { EventsSummaryDTO } from './EventsSummaryDTO';
import { Type } from 'class-transformer';

export class EventsTimetableDTO {

  @Type(() => EventsSummaryDTO)
  public events: EventsSummaryDTO[] = [];

  @Type(() => FreeTimeSlotDTO)
  public freeTimeSlots?: FreeTimeSlotDTO[] = [];


  constructor() {
  }
}