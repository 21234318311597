<div class="preview-image">
  <div *ngIf="file.type === FileType.IMAGE">
    <a [href]="file.url" target="_blank">
      <img [src]="file.url"/>
    </a>
  </div>

  <div *ngIf="file.type === FileType.DOCUMENT">
    <a [href]="file.url" class="btn btn-primary">{{'COMMON.DOWNLOAD' | translate}}</a>
  </div>
</div>