import { InsuranceType } from '@enum/doctor/InsuranceType';
import { FileDTO } from '@dto/file/FileDTO';
import { Type } from 'class-transformer';

export class LicenseDTO {

  public ban?: string;

  @Type(() => FileDTO)

  public doctorCertificateBackFile: FileDTO;

  @Type(() => FileDTO)

  public doctorCertificateFrontFile: FileDTO;

  @Type(() => FileDTO)

  public approbationCertificateFile: FileDTO;

  @Type(() => FileDTO)

  public specialtyCertificateFile: FileDTO;

  public insuranceType: InsuranceType;

  public lanrNumber?: string;

  public doctorOfficeNumber?: string;

  public worksWithKids: boolean;
}
  