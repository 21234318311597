import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LocalVideoTrack } from 'twilio-video';
import { VideoCallStorage } from 'app/component/ui/videoCall/VideoCallStorage';

@Component({
  selector: 'app-video-call-waiting-room',
  templateUrl: './VideoCallWaitingRoomComponent.html',
  styleUrls: [ './VideoCallWaitingRoomComponent.scss' ]
})
export class VideoCallWaitingRoomComponent implements OnInit, AfterViewInit {
  @ViewChild('cameraPreview') public cameraPreview: ElementRef;

  private subscription: Subscription = new Subscription();

  constructor(private renderer: Renderer2,
              public videoCallStorage: VideoCallStorage) {
  }

  public ngOnInit(): void {
    this.videoCallStorage.generateLocalVideoTrack(false);
    this.videoCallStorage.generateLocalAudioTrack(false);
  }

  public ngAfterViewInit(): void {
    this.videoCallStorage.videoLocalTrack$.subscribe((value: LocalVideoTrack) => {
      if (value) {
        const video = value.attach();

        this.renderer.setStyle(video, 'width', '100%');
        this.renderer.setStyle(video, 'height', '100%');

        this.renderer.appendChild(this.cameraPreview.nativeElement, video);
      } else {
        const videoContainer = this.cameraPreview.nativeElement;

        while (videoContainer.lastElementChild) {
          videoContainer.removeChild(videoContainer.lastElementChild);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.videoCallStorage.stopLocalVideoTrack();
    this.videoCallStorage.stopLocalAudioTrack();

    this.subscription.unsubscribe();
  }

  public back(): void {
    this.videoCallStorage.close();
  }

  public startVideoCall(): void {
    this.videoCallStorage.joinRoom();
  }
}
