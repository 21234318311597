import { Component, OnInit } from '@angular/core';
import { PageDTO } from '@dto/PageDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { StatisticPageEntityDTO } from '@dto/statistic/StatisticPageEntityDTO';
import { StatisticPageCriteriaDTO } from '@dto/statistic/StatisticPageCriteriaDTO';
import { Constant } from '@common/Constant';
import { StatisticService } from '@service/StatisticService';
import moment from 'moment';
import { StatisticExportCriteriaDTO } from '@dto/statistic/StatisticExportCriteriaDTO';
import { ViewUtil } from '@util/ViewUtil';
import { FileUtil } from '@util/FileUtil';
import { PageUtil } from '@util/PageUtil';

@Component({
  selector: 'app-statistic-list',
  templateUrl: './StatisticListComponent.html',
  styleUrls: [ './StatisticListComponent.scss' ]
})
export class StatisticListComponent implements OnInit {
  public data: PageDTO<StatisticPageEntityDTO>;

  public criteria: StatisticPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  public yearOptions: number[] = this.generateYearsOptions();

  public monthOptions: any = moment.months().map(m => m.toUpperCase());

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private fileUtil: FileUtil,
              private viewUtil: ViewUtil,
              private statisticService: StatisticService) {
    this.criteria = this.createCriteriaObject(transition.params()?.criteria);
    this.applicationModel.selectSideBarItemWithState(State.MAIN.STATISTIC.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }

  public onYearFilterChanged(year: number): void {
    this.onCriteriaChanged({ ...this.criteria, year } as PageCriteriaDTO);
  }

  public onMonthFilterChanged(month: string): void {
    this.onCriteriaChanged({ ...this.criteria, month } as PageCriteriaDTO);
  }

  public export(): void {
    const exportCriteria = new StatisticExportCriteriaDTO();

    exportCriteria.year = this.criteria.year;
    exportCriteria.month = this.criteria.month;

    this.statisticService.getExcelReport(exportCriteria).toPromise()
      .then((file: File) => this.fileUtil.saveFile(file))
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private getData(): Promise<void> {
    return this.statisticService.getPage(this.criteria).toPromise()
      .then((result: PageDTO<StatisticPageEntityDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<StatisticPageCriteriaDTO>(StatisticPageCriteriaDTO, this.criteria, changedCriteria as StatisticPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }

  private createCriteriaObject(rawCriteria: string): StatisticPageCriteriaDTO {
    const currentDate = moment();

    return PageUtil.prepareCriteria<StatisticPageCriteriaDTO>(StatisticPageCriteriaDTO, rawCriteria, {
      month: currentDate.format('MMMM').toUpperCase(),
      year: currentDate.format('YYYY')
    });
  }

  private generateYearsOptions(): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = 2022;
    const result = [];

    for (let year = currentYear; year >= startYear; year--) {
      result.push(year);
    }

    return result;
  }
}
