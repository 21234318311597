<section class="page">
  <div class="header-container">
    <button type="button"
            class="back"
            [uiSref]="State.MAIN.PRACTICE.CATEGORY">
      <i-tabler name="chevron-left"></i-tabler>
      <div>{{ 'VIEW.MAIN.WORKDAYS_SETTING.TITLE' | translate }}</div>
    </button>

  </div>

  <div class="content">

    <app-tab [tabs]="tabs"></app-tab>

    <div class="pt-4">
      <ui-view></ui-view>
    </div>
  </div>
</section>