import { Component } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FileUtil } from '@util/FileUtil';

@Component({
  selector: 'app-avatar-cropper-popup',
  templateUrl: './AvatarCropperPopupComponent.html',
  styleUrls: [ './AvatarCropperPopupComponent.scss' ]
})
export class AvatarCropperPopupComponent extends BaseModalComponent<Blob> {
  public image: File;

  public croppedFile: Blob;

  public transform: ImageTransform = { scale: 1 };

  constructor(public bsModalRef: BsModalRef,
              private fileUtil: FileUtil) {
    super();
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedFile = this.fileUtil.base64DataToBlob(event.base64);
  }
}
