import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-internal-url-browser-popup',
  templateUrl: 'InternalUrlBrowserPopupComponent.html',
  styleUrls: [ 'InternalUrlBrowserPopupComponent.scss' ]
})
export class InternalUrlBrowserPopupComponent implements OnInit {

  public title: string;
  public url: string;

  constructor(private bsModalRef: BsModalRef) {
  }

  public ngOnInit():void {
  }

  public close(): void {
    this.bsModalRef.hide();
  }

}
