import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EventSlot } from '@local/scheduler/EventSlot';

@Component({
  selector: 'app-scheduler-conflicts-popup',
  templateUrl: './SchedulerConflictsPopupComponent.html',
  styleUrls: [ './SchedulerConflictsPopupComponent.scss' ]
})
export class SchedulerConflictsPopupComponent extends BaseModalComponent<boolean> implements OnInit {
  public conflicts: EventSlot[];

  constructor(public bsModalRef: BsModalRef) {
    super();
  }

  public ngOnInit(): void {
  }

  public conflictsChanged(conflicts: EventSlot[]): void {
    if (conflicts.length === 0) {
      this.ok(true);
    }
  }
}
