import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { EventsTimetableDTO } from '@dto/events/EventsTimetableDTO';
import { Constant } from '@common/Constant';
import { VacationDTO } from '@dto/workingDay/VacationDTO';
import { EventDetailsDTO } from '@dto/events/EventDetailsDTO';
import { EventNoteDTO } from '@dto/events/EventNoteDTO';
import { PageDTO } from '@dto/PageDTO';
import { EventPageEntityDTO } from '@dto/events/EventPageEntityDTO';
import { EventPageCriteriaDTO } from '@dto/events/EventPageCriteriaDTO';
import { HttpUtil } from '@util/HttpUtil';
import { DoctorExportCriteriaDTO } from '@dto/doctor/DoctorExportCriteriaDTO';
import { EventExportCriteriaDTO } from '@dto/events/EventExportCriteriaDTO';

@Injectable({ providedIn: 'root' })
export class EventService {

  constructor(private http: HttpClient) {
  }

  public getUpcomingVisits(dateFrom: Date, dateTo: Date): Observable<EventsTimetableDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    const params = HttpUtil.createHttpParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString()
    });

    return this.http.get<EventsTimetableDTO>(`${ ApplicationConfig.apiUrl }/events/doctor-events`, {
      params,
      headers
    })
      .pipe(
        map((response: EventsTimetableDTO) => ObjectUtil.plainToClass(EventsTimetableDTO, response))
      );
  }

  public getUpcomingVisitsForDoctor(dateFrom: Date, dateTo: Date, doctorId: number): Observable<EventsTimetableDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    const params = HttpUtil.createHttpParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString()
    });

    return this.http.get<EventsTimetableDTO>(`${ ApplicationConfig.apiUrl }/events/doctor-events/${ doctorId }`, {
      params,
      headers
    })
      .pipe(
        map((response: EventsTimetableDTO) => ObjectUtil.plainToClass(EventsTimetableDTO, response))
      );
  }

  public getPage(criteria: EventPageCriteriaDTO): Observable<PageDTO<EventPageEntityDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/all`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassFromExist(new PageDTO<EventPageEntityDTO>(EventPageEntityDTO), response))
      );
  }

  public getExcel(criteria: EventExportCriteriaDTO): Observable<File> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/all/export`,
      {
        params: params,
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => new File([ response.body ], `events.xlsx`))
      );
  }

  public getEventDetails(eventId: number): Observable<EventDetailsDTO> {
    return this.http.get<VacationDTO[]>(`${ ApplicationConfig.apiUrl }/doctors/events/${ eventId }`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(EventDetailsDTO, response))
      );
  }

  public updateNote(eventId: number, note: EventNoteDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/events/${ eventId }/note`, ObjectUtil.classToPlain(note));
  }

  public deleteEvent(eventId: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/events/${ eventId }/doctor`);
  }
}