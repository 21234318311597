export class Event {

  public static readonly AUTH = {
    LOGIN: {
      SUCCESS:        'auth_login_success',
      ERROR:          'auth_login_error'
    },
    LOGOUT: {
      SUCCESS:        'auth_logout_success',
      ERROR:          'auth_logout_error'
    },
    ERROR: {
      UNAUTHORIZED:   'auth_error_unauthorized',
      FORBIDDEN:      'auth_error_forbidden',
    },
    SESSION_TIMEOUT:  'auth_sessionTimeout',
    TOKEN_REFRESH:    'auth_tokenRefresh'
  };

  public static readonly USER = {
    GET_CURRENT: {
      SUCCESS:        'user_getCurrent_success',
      ERROR:          'user_getCurrent_error'
    },
    UPDATE_CURRENT: {
      SUCCESS:        'user_updateCurrent_success',
      ERROR:          'user_updateCurrent_error'
    },
    CHANGE_PASSWORD: {
      SUCCESS:        'changePassword_success',
      ERROR:          'changePassword_error'
    },
    SECURITY_DATA:    'user_securityData'
  };

  public static readonly SYSTEM = {
    LOADING:            'system_loading',
    GENERAL_ERROR:      'system_generalError',
    GENERAL_MESSAGE:    'system_generalMessage',
    SET_LANGUAGE:       'system_setLanguage',
    REDRAW:             'system_redraw'
  };

  public static readonly DOCTOR = {
    GET_CURRENT: {
      SUCCESS:        'doctor_getCurrent_success',
      ERROR:          'doctor_getCurrent_error'
    },
    UPDATE_CURRENT: {
      SUCCESS:        'doctor_updateCurrent_success',
      ERROR:          'doctor_updateCurrent_error'
    },
    DELETE_PROFILE_PHOTO: {
      SUCCESS:        'doctor_deleteProfilePhoto_success',
      ERROR:          'doctor_deleteProfilePhoto_error'
    },
    GET_PRACTICE: {
      SUCCESS:        'doctor_getPractice_success',
      ERROR:          'doctor_getPractice_error'
    },
    UPDATE_PRACTICE: {
      SUCCESS:        'doctor_updatePractice_success',
      ERROR:          'doctor_updatePractice_error'
    },
    GET_DEFAULT_TIME: {
      SUCCESS:        'doctor_getDefaultVCTime_success',
      ERROR:          'doctor_getDefaultVCTime_error'
    },
    GET_QUALIFICATIONS: {
      SUCCESS:        'doctor_getQualifications_success',
      ERROR:          'doctor_getQualifications_error'
    },
    NEW_QUALIFICATION: {
      SUCCESS:        'doctor_addQualification_success',
      ERROR:          'doctor_addQualification_error'
    },
    DELETE_QUALIFICATION: {
      SUCCESS:        'doctor_deleteQualification_success',
      ERROR:          'doctor_deleteQualification_error'
    },
    GET_LICENSE: {
      SUCCESS:        'doctor_getLicense_success',
      ERROR:          'doctor_getLicense_error'
    },
    UPDATE_LICENSE: {
      SUCCESS:        'doctor_updateLicense_success',
      ERROR:          'doctor_updateLicense_error'
    },
    DELETE_PHOTO: {
      SUCCESS:        'doctor_deletePhoto_success',
      ERROR:          'doctor_deletePhoto_error'
    },
    UPDATE_PHOTO: {
      SUCCESS:        'doctor_updatePhoto_success',
      ERROR:          'doctor_updatePhoto_error'
    },
    GET_INVOICES: {
      SUCCESS:        'doctor_getInvoices_success',
      ERROR:          'doctor_getInvoices_error'
    },
    GET_INVOICE_DETAILS: {
      SUCCESS:        'doctor_getInvoice_success',
      ERROR:          'doctor_getInvoice_error'
    },
    CREATE_DOCTOR_INVITATION: {
      SUCCESS:        'doctor_createDoctorInvitation_success',
      ERROR:          'doctor_createDoctorInvitation_error'
    },
    CREATE_PATIENT_INVITATION: {
      SUCCESS:        'doctor_createPatientInvitation_success',
      ERROR:          'doctor_createPatientInvitation_error'
    },
    GET_NOTIFICATION: {
      SUCCESS:        'doctor_getNotification_success',
      ERROR:          'doctor_getNotification_error'
    },
    UPDATE_NOTIFICATION: {
      SUCCESS:        'doctor_updateNotification_success',
      ERROR:          'doctor_updateNotification_error'
    },
  };

  public static readonly SCHEDULER = {
    GET_TIMETABLES: {
      SUCCESS:        'doctor_getTimetable_success',
      ERROR:          'doctor_getTimetable_error'
    },
    NEW_TIMETABLE: {
      SUCCESS:        'doctor_newTimetable_success',
      ERROR:          'doctor_newTimetable_error'
    },
    DELETE_TIMETABLE: {
      SUCCESS:        'doctor_deleteTimetable_success',
      ERROR:          'doctor_deleteTimetable_error'
    },
    UPDATE_TIMETABLE: {
      SUCCESS:        'doctor_updateTimetable_success',
      ERROR:          'doctor_updateTimetable_error'
    },
    GET_SCHEDULER: {
      SUCCESS:        'doctor_getScheduler_success',
      ERROR:          'doctor_getScheduler_error'
    },
    GET_ABSENCES: {
      SUCCESS:        'doctor_getAbsences_success',
      ERROR:          'doctor_getAbsences_error'
    },
    NEW_ABSENCE: {
      SUCCESS:        'doctor_newAbsence_success',
      ERROR:          'doctor_newgetAbsence_error'
    },
    DELETE_ABSENCE: {
      SUCCESS:        'doctor_deleteAbsence_success',
      ERROR:          'doctor_deleteAbsence_error'
    },
    UPDATE_ABSENCE: {
      SUCCESS:        'doctor_updateAbsence_success',
      ERROR:          'doctor_updateAbsence_error'
    },
    DELETE_EVENT: {
      SUCCESS:        'doctor_deleteEvent_success',
      ERROR:          'doctor_deleteEvent_error'
    },
  }

  public static readonly VIDEO_CALL = {
    CREATE_ROOM: {
      SUCCESS:        'doctor_createRoom_success',
      ERROR:          'doctor_createRoom_error'
    },
  }

  public static readonly EPRESCRIPTION= {
    GET_EPRESCRIPTION_REFILL: {
      SUCCESS:        'patient_getEPrescriptionRefill_success',
      ERROR:          'patient_getEPrescriptionRefill_error'
    },
    GET_EPRESCRIPTION_DETAILS: {
      SUCCESS:        'ePrescription_getEPrescriptionRefillDetails_success',
      ERROR:          'ePrescription_getEPrescriptionRefillDetails_error'
    },
    UPDATE_EPRESCRIPTION_ITEM: {
      SUCCESS:        'ePrescription_setItemDetails_success',
      ERROR:          'ePrescription_setItemDetails_error'
    },
  }

  public static readonly PATIENT = {
    GET_PATIENTS: {
      SUCCESS:        'patient_getList_success',
      ERROR:          'patient_getList_error'
    },
    GET_PATIENT_DETAILS: {
      SUCCESS:        'patient_getDetails_success',
      ERROR:          'patient_getDetails_error'
    },
    GET_PATIENT_INSURANCE: {
      SUCCESS:        'patient_getInsurance_success',
      ERROR:          'patient_getInsurance_error'
    },
    UPDATE_PATIENT_EXTERNAL_ID: {
      SUCCESS:        'patient_setExternalId_success',
      ERROR:          'patient_setExternalId_error'
    },
    GET_EVENTS_HISTORY: {
      SUCCESS:        'patient_getEventsHistory_success',
      ERROR:          'patient_getEventsHistory_error'
    },
  }

}
