<div class="modal-header">
  <h4 class="modal-title">{{ 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.VACATION' | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
      <i-tabler name="x"></i-tabler>
    </button>
  </div>
</div>

<div class="modal-body">
  <div class="weeks">
    <ng-container *ngFor="let vacation of data">
      <div class="week">
        <div class="d-flex week-wrapper">
          <div class="time">
            <div
              class="title font-weight-bold">{{'VIEW.MAIN.WORKDAYS_SETTING.VACATION.DATA.DATE_FROM' | translate}}</div>
            <div class="value">{{vacation.dateFrom | date:Constant.DATE_FORMAT}}</div>
            <div class="value" *ngIf="!vacation.wholeDay">{{vacation.dateFrom | date:Constant.TIME_FORMAT}}</div>
          </div>
          <div class="time">
            <div class="title font-weight-bold">{{'VIEW.MAIN.WORKDAYS_SETTING.VACATION.DATA.DATE_TO' | translate}}</div>
            <div class="value">{{vacation.dateFrom | date:Constant.DATE_FORMAT}}</div>
            <div class="value" *ngIf="!vacation.wholeDay">{{vacation.dateTo | date:Constant.TIME_FORMAT}}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="not-available">{{'VIEW.MAIN.WORKDAYS_SETTING.VACATION.NOT_DEFINED' | translate}}</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="cancel()">{{ 'COMMON.CLOSE' | translate }}</button>
</div>
