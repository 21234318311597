import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthModel } from 'app/model/AuthModel';
import { UserModel } from 'app/model/UserModel';
import { StateService } from '@uirouter/core';
import { StateUtil } from 'app/util/StateUtil';
import { ViewUtil } from 'app/util/ViewUtil';
import { Subscription } from 'rxjs';
import { CurrentUserDTO } from '@dto/user/CurrentUserDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { State } from '@common/State';
import { UserType } from '@enum/user/UserType';

@Component({
  selector: 'app-header',
  templateUrl: './HeaderComponent.html',
  styleUrls: [ './HeaderComponent.scss' ]
})

export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('sidebar', { static: true }) private sidebarElement: ElementRef<any>;

  @Input() public showSidebar: boolean = true;

  public collapsed: boolean = true;

  public currentUser: CurrentUserDTO;

  public supportEmail: string = 'aerzte-support@telehealth.de';

  public currentUserFullName: string = '';

  private currentUserSubscription: Subscription;

  public UserType: typeof UserType = UserType;

  constructor(private stateService: StateService,
              public stateUtil: StateUtil,
              public viewUtil: ViewUtil,
              private authModel: AuthModel,
              private appModel: ApplicationModel,
              private userModel: UserModel) {
  }

  public ngOnInit(): void {
    this.currentUserSubscription = this.userModel.currentUser$.subscribe((value: CurrentUserDTO) => {
      queueMicrotask(() => {
        if (value !== undefined) {
          this.currentUser = value;
          this.currentUserFullName = `${ this.currentUser?.title || '' } ${ this.currentUser?.firstName } ${ this.currentUser?.lastName }`.trim();
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
  }

  public onCollapsed(collapsed: boolean): void {
    if (collapsed) {
      $('body').removeClass('stop-scrolling');
    }
  }

  public toggle(): void {
    const collapsed = !this.collapsed;

    if (!collapsed) {
      $('body').addClass('stop-scrolling');
    }

    this.collapsed = collapsed;
  }

  public onChangedState(): void {
    this.collapsed = true;
  }

  public logout(): void {
    this.authModel.logout()
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public changePassword(): void {
    this.onChangedState();
    this.stateService.go(State.MAIN.ACCOUNT.CHANGE_PASSWORD);
  }

  public myProfile(): void {
    this.onChangedState();
    this.stateService.go(State.MAIN.PROFILE.BASIC_DATA);
  }
}
