import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InsuranceDTO } from '@dto/insurance/InsuranceDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { Constant } from '@common/Constant';
import { HealthConditionAnswerDTO } from '@dto/events/HealthConditionAnswerDTO';
import { IInsuranceService } from '@local/interface/IInsuranceService';

@Injectable({
  providedIn: 'root'
})
export class EventPatientService implements IInsuranceService {

  constructor(private http: HttpClient) {
  }

  public getInsurance(eventId: number): Observable<InsuranceDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/patient/insurance-detail`, { headers })
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(InsuranceDTO, response))
      );
  }

  public getHealthConditionAnswers(eventId: number): Observable<HealthConditionAnswerDTO[]> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/health-condition`, { headers })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassArray(HealthConditionAnswerDTO, response || []))
      );
  }

  public reportInsuranceProblem(id: number, message: string): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/events/${ id }/patients/insurance-issue`, { message });
  }

  public getBasicData(eventId: number): Observable<PatientDTO> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/patient/basic-data`, { headers })
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(PatientDTO, response?.patientBasicData))
      );
  }

  public getUploadedFiles(eventId: number): Observable<string[]> {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.set(Constant.HTTP_SILENT_MODE, '');

    return this.http.get(`${ ApplicationConfig.apiUrl }/events/${ eventId }/files`, { headers })
      .pipe(
        map((response: any) => response)
      );
  }
}
