import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {

  public transform(value: any[], separator: string = ', '): any {
    return (value && value.length) ? value.join(separator) : null;
  }
}
