<section class="page">
  <div class="header-container">
    <div class="header">{{ 'VIEW.MAIN.PRACTICE.PRACTICE' | translate }}</div>
    <div class="actions">
      <button type="button"
              class="btn btn-primary"
              [uiSref]="State.MAIN.WORKDAYS.WORKDAYS">{{ 'VIEW.MAIN.PRACTICE.ACTION.MY_WORKING_HOURS' | translate}} </button>

    </div>
    
  </div>
  <div class="content">

    <app-tab [tabs]="tabs"></app-tab>

    <div class="pt-4">
      <ui-view></ui-view>
    </div>
  </div>
</section>