<ng-container *ngIf="patient$ | async as patient">
  <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.BASIC_DATA' | translate}}</div>

  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.FIRST_NAME' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">{{patient?.firstName | replaceEmpty}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.LAST_NAME' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">{{patient?.lastName | replaceEmpty}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.EMAIL' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">{{patient?.email | replaceEmpty}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.PHONE' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">{{patient?.phoneNumber | replaceEmpty}}</div>
      </div>

      <div class="form-group" *ngIf="!videoCallService.isInstantCall">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.GENDER' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{patient?.gender | prefix: 'ENUM.PATIENT_GENDER.' | translate}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.BIRTHDAY_AND_AGE' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">
          <span>{{patient?.dateOfBirth | date: Constant.DATE_FORMAT}}</span>
          <span *ngIf="patient?.dateOfBirth">&nbsp;({{patient.dateOfBirth | birthDateToAge}})</span>
        </div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.DATA.ADDRESS' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">
          <div>{{patient?.address?.fullStreetName}}</div>
          <div>{{ patient?.address?.cityCode }} {{ patient?.address?.city }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
