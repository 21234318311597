import { Component, OnInit, ViewChild } from '@angular/core';
import { State } from 'app/common/State';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { InsuranceType } from '@enum/doctor/InsuranceType';
import { NgForm } from '@angular/forms';
import { ViewUtil } from '@util/ViewUtil';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateUtil } from '@util/StateUtil';
import { LicenseType } from '@enum/doctor/LicenseType';
import { Transition } from '@uirouter/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { Constant } from '@common/Constant';
import { Observable } from 'rxjs';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-update-license',
  templateUrl: './ProfileLicenseEditComponent.html',
  styleUrls: [ './ProfileLicenseEditComponent.scss' ]
})
export class ProfileLicenseEditComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public disabledSubmit: boolean = false;

  public license: LicenseDTO;

  public insuranceTypeOptions: InsuranceType[] = Object.keys(InsuranceType) as InsuranceType[];

  public LicensePhotoType: typeof LicenseType = LicenseType;

  public Constant: typeof Constant = Constant;

  public DoctorStatus: typeof DoctorStatus = DoctorStatus;

  public doctorIsActive$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.ACTIVE);

  public doctorIsIncompleteRegistration$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.INCOMPLETE_REGISTRATION);

  constructor(private stateUtil: StateUtil,
              private viewUtil: ViewUtil,
              private applicationModel: ApplicationModel,
              private currentDoctorService: CurrentDoctorService,
              private modalService: BsModalService,
              private transition: Transition,
              private userModel: UserModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PROFILE.BASIC_DATA);
  }

  public save(): void {
    if (this.form.valid) {
      this.disabledSubmit = true;

      this.currentDoctorService.updateLicense(this.license)
        .toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.UPDATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        })
        .finally(() => {
          this.disabledSubmit = false;
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public ngOnInit(): void {
    this.currentDoctorService.getLicense().toPromise()
      .then((license: LicenseDTO) => {
        this.license = license;
      });
  }

  public back(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE.LICENSE);
  }

  public changeCertificate(event: Event, certificateType: LicenseType, currentFileUrl: string = null): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files.length > 0) {
      const file = files[0];

      if (currentFileUrl) {
        this.modalService.show(PopupConfirmationComponent, {
          initialState: {
            title: 'VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.REPLACE_CERTIFICATE.TITLE',
            okText: 'COMMON.REPLACE'
          }
        }).content.close$.toPromise()
          .then(() => this.uploadCertificate(file, certificateType))
          .then(() => {
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.REPLACE_CERTIFICATE.SUCCESS');
          });
      } else {
        this.uploadCertificate(file, certificateType)
          .then(() => {
            this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.LICENSE.MESSAGE.UPLOAD_CERTIFICATE.SUCCESS');
          });
      }
    }
  }

  private uploadCertificate(file: File, type: LicenseType): Promise<void> {
    return this.currentDoctorService.uploadCertificate(file, type).toPromise();
  }

  private getLicense(): Promise<LicenseDTO> {
    return this.currentDoctorService.getLicense().toPromise()
      .then((license: LicenseDTO) => {
        this.license = license;
        return license;
      });
  }

  public deleteCertificate(certificateType: LicenseType): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.PROFILE.LICENSE.MESSAGE.DELETE_CERTIFICATE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.toPromise()
      .then(() => this.currentDoctorService.deleteCertificate(certificateType).toPromise())
      .then(() => this.getLicense())
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.PROFILE.LICENSE.MESSAGE.DELETE_CERTIFICATE.SUCCESS');
      });
  }
}