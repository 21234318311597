import { Injectable, Injector, isDevMode, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventManager } from 'app/util/other/EventManager';
import { Event } from 'app/common/Event';
import { ApplicationState } from 'app/data/local/ApplicationState';
import { ApplicationService } from 'app/service/ApplicationService';
import { SideBarItem } from 'app/data/local/ui/SideBarItem';
import * as _ from 'lodash';
import { ApplicationConfig } from 'app/config/ApplicationConfig';

@Injectable({ providedIn: 'root' })
export class ApplicationModel implements OnDestroy {
  public playGoogleStoreApplicationUrl: string = 'https://play.google.com/store/apps/details?id=de.telehealth.app';

  public appleStoreApplicationUrl = 'https://apps.apple.com/us/app/telehealth-de/id6446632086';

  public devMode: boolean = isDevMode();

  public stateBeforeLogin: ApplicationState;

  public goingToPreviousState: boolean = false;

  public goingToTransientState: boolean = false;

  public resolvingUnauthorizedState: boolean = false;

  public stateHistory: ApplicationState[] = [];

  public currentState: ApplicationState;

  public currentUrl: string;

  public sideBarItems$: BehaviorSubject<SideBarItem[]> = new BehaviorSubject<SideBarItem[]>([]);

  private sidebarVisible: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public sidebarVisible$: Observable<boolean> = this.sidebarVisible.asObservable();

  constructor(private eventManager: EventManager,
              private applicationService: ApplicationService,
              private injector: Injector) {

    this.setupListeners();
  }

  public get versionNumberString(): string {
    return ApplicationConfig.version.substring(0, ApplicationConfig.version.lastIndexOf(' '));
  }

  public ngOnDestroy(): void {
  }

  private setupListeners(): void {
    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.sideBarItems = [];
    });
    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, () => {
      this.sideBarItems = [];
    });
  }

  public selectSideBarItemWithState(stateName: string): void {
    const sideBarItems: SideBarItem[] = _.cloneDeep(this.sideBarItems$.value);

    _.forEach(sideBarItems, (sideBarItem: SideBarItem) => {
      sideBarItem.selected = (sideBarItem.stateName === stateName || stateName.includes(sideBarItem.stateName));
    });

    this.sideBarItems = sideBarItems;
  }

  public deselectSideBarItems(): void {
    const sideBarItems: SideBarItem[] = _.cloneDeep(this.sideBarItems$.value);

    _.forEach(sideBarItems, (item: SideBarItem) => {
      item.selected = false;
    });

    this.sideBarItems = sideBarItems;
  }

  public set sideBarItems(value: SideBarItem[]) {
    this.sideBarItems$.next(value.filter(s => s.visible));
  }
}


