import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthModel } from '@model/AuthModel';
import { VideoCallSimpleInfoDTO } from '@dto/videocall/VideoCallSimpleInfoDTO';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { VIDEO_CALL_SERVICE } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { DoctorVideoCallService } from '@service/event/DoctorVideoCallService';
import { Observable } from 'rxjs';
import { StateService } from '@uirouter/core';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-meeting',
  templateUrl: './MeetingComponent.html',
  styleUrls: [ './MeetingComponent.scss' ],
  providers: [ {
    provide: VIDEO_CALL_SERVICE,
    useExisting: DoctorVideoCallService
  } ]
})
export class MeetingComponent implements OnInit, OnDestroy {
  public eventId: number = this.transition.params().eventId;

  public simpleInfo$: Observable<VideoCallSimpleInfoDTO> = this.doctorVideoCallService.getDetails(this.transition.params().eventId)
    .pipe(
      tap(() => this.setTab())
    );

  public State: typeof State = State;

  public onMeeting: boolean = false;

  public tabs: ITab[] = [];

  constructor(private authModel: AuthModel,
              private stateService: StateService,
              private applicationModel: ApplicationModel,
              private doctorVideoCallService: DoctorVideoCallService,
              private transition: Transition) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public joinedToRoom(value: boolean): void {
    this.onMeeting = value;
  }

  public isMainState(): boolean {
    return this.stateService.is(State.RAW.VIDEO_CALL.VIDEO_CALL);
  }

  private setTab(): void {
    this.tabs = [
      {
        state: State.RAW.VIDEO_CALL.VIDEO_CALL,
        title: 'VIEW.VIDEO_CALL.MENU.FULL_VIEW'
      },
      {
        state: State.RAW.VIDEO_CALL.BASIC_DATA,
        title: 'VIEW.VIDEO_CALL.MENU.BASIC_DATA'
      },
      {
        state: State.RAW.VIDEO_CALL.INSURANCE,
        title: 'VIEW.VIDEO_CALL.MENU.INSURANCE'
      },
      {
        state: State.RAW.VIDEO_CALL.HEALTH_CONDITION,
        title: 'VIEW.VIDEO_CALL.MENU.HEALTH_CONDITION',
        hidden: this.doctorVideoCallService.isInstantCall
      },
      {
        state: State.RAW.VIDEO_CALL.PRESCRIPTION,
        title: 'VIEW.VIDEO_CALL.MENU.PRESCRIPTION',
        hidden: this.doctorVideoCallService.isInstantCall
      },
      {
        state: State.RAW.VIDEO_CALL.SICK_LEAVES,
        title: 'VIEW.VIDEO_CALL.MENU.SICK_LEAVES',
        hidden: this.doctorVideoCallService.isInstantCall
      },
      {
        state: State.RAW.VIDEO_CALL.NOTES,
        title: 'VIEW.VIDEO_CALL.MENU.NOTES',
        hidden: this.doctorVideoCallService.isInstantCall
      },
      {
        state: State.RAW.VIDEO_CALL.UPLOAD_IMAGES,
        title: 'VIEW.VIDEO_CALL.MENU.UPLOAD_IMAGES',
        hidden: this.doctorVideoCallService.isInstantCall
      }
    ];
  }
}
