import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[validateNonEmptyOrEmptyString]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NonEmptyOrEmptyStringValidatorDirective), multi: true }
  ]
})
export class NonEmptyOrEmptyStringValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (control.value === null || control.value === undefined) {
      return { required: true };
    } else {
      return null;
    }
  }
}