import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import momentDurationPluginInit from 'moment-duration-format';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'humanDuration' })
export class HumanDurationFormatPipe implements PipeTransform {

  constructor() {
    momentDurationPluginInit(moment);
  }

  public transform(value: number, unit: string = 'seconds'): string {
    if (value) {
      // @ts-ignore
      const duration = moment.duration(value, unit);
      const options = { usePlural: false, trim: true };

      const minutes = duration.get('minutes');
      const seconds = duration.get('seconds');
      if (minutes && seconds) {
        // @ts-ignore
        return duration.format('m [min] s [sec]', options);
      } else if (seconds && !minutes) {
        // @ts-ignore
        return duration.format('s [sec]', options);
      } else if (!seconds && minutes) {
        // @ts-ignore
        return duration.format('m [min]', options);
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }
}
