<section class="day-of-slots-container">
  <ng-container *ngFor="let slot of data">
    <ng-container *ngIf="slotTemplate">
      <ng-container [ngTemplateOutlet]="slotTemplate"
                    [ngTemplateOutletContext]="{$implicit: slot}"></ng-container>
    </ng-container>
    <ng-container *ngIf="!slotTemplate">
      <app-scheduler-slot [slot]="slot"></app-scheduler-slot>
    </ng-container>
  </ng-container>
</section>