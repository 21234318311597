import { Component } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VacationDTO } from '@dto/workingDay/VacationDTO';
import { Constant } from '@common/Constant';

@Component({
  selector: 'app-doctor-vacations-popup',
  templateUrl: './DoctorVacationsPopupComponent.html',
  styleUrls: [ './DoctorVacationsPopupComponent.scss' ]
})
export class DoctorVacationsPopupComponent extends BaseModalComponent<boolean> {
  public data: VacationDTO[];

  public Constant: typeof Constant = Constant;

  constructor(public bsModalRef: BsModalRef) {
    super();
  }

}
