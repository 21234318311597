import { Injectable } from '@angular/core';
import { InvitationRequestDTO } from '@dto/doctor/InvitationRequestDTO';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { ObjectUtil } from '@util/ObjectUtil';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  constructor(private http: HttpClient) {
  }

  public createDoctorInvitation(invitation: InvitationRequestDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/invitation/doctor/to-doctor`, ObjectUtil.classToPlain(invitation));
  };

  public createPatientInvitation(invitation: InvitationRequestDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/invitation/doctor/to-patient`, ObjectUtil.classToPlain(invitation));
  };
}
