import { Expose, Transform, Type } from 'class-transformer';
import { VisitTypeDTO } from '@dto/doctor/VisitTypeDTO';
import { DateUtil } from '@util/DateUtil';
import { SpecialityDTO } from '@dto/doctor/SpecialityDTO';
import { VideoCallPatientDTO } from '@dto/videocall/VideoCallPatientDTO';
import { VideoCallDoctorDTO } from '@dto/videocall/VideoCallDoctorDTO';
import { IVideoCallData } from 'app/component/ui/videoCall/interface/IVideoCallData';

export class VideoCallSimpleInfoDTO implements IVideoCallData {
  @Type(() => VisitTypeDTO)
  public visitTypes: VisitTypeDTO[];

  @Type(() => SpecialityDTO)
  public specialities: SpecialityDTO[];

  @Transform(DateUtil.dateTimeConversion)
  public dateTime: Date;

  @Expose({ name: 'patientIconDetails' })
  @Type(() => VideoCallPatientDTO)
  public patient: VideoCallPatientDTO;

  @Expose({ name: 'doctorIconDetails' })
  @Type(() => VideoCallDoctorDTO)
  public doctor: VideoCallDoctorDTO;

  public couldJoin: boolean;

  public duration: number;

  public isPatientJoined: boolean;

  public isUnregisteredPatient: boolean;

  public isRoomExist: boolean;
}

