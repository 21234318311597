import { Component, Input, OnInit } from '@angular/core';
import { EventSlot } from '@local/scheduler/EventSlot';
import { StateService } from '@uirouter/core';
import { SlotStatus } from '@local/scheduler/SlotStatus';

@Component({
  selector: 'app-doctor-scheduler-slot',
  templateUrl: './DoctorSchedulerSlotComponent.html',
  styleUrls: [ './SchedulerSlotComponent.scss' ]
})
export class DoctorSchedulerSlotComponent implements OnInit {
  @Input() public slot: EventSlot;

  public SlotStatus: typeof SlotStatus = SlotStatus;

  constructor(private stateService: StateService) {
  }

  public ngOnInit(): void {
  }

  public showDetails(eventId: number): void {
    console.log('show details', eventId);
  }

  public showPatient(patientId: number): void {
    console.log('show patient', patientId);
  }
}
