import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';
import { ViewUtil } from 'app/util/ViewUtil';
import { PrescriptionRefillPageCriteriaDTO } from '@dto/prescription/PrescriptionRefillPageCriteriaDTO';
import { PrescriptionRefillPageEntityDTO } from '@dto/prescription/PrescriptionRefillPageEntityDTO';
import { ApplicationModel } from '@model/ApplicationModel';
import { PageDTO } from '@dto/PageDTO';
import { Constant } from '@common/Constant';
import { PrescriptionService } from '@service/PrescriptionService';
import { Transition } from '@uirouter/angular';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { PrescriptionRefillStatus } from '@enum/prescription/PrescriptionRefillStatus';
import { SortDirection } from '@enum/SortDirection';
import { PageUtil } from '@util/PageUtil';

@Component({
  selector: 'app-prescription-request-list',
  templateUrl: './PrescriptionRequestListComponent.html',
  styleUrls: [ './PrescriptionRequestListComponent.scss' ]
})
export class PrescriptionRequestListComponent implements OnInit {
  public data: PageDTO<PrescriptionRefillPageEntityDTO>;

  public criteria: PrescriptionRefillPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public statuses: PrescriptionRefillStatus[] = Object.keys(PrescriptionRefillStatus) as PrescriptionRefillStatus[];

  public Constant: typeof Constant = Constant;

  constructor(private applicationModel: ApplicationModel,
              private transition: Transition,
              private viewUtil: ViewUtil,
              private prescriptionService: PrescriptionService,
              private stateService: StateService) {
    this.criteria = PageUtil.prepareCriteria<PrescriptionRefillPageCriteriaDTO>(PrescriptionRefillPageCriteriaDTO, transition.params()?.criteria, { sortOrders: [ `createdDate ${ SortDirection.DESC.toLowerCase() }` ] });
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PRESCRIPTION_REQUEST.LIST);
  }

  public ngOnInit(): void {
    this.getData();
  }


  public goDetails(data: PrescriptionRefillPageEntityDTO): void {
    this.stateService.go(State.MAIN.PRESCRIPTION_REQUEST.DETAILS, { id: data.id });
  }

  public onStatusFilterChanged(status: PrescriptionRefillStatus): void {
    this.onCriteriaChanged({ ...this.criteria, status: status } as PageCriteriaDTO);
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<PrescriptionRefillPageCriteriaDTO>(PrescriptionRefillPageCriteriaDTO, this.criteria, changedCriteria as PrescriptionRefillPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }

  private getData(): Promise<void> {
    return this.prescriptionService.getPrescriptionRefillPage(this.criteria).toPromise()
      .then((result: PageDTO<PrescriptionRefillPageEntityDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }
}
