import { PatientGender } from '@enum/common/PatientGender';

export class PatientAvatarHelper {
  public static getUrl(url: string, gender: PatientGender = PatientGender.DIVERS, isAdult: boolean = true): string {
    const baseUrl: string = 'asset/image/icon/patient';

    if (!url) {
      switch (gender) {
        case PatientGender.MALE:
          return isAdult ? `${ baseUrl }/patient-male.svg` : `${ baseUrl }/patient-child-male.svg`;
        case PatientGender.FEMALE:
          return isAdult ? `${ baseUrl }/patient-female.svg` : `${ baseUrl }/patient-child-female.svg`;
        default:
          return isAdult ? `${ baseUrl }/patient-divers.svg` : `${ baseUrl }/patient-child-divers.svg`;
      }
    }

    return url;
  }
}