import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import StackFrame = StackTrace.StackFrame;
import { UserModel } from 'app/model/UserModel';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ApplicationErrorDTO } from 'app/data/dto/ApplicationErrorDTO';
import { ErrorHandler as AngularErrorHandler } from '@angular/core/esm2015/src/error_handler';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends AngularErrorHandler implements ErrorHandler {

  private userModel: UserModel;
  private applicationModel: ApplicationModel;

  constructor(private injector: Injector) {
    super();
  }

  public handleError(error: any): void {
    super.handleError(error);

    this.userModel = this.injector.get(UserModel);
    this.applicationModel = this.injector.get(ApplicationModel);

    if (this.applicationModel && !this.applicationModel.devMode) {
      const applicationError: ApplicationErrorDTO = new ApplicationErrorDTO();

      applicationError.url = window.location.href;
      applicationError.message = error.message ? error.message : error.toString();

      if (this.userModel?.currentUser) {
   //   applicationError.userId = this.userModel.currentUser.id;
        applicationError.userName = this.userModel.currentUser.firstName+ ' ' + this.userModel.currentUser.lastName;
      }

      StackTrace.fromError(error)
        .then((stackframes: StackFrame[]) => {
          applicationError.stackTrace = stackframes.splice(0, 20).map((stackframe: StackFrame) => stackframe.toString());
        });
    }
  }
}
