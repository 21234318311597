import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { Transform, Type } from 'class-transformer';
import { VisitTypeDTO } from '@dto/doctor/VisitTypeDTO';
import { EventStatus } from '@enum/event/EventStatus';
import { DateUtil } from '@util/DateUtil';
import { HealthConditionAnswerDTO } from '@dto/events/HealthConditionAnswerDTO';
import { SickLeaveDTO } from '@dto/sickLeave/SickLeaveDTO';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';

export class EventDetailsDTO extends IdentifiableObjectDTO<number> {
  @Type(() => PatientDTO)
  public patient: PatientDTO;

  @Type(() => VisitTypeDTO)
  public visitTypes: VisitTypeDTO[];

  public note: string;

  public status: EventStatus;

  public noShow: boolean;

  public newPatient: boolean;

  @Transform(DateUtil.dateTimeConversion)
  public dateTime: Date;

  @Type(() => HealthConditionAnswerDTO)
  public healthConditions: HealthConditionAnswerDTO[];

  @Type(() => SickLeaveDTO)
  public sickLeaves: SickLeaveDTO[];

  public uploadedFiles: string[];

  @Type(() => PrescriptionDTO)
  public eprescriptions: PrescriptionDTO[];
}