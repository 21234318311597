import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICalendarDateRange } from '@local/scheduler/ICalendarDateRange';
import { EventSlot } from '@local/scheduler/EventSlot';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import moment from 'moment';
import { EventSlotMapper } from 'app/data/mapper/EventSlotMapper';

@Component({
  selector: 'app-scheduler-conflicts',
  templateUrl: './SchedulerConflictsComponent.html',
  styleUrls: [ './SchedulerConflictsComponent.scss' ]
})
export class SchedulerConflictsComponent implements OnInit {
  @Input()
  public set conflicts(value: EventSlot[]) {
    this._conflicts = value;
    this.firstSlotDate = value?.length > 0 ? value[0]?.startDate : null;

    this.setData();
  }

  @Output() public conflictsChange: EventEmitter<EventSlot[]> = new EventEmitter<EventSlot[]>();

  private _conflicts: EventSlot[];

  public data: EventSlotList[];

  public firstSlotDate: Date;

  private dateRange: ICalendarDateRange;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public onDateRangeChanged(dateRange: ICalendarDateRange): void {
    this.dateRange = dateRange;

    this.setData();
  }

  public slotCancelled(slot: EventSlot): void {
    const slots = this._conflicts.filter((eventSlot: EventSlot) => eventSlot.eventId !== slot.eventId);

    this._conflicts = slots;
    this.conflictsChange.emit(slots);
  }

  private setData(): void {
    if (this.dateRange) {
      const conflicts = this._conflicts;

      const filteredSlots = conflicts.filter((eventSlot: EventSlot) => {
        return moment(eventSlot.startDate).isBetween(moment(this.dateRange.startDate), moment(this.dateRange.endDate));
      });

      this.data = EventSlotMapper.eventSlotsToList(this.dateRange.startDate, filteredSlots);
    }
  }

}
