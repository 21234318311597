import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { NgForm } from '@angular/forms';
import { AuthModel } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reset-password-start',
  templateUrl: 'ResetPasswordStartComponent.html',
  styleUrls: [ 'ResetPasswordStartComponent.scss' ]
})
export class ResetPasswordStartComponent implements OnInit, OnDestroy {
  @ViewChild('form')
  private form: NgForm;

  public resetPasswordSuccess: boolean = false;

  public sendAgainActive: boolean = true;

  public formData: { email: string } = {
    email: null
  };

  constructor(private stateService: StateService,
              public viewUtil: ViewUtil,
              private modalService: BsModalService,
              private authModel: AuthModel) {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public submit(): void {
    if (this.form.valid) {
      this.authModel.startPasswordReset(this.formData.email)
        .then(() => {
          this.resetPasswordSuccess = true;
        })
        .catch((error) => {
          this.resetPasswordSuccess = false;
          this.viewUtil.handleServerError(error);
        });
    }
  }

  public sendAgain(): void {
    this.sendAgainActive = false;
    this.submit();

    setTimeout(() => {
      this.sendAgainActive = true;
    }, 15000);
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
