import { Injectable, OnDestroy } from '@angular/core';
import { EventManager } from 'app/util/other/EventManager';
import { PrescriptionService } from '@service/PrescriptionService';
import { PrescriptionRefillPageCriteriaDTO } from '@dto/prescription/PrescriptionRefillPageCriteriaDTO';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import { Duration } from 'moment';
import { UserModel } from '@model/UserModel';
import { CurrentUserDTO } from '@dto/user/CurrentUserDTO';
import { PrescriptionRefillStatus } from '@enum/prescription/PrescriptionRefillStatus';

@Injectable({
  providedIn: 'root'
})
export class PrescriptionModel implements OnDestroy {

  private static readonly REFRESH_INTERVAL: Duration = moment.duration('30', 'seconds');

  private numberOfNewPrescriptions: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public numberOfNewPrescriptions$: Observable<number> = this.numberOfNewPrescriptions.asObservable();

  private numberOfNewPrescriptionInterval: ReturnType<typeof setInterval>;

  constructor(private eventManager: EventManager,
              private userModel: UserModel,
              private prescriptionService: PrescriptionService) {
    this.setupListeners();

    this.userModel.currentUser$.subscribe((user: CurrentUserDTO) => {
      if (this.userModel.doctorIsRegisteredCompletely()) {
        this.startRefreshingNewPrescriptionNumber();
      } else {
        this.cancelRefreshingNewPrescriptionNumber();
      }
    });
  }

  private setupListeners(): void {
  }

  public ngOnDestroy(): void {
    this.cancelRefreshingNewPrescriptionNumber();
  }


  public updateNumberOfNewPrescription(): void {
    const criteria = new PrescriptionRefillPageCriteriaDTO();

    criteria.status = PrescriptionRefillStatus.NEW;

    this.prescriptionService.getNumberOfNewPrescriptions(criteria)
      .subscribe((value: number) => {
        this.numberOfNewPrescriptions.next(value);
      });
  }

  public startRefreshingNewPrescriptionNumber(): void {
    this.updateNumberOfNewPrescription();

    this.numberOfNewPrescriptionInterval = setInterval(() => {
      this.updateNumberOfNewPrescription();
    }, PrescriptionModel.REFRESH_INTERVAL.as('milliseconds'));
  }

  public cancelRefreshingNewPrescriptionNumber(): void {
    if (this.numberOfNewPrescriptionInterval) {
      clearInterval(this.numberOfNewPrescriptionInterval);
      this.numberOfNewPrescriptionInterval = null;
    }
  }
}
