<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.MAIN.VIDEO_CALLS' | translate}}</div>

    <div class="actions">
<!--      <button type="button" class="btn btn-primary"-->
<!--              (click)="export()"> {{ 'COMMON.EXPORT' | translate}} </button>-->
    </div>
  </div>

  <div class="content">
    <app-data-grid *ngIf="data" [data]="data.content"
                   [criteria]="criteria"
                   [context]="{ host: this }"
                   [selectionEnabled]="false"
                   [showFilters]="true"
                   [showSearch]="true"
                   [showPagination]="true"
                   [pageTotal]="data.totalPages" [itemTotal]="data.totalElements"
                   (criteriaChanged)="onCriteriaChanged($event)"
                   [columns]="$any([
                    { colId: '1', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.DATE_TIME' | translate), maxWidth: 130, field: 'dateTimeFrom', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: dateTimeTemplate, preventSelection: false } },
                    { colId: '2', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.STATUS' | translate), maxWidth: 100, field: 'status', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: statusTemplate, preventSelection: false } },
                    { colId: '3', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.DOCTOR.FIRST_NAME' | translate), maxWidth: 130, field: 'doctorFirstname', suppressSizeToFit: true },
                    { colId: '4', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.DOCTOR.LAST_NAME' | translate), maxWidth: 150, field: 'doctorLastname', suppressSizeToFit: true },
                    { colId: '5', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.PATIENT.FIRST_NAME' | translate), maxWidth: 130, field: 'patientFirstname', suppressSizeToFit: true },
                    { colId: '6', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.PATIENT.LAST_NAME' | translate), maxWidth: 150, field: 'patientLastname', suppressSizeToFit: true },
                    { colId: '7', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.INSURANCE' | translate), maxWidth: 130, field: 'insuranceType', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: insuranceTypeTemplate, preventSelection: false } },
                    { colId: '8', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.DOCTOR_RATING' | translate), maxWidth: 85, field: 'doctorRating', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: ratingTemplate, preventSelection: false } },
                    { colId: '9', headerName: ('VIEW.MAIN.EVENT_DETAILS.DATA.CONNECTION_RATING' | translate), minWidth: 150, flex: 1, field: 'connectionRating', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: ratingTemplate, preventSelection: false }},
                  ])">

      <app-data-grid-filters>
        <div class="filter">
          <ng-select name="filter-status"
                     class="sm"
                     [placeholder]="'VIEW.MAIN.EVENT_DETAILS.FILTER.STATUS.PLACEHOLDER' | translate"
                     [searchable]="false"
                     [(ngModel)]="criteria.status" (ngModelChange)="onStatusFilterChanged($event)">
            <ng-option *ngFor="let status of statuses"
                       [value]="status">{{ status | prefix:'ENUM.EVENT_STATUS.' | translate }}</ng-option>
          </ng-select>
        </div>

        <div class="filter">
          <ng-select name="filter-insurance-type"
                     class="sm"
                     [placeholder]="'VIEW.MAIN.EVENT_DETAILS.FILTER.INSURANCE_TYPE.PLACEHOLDER' | translate"
                     [searchable]="false"
                     [(ngModel)]="criteria.insuranceType" (ngModelChange)="onInsuranceTypeFilterChanged($event)">
            <ng-option *ngFor="let insuranceType of patientInsuranceTypes"
                       [value]="insuranceType">{{ insuranceType | prefix:'ENUM.PATIENT_INSURANCE_TYPE.' | translate }}</ng-option>
          </ng-select>
        </div>
      </app-data-grid-filters>
    </app-data-grid>
  </div>
</section>

<!--Templates-->
<ng-template #statusTemplate let-value="value">
  <ng-container [ngSwitch]="value">
    <ng-container *ngSwitchCase="EventStatus.UPCOMING">
      <span class="color-primary">{{value | prefix: 'ENUM.EVENT_STATUS.' | translate }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="EventStatus.PAST">
      <span class="color-gray-3">{{value | prefix: 'ENUM.EVENT_STATUS.' | translate }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="EventStatus.CANCELLED">
      <span class="color-danger">{{value | prefix: 'ENUM.EVENT_STATUS.' | translate }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'NO_SHOW'">
      <span class="color-blue">{{value | prefix: 'ENUM.EVENT_STATUS.' | translate }}</span>
    </ng-container>

    <ng-container *ngSwitchDefault>-</ng-container>
  </ng-container>
</ng-template>

<ng-template #dateTimeTemplate let-value="value">
  <span>{{value | date:Constant.DATE_TIME_FORMAT}}</span>
</ng-template>

<ng-template #ratingTemplate let-value="value">
  <span>{{value | number : '1.1' | replaceEmpty}}</span>
</ng-template>

<ng-template #insuranceTypeTemplate let-value="value">
  <span>{{value | prefix:'ENUM.PATIENT_INSURANCE_TYPE.' | translate }}</span>
</ng-template>


