import { Component, OnInit } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { Transition } from '@uirouter/core';
import { DoctorVideoCallService } from '@service/event/DoctorVideoCallService';
import { EventNoteDTO } from '@dto/events/EventNoteDTO';
import { Constant } from '@common/Constant';

@Component({
  selector: 'app-note',
  templateUrl: './EventNoteComponent.html',
  styleUrls: [ './EventNoteComponent.scss' ]
})
export class EventNoteComponent implements OnInit {
  private eventId: number = this.transition.params().eventId;

  public isProcessing: boolean = false;

  public note: EventNoteDTO = new EventNoteDTO();

  public MAX_NOTE_LENGTH = 9999;

  public Constant: typeof Constant = Constant;

  constructor(private doctorVideoCallService: DoctorVideoCallService,
              private transition: Transition,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
    this.getNote();
  }

  public save(): void {
    this.isProcessing = true;
    this.doctorVideoCallService.updateNote(this.eventId, this.note).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.VIDEO_CALL.NOTE.MESSAGE.UPDATE_SUCCESS');
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  private getNote(): void {
    this.doctorVideoCallService.getNote(this.eventId).toPromise()
      .then((note: EventNoteDTO) => {
        this.note = note;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }
}
