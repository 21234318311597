import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { EventManager } from 'app/util/other/EventManager';
import { Event } from 'app/common/Event';
import { Token } from 'app/data/local/auth/Token';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Duration } from 'moment';
import { SecurityDataDTO } from '@dto/auth/SecurityDataDTO';
import { CognitoAuthService } from '@service/CognitoAuthService';
import { LoaderModel } from '@model/LoaderModel';
import { isNil, isUndefined } from 'lodash';
import { AuthLoginResult } from '@service/interface/AuthServiceInterface';

export type LoginResult = { token?: Token, verificationCodeRequired?: boolean };

@Injectable({ providedIn: 'root' })
export class AuthModel implements OnDestroy {

  private static readonly TOKEN_REFRESH_INTERVAL: Duration = moment.duration('1', 'minutes');

  private static readonly SESSION_TIMEOUT: Duration = moment.duration('60', 'minutes');

  // public token: Token; (as getter/setter below)
  public token$: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  // public securityData: SecurityDataDTO; (as getter/setter below)
  public securityData$: BehaviorSubject<SecurityDataDTO> = new BehaviorSubject<SecurityDataDTO>(null);

  // public isLoggedIn: boolean; (as getter/setter below)
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private rememberLogin: boolean = false;

  private timeoutSession: boolean = true;

  private tokenRefreshIntervalId: ReturnType<typeof setInterval>;

  private sessionTimeoutId: ReturnType<typeof setTimeout>;

  constructor(private eventManager: EventManager,
              private zone: NgZone,
              private loaderService: LoaderModel,
              private authService: CognitoAuthService) {

    this.setupListeners();

    this.authService.setRememberLogin(this.rememberLogin);
  }

  public get token(): Token {
    return this.token$.value;
  }

  public set token(value: Token) {
    this.token$.next(value);
  }

  public get securityData(): SecurityDataDTO {
    return this.securityData$.value;
  }

  public set securityData(value: SecurityDataDTO) {
    this.securityData$.next(value);
  }

  public get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  public ngOnDestroy(): void {
    this.cancelTokenRefreshSchedule();
    this.cancelSessionTimeout();
  }

  private setupListeners(): void {
    this.eventManager.on(Event.USER.SECURITY_DATA, (result: SecurityDataDTO) => {
      if (!isUndefined(result)) {
        this.securityData = result;
      }
    });

    this.eventManager.on(Event.USER.GET_CURRENT.ERROR, () => {
      this.logout();
    });

    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, (result) => {
      // this needs to be set up right away without any delay, as it is checked in Application, during routing
      // which would also happen in response to this event
      this.isLoggedIn = false;

      // we don't care about the result of the deleteToken operation at this point, we're in error state anyway
      this.deleteToken()
        .finally(() => {
          this.token = null;
          this.securityData = null;
          this.cancelTokenRefreshSchedule();
        });
    });
  }

  public login(email: string, password: string, silent: boolean = false): Promise<LoginResult | null> {
    this.loaderService.setLoading(true, 'cognito:login');

    return this.authService.login(email, password).toPromise()
      .then((result: AuthLoginResult) => {
        if (!result.verificationCodeRequired) {
          return this.processToken(result, silent);
        } else {
          this.loaderService.setLoading(false, 'cognito:login');
          return Promise.resolve({ verificationCodeRequired: true });
        }
      })
      .catch((error) => {
        this.token = null;
        this.securityData = null;
        this.isLoggedIn = false;

        if (!silent) {
          this.eventManager.broadcast(Event.AUTH.LOGIN.ERROR);
        }

        this.loaderService.setLoading(false, 'cognito:login');

        return Promise.reject(error);
      });
  }

  public sendVerificationCode(code: string): Promise<LoginResult> {
    this.loaderService.setLoading(true, 'cognito:login');

    return this.authService.sendCustomChallengeAnswer(code).toPromise()
      .then((result: AuthLoginResult) => this.processToken(result, false))
      .finally(() => this.loaderService.setLoading(false, 'cognito:login'));
  }

  public processToken(result: AuthLoginResult, silent: boolean = false): Promise<LoginResult> {
    return this.setToken(result.token)
      .then(() => {
        this.token = result.token;

        if (!isUndefined(result.securityData)) {
          this.securityData = result.securityData;
        }

        this.isLoggedIn = true;

        if (!silent) {
          this.eventManager.broadcast(Event.AUTH.LOGIN.SUCCESS, this.token);
        }

        // token can be null in certain cases (Cognito "temp password login")
        // so the user in this case is logged in only "barely", will be asked to relogin again
        if (!isNil(this.token)) {
          this.scheduleTokenRefresh(this.token);
        }

        if (this.timeoutSession) {
          this.setSessionTimeout();
        }

        return { token: this.token };
      })
      .catch((error) => {
        // shouldn't happen really, but we're covering for safety
        this.token = null;
        this.securityData = null;
        this.isLoggedIn = false;

        if (!silent) {
          this.eventManager.broadcast(Event.AUTH.LOGIN.ERROR);
        }

        return Promise.reject(error);
      })
      .finally(() => {
        this.loaderService.setLoading(false, 'cognito:login');
      });
  }

  public logout(): Promise<void> {
    return this.authService.logout().toPromise()
      .then(() => this.cleaningAfterLogout());
  }

  public globalLogout(): Promise<void> {
    return this.authService.globalLogout().toPromise()
      .then(() => this.cleaningAfterLogout());
  }

  private cleaningAfterLogout(): Promise<void> {
    return this.deleteToken().finally(() => {
      this.token = null;
      this.securityData = null;
      this.isLoggedIn = false;

      this.cancelTokenRefreshSchedule();
      this.eventManager.broadcast(Event.AUTH.LOGOUT.SUCCESS);

      return;
    });
  }

  public refresh(token: Token): Promise<Token> {
    return new Promise((resolve, reject) => {
      this.authService.refresh(token).toPromise()
        .then((result: { token: Token, securityData: SecurityDataDTO }) => {
          this.setToken(result.token)
            .then((savedToken: Token) => {
              this.token = result.token;
              if (!isUndefined(result.securityData)) {
                this.securityData = result.securityData;
              }

              this.eventManager.broadcast(Event.AUTH.TOKEN_REFRESH, this.token);

              this.rescheduleRefresh(this.token);

              resolve(this.token);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public recoverToken(): Promise<Token> {
    return new Promise<Token>((resolve, reject) => {
      this.getToken()
        .then((token: Token) => {
          if (isNil(token)) {
            this.isLoggedIn = false;
            reject();
          } else {
            if (token.isExpired() || token.isNearlyExpired()) {
              this.refresh(token)
                .then((refreshedToken: Token) => {
                  this.isLoggedIn = true;

                  if (this.timeoutSession) {
                    this.setSessionTimeout();
                  }

                  resolve(refreshedToken);
                })
                .catch((error) => {
                  this.isLoggedIn = false;
                  reject(error);
                });
            } else {
              this.token = token;
              this.isLoggedIn = true;

              this.scheduleTokenRefresh(token);

              if (this.timeoutSession) {
                this.setSessionTimeout();
              }

              resolve(token);
            }
          }
        })
        .catch((error) => {
          this.isLoggedIn = false;
          reject(error);
        });
    });
  }

  public startPasswordReset(email: string): Promise<void> {
    this.loaderService.setLoading(true, 'cognito:passwordReset');

    return this.authService.startPasswordReset(email).toPromise()
      .finally(() => {
        this.loaderService.setLoading(false, 'cognito:passwordReset');
      });
  }

  public completePasswordReset(email: string, verificationCode: string, newPassword: string): Promise<void> {
    this.loaderService.setLoading(true, 'cognito:completePasswordReset');

    return this.authService.completePasswordReset(email, verificationCode, newPassword).toPromise()
      .finally(() => {
        this.loaderService.setLoading(false, 'cognito:completePasswordReset');
      });
  }

  public changePassword(currentPassword: string, newPassword: string): Promise<void> {
    this.loaderService.setLoading(true, 'cognito:changePassword');

    return this.authService.changePassword(currentPassword, newPassword).toPromise()
      .finally(() => {
        this.loaderService.setLoading(false, 'cognito:changePassword');
      });
  }

  public changePasswordForced(newPassword: string): Promise<void> {
    this.loaderService.setLoading(true, 'cognito:changePasswordForced');

    return this.authService.changePasswordForced(newPassword).toPromise()
      .then(() => {
        if (this.securityData) {
          this.securityData.requireNewPassword = false;
        }

        return;
      })
      .finally(() => {
        this.loaderService.setLoading(false, 'cognito:changePasswordForced');
      });
  }

  // --

  // run outside of zone, since it contains setInterval, which would delay stability of ApplicationRef
  // this is executed from AuthModel.recoverToken, which is called upon during application initialization (Application.configureTransitions)
  // so we need to a) keep it outside the zone, so that we don't threat stability at that point of time (initialization), but, at the same time
  // b) keep the results of each interval tick inside the zone anyway, so that it will properly trigger change detection (which is needed here)
  // cannot use standard ChangeDetectorRef.detectChanges, as we're outside of component tree scope and injector couldn't get it here
  // (this whole thing is important for HMR among other things)
  private scheduleTokenRefresh(token: Token): void {
    this.zone.runOutsideAngular(() => {
      this.tokenRefreshIntervalId = setInterval(() => {
        this.zone.run(() => {
          if (this.token.isNearlyExpired()) {
            this.refresh(this.token)
              .then((refreshedToken: Token) => {
              })
              .catch((error) => {
              });
          }
        });
      }, AuthModel.TOKEN_REFRESH_INTERVAL.as('milliseconds'));
    });
  }

  private cancelTokenRefreshSchedule(): void {
    if (this.tokenRefreshIntervalId) {
      clearInterval(this.tokenRefreshIntervalId);
      this.tokenRefreshIntervalId = null;
    }
  }

  private rescheduleRefresh(token: Token): void {
    this.cancelTokenRefreshSchedule();
    this.scheduleTokenRefresh(token);
  }

  // run outside of zone, since it contains setInterval, which would delay stability of ApplicationRef
  // this is executed from AuthModel.recoverToken, which is called upon during application initialization (Application.configureTransitions)
  // so we need to a) keep it outside the zone, so that we don't threat stability at that point of time (initialization), but, at the same time
  // b) keep the results of the timeout inside the zone anyway, so that it will properly trigger change detection (which is needed here)
  // cannot use standard ChangeDetectorRef.detectChanges, as we're outside of component tree scope and injector couldn't get it here
  // (this whole thing is important for HMR among other things)
  private setSessionTimeout(): void {
    this.zone.runOutsideAngular(() => {
      this.sessionTimeoutId = setTimeout(() => {
        this.zone.run(() => {
          this.eventManager.broadcast(Event.AUTH.SESSION_TIMEOUT);
          this.sessionTimeoutId = null;
        });
      }, AuthModel.SESSION_TIMEOUT.as('milliseconds'));
    });
  }

  private cancelSessionTimeout(): void {
    if (this.sessionTimeoutId) {
      clearTimeout(this.sessionTimeoutId);
      this.sessionTimeoutId = null;
    }
  }

  public resetSessionTimeout(): void {
    this.cancelSessionTimeout();
    this.setSessionTimeout();
  }

  private setToken(token: Token): Promise<Token> {
    return this.authService.setToken(token).toPromise();
  }

  private getToken(): Promise<Token> {
    return this.authService.getToken().toPromise();
  }

  private deleteToken(): Promise<void> {
    return this.authService.deleteToken().toPromise();
  }

}
