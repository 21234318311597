import { Injectable } from '@angular/core';
import { Event } from 'app/common/Event';
import { EventManager } from 'app/util/other/EventManager';
import { UserService } from 'app/service/UserService';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentUserDTO } from '@dto/user/CurrentUserDTO';
import { SecurityDataDTO } from '@dto/auth/SecurityDataDTO';
import { UserType } from '@enum/user/UserType';
import { map } from 'rxjs/operators';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';

@Injectable({ providedIn: 'root' })
export class UserModel {
  public currentUser$: BehaviorSubject<CurrentUserDTO> = new BehaviorSubject<CurrentUserDTO>(undefined);

  constructor(private eventManager: EventManager,
              private userService: UserService) {
    this.setupListeners();
  }

  public get currentUser(): CurrentUserDTO {
    return this.currentUser$.value;
  }

  public set currentUser(value: CurrentUserDTO) {
    this.currentUser$.next(value);
  }

  public isUserType(value: UserType): boolean {
    return this.currentUser?.type === value;
  }

  public get status$(): Observable<DoctorStatus> {
    return this.currentUser$.pipe(
      map(user => user.status)
    );
  }

  public isStatus$(status: DoctorStatus): Observable<boolean> {
    return this.status$.pipe(
      map((currentStatus: DoctorStatus) => currentStatus === status)
    );
  }

  public isUserType$(value: UserType): Observable<boolean> {
    return this.currentUser$.pipe(
      map((user: CurrentUserDTO) => user?.type === value)
    );
  }

  public get isAdmin$(): Observable<boolean> {
    return this.isUserType$(UserType.ADMIN);
  }

  public get isAdmin(): boolean {
    return this.isUserType(UserType.ADMIN);
  }

  public get isDoctor(): boolean {
    return this.isUserType(UserType.DOCTOR);
  }

  public get isDoctor$(): Observable<boolean> {
    return this.isUserType$(UserType.DOCTOR);
  }

  public userIsUnderRegistrationProcess(): boolean {
    const { status } = this.currentUser;

    return [ DoctorStatus.INCOMPLETE_REGISTRATION, DoctorStatus.NOT_ACCEPTED ].includes(status);
  }

  public userIsRegisteredCompletely(): boolean {
    return !this.userIsUnderRegistrationProcess();
  }

  public doctorIsRegisteredCompletely(): boolean {
    return this.isDoctor && this.userIsRegisteredCompletely();
  }

  public doctorIsUnderRegistrationProcess(): boolean {
    return this.isDoctor && this.userIsUnderRegistrationProcess();
  }

  private setupListeners(): void {
    this.eventManager.on(Event.AUTH.LOGOUT.SUCCESS, () => {
      this.currentUser = null;
    });
    this.eventManager.on(Event.AUTH.ERROR.UNAUTHORIZED, (result) => {
      this.currentUser = null;
    });
  }

  public getCurrentUser(): Promise<CurrentUserDTO> {
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
        .subscribe((result: { user: CurrentUserDTO, securityData: SecurityDataDTO }) => {
            this.currentUser = result.user;
            this.eventManager.broadcast(Event.USER.GET_CURRENT.SUCCESS, result.user);
            this.eventManager.broadcast(Event.USER.SECURITY_DATA, result.securityData);

            return resolve(result.user);
          }, (error) => {
            this.eventManager.broadcast(Event.USER.GET_CURRENT.ERROR, error);
            reject(error);
          }
        );
    });
  }
}
