import * as moment from 'moment';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AbstractStateComponent, StateConfig } from 'app/config/StateConfig';
import { AccountChangePasswordComponent } from 'app/component/view/main/account/AccountChangePasswordComponent';
import { AgGridModule } from 'ag-grid-angular';
import { AgeOver18Validator } from 'app/util/validator/AgeOver18Validator';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppAcceptLanguageInterceptor } from './util/interceptor/AppAcceptLanguageInterceptor';
import { AppLanguageInterceptor } from 'app/util/interceptor/AppLanguageInterceptor';
import { AppTypeInterceptor } from 'app/util/interceptor/AppTypeInterceptor';
import { AppVersionInterceptor } from 'app/util/interceptor/AppVersionInterceptor';
import { Application } from 'app/Application';
import { ApplicationComponent } from 'app/component/ApplicationComponent';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ArrayJoinPipe } from 'app/util/pipe/ArrayJoinPipe';
import { AuthModel } from 'app/model/AuthModel';
import { BirthDateToAgePipe } from 'app/util/pipe/BirthDateToAgePipe';
import { BooleanPipe } from 'app/util/pipe/BooleanPipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CheckboxArrayRequiredValidator } from 'app/util/validator/CheckboxArrayRequiredValidator';
import { ConsolePipe } from 'app/util/pipe/ConsolePipe';
import { CustomFormsModule as NgValidatorsModule } from 'ngx-custom-validators-fork';
import { DataGridBooleanCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridBooleanCellRendererComponent';
import { DataGridComponent } from 'app/component/ui/dataGrid/DataGridComponent';
import { DataGridEmailCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridEmailCellRendererComponent';
import { DateNowRelationPipe } from 'app/util/pipe/DateNowRelationPipe';
import { DatePipe } from '@angular/common';
import { DateFormatPipe } from 'app/util/pipe/DateFormatPipe';
import { DistanceFormatPipe } from 'app/util/pipe/DistanceFormatPipe';
import { DurationFormatPipe } from 'app/util/pipe/DurationFormatPipe';
import { ErrorAccessDeniedComponent } from 'app/component/view/preliminary/error/ErrorAccessDeniedComponent';
import { ErrorInterceptor } from 'app/util/interceptor/ErrorInterceptor';
import { ErrorNotFoundComponent } from 'app/component/view/preliminary/error/ErrorNotFoundComponent';
import { FileAllowedExtensionsValidator } from 'app/util/validator/FileAllowedExtensionsValidator';
import { FileMaxSizeValidator } from 'app/util/validator/FileMaxSizeValidator';
import { FileRequiredValidator } from 'app/util/validator/FileRequiredValidator';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalErrorHandler } from 'app/util/other/GlobalErrorHandler';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InputCheckBoxArrayValueAccessorDirective } from 'app/util/directive/InputCheckBoxArrayValueAccessorDirective';
import { InputFileValueAccessorDirective } from 'app/util/directive/InputFileValueAccessorDirective';
import { InputMultipleFileValueAccessorDirective } from 'app/util/directive/InputMultipleFileValueAccessorDirective';
import { InputNoSpaceDirective } from 'app/util/directive/InputNoSpaceDirective';
import { InputNumberOnlyDirective } from 'app/util/directive/InputNumberOnlyDirective';
import { InputPasswordToggleDirective } from 'app/util/directive/InputPasswordToggleDirective';
import { InputPositiveIntegerOnlyDirective } from 'app/util/directive/InputPositiveIntegerOnlyDirective';
import { InputToIntegerDirective } from 'app/util/directive/InputToIntegerDirective';
import { InputToNumberDirective } from 'app/util/directive/InputToNumberDirective';
import { InternalUrlBrowserComponent } from 'app/component/ui/internalUrlBrowser/InternalUrlBrowserComponent';
import { InternalUrlBrowserPopupComponent } from 'app/component/ui/internalUrlBrowser/popup/InternalUrlBrowserPopupComponent';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { LoadingInterceptor } from 'app/util/interceptor/LoadingInterceptor';
import { LoginComponent } from 'app/component/view/preliminary/login/LoginComponent';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { MaxDateTimeValidator } from 'app/util/validator/MaxDateTimeValidator';
import { MaxDateValidator } from 'app/util/validator/MaxDateValidator';
import { MaxLengthValidator } from 'app/util/validator/MaxLengthValidator';
import { MinDateTimeValidator } from 'app/util/validator/MinDateTimeValidator';
import { MinDateValidator } from 'app/util/validator/MinDateValidator';
import { MinutesToTimePipe } from 'app/util/pipe/MinutesToTimePipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgPipesModule } from 'ngx-pipes';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxTranslateCutModule } from 'ngx-translate-cut';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { Nl2BrPipe } from 'app/util/pipe/Nl2BrPipe';
import { OAuth2Interceptor } from 'app/util/interceptor/OAuth2Interceptor';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PasswordRequirementsValidator } from 'app/util/validator/PasswordRequirementsValidator';
import { PopupAlertComponent } from 'app/component/ui/popup/PopupAlertComponent';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { PrefixPipe } from 'app/util/pipe/PrefixPipe';
import { PreliminaryLayoutComponent } from 'app/component/view/preliminary/PreliminaryLayoutComponent';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RawLayoutComponent } from 'app/component/view/raw/RawLayoutComponent';
import { ReasonableBirthdateValidator } from 'app/util/validator/ReasonableBirthdateValidator';
import { ReplaceEmptyPipe } from 'app/util/pipe/ReplaceEmptyPipe';
import { ResetPasswordCompleteComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordCompleteComponent';
import { ResetPasswordStartComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordStartComponent';
import { ResponsiveDataGridComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridComponent';
import { ResponsiveDataGridDynamicElementComponent } from 'app/component/ui/responsiveDataGrid/ResponsiveDataGridDynamicElementComponent';
import { RouterConfig } from 'app/config/RouterConfig';
import { SanitizeHtmlPipe } from 'app/util/pipe/SanitizeHtmlPipe';
import { SanitizeStylePipe } from 'app/util/pipe/SanitizeStylePipe';
import { SanitizeUrlPipe } from 'app/util/pipe/SanitizeUrlPipe';
import { SplitPipe } from 'app/util/pipe/SplitPipe';
import { StorageConfig } from 'app/config/StorageConfig';
import { StorageModule } from '@ngx-pwa/local-storage';
import { SuffixPipe } from 'app/util/pipe/SuffixPipe';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TimezoneInterceptor } from 'app/util/interceptor/TimezoneInterceptor';
import { ToastrModule } from 'ngx-toastr';
import { TranslateConfig } from 'app/config/TranslateConfig';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { UnescapePipe } from 'app/util/pipe/UnescapePipe';
import { UserModel } from 'app/model/UserModel';

import { PasswordRequirementsComponent } from 'app/component/ui/passwordRequirements/PasswordRequirementsComponent';
import { HeaderComponent } from 'app/component/ui/layout/header/HeaderComponent';
import { SchedulerComponent } from 'app/component/view/main/dashboard/scheduler/SchedulerComponent';
import { VacationComponent } from 'app/component/view/main/workdaysSettings/view/vacation/VacationComponent';
import { SchedulerConflictsComponent } from 'app/component/ui/scheduler/conflicts/SchedulerConflictsComponent';
import { WorkingHoursComponent } from 'app/component/view/main/workdaysSettings/view/workinghours/WorkingHoursComponent';
import { PatientListComponent } from 'app/component/view/main/patient/PatientListComponent';
import { InvitePatientComponent } from 'app/component/view/main/invite/InvitePatientComponent';
import { PracticeCategoryComponent } from 'app/component/view/main/practice/category/PracticeCategoryComponent';
import { ProfileQualificationComponent } from 'app/component/view/main/profile/qualification/ProfileQualificationComponent';
import { ProfileLicenseComponent } from 'app/component/view/main/profile/license/ProfileLicenseComponent';
import { ProfilePracticeComponent } from 'app/component/view/main/profile/practice/ProfilePracticeComponent';
import { DoctorSettingsComponent } from 'app/component/view/main/settings/view/doctor/DoctorSettingsComponent';
import { ProfileComponent } from 'app/component/view/main/profile/ProfileComponent';
import { ProfileBasicDataComponent } from 'app/component/view/main/profile/basicData/ProfileBasicDataComponent';
import { ProfileBasicDataEditComponent } from './component/view/main/profile/basicData/ProfileBasicDataEditComponent';
import { ProfilePracticeEditComponent } from './component/view/main/profile/practice/ProfilePracticeEditComponent';
import { ProfileLicenseEditComponent } from './component/view/main/profile/license/ProfileLicenseEditComponent';
import { ProfileQualificationEditComponent } from './component/view/main/profile/qualification/ProfileQualificationEditComponent';
import { TruncatePipe } from './util/pipe/TruncatePipe';
import { WorkDaysSettingsComponent } from './component/view/main/workdaysSettings/WorkDaysSettingsComponent';
import { SchedulerSlotsGridComponent } from './component/ui/scheduler/SchedulerSlotsGridComponent';
import { MicrophoneVolumeIndicatorComponent } from './component/ui/videoCall/mirophoneIndicator/MicrophoneVolumeIndicatorComponent';
import { SchedulerModel } from '@model/SchedulerModel';
import { EventBasicDataComponent } from './component/view/raw/meeting/view/basicData/EventBasicDataComponent';
import { EventInsuranceComponent } from './component/view/raw/meeting/view/insurance/EventInsuranceComponent';
import { EventNoteComponent } from './component/view/raw/meeting/view/note/EventNoteComponent';
import { PatientInsuranceReportProblemPopupComponent } from './component/view/main/patient/details/insurance/popup/PatientInsuranceReportProblemPopupComponent';
import { InviteComponent } from './component/view/main/invite/InviteComponent';
import { InviteDoctorComponent } from './component/view/main/invite/InviteDoctorComponent';
import { EventUploadFilesComponent } from './component/view/raw/meeting/view/uploadedFiles/EventUploadFilesComponent';
import { EventHealthConditionComponent } from './component/view/raw/meeting/view/healthCondition/EventHealthConditionComponent';
import { PatientDetailsComponent } from './component/view/main/patient/details/PatientDetailsComponent';
import { SchedulerConflictSlotComponent } from './component/ui/scheduler/slot/SchedulerConflictSlotComponent';
import { EventPrescriptionComponent } from './component/view/raw/meeting/view/prescription/EventPrescriptionComponent';
import { EventSickLeavesComponent } from './component/view/raw/meeting/view/sickLeaves/EventSickLeavesComponent';
import { PrescriptionModel } from '@model/PrescriptionModel';
import { PrescriptionRequestListComponent } from './component/view/main/prescription/PrescriptionRequestListComponent';
import { PrescriptionRequestComponent } from './component/view/main/prescription/PrescriptionRequestComponent';
import { ImageNamePipe } from './util/pipe/ImageNamePipe';
import { StyleguideComponent } from './component/view/preliminary/styleguide/StyleguideComponent';
import { IconModule } from './module/iconModule/IconModule';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SidebarComponent } from './component/ui/layout/sidebar/SidebarComponent';
import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { PageActionContainerComponent } from './component/ui/page/action/PageActionContainerComponent';
import { PageActionPortalComponent } from './component/ui/page/action/PageActionPortalComponent';
import { CollectionJoinPipe } from '@util/pipe/CollectionJoinPipe';
import { DictionaryModel } from '@model/DictionaryModel';
import { NewDoctorsListComponent } from './component/view/main/dashboard/doctor/NewDoctorsListComponent';
import { DashboardComponent } from './component/view/main/dashboard/DashboardComponent';
import { IsUserTypePipe } from './util/pipe/IsUserTypePipe';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { VideoCallComponent } from './component/ui/videoCall/VideoCallComponent';
import { VideoCallRoomComponent } from './component/ui/videoCall/room/VideoCallRoomComponent';
import { VideoCallWaitingRoomComponent } from './component/ui/videoCall/waitingRoom/VideoCallWaitingRoomComponent';
import { VideoCallSettingComponent } from './component/ui/videoCall/setting/VideoCallSettingComponent';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TimeLeftDirective } from './util/directive/TimeLeftDirective';
import { TabComponent } from './component/ui/tab/TabComponent';
import { EventPrescriptionEditPopupComponent } from './component/view/raw/meeting/view/prescription/popup/EventPrescriptionEditPopupComponent';
import { CastDatePipe } from '@util/pipe/CastDatePipe';
import { DatePickerComponent } from './component/ui/control/DatePickerComponent';
import { TimePickerComponent } from './component/ui/control/TimePickerComponent';
import { SchedulerConflictsPopupComponent } from './component/ui/scheduler/popup/SchedulerConflictsPopupComponent';
import { CalendarSelectorComponent } from './component/ui/scheduler/calendar/CalendarSelectorComponent';
import { SchedulerDayHeaderComponent } from './component/ui/scheduler/day/SchedulerDayHeaderComponent';
import { SchedulerSlotComponent } from './component/ui/scheduler/slot/SchedulerSlotComponent';
import { PrescriptionEditPopupComponent } from './component/view/main/prescription/popup/PrescriptionEditPopupComponent';
import { PatientBasicDataComponent } from './component/view/main/patient/details/basicData/PatientBasicDataComponent';
import { PatientHistoryComponent } from './component/view/main/patient/details/history/PatientHistoryComponent';
import { PatientInsuranceComponent } from './component/view/main/patient/details/insurance/PatientInsuranceComponent';
import { HumanDurationFormatPipe } from '@util/pipe/HumanDurationFormatPipe';
import { EventComponent } from './component/view/main/event/EventComponent';
import { MeetingComponent } from 'app/component/view/raw/meeting/MeetingComponent';
import { EncodeHttpParamsInterceptor } from '@util/interceptor/EncodeHttpParamsInterceptor';
import { PageTitleContainerComponent } from './component/ui/page/title/PageTitleContainerComponent';
import { PageTitlePortalComponent } from './component/ui/page/title/PageTitlePortalComponent';
import { DoctorDetailsComponent } from './component/view/main/doctor/details/DoctorDetailsComponent';
import { DoctorBasicDataComponent } from './component/view/main/doctor/details/basicData/DoctorBasicDataComponent';
import { PopupConfirmationWithAnswerComponent } from './component/ui/popup/PopupConfirmationWithAnswerComponent';
import { DoctorLicenseComponent } from './component/view/main/doctor/details/license/DoctorLicenseComponent';
import { DoctorQualificationComponent } from './component/view/main/doctor/details/qualification/DoctorQualificationComponent';
import { DoctorPracticeComponent } from './component/view/main/doctor/details/practice/DoctorPracticeComponent';
import { DoctorListComponent } from './component/view/main/doctor/DoctorListComponent';
import { DoctorBasicDataEditComponent } from './component/view/main/doctor/details/basicData/DoctorBasicDataEditComponent';
import { DoctorQualificationEditComponent } from './component/view/main/doctor/details/qualification/DoctorQualificationEditComponent';
import { DoctorLicenseEditComponent } from './component/view/main/doctor/details/license/DoctorLicenseEditComponent';
import { DoctorPracticeEditComponent } from './component/view/main/doctor/details/practice/DoctorPracticeEditComponent';
import { DoctorPracticeCreateComponent } from './component/view/main/doctor/details/practice/DoctorPracticeCreateComponent';
import { DoctorPracticeFormComponent } from './component/view/main/doctor/details/practice/partial/DoctorPracticeFormComponent';
import { ProfilePracticeCreateComponent } from './component/view/main/profile/practice/ProfilePracticeCreateComponent';
import { DoctorSchedulerComponent } from './component/view/main/doctor/details/scheduler/DoctorSchedulerComponent';
import { DoctorSchedulerSlotComponent } from './component/ui/scheduler/slot/DoctorSchedulerSlotComponent';
import { DoctorWorkingHoursPopupComponent } from './component/view/main/doctor/details/scheduler/popup/DoctorWorkingHoursPopupComponent';
import { DoctorVacationsPopupComponent } from './component/view/main/doctor/details/scheduler/popup/DoctorVacationsPopupComponent';
import { DoctorCategoryComponent } from './component/view/main/doctor/details/category/DoctorCategoryComponent';
import { EventListComponent } from './component/view/main/event/EventListComponent';
import { StatisticListComponent } from './component/view/main/statistic/StatisticListComponent';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AvatarCropperPopupComponent } from './component/ui/avatar/AvatarCropperPopupComponent';
import { AvatarManagerComponent } from './component/ui/avatar/AvatarManagerComponent';
import { AvatarPreviewComponent } from './component/ui/avatar/AvatarPreviewComponent';
import { MigratingAccountComponent } from './component/view/raw/migrating/MigratingAccountComponent';
import { NetworkStatusPopupComponent } from './component/ui/popup/networkStatus/NetworkStatusPopupComponent';
import { NetworkStatusInterceptor } from '@util/interceptor/NetworkStatusInterceptor';
import { PatientAdminListComponent } from './component/view/main/patient/admin/PatientAdminListComponent';
import { AngularIbanModule } from 'angular-iban';
import { EmptyToNullDirective } from './util/directive/EmptyToNullDirective';
import { GuestVideoCallComponent } from './component/view/raw/guest/GuestVideoCallComponent';
import { LocaleId } from '@local/LocalId';
import { LocaleService } from '@service/LocaleService';
import { EventCancelComponent } from './component/view/raw/event/view/cancel/EventCancelComponent';
import { FilePreviewComponent } from './component/ui/file/preview/FilePreviewComponent';
import { AdminSettingsComponent } from './component/view/main/settings/view/admin/AdminSettingsComponent';
import { SettingsComponent } from './component/view/main/settings/SettingsComponent';
import { SettingsGlobalLogoutComponent } from './component/view/main/settings/component/SettingsGlobalLogoutComponent';
import { ProcessingDataConsentComponent } from 'app/component/view/raw/processingData/ProcessingDataConsentComponent';
import { RegistrationCompleteComponent } from 'app/component/view/preliminary/registration/registrationComplete/RegistrationCompleteComponent';
import { RegistrationCompleteUnsuccessfulComponent } from 'app/component/view/preliminary/registration/registrationCompleteUnsuccessful/RegistrationCompleteUnsuccessfulComponent';
import { RegistrationComponent } from 'app/component/view/preliminary/registration/RegistrationComponent';
import { PracticeComponent } from './component/view/main/practice/PracticeComponent';
import { NonEmptyOrEmptyStringValidatorDirective } from '@util/validator/NonEmptyOrEmptyStringValidator';
import { NavbarComponent } from './component/ui/layout/navbar/NavbarComponent';

@NgModule({
  declarations: [
    ApplicationComponent,
    // component/view
    MainLayoutComponent, PreliminaryLayoutComponent, RawLayoutComponent,
    AbstractStateComponent,
    LoginComponent, ResetPasswordStartComponent, ResetPasswordCompleteComponent,
    ErrorNotFoundComponent, ErrorAccessDeniedComponent,
    AccountChangePasswordComponent,
    // component/ui
    DataGridComponent,
    InternalUrlBrowserComponent, InternalUrlBrowserPopupComponent,
    PopupConfirmationComponent, PopupAlertComponent,
    ResponsiveDataGridComponent,
    // telehealth component
    PasswordRequirementsComponent,
    // util/pipe
    ArrayJoinPipe, BirthDateToAgePipe, BooleanPipe, ConsolePipe, DateFormatPipe, DateNowRelationPipe, DistanceFormatPipe, DurationFormatPipe,
    MinutesToTimePipe, Nl2BrPipe, PrefixPipe, ReplaceEmptyPipe, SanitizeHtmlPipe, SanitizeStylePipe, SanitizeUrlPipe, SplitPipe, SuffixPipe, UnescapePipe, TruncatePipe, ImageNamePipe,
    CastDatePipe,
    // util/directive
    InputNoSpaceDirective, InputNumberOnlyDirective, InputPositiveIntegerOnlyDirective, InputToNumberDirective, InputPasswordToggleDirective,
    InputToIntegerDirective, InputFileValueAccessorDirective, InputMultipleFileValueAccessorDirective, InputCheckBoxArrayValueAccessorDirective,
    // util/validator
    AgeOver18Validator, CheckboxArrayRequiredValidator, FileAllowedExtensionsValidator, FileMaxSizeValidator, FileRequiredValidator,
    MaxDateTimeValidator, MaxDateValidator, MaxLengthValidator, MinDateTimeValidator, MinDateValidator, ReasonableBirthdateValidator, PasswordRequirementsValidator, NonEmptyOrEmptyStringValidatorDirective,
    // cellRenderers
    ResponsiveDataGridDynamicElementComponent,
    DataGridEmailCellRendererComponent, DataGridBooleanCellRendererComponent, DataGridTemplateCellRendererComponent,
    HeaderComponent,
    SchedulerComponent,
    VacationComponent,
    SchedulerConflictsComponent,
    WorkingHoursComponent,
    PatientListComponent,
    InvitePatientComponent,
    PracticeCategoryComponent,
    ProfileQualificationComponent,
    ProfileLicenseComponent,
    ProfilePracticeComponent,
    DoctorSettingsComponent,
    ProfileComponent,
    ProfileBasicDataComponent,
    ProfileBasicDataEditComponent,
    ProfilePracticeEditComponent,
    ProfileLicenseEditComponent,
    ProfileQualificationEditComponent,
    WorkDaysSettingsComponent,
    SchedulerSlotsGridComponent,
    VideoCallComponent,
    MeetingComponent,
    MicrophoneVolumeIndicatorComponent,
    EventBasicDataComponent,
    EventInsuranceComponent,
    EventNoteComponent,
    PatientInsuranceReportProblemPopupComponent,
    InviteComponent,
    InviteDoctorComponent,
    EventUploadFilesComponent,
    EventHealthConditionComponent,
    PatientDetailsComponent,
    PatientDetailsComponent,
    PatientInsuranceComponent,
    SchedulerConflictSlotComponent,
    EventPrescriptionComponent,
    EventSickLeavesComponent,
    PrescriptionRequestListComponent,
    PrescriptionRequestComponent,
    StyleguideComponent,
    SidebarComponent,
    PageActionContainerComponent,
    PageActionPortalComponent,
    CollectionJoinPipe,
    NewDoctorsListComponent,
    DashboardComponent,
    IsUserTypePipe,
    HumanDurationFormatPipe,
    VideoCallComponent,
    VideoCallRoomComponent,
    VideoCallWaitingRoomComponent,
    VideoCallSettingComponent,
    TimeLeftDirective,
    TabComponent,
    EventPrescriptionEditPopupComponent,
    DatePickerComponent,
    TimePickerComponent,
    SchedulerConflictsPopupComponent,
    CalendarSelectorComponent,
    SchedulerDayHeaderComponent,
    SchedulerSlotComponent,
    PrescriptionEditPopupComponent,
    PatientBasicDataComponent,
    PatientHistoryComponent,
    EventComponent,
    PageTitleContainerComponent,
    PageTitlePortalComponent,
    DoctorDetailsComponent,
    DoctorBasicDataComponent,
    PopupConfirmationWithAnswerComponent,
    DoctorLicenseComponent,
    DoctorQualificationComponent,
    DoctorPracticeComponent,
    DoctorListComponent,
    DoctorBasicDataEditComponent,
    DoctorQualificationEditComponent,
    DoctorLicenseEditComponent,
    DoctorPracticeEditComponent,
    DoctorPracticeCreateComponent,
    DoctorPracticeFormComponent,
    ProfilePracticeCreateComponent,
    DoctorSchedulerComponent,
    DoctorSchedulerSlotComponent,
    DoctorWorkingHoursPopupComponent,
    DoctorVacationsPopupComponent,
    DoctorCategoryComponent,
    EventListComponent,
    StatisticListComponent,
    AvatarCropperPopupComponent,
    AvatarManagerComponent,
    AvatarPreviewComponent,
    MigratingAccountComponent,
    NetworkStatusPopupComponent,
    PatientAdminListComponent,
    EmptyToNullDirective,
    GuestVideoCallComponent,
    EventCancelComponent,
    FilePreviewComponent,
    AdminSettingsComponent,
    SettingsComponent,
    SettingsGlobalLogoutComponent,
    ProcessingDataConsentComponent,
    RegistrationComponent,
    RegistrationCompleteComponent,
    RegistrationCompleteUnsuccessfulComponent,
    PracticeComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UIRouterModule.forRoot({ config: RouterConfig, states: StateConfig, useHash: false }),
    NgValidatorsModule,
    NgPipesModule,
    TranslateModule.forRoot(TranslateConfig),
    NgxTranslateCutModule,
    ImageCropperModule,
    AngularIbanModule,
    NgxUiLoaderModule.forRoot({
      fgsColor: '#2EA39D',
      fgsPosition: 'center-center',
      fgsSize: 50,
      fgsType: 'ball-spin',
      blur: 1,
      delay: 0,
      fastFadeOut: true,
      hasProgressBar: false,
      overlayBorderRadius: '0',
      overlayColor: 'rgba(0,0,0,0.1)'
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      extendedTimeOut: moment.duration(3, 'seconds').as('milliseconds'),
      enableHtml: true
    }),
    NgxFilesizeModule,
    AngularSvgIconModule.forRoot(),
    NgSelectModule,
    ButtonsModule.forRoot(), ModalModule.forRoot(), TabsModule.forRoot(), PaginationModule.forRoot(), ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(), TimepickerModule.forRoot(), BsDropdownModule.forRoot(),
    NgxSliderModule,
    FileUploadModule,
    PortalModule,
    StorageModule.forRoot(StorageConfig),
    AgGridModule.withComponents([]),
    IconModule, CollapseModule, A11yModule, OverlayModule, DragDropModule
  ],
  providers: [
    DatePipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: NgSelectConfig, useValue: { disableVirtualScroll: true, openOnEnter: true } },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: [LanguageVersion.en, LanguageVersion.de] } },
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
      deps: [LocaleService]
    },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: NetworkStatusInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: LoadingInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: TimezoneInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppLanguageInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppAcceptLanguageInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppTypeInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AppVersionInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: OAuth2Interceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: ErrorInterceptor },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: EncodeHttpParamsInterceptor },
    {
      provide: APP_INITIALIZER, multi: true,
      useFactory: (
        applicationModel: ApplicationModel,
        authModel: AuthModel,
        userModel: UserModel,
        eventModel: SchedulerModel,
        prescriptionModel: PrescriptionModel,
        dictionary: DictionaryModel,
        application: Application
      ) => (): void => {
      },
      deps: [
        ApplicationModel,
        AuthModel,
        UserModel,
        SchedulerModel,
        PrescriptionModel,
        DictionaryModel,
        Application
      ]
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [ApplicationComponent]
})
export class ApplicationModule {
}