import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { Observable } from 'rxjs';
import { EventTimesDTO } from '@dto/events/EventTimesDTO';

@Component({
  selector: 'app-profile-category',
  templateUrl: './PracticeCategoryComponent.html',
  styleUrls: [ './PracticeCategoryComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PracticeCategoryComponent implements OnInit {
  public data$: Observable<EventTimesDTO> = this.currentDoctorService.getVisitCategories();

  constructor(public currentDoctorService: CurrentDoctorService) {
  }

  public ngOnInit(): void {
  }
}
