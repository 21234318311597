import { VisitTypeDTO } from '@dto/doctor/VisitTypeDTO';
import { DateUtil } from '@util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { EventStatus } from '@enum/event/EventStatus';

export class PatientEventHistoryDTO {
  public eventId: number;

  @Transform(DateUtil.dateTimeConversion)
  public dateTime: Date;

  public duration: number; // in seconds (for past events)

  public status: EventStatus;

  @Type(() => VisitTypeDTO)
  public visitTypes: VisitTypeDTO[];
}