<div id="layout"
     (mousedown)="onMouseDown($event)"
     (keypress)="onMouseDown($event)">

  <div class="container-fluid px-0 position-relative">
    <div class="bg-white">
      <div class="container px-0">
        <app-header></app-header>
      </div>
    </div>
  </div>

  <div class="container px-0">
    <div class="content d-flex">
      <div class="sidebar-container bg-white pt-3 pr-1 pr-lg-3 pr-xl-4"
           *ngIf="applicationModel.sidebarVisible$ | async">
        <app-sidebar></app-sidebar>
      </div>

      <main>
        <ui-view></ui-view>
      </main>
    </div>
  </div>

</div>
