<form #form="ngForm" novalidate (ngSubmit)="save()">
  <section class="page">
    <div class="header-container separator">
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{ 'VIEW.MAIN.DOCTOR.BASIC_DATA.EDIT_DATA' | translate }}</div>
      </button>

      <div class="actions">
        <button type="submit" class="btn btn-primary"
                [disabled]="disabledSubmit"> {{ 'COMMON.SAVE' | translate}} </button>
      </div>
    </div>

    <div class="content">
      <ng-container *ngIf="doctor">
        <div class="container px-0">
          <div class="row mb-4">
            <div class="col-12">
              <app-avatar-manager [data]="doctor"
                                  (uploadEvent)="uploadAvatar($event)"
                                  (deleteEvent)="deleteAvatar()"></app-avatar-manager>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="font-weight-bold mb-4">{{'VIEW.MAIN.DOCTOR.BASIC_DATA.PERSONAL_INFO' | translate}}</div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="status">{{ 'VIEW.MAIN.DOCTOR.DATA.STATUS' | translate }}</label>
                    <ng-select id="status" name="status" #status="ngModel"
                               [placeholder]="'COMMON.CHOOSE' | translate"
                               [items]="statusOptions"
                               [searchable]="false"
                               [clearable]="false"
                               [ngClass]="{'is-invalid': form.submitted && status.invalid }"
                               [disabled]="true"
                               [(ngModel)]="doctor.status"
                               required>
                      <ng-template ng-label-tmp
                                   let-item="item">{{item | prefix: 'ENUM.DOCTOR_STATUS.' | translate}}</ng-template>
                      <ng-template ng-option-tmp
                                   let-item="item">{{item | prefix: 'ENUM.DOCTOR_STATUS.' | translate}}</ng-template>
                    </ng-select>
                  </div>

                  <div class="form-group">
                    <label for="customerId">{{ 'VIEW.MAIN.DOCTOR.DATA.CUSTOMER_ID' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="customerId" id="customerId"
                           [ngClass]="{'is-invalid': form.submitted && customerId.invalid }" #customerId="ngModel"
                           [(ngModel)]="doctor.customerId" disabled/>
                  </div>

                  <div class="form-group">
                    <label for="title">{{ 'VIEW.MAIN.DOCTOR.DATA.TITLE' | translate }}</label>
                    <ng-select id="title" name="title" #title="ngModel"
                               [placeholder]="'COMMON.CHOOSE' | translate"
                               [items]="titleOptions"
                               [searchable]="false"
                               [clearable]="false"
                               [ngClass]="{'is-invalid': form.submitted && title.invalid }"
                               [(ngModel)]="doctor.title">
                      <ng-template ng-label-tmp let-item="item">
                        {{item === '' ? ('ENUM.DOCTOR_TITLE.NO_TITLE' | translate) : item }}</ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        {{item === '' ? ('ENUM.DOCTOR_TITLE.NO_TITLE' | translate) : item }}</ng-template>
                    </ng-select>
                  </div>

                  <div class="form-group">
                    <label for="firstName">{{ 'VIEW.MAIN.DOCTOR.DATA.FIRST_NAME' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="firstName" id="firstName"
                           [ngClass]="{'is-invalid': form.submitted && firstName.invalid }" #firstName="ngModel"
                           [(ngModel)]="doctor.firstName"
                           [maxlength]="Constant.TEXT_MAX_LENGTH"
                           required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && firstName.invalid">
                      <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div
                        *ngIf="firstName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: firstName.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="lastName">{{ 'VIEW.MAIN.DOCTOR.DATA.LAST_NAME' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="lastName" id="lastName"
                           [ngClass]="{'is-invalid': form.submitted && lastName.invalid }" #lastName="ngModel"
                           [(ngModel)]="doctor.lastName"
                           [maxlength]="Constant.TEXT_MAX_LENGTH"
                           required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && lastName.invalid">
                      <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div
                        *ngIf="lastName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: lastName.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="gender">{{ 'VIEW.MAIN.DOCTOR.DATA.GENDER' | translate }}</label>
                    <ng-select id="gender" name="gender" #gender="ngModel"
                               [placeholder]="'COMMON.CHOOSE' | translate"
                               [items]="genderOptions"
                               [searchable]="false"
                               [clearable]="false"
                               [ngClass]="{'is-invalid': form.submitted && gender.invalid }"
                               [(ngModel)]="doctor.gender"
                               required>
                      <ng-template ng-label-tmp
                                   let-item="item">{{item | prefix: 'ENUM.GENDER.' | translate}}</ng-template>
                      <ng-template ng-option-tmp
                                   let-item="item">{{item | prefix: 'ENUM.GENDER.' | translate}}</ng-template>
                    </ng-select>
                  </div>

                  <div class="form-group">
                    <label for="email">{{ 'VIEW.MAIN.DOCTOR.DATA.EMAIL' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="email" id="email"
                           [ngClass]="{'is-invalid': form.submitted && email.invalid }" #email="ngModel"
                           [(ngModel)]="doctor.email"
                           disabled/>
                  </div>

                  <div class="form-group">
                    <label for="phoneNumber">{{ 'VIEW.MAIN.DOCTOR.DATA.PHONE_NUMBER' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="phoneNumber" id="phoneNumber"
                           [ngClass]="{'is-invalid': form.submitted && phoneNumber.invalid }" #phoneNumber="ngModel"
                           [(ngModel)]="doctor.phoneNumber"
                           [pattern]="Constant.PHONE_PATTERN" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && phoneNumber.invalid">
                      <div *ngIf="phoneNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="phoneNumber.errors['pattern']">{{ 'VALIDATION.PHONE' | translate }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="streetWithNumber">{{ 'VIEW.MAIN.DOCTOR.DATA.STREET' | translate }}</label>
                    <input type="text" class="form-control form-control-sm"
                           name="streetWithNumber" id="streetWithNumber"
                           [ngClass]="{'is-invalid': form.submitted && streetWithNumber.invalid }"
                           #streetWithNumber="ngModel"
                           [(ngModel)]="doctor.address.streetWithNumber"
                           [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && streetWithNumber.invalid">
                      <div *ngIf="streetWithNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div
                        *ngIf="streetWithNumber.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: streetWithNumber.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="cityCode">{{ 'VIEW.MAIN.DOCTOR.DATA.POST_CODE' | translate }}</label>
                    <input type="text" class="form-control form-control-sm"
                           name="cityCode" id="cityCode"
                           [ngClass]="{'is-invalid': form.submitted && cityCode.invalid }" #cityCode="ngModel"
                           [(ngModel)]="doctor.address.cityCode"
                           [pattern]="Constant.POSTAL_CODE_PATTERN"
                           [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && cityCode.invalid">
                      <div *ngIf="cityCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="cityCode.errors['pattern']">{{ 'VALIDATION.POSTAL_CODE' | translate }}</div>
                      <div
                        *ngIf="cityCode.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: cityCode.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="city">{{ 'VIEW.MAIN.DOCTOR.DATA.CITY' | translate }}</label>
                    <input type="text" class="form-control form-control-sm"
                           name="city" id="city"
                           [ngClass]="{'is-invalid': form.submitted && city.invalid }" #city="ngModel"
                           [(ngModel)]="doctor.address.city"
                           [maxlength]="Constant.TEXT_MAX_LENGTH" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && city.invalid">
                      <div *ngIf="city.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div
                        *ngIf="city.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: city.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label for="speciality">{{ 'VIEW.MAIN.DOCTOR.DATA.SPECIALITY' | translate }}</label>
                    <ng-select name="speciality"
                               id="speciality"
                               class="w-100"
                               [placeholder]="'COMMON.CHOOSE' | translate"
                               [ngClass]="{'is-invalid': form.submitted && speciality.invalid }" #speciality="ngModel"
                               [items]="specialities$ | async"
                               [searchable]="false"
                               [clearable]="false"
                               bindLabel="name"
                               [(ngModel)]="doctor.speciality"
                               required>
                    </ng-select>

                    <div class="invalid-feedback" *ngIf="form.submitted && speciality.invalid">
                      <div *ngIf="speciality.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="bio">{{ 'VIEW.MAIN.DOCTOR.DATA.BIO' | translate }}</label>
                    <textarea type="text" class="form-control form-control-sm" name="bio" id="bio"
                              rows="8"
                              [ngClass]="{'is-invalid': form.submitted && bio.invalid }" #bio="ngModel"
                              [(ngModel)]="doctor.bio"
                              [maxlength]="Constant.TEXT_MAX_LENGTH_LG"></textarea>

                    <div class="invalid-feedback" *ngIf="form.submitted && bio.invalid">
                      <div
                        *ngIf="bio.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: bio.errors['maxlength'].requiredLength} }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BUSINESS_OWNER' | translate }}</label>

                    <div class="custom-control custom-radio primary">
                      <input type="radio" class="custom-control-input" id="businessOwner_yes"
                             name="businessOwner" [(ngModel)]="doctor.businessOwner" [value]="true">
                      <label class="custom-control-label" for="businessOwner_yes">{{'COMMON.YES' | translate}}</label>
                    </div>

                    <div class="custom-control custom-radio primary mt-1">
                      <input type="radio" class="custom-control-input" id="businessOwner_no"
                             name="businessOwner" [(ngModel)]="doctor.businessOwner" [value]="false">
                      <label class="custom-control-label" for="businessOwner_no">{{'COMMON.NO' | translate}}</label>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="doctor?.businessOwner">
                    <label for="ustId">{{ 'VIEW.MAIN.DOCTOR.DATA.UST_ID' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="ustId" id="ustId"
                           [ngClass]="{'is-invalid': form.submitted && ustId.invalid }" #ustId="ngModel"
                           [(ngModel)]="doctor.ustId"
                           [pattern]="Constant.UST_ID_PATTERN" required/>
                    <div class="invalid-feedback" *ngIf="form.submitted && ustId.invalid">
                      <div *ngIf="ustId.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="ustId.errors['pattern']">{{ 'VALIDATION.UST_ID' | translate }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 mt-2 mt-md-0">
              <div class="font-weight-bold mb-4">{{ 'VIEW.MAIN.DOCTOR.BASIC_DATA.BILLING_INFO' | translate }}</div>

              <div class="form-group">
                <label for="billingName">{{ 'VIEW.MAIN.DOCTOR.DATA.BILLING_NAME' | translate }}</label>
                <input type="text" class="form-control form-control-sm"
                       name="billingName" id="billingName"
                       appEmptyToNull
                       [ngClass]="{'is-invalid': form.submitted && billingName.invalid }" #billingName="ngModel"
                       [(ngModel)]="doctor.billingName"
                       [maxlength]="Constant.TEXT_MAX_LENGTH_LG" [required]="doctor.paymentAcceptation"/>

                <div class="invalid-feedback" *ngIf="form.submitted && billingName.invalid">
                  <div *ngIf="billingName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  <div
                    *ngIf="billingName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: billingName.errors['maxlength'].requiredLength} }}</div>
                </div>
              </div>

              <div class="form-group">
                <label for="bic">{{ 'VIEW.MAIN.DOCTOR.DATA.BIC' | translate }}</label>
                <input type="text" class="form-control form-control-sm"
                       name="bic" id="bic"
                       appEmptyToNull
                       [ngClass]="{'is-invalid': form.submitted && bic.invalid }" #bic="ngModel"
                       [(ngModel)]="doctor.bic"
                       [pattern]="Constant.BIC_PATTERN" [required]="doctor.paymentAcceptation"/>

                <div class="invalid-feedback" *ngIf="form.submitted && bic.invalid">
                  <div *ngIf="bic.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  <div *ngIf="bic.errors['pattern']">{{ 'VALIDATION.BIC' | translate }}</div>
                </div>
              </div>

              <div class="form-group">
                <label for="iban">{{ 'VIEW.MAIN.DOCTOR.DATA.IBAN' | translate }}</label>
                <input type="text" class="form-control form-control-sm"
                       name="iban" id="iban"
                       appEmptyToNull
                       [ngClass]="{'is-invalid': form.submitted && iban.invalid }" #iban="ngModel"
                       [(ngModel)]="doctor.iban"
                       ibanValidator [required]="doctor.paymentAcceptation"/>

                <div class="invalid-feedback" *ngIf="form.submitted && iban.invalid">
                  <div *ngIf="iban.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  <div *ngIf="iban.errors['iban']">{{ 'VALIDATION.IBAN' | translate }}</div>
                </div>
              </div>

              <div class="form-group">
                <label for="billingEmail">{{ 'VIEW.MAIN.DOCTOR.DATA.BILLING_EMAIL' | translate }}</label>
                <input type="text" class="form-control form-control-sm"
                       name="billingEmail" id="billingEmail"
                       appEmptyToNull
                       [ngClass]="{'is-invalid': form.submitted && billingEmail.invalid }" #billingEmail="ngModel"
                       [(ngModel)]="doctor.billingEmail"
                       ngvemail [required]="doctor.paymentAcceptation"/>

                <div class="invalid-feedback" *ngIf="form.submitted && billingEmail.invalid">
                  <div *ngIf="billingEmail.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  <div *ngIf="billingEmail.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
                </div>
              </div>

              <div class="form-group">
                <div class="custom-control custom-checkbox primary">
                  <input type="checkbox" class="custom-control-input"
                         id="paymentAcceptation" name="paymentAcceptation"
                         [(ngModel)]="doctor.paymentAcceptation"
                         [value]="true">
                  <label class="custom-control-label color-black"
                         for="paymentAcceptation">{{ 'VIEW.MAIN.DOCTOR.BASIC_DATA.AGREEMENT_TO_PAYMENT' | translate }}</label>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</form>
