<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <div class="modal-control">
    <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
      <i-tabler name="x"></i-tabler>
    </button>
  </div>
</div>

<div class="modal-body" *ngIf="message">
  <p class="lead mb-0">{{ message | translate: messageVariables }}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary"
          (click)="ok(true)">{{ okText | translate }}</button>
</div>
