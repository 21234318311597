import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { UserModel } from 'app/model/UserModel';
import { AuthModel } from 'app/model/AuthModel';
import { PrescriptionModel } from '@model/PrescriptionModel';
import { State } from 'app/common/State';
import { StateUtil } from 'app/util/StateUtil';
import { SideBarItem } from 'app/data/local/ui/SideBarItem';
import { ViewUtil } from 'app/util/ViewUtil';
import { EventManager } from 'app/util/other/EventManager';
import { Event } from 'app/common/Event';
import { ObjectUtil } from 'app/util/ObjectUtil';

@Component({
  selector: 'app-main-layout',
  templateUrl: 'MainLayoutComponent.html',
  styleUrls: [ 'MainLayoutComponent.scss' ]
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  public State: typeof State = State;

  private inactiveSessionLogoutBoundFunction: () => any = this.inactiveSessionLogout.bind(this);

  constructor(public stateUtil: StateUtil,
              public viewUtil: ViewUtil,
              public applicationModel: ApplicationModel,
              private eventManager: EventManager,
              private userModel: UserModel,
              private authModel: AuthModel,
              private prescriptionModel: PrescriptionModel) {
  }

  public ngOnInit(): void {
    this.updateSidebarMenu();

    this.eventManager.on(Event.AUTH.SESSION_TIMEOUT, this.inactiveSessionLogoutBoundFunction);
  }

  public ngOnDestroy(): void {
    this.eventManager.destroyListener(Event.AUTH.SESSION_TIMEOUT, this.inactiveSessionLogoutBoundFunction);
  }

  public logout(): void {
    this.authModel.logout();
  }

  public onMouseDown($event): void {
    this.authModel.resetSessionTimeout();
  }

  private inactiveSessionLogout(): void {
    this.viewUtil.closeAllModals();
    this.logout();
    this.viewUtil.showToastWarning('MESSAGE.INACTIVITY_LOGGED_OUT');
  }

  private updateSidebarMenu(): void {
    const items: SideBarItem[] = [
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.DASHBOARD',
        icon: 'home',
        stateName: State.MAIN.DASHBOARD.DASHBOARD,
        visible: this.userModel.userIsRegisteredCompletely()
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.PRESCRIPTIONS',
        icon: 'file-diff',
        stateName: State.MAIN.PRESCRIPTION_REQUEST.LIST,
        visible: this.userModel.doctorIsRegisteredCompletely(),
        badge$: () => this.prescriptionModel.numberOfNewPrescriptions$
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.MY_PATIENTS',
        icon: 'user',
        stateName: State.MAIN.MY_PATIENT.LIST,
        visible: this.userModel.doctorIsRegisteredCompletely()
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.VIDEO_CALLS',
        icon: 'video',
        stateName: State.MAIN.EVENT.LIST,
        visible: this.userModel.isAdmin
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.DOCTORS',
        icon: 'user',
        stateName: State.MAIN.DOCTOR.LIST,
        visible: this.userModel.isAdmin
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.PATIENTS',
        icon: 'user',
        stateName: State.MAIN.PATIENT.LIST,
        visible: this.userModel.isAdmin
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.STATISTICS',
        icon: 'trending-up',
        stateName: State.MAIN.STATISTIC.LIST,
        visible: this.userModel.isAdmin
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.INVITE',
        icon: 'send',
        stateName: State.MAIN.INVITE.INVITE,
        visible: this.userModel.doctorIsRegisteredCompletely()
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.PROFILE',
        icon: 'user',
        stateName: State.MAIN.PROFILE.BASIC_DATA,
        visible: this.userModel.isDoctor
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.PRACTICE',
        icon: 'user',
        stateName: State.MAIN.PRACTICE.CATEGORY,
        visible: this.userModel.doctorIsRegisteredCompletely()
      }),
      ObjectUtil.plainToClass(SideBarItem, {
        label: 'VIEW.MAIN.MAIN.SETTINGS',
        icon: 'settings',
        stateName: State.MAIN.SETTINGS.SETTINGS,
        visible: this.userModel.isAdmin || this.userModel.doctorIsRegisteredCompletely()
      })
    ];

    this.applicationModel.sideBarItems = items;
  }

}
