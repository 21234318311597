<div class="settings">
  <ng-container *ngIf="videoCallStorage.audioLocalTrack$ | async">
    <button type="button" class="btn btn-link on" (click)="videoCallStorage.stopLocalAudioTrack()">
      <i-tabler name="microphone"></i-tabler>
    </button>
  </ng-container>

  <ng-container *ngIf="(videoCallStorage.audioLocalTrack$ | async) === null">
    <button type="button" class="btn btn-link off" (click)="videoCallStorage.generateLocalAudioTrack(true)"
            [disabled]="!videoCallStorage.availableAudioDevice">
      <i-tabler name="microphone-off"></i-tabler>
    </button>
  </ng-container>


  <ng-container *ngIf="videoCallStorage.videoLocalTrack$ | async">
    <button type="button" class="btn btn-link on" (click)="videoCallStorage.stopLocalVideoTrack()">
      <i-tabler name="video"></i-tabler>
    </button>
  </ng-container>

  <ng-container *ngIf="(videoCallStorage.videoLocalTrack$ | async) === null">
    <button type="button" class="btn btn-link off" (click)="videoCallStorage.generateLocalVideoTrack(true)"
            [disabled]="!videoCallStorage.availableVideoDevice">
      <i-tabler name="video-off"></i-tabler>
    </button>
  </ng-container>
</div>