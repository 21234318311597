export class ApplicationErrorDTO {

  public cause: string;

  public message: string;

  public stackTrace: string[];

  public url: string;

  public userId: number;

  public userName: string;

}
