<form #form="ngForm" novalidate (ngSubmit)="save()">
  <ng-container *ngIf="qualifications">
    <section class="page">
      <div class="header-container separator">
        <button type="button"
                class="back" (click)="backToProfile()">
          <i-tabler name="chevron-left"></i-tabler>
          <div>{{ 'VIEW.MAIN.DOCTOR.QUALIFICATIONS.EDIT_DATA' | translate }}</div>
        </button>

        <div class="actions">
          <button type="submit" class="btn btn-primary"
                  [disabled]="disabledSubmit"> {{ 'COMMON.SAVE' | translate}} </button>
        </div>
      </div>

      <div class="content">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="font-weight-bold mb-4">{{'VIEW.MAIN.DOCTOR.QUALIFICATIONS.QUALIFICATIONS' | translate }}</div>

            <ng-container *ngFor="let item of qualifications;let index = index">
              <div class="form-group mb-1">
                <div class="d-flex align-items-center">
                  <ng-select name="qualification-{{index}}"
                             class="w-100"
                             [notFoundText]="'COMPONENT.SELECT.NOT_FOUND' | translate"
                             [placeholder]="'COMMON.CHOOSE' | translate"
                             [items]="availableQualifications$ | async"
                             [searchable]="true"
                             [clearable]="false"
                             bindLabel="name"
                             [(ngModel)]="qualifications[index]"
                             [ngModelOptions]="{standalone: true}"
                             (change)="onSelectedChanged()"
                             required>
                  </ng-select>

                  <button type="button" class="btn btn-link btn-link--black remove ml-1" (click)="remove(index)">
                    <i-tabler name="trash" class="d-flex i-lg"></i-tabler>
                  </button>
                </div>
              </div>
            </ng-container>

            <div class="mr-4 mt-4">
              <ng-select name="new-qualification"
                         class="w-100"
                         [notFoundText]="'COMPONENT.SELECT.NOT_FOUND' | translate"
                         [placeholder]="'VIEW.MAIN.DOCTOR.QUALIFICATIONS.PLACEHOLDER' | translate"
                         [items]="availableQualifications$ | async"
                         [searchable]="true"
                         [clearable]="false"
                         bindLabel="name"
                         [(ngModel)]="newQualification">
              </ng-select>

              <button type="button"
                      class="btn btn-primary mt-1 btn-block"
                      [disabled]="!newQualification"
                      (click)="addNewQualification()">{{'VIEW.MAIN.DOCTOR.QUALIFICATIONS.ADD_BUTTON' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</form>
