<section class="page">
  <div class="header-container align-items-start separator">
    <div>
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{'VIEW.MAIN.EVENT_DETAILS.EVENT_DETAILS' | translate }}</div>
      </button>

      <div class="badges mt-1" *ngIf="data">
        <span class="th-badge th-badge--gray"
              *ngIf="data.status === EventStatus.PAST">{{'ENUM.EVENT_STATUS.PAST' | translate}}</span>
        <span class="th-badge th-badge--danger"
              *ngIf="data.noShow">{{'VIEW.MAIN.EVENT_DETAILS.DATA.NO_SHOW' | translate}}</span>
        <span class="th-badge th-badge--danger"
              *ngIf="data.status === EventStatus.CANCELLED">{{'ENUM.EVENT_STATUS.CANCELLED' | translate}}</span>
        <span class="th-badge th-badge--secondary"
              *ngIf="data.newPatient">{{'VIEW.MAIN.EVENT_DETAILS.DATA.NEW_PATIENT' | translate}}</span>
        <span class="th-badge th-badge--black"
              *ngIf="data.patient.isUnregisteredPatient">{{'COMPONENT.SLOT.UNREGISTERED' | translate}}</span>
      </div>
    </div>

    <div class="actions" *ngIf="data">
      <button type="button"
              class="btn btn-danger"
              *ngIf="data?.status === EventStatus.UPCOMING"
              (click)="cancelEvent()">{{ 'VIEW.MAIN.EVENT_DETAILS.ACTION.CANCEL_EVENT' | translate}} </button>

      <button type="button"
              class="btn btn-primary"
              [uiSref]="State.MAIN.MY_PATIENT.DETAILS.BASIC_DATA"
              [uiParams]="{ patientId: data?.patient?.id }">{{ 'VIEW.MAIN.EVENT_DETAILS.ACTION.SHOW_PATIENT_DETAILS' | translate}} </button>
    </div>
  </div>

  <div class="content" *ngIf="data">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4">
        <div class="font-weight-bold mb-4">{{'VIEW.MAIN.EVENT_DETAILS.BASIC_DATA' | translate}}</div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.EVENT_DETAILS.DATA.PATIENT.FIRST_NAME' | translate }}</label>
          <div class="form-control-plaintext form-control-sm">{{data?.patient?.firstName | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.EVENT_DETAILS.DATA.PATIENT.LAST_NAME' | translate }}</label>
          <div class="form-control-plaintext form-control-sm">{{data?.patient?.lastName | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.EVENT_DETAILS.DATA.DATE_TIME' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{data?.dateTime | date:Constant.DATE_TIME_FORMAT | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.EVENT_DETAILS.DATA.VISIT_TYPE' | translate }}</label>
          <div class="form-control-plaintext form-control-sm">
            <ng-container *ngFor="let visitType of data?.visitTypes">
              <div>{{visitType?.name}}</div>
            </ng-container>

            <ng-container *ngIf="!data?.visitTypes || data?.visitTypes?.length === 0">-</ng-container>
          </div>
        </div>

        <div class="form-group" *ngIf="!data?.patient.isUnregisteredPatient">
          <label>{{ 'VIEW.MAIN.EVENT_DETAILS.DATA.PATIENT.EXTERNAL_ID' | translate }}</label>
          <div class="form-control-plaintext form-control-sm">{{data?.patient?.externalIdNumber | replaceEmpty}}</div>
        </div>

        <div *ngIf="data.status === EventStatus.PAST && !data?.patient.isUnregisteredPatient">
          <div class="font-weight-bold mt-2 mb-4">
            <span>{{'VIEW.MAIN.EVENT_DETAILS.NOTES' | translate}}</span>
            <button type="button" class="btn btn-link btn-link--black ml-1" *ngIf="!isEditing"
                    (click)="isEditing = true">
              <i-tabler class="i-lg" name="pencil"></i-tabler>
            </button>
          </div>


          <ng-container *ngIf="isEditing">
            <form novalidate #form="ngForm" (ngSubmit)="saveNote()">
          <textarea id="note"
                    rows="10"
                    class="form-control form-control-sm"
                    name="note"
                    [(ngModel)]="data.note"></textarea>
              <button type="submit" class="btn btn-primary mt-2">{{'COMMON.SAVE' | translate}}</button>
            </form>
          </ng-container>

          <ng-container *ngIf="!isEditing">
            <div>{{data?.note | replaceEmpty}}</div>
          </ng-container>

          <ng-container *ngIf="data?.eprescriptions?.length > 0">
            <div class="font-weight-bold mt-4">{{'VIEW.MAIN.EVENT_DETAILS.PRESCRIPTIONS' | translate}}</div>

            <ng-container *ngFor="let prescription of data.eprescriptions">
              <div class="mt-4">
                <div>{{prescription?.medicamentName | replaceEmpty}}</div>
                <div>{{prescription?.medicamentGrammage | replaceEmpty}}</div>
                <div>{{prescription?.doze | replaceEmpty}}</div>
              </div>
            </ng-container>
          </ng-container>


          <ng-container *ngIf="data?.sickLeaves?.length > 0">
            <div class="font-weight-bold mt-4">{{'VIEW.MAIN.EVENT_DETAILS.SICK_LEAVES' | translate}}</div>

            <ng-container *ngFor="let sickLeave of data.sickLeaves">
              <div class="mt-4">
              <span>
                {{sickLeave?.dateFrom | date:Constant.DATE_FORMAT}}
                - {{sickLeave?.dateTo | date:Constant.DATE_FORMAT}}
              </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!data?.patient.isUnregisteredPatient" class="col-12 col-sm-6 col-lg-4">
        <div
          class="font-weight-bold mb-4 mt-4 mt-sm-0">{{'VIEW.MAIN.EVENT_DETAILS.HEALTH_CONDITIONS' | translate}}</div>

        <ng-container *ngIf="data?.healthConditions?.length > 0; else notProvidedTemplate">
          <ng-container *ngFor="let healthCondition of data.healthConditions">
            <div class="form-group">
              <label>{{healthCondition?.question | replaceEmpty}}</label>
              <div class="form-control-plaintext form-control-sm">{{healthCondition?.answers | replaceEmpty}}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="!data?.patient.isUnregisteredPatient" class="col-12 col-sm-4">
        <div class="font-weight-bold mb-4 mt-4 mt-lg-0">{{'VIEW.MAIN.EVENT_DETAILS.UPLOADED_FILES' | translate}}</div>

        <ng-container *ngIf="data?.uploadedFiles?.length > 0; else notProvidedTemplate">
          <ng-container *ngFor="let fileUrl of data.uploadedFiles">
            <div class="image-container">
              <a [href]="fileUrl" target="_blank">
                <img [src]="fileUrl"/>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</section>

<ng-template #notProvidedTemplate>
  <div>{{'VIEW.MAIN.EVENT_DETAILS.NOT_PROVIDED' | translate}}</div>
</ng-template>
