import * as $ from 'jquery';
import * as htmlToText from 'html-to-text';
import { Layout } from 'app/common/Layout';
import * as moment from 'moment';
import { Duration } from 'moment';

export class HtmlUtil {

  constructor() {
  }

  public static addLayoutClassToBody(layout: string): void {
    $('body')
      .removeClass(`layout-${Layout.RAW}`)
      .removeClass(`layout-${Layout.PRELIMINARY}`)
      .removeClass(`layout-${Layout.MAIN}`)
      .addClass(`layout-${layout}`);
  }

  public static disableButtons(): JQuery {
    return $('html button:not([disabled])').prop('disabled', true) as JQuery;
  }

  public static enableButtons(buttonElementsSelector: JQuery): void {
    buttonElementsSelector.prop('disabled', false);
  }

  // we cannot pass selector here yet, because jquery cannot "catch" element that does not exist at this point
  public static waitFor(selectorString: string, timeout: Duration = moment.duration(5, 'seconds')): Promise<JQuery> {
    return new Promise((resolve, reject) => {
      let currentTime: number = 0;

      const intervalId: ReturnType<typeof setInterval> = setInterval(() => {
        currentTime += 10;

        const selectedElement: JQuery = $(selectorString) as JQuery;

        if (selectedElement.length > 0) {
          clearInterval(intervalId);
          resolve(selectedElement);
        }
        else if (currentTime >= timeout.as('milliseconds')) {
          clearInterval(intervalId);
          reject();
        }
      }, 10);
    });
  }

  public static scrollToTop(selector: JQuery, animated: boolean): void {
    if (selector) {
      if (selector.length > 0) {
        setTimeout(() => {
          selector.animate({ scrollTop: 0 }, animated ? 500 : 0);
        });
      }
      else {
        setTimeout(() => {
          HtmlUtil.scrollToTop(selector, animated);
        });
      }
    }
    else {
      setTimeout(() => {
        const bodySelector = $('html, body');
        bodySelector.animate({ scrollTop: 0 }, animated ? 500 : 0);
      });
    }
  }

  public static scrollToBottom(selector: JQuery, animated: boolean): void {
    if (selector) {
      if (selector.length > 0) {
        setTimeout(() => {
          selector.animate({ scrollTop: selector.prop('scrollHeight') }, animated ? 500 : 0);
        });
      }
      else {
        setTimeout(() => {
          HtmlUtil.scrollToTop(selector, animated);
        });
      }
    }
    else {
      setTimeout(() => {
        const bodySelector = $('html, body');
        bodySelector.animate({ scrollTop: bodySelector.prop('scrollHeight') }, animated ? 500 : 0);
      });
    }
  }

  public static scrollToSelector(selector: JQuery, parentSelector: JQuery, animated: boolean, offset: number = 0): void {
    if (selector) {
      if (parentSelector?.length > 0) {
        if (selector.length > 0) {
          setTimeout(() => {
            parentSelector.animate({ scrollTop: selector.offset().top + offset }, animated ? 500 : 0);
          });
        }
        else {
          setTimeout(() => {
            HtmlUtil.scrollToSelector(selector, parentSelector, animated, offset);
          });
        }
      }
      else {
        setTimeout(() => {
          const bodySelector = $('html, body');
          bodySelector.animate({ scrollTop: selector.offset().top + offset }, animated ? 500 : 0);
        });
      }
    }
  }

  public static isScrolledToBottom(selector: JQuery, tolerance: number = 0): boolean {
    if ((selector.prop('scrollHeight') - selector.scrollTop() - tolerance) <= selector.prop('clientHeight')) {
      return true;
    }
    else {
      return false;
    }
  }

  public static togglePasswordInput(input: HTMLInputElement): void {
    const inputType: string = $(input).prop('type');

    if (inputType === 'password') {
      $(input).prop('type', 'text');
    }
    else {
      $(input).prop('type', 'password');
    }
  }

  public static resetFileInput(element: HTMLInputElement): void {
    // file input is ready only and getting/setting its value is inconsistent across browsers, but messing with its type tends to work everywhere as "reset"
    if (element) {
      element.type = '';
      element.type = 'file';
    }
  }

  public static getScrollbarWidth(): number {
    const outer = $('<div>').css({ visibility: 'hidden', width: 100, overflow: 'scroll' }).appendTo('body');
    const widthWithScroll = $('<div>').css({ width: '100%' }).appendTo(outer).outerWidth();
    outer.remove();
    return 100 - widthWithScroll;
  }

  public static htmlToText(html: string): string {
    return htmlToText.htmlToText(html, {
      preserveNewlines: false,
      whitespaceCharacters: '\t\r\n\f\u200b' // default set from htmlToText minus space char (' ')
    });
  }

}
