import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import { StateUtil } from '@util/StateUtil';
import { DoctorModel } from '@model/DoctorModel';
import { State } from '@common/State';

@Component({
  selector: 'app-doctor-qualification',
  templateUrl: './DoctorQualificationComponent.html',
  styleUrls: [ './DoctorQualificationComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoctorQualificationComponent {

  public data$: Observable<QualificationListDTO> = this.doctorModel.qualifications$;

  constructor(public doctorModel: DoctorModel,
              private stateUtil: StateUtil) {
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.DOCTOR.EDIT.QUALIFICATION, { doctorId: this.doctorModel.id });
  }
}
