import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DoctorModel } from '@model/DoctorModel';
import { ViewUtil } from '@util/ViewUtil';
import { StateUtil } from '@util/StateUtil';
import { Constant } from '@common/Constant';
import { State } from '@common/State';

@Component({
  selector: 'app-doctor-basic-data',
  templateUrl: './DoctorBasicDataComponent.html',
  styleUrls: [ './DoctorBasicDataComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoctorBasicDataComponent implements OnInit {
  public Constant: typeof Constant = Constant;

  constructor(public doctorModel: DoctorModel,
              public viewUtil: ViewUtil,
              private stateUtil: StateUtil) {
  }

  public ngOnInit(): void {
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.DOCTOR.EDIT.BASIC_DATA, { doctorId: this.doctorModel.id });
  }
}
