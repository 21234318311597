import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';

@Component({
  selector: 'app-registration-complete-unsuccessful',
  templateUrl: './RegistrationCompleteUnsuccessfulComponent.html',
  styleUrls: [ './RegistrationCompleteUnsuccessfulComponent.scss' ]
})
export class RegistrationCompleteUnsuccessfulComponent {

  constructor(private stateService: StateService) {
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }
}
