import { Component } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { Constant } from '@common/Constant';
import { EventPrescriptionService } from '@service/event/EventPrescriptionService';
import { ViewUtil } from '@util/ViewUtil';

@Component({
  selector: 'app-event-prescription-edit-popup',
  templateUrl: './EventPrescriptionEditPopupComponent.html',
  styleUrls: [ './EventPrescriptionEditPopupComponent.scss' ]
})
export class EventPrescriptionEditPopupComponent extends BaseModalComponent<boolean> {
  public prescription: PrescriptionDTO;

  public isProcessing: boolean = false;

  public eventId: number;

  public Constant: typeof Constant = Constant;

  constructor(public bsModalRef: BsModalRef,
              private viewUtil: ViewUtil,
              private eventPrescriptionService: EventPrescriptionService) {
    super();
  }

  public save(): void {
    this.isProcessing = true;

    this.eventPrescriptionService.edit(this.eventId, this.prescription).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.PRESCRIPTION.MESSAGE.UPDATED_SUCCESS');
        this.ok(true);
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
