<form #form="ngForm" novalidate (ngSubmit)="save()">
  <div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.NOTE.TITLE' | translate}}</div>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="note">{{'VIEW.VIDEO_CALL.NOTE.LABEL' | translate}}</label>
        <textarea id="note"
                  rows="20"
                  class="form-control form-control-sm"
                  name="note"
                  #noteModel="ngModel"
                  [ngClass]="{'is-invalid': form.touched && noteModel.invalid }"
                  [maxlength]="MAX_NOTE_LENGTH"
                  [minlength]="Constant.TEXT_MIN_LENGTH"
                  [(ngModel)]="note.text"></textarea>
        <div class="invalid-feedback" *ngIf="form.touched && noteModel.invalid">
          <div
            *ngIf="noteModel.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate: {requiredLength: noteModel.errors['maxlength'].requiredLength} }}</div>
          <div
            *ngIf="noteModel.errors['minlength']">{{ 'VALIDATION.MINLENGTH' | translate:{requiredLength: noteModel.errors['minlength'].requiredLength} }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button type="submit" class="btn btn-primary"
              [disabled]="form.invalid || isProcessing">{{'COMMON.SUBMIT' | translate}}</button>
    </div>
  </div>
</form>

