import { Injectable, Injector, NgZone, OnDestroy, PipeTransform, Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServerErrorDTO } from 'app/data/dto/ServerErrorDTO';
import { ServerErrorCode } from 'app/data/enum/ServerErrorCode';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { ValueFormatterParams } from 'ag-grid-community';
import { ReplaceEmptyPipe } from 'app/util/pipe/ReplaceEmptyPipe';
import { IdentifiableObjectDTO } from 'app/data/dto/IdentifiableObjectDTO';
import { Subscription } from 'rxjs';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { OptionItem } from 'app/data/local/generic/OptionItem';
import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({ providedIn: 'root' })
export class ViewUtil implements OnDestroy {
  private get toastrService(): ToastrService {
    return this.injector.get<ToastrService>(ToastrService);
  }

  // avoid circular dependency issues (relies on ApplicationRef)
  private get modalService(): BsModalService {
    return this.injector.get<BsModalService>(BsModalService);
  }

  constructor(private translateService: TranslateService,
              private injector: Injector) {
    
  }

  public ngOnDestroy(): void {
  }

  public showToastSuccess(text: string, variables:any = {}, forceShow: boolean = true): void {
    this.translate([ text ], variables)
      .then((translations: string) => {
        if (forceShow) {
          this.removeToasts();
        }
        this.toastrService.success(translations[text]);
      });
  }

  public showToastInfo(text: string, forceShow: boolean = true): void {
    this.translate([ text ])
      .then((translations: string) => {
        if (forceShow) {
          this.removeToasts();
        }
        this.toastrService.info(translations[text]);
      });
  }

  public showToastWarning(text: string, forceShow: boolean = true): void {
    this.translate([ text ])
      .then((translations: string) => {
        if (forceShow) {
          this.removeToasts();
        }
        this.toastrService.warning(translations[text]);
      });
  }

  public removeToasts(): void {
    _.forEach(this.toastrService.toasts, (toast: ActiveToast<any>) => {
      this.toastrService.remove(toast.toastId);
    });
  }

  public showToastError(text: string, forceShow: boolean = true): void {
    this.translate([ text ])
      .then((translations: string) => {
        if (forceShow) {
          this.removeToasts();
        }
        this.toastrService.error(translations[text]);
      });
  }

  public closeAllModals(): void {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
  }

  public handleServerError(error: any, failsafeMessage: string = 'ERROR.OPERATION_UNSUCCESSFUL'): void {
    if (error?.error && (error.error instanceof ServerErrorDTO) && error.error?.errorCode) {
      if (ServerErrorCode[error.error.errorCode]) {
        this.translate(`SERVER_ERROR_CODE.${ServerErrorCode[error.error.errorCode]}`)
          .then((translation: string) => {
            this.showToastError(translation);
          });
      }
      else {
        if (!failsafeMessage) {
          if (error.error.errorDescription) {
            this.showToastError( error.error.errorDescription);
          }
          else {
            this.showToastError( error.error.errorCode);
          }
        }
        else {
          this.translate(failsafeMessage)
            .then((translation: string) => {
              if (error.error.errorDescription) {
                this.showToastError(error.error.errorDescription);
              }
              else {
                this.showToastError(translation + ' - ' + error.error.errorCode);
              }
            });
        }
      }
    }
    else {
      this.translate(failsafeMessage)
        .then((translation: string) => {
          this.showToastError(translation);
        });
    }
  }

  public translate(key: string | Array<string>, params: { [key: string]: any } = null): Promise<string | any> {
    return this.translateService.get(key, params).toPromise()
      .then((translation: string | any) => {
        return translation;
      });
  }

  public translateInstant(key: string | Array<string>, params: { [key: string]: any } = null): string {
      return this.translateService.instant(key, params);
  }

  public dataGridPipeValueFormatter<T>(params: ValueFormatterParams, pipeClass: T, ...pipeArgs: any[]): string {
    const pipe: PipeTransform = this.injector.get<PipeTransform>(pipeClass as unknown as Type<PipeTransform>);

    if (pipe) {
      return pipe.transform(params.value, ...pipeArgs);
    }
    else {
      return null;
    }
  }

  public dataGridArrayRawValueFormatter(params: ValueFormatterParams): any {
    return params.data;
  }

  public dataGridReplaceEmptyValueFormatter(params: ValueFormatterParams): any {
    return new ReplaceEmptyPipe().transform(params.value);
  }

  public dataGridPipeValueFormatterWithTranslate<T>(params: ValueFormatterParams, pipeClass: T, ...pipeArgs: any[]): string {
    return this.translateInstant(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs));
  }

  public dataGridPipeValueFormatterWithReplaceEmpty<T>(params: ValueFormatterParams, pipeClass: T, ...pipeArgs: any[]): string {
    return new ReplaceEmptyPipe().transform(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs));
  }

  public dataGridPipeValueFormatterWithTranslateAndReplaceEmpty<T>(params: ValueFormatterParams, pipeClass: T, ...pipeArgs: any[]): string {
    return this.translateInstant(new ReplaceEmptyPipe().transform(this.dataGridPipeValueFormatter(params, pipeClass, ...pipeArgs)));
  }

  public createEnumSelectOptions<T, V>(enumClass: V, enumLangKey: string): OptionItem<T>[] {
    const options: OptionItem<T>[] = [];

    for (const key of Object.keys(enumClass)) {
      const option: OptionItem<T> = new OptionItem<T>();
      option.label = `ENUM.${enumLangKey}.${key}`;
      option.value = enumClass[key] as T;
      options.push(option);
    }

    return options;
  }

  public identifiableObjectSelectComparator(val1: IdentifiableObjectDTO<any>, val2: IdentifiableObjectDTO<any>): boolean {
    return (val1 && val2) ? val1.id === val2.id : val1 === val2;
  }

}
