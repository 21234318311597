import { Component, OnInit } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { WorkingDaysTimetableDTO } from '@dto/workingDay/WorkingDaysTimetableDTO';
import { DoctorWorkingDayService } from '@service/DoctorWorkingDayService';
import { WorkingDaysTimeRangeDTO } from '@dto/workingDay/WorkingDaysTimeRangeDTO';
import { ObjectUtil } from '@util/ObjectUtil';
import { WorkingDaysCreateDTO } from '@dto/workingDay/WorkingDaysCreateDTO';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import moment from 'moment';
import { ServerErrorCode } from '@enum/ServerErrorCode';
import { HttpErrorResponse } from '@angular/common/http';
import { EventSlot } from '@local/scheduler/EventSlot';
import { SchedulerConflictsPopupComponent } from 'app/component/ui/scheduler/popup/SchedulerConflictsPopupComponent';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-working-hours',
  templateUrl: './WorkingHoursComponent.html',
  styleUrls: [ './WorkingHoursComponent.scss' ]
})
export class WorkingHoursComponent implements OnInit {
  public data: WorkingDaysTimetableDTO[];

  public isEditing: boolean = false;

  public europeTimeZoneOffset: string = (moment.tz('Europe/Berlin').utcOffset() / 60).toString().padStart(2, '0');

  constructor(private doctorWorkingDayService: DoctorWorkingDayService,
              private modalService: BsModalService,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
    this.getWorkHours();
  }

  private getWorkHours(): void {
    this.doctorWorkingDayService.getWorkingHours().toPromise()
      .then((scheduler: WorkingDaysTimetableDTO[]) => {
        this.data = scheduler;
        this.isEditing = false;
      })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      });
  }

  public addWorkingHours(day: WorkingDaysTimetableDTO): void {
    const timeRange = new WorkingDaysTimeRangeDTO();
    timeRange.isEditing = true;
    this.isEditing = true;

    day.hours.push(timeRange);
  }

  public edit(timeRange: WorkingDaysTimeRangeDTO): void {
    timeRange.isEditing = true;
    this.isEditing = true;
  }

  public remove(timeRange: WorkingDaysTimeRangeDTO, day: WorkingDaysTimetableDTO, timeRangeIndex: number): void {
    if (timeRange.id) {
      this.delete(timeRange);
    } else {
      day.hours = day.hours.filter(hours => hours.id);
      this.isEditing = false;
    }
  }

  public save(form: NgForm, timeRange: WorkingDaysTimeRangeDTO, day: WorkingDaysTimetableDTO): void {
    if (form.valid) {
      if (timeRange.id) {
        this.update(timeRange);
      } else {
        this.createNew(ObjectUtil.plainToClass(WorkingDaysCreateDTO, {
          dayOfWeek: day.dayOfWeek,
          timeRange
        } as WorkingDaysCreateDTO));
      }
    }
  }

  private delete(timeRange: WorkingDaysTimeRangeDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.MESSAGE.DELETE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => this.deleteTimeRange(timeRange));
  }

  private createNew(data: WorkingDaysCreateDTO): void {
    this.doctorWorkingDayService.createWorkingHour(data).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.MESSAGE.CREATE_SUCCESS');
        this.getWorkHours();
      })
      .catch((ex: HttpErrorResponse) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private update(data: WorkingDaysTimeRangeDTO): void {
    this.doctorWorkingDayService.updateWorkingHour(data).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.MESSAGE.UPDATE_SUCCESS');
        this.getWorkHours();
      })
      .catch((ex: HttpErrorResponse) => {
        if (ex?.error?.errorCode === ServerErrorCode.FOUND_CONFLICTING_EVENTS) {
          this.detectedConflicts()
            .then(() => this.showEventConflicts(data.id, data.timeFrom, data.timeTo))
            .then(() => this.update(data));
        } else {
          this.viewUtil.handleServerError(ex);
        }
      });
  }

  private detectedConflicts(): Promise<boolean> {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.TITLE',
        message: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.DESCRIPTION',
        okText: 'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.MESSAGE.DETECTED_CONFLICTS.BUTTON_OK'
      }
    });

    return modal.content.close$.toPromise();
  }

  private showEventConflicts(id: number, timeFrom?: string, timeTo?: string): Promise<boolean> {
    return this.doctorWorkingDayService.getWorkingHoursConflicts(id, timeFrom, timeTo).toPromise()
      .then((conflicts: EventSlot[]) => this.modalService.show(SchedulerConflictsPopupComponent, {
        class: 'full-screen',
        initialState: {
          conflicts
        }
      }).content.close$.toPromise());
  }

  private deleteTimeRange(timeRange: WorkingDaysTimeRangeDTO): void {
    this.doctorWorkingDayService.deleteWorkingHour(timeRange.id).toPromise()
      .then(() => {
        this.getWorkHours();
        this.viewUtil.showToastSuccess('VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.MESSAGE.DELETE.SUCCESS');
      })
      .catch((ex) => {
        if (ex?.error?.errorCode === ServerErrorCode.FOUND_CONFLICTING_EVENTS) {
          this.detectedConflicts()
            .then(() => this.showEventConflicts(timeRange.id))
            .then(() => this.deleteTimeRange(timeRange));
        } else {
          this.viewUtil.handleServerError(ex);
        }
      });
  }
}
