import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable } from 'rxjs';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { State } from '@common/State';
import { StateUtil } from '@util/StateUtil';
import { UserModel } from '@model/UserModel';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-profile-license',
  templateUrl: './ProfileLicenseComponent.html',
  styleUrls: [ './ProfileLicenseComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileLicenseComponent {
  public data$: Observable<LicenseDTO> = this.currentDoctorService.getLicense();

  public isEditable$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.NOT_ACCEPTED)
    .pipe(
      map(status => !status)
    );

  constructor(private currentDoctorService: CurrentDoctorService,
              private userModel: UserModel,
              private stateUtil: StateUtil,
              public viewUtil: ViewUtil) {

  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE_EDIT.LICENSE);
  }
}
