import { Ng2StateDeclaration } from '@uirouter/angular';
import { Component } from '@angular/core';
import { State } from 'app/common/State';
import { Layout } from 'app/common/Layout';
import { MainLayoutComponent } from 'app/component/view/main/MainLayoutComponent';
import { PreliminaryLayoutComponent } from 'app/component/view/preliminary/PreliminaryLayoutComponent';
import { RawLayoutComponent } from 'app/component/view/raw/RawLayoutComponent';
import { MeetingComponent } from 'app/component/view/raw/meeting/MeetingComponent';
import { ErrorNotFoundComponent } from 'app/component/view/preliminary/error/ErrorNotFoundComponent';
import { ErrorAccessDeniedComponent } from 'app/component/view/preliminary/error/ErrorAccessDeniedComponent';
import { LoginComponent } from 'app/component/view/preliminary/login/LoginComponent';
import { ResetPasswordStartComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordStartComponent';
import { ResetPasswordCompleteComponent } from 'app/component/view/preliminary/resetPassword/ResetPasswordCompleteComponent';
import { AccountChangePasswordComponent } from 'app/component/view/main/account/AccountChangePasswordComponent';
import { SchedulerComponent } from 'app/component/view/main/dashboard/scheduler/SchedulerComponent';
import { PrescriptionRequestListComponent } from 'app/component/view/main/prescription/PrescriptionRequestListComponent';
import { PatientListComponent } from 'app/component/view/main/patient/PatientListComponent';
import { InviteComponent } from 'app/component/view/main/invite/InviteComponent';
import { ProfileBasicDataEditComponent } from 'app/component/view/main/profile/basicData/ProfileBasicDataEditComponent';
import { ProfileComponent } from 'app/component/view/main/profile/ProfileComponent';
import { ProfileLicenseEditComponent } from 'app/component/view/main/profile/license/ProfileLicenseEditComponent';
import { ProfilePracticeEditComponent } from 'app/component/view/main/profile/practice/ProfilePracticeEditComponent';
import { ProfileQualificationEditComponent } from 'app/component/view/main/profile/qualification/ProfileQualificationEditComponent';
import { WorkDaysSettingsComponent } from 'app/component/view/main/workdaysSettings/WorkDaysSettingsComponent';
import { PatientDetailsComponent } from 'app/component/view/main/patient/details/PatientDetailsComponent';
import { PrescriptionRequestComponent } from 'app/component/view/main/prescription/PrescriptionRequestComponent';
import { StyleguideComponent } from 'app/component/view/preliminary/styleguide/StyleguideComponent';
import { ProfileBasicDataComponent } from 'app/component/view/main/profile/basicData/ProfileBasicDataComponent';
import { PracticeCategoryComponent } from 'app/component/view/main/practice/category/PracticeCategoryComponent';
import { ProfileLicenseComponent } from 'app/component/view/main/profile/license/ProfileLicenseComponent';
import { ProfilePracticeComponent } from 'app/component/view/main/profile/practice/ProfilePracticeComponent';
import { ProfileQualificationComponent } from 'app/component/view/main/profile/qualification/ProfileQualificationComponent';
import { UserType } from '@enum/user/UserType';
import { NewDoctorsListComponent } from 'app/component/view/main/dashboard/doctor/NewDoctorsListComponent';
import { DashboardComponent } from 'app/component/view/main/dashboard/DashboardComponent';
import { EventBasicDataComponent } from 'app/component/view/raw/meeting/view/basicData/EventBasicDataComponent';
import { EventInsuranceComponent } from 'app/component/view/raw/meeting/view/insurance/EventInsuranceComponent';
import { EventPrescriptionComponent } from 'app/component/view/raw/meeting/view/prescription/EventPrescriptionComponent';
import { EventHealthConditionComponent } from 'app/component/view/raw/meeting/view/healthCondition/EventHealthConditionComponent';
import { EventNoteComponent } from 'app/component/view/raw/meeting/view/note/EventNoteComponent';
import { EventSickLeavesComponent } from 'app/component/view/raw/meeting/view/sickLeaves/EventSickLeavesComponent';
import { EventUploadFilesComponent } from 'app/component/view/raw/meeting/view/uploadedFiles/EventUploadFilesComponent';
import { WorkingHoursComponent } from 'app/component/view/main/workdaysSettings/view/workinghours/WorkingHoursComponent';
import { VacationComponent } from 'app/component/view/main/workdaysSettings/view/vacation/VacationComponent';
import { PatientBasicDataComponent } from 'app/component/view/main/patient/details/basicData/PatientBasicDataComponent';
import { PatientHistoryComponent } from 'app/component/view/main/patient/details/history/PatientHistoryComponent';
import { PatientInsuranceComponent } from 'app/component/view/main/patient/details/insurance/PatientInsuranceComponent';
import { EventComponent } from 'app/component/view/main/event/EventComponent';
import { InviteDoctorComponent } from 'app/component/view/main/invite/InviteDoctorComponent';
import { InvitePatientComponent } from 'app/component/view/main/invite/InvitePatientComponent';
import { DoctorDetailsComponent } from 'app/component/view/main/doctor/details/DoctorDetailsComponent';
import { DoctorBasicDataComponent } from 'app/component/view/main/doctor/details/basicData/DoctorBasicDataComponent';
import { DoctorLicenseComponent } from 'app/component/view/main/doctor/details/license/DoctorLicenseComponent';
import { DoctorQualificationComponent } from 'app/component/view/main/doctor/details/qualification/DoctorQualificationComponent';
import { DoctorPracticeComponent } from 'app/component/view/main/doctor/details/practice/DoctorPracticeComponent';
import { DoctorListComponent } from 'app/component/view/main/doctor/DoctorListComponent';
import { DoctorBasicDataEditComponent } from 'app/component/view/main/doctor/details/basicData/DoctorBasicDataEditComponent';
import { DoctorQualificationEditComponent } from 'app/component/view/main/doctor/details/qualification/DoctorQualificationEditComponent';
import { DoctorLicenseEditComponent } from 'app/component/view/main/doctor/details/license/DoctorLicenseEditComponent';
import { DoctorPracticeEditComponent } from 'app/component/view/main/doctor/details/practice/DoctorPracticeEditComponent';
import { DoctorPracticeCreateComponent } from 'app/component/view/main/doctor/details/practice/DoctorPracticeCreateComponent';
import { ProfilePracticeCreateComponent } from 'app/component/view/main/profile/practice/ProfilePracticeCreateComponent';
import { DoctorSchedulerComponent } from 'app/component/view/main/doctor/details/scheduler/DoctorSchedulerComponent';
import { DoctorCategoryComponent } from 'app/component/view/main/doctor/details/category/DoctorCategoryComponent';
import { EventListComponent } from 'app/component/view/main/event/EventListComponent';
import { StatisticListComponent } from 'app/component/view/main/statistic/StatisticListComponent';
import { MigratingAccountComponent } from 'app/component/view/raw/migrating/MigratingAccountComponent';
import { PatientAdminListComponent } from 'app/component/view/main/patient/admin/PatientAdminListComponent';
import { GuestVideoCallComponent } from 'app/component/view/raw/guest/GuestVideoCallComponent';
import { EventCancelComponent } from 'app/component/view/raw/event/view/cancel/EventCancelComponent';
import { SettingsComponent } from 'app/component/view/main/settings/SettingsComponent';
import { ProcessingDataConsentComponent } from 'app/component/view/raw/processingData/ProcessingDataConsentComponent';
import { RegistrationComponent } from 'app/component/view/preliminary/registration/RegistrationComponent';
import { RegistrationCompleteUnsuccessfulComponent } from 'app/component/view/preliminary/registration/registrationCompleteUnsuccessful/RegistrationCompleteUnsuccessfulComponent';
import { RegistrationCompleteComponent } from 'app/component/view/preliminary/registration/registrationComplete/RegistrationCompleteComponent';
import { PracticeComponent } from 'app/component/view/main/practice/PracticeComponent';

@Component({ selector: 'app-abstract', template: '<ui-view></ui-view>' })
export class AbstractStateComponent {
}

export const StateConfig: Ng2StateDeclaration[] = [

  { abstract: true, name: Layout.MAIN, component: MainLayoutComponent },
  { abstract: true, name: Layout.PRELIMINARY, component: PreliminaryLayoutComponent },
  { abstract: true, name: Layout.RAW, component: RawLayoutComponent },

  { url: '^/error', abstract: true, name: State.PRELIMINARY.ERROR.ERROR, component: AbstractStateComponent },
  { url: '/not-found', name: State.PRELIMINARY.ERROR.NOT_FOUND, component: ErrorNotFoundComponent },
  { url: '/access-denied', name: State.PRELIMINARY.ERROR.ACCESS_DENIED, component: ErrorAccessDeniedComponent },
  { url: '/login', name: State.PRELIMINARY.LOGIN, component: LoginComponent },
  { url: '/forgot-password', name: State.PRELIMINARY.RESET_PASSWORD_START, component: ResetPasswordStartComponent },
  { url: '/styleguide', name: State.PRELIMINARY.STYLEGUIDE, component: StyleguideComponent },
  {
    url: '/reset-password/{email:[a-zA-Z0-9\%\@\.\_\-]+}/{token:.+}?{lang}',
    name: State.PRELIMINARY.RESET_PASSWORD_COMPLETE,
    component: ResetPasswordCompleteComponent
  },
  { url: '/registration', name: State.PRELIMINARY.REGISTER, component: RegistrationComponent },
  {
    url: '/registration-complete',
    name: State.PRELIMINARY.REGISTER_COMPLETE,
    component: RegistrationCompleteComponent
  },
  {
    url: '/registration-complete-unsuccessful',
    name: State.PRELIMINARY.REGISTER_COMPLETE_UNSUCCESSFUL,
    component: RegistrationCompleteUnsuccessfulComponent
  },

  { url: '^/account', abstract: true, name: State.MAIN.ACCOUNT.ACCOUNT, component: AbstractStateComponent },
  { url: '/change-password', name: State.MAIN.ACCOUNT.CHANGE_PASSWORD, component: AccountChangePasswordComponent },
  {
    url: '/password-change-required',
    name: State.MAIN.ACCOUNT.PASSWORD_CHANGE_REQUIRED,
    component: AccountChangePasswordComponent
  },

  { url: '^/dashboard', name: State.MAIN.DASHBOARD.DASHBOARD, component: DashboardComponent },
  {
    url: '/doctors?{criteria}',
    name: State.MAIN.DASHBOARD.NEW_DOCTORS,
    component: NewDoctorsListComponent,
    data: { type: UserType.ADMIN }
  },
  {
    url: '/scheduler',
    name: State.MAIN.DASHBOARD.MY_SCHEDULER,
    component: SchedulerComponent,
    data: { type: UserType.DOCTOR }
  },

  {
    url: '^/workdays',
    name: State.MAIN.WORKDAYS.WORKDAYS,
    component: WorkDaysSettingsComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/working-hours', name: State.MAIN.WORKDAYS.WORKING_HOURS, component: WorkingHoursComponent },
  { url: '/vacations', name: State.MAIN.WORKDAYS.VACATIONS, component: VacationComponent },

  {
    url: '^/presciption/requests',
    abstract: true,
    name: State.MAIN.PRESCRIPTION_REQUEST.PRESCRIPTION_REQUEST,
    component: AbstractStateComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/list?{criteria}', name: State.MAIN.PRESCRIPTION_REQUEST.LIST, component: PrescriptionRequestListComponent },
  { url: '/refill/{id:int}', name: State.MAIN.PRESCRIPTION_REQUEST.DETAILS, component: PrescriptionRequestComponent },

  {
    url: '^/my-patients',
    abstract: true,
    name: State.MAIN.MY_PATIENT.MY_PATIENT,
    component: AbstractStateComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/list?{criteria}', name: State.MAIN.MY_PATIENT.LIST, component: PatientListComponent },
  { url: '/{patientId:int}', name: State.MAIN.MY_PATIENT.DETAILS.DETAILS, component: PatientDetailsComponent },
  { url: '/basic-data', name: State.MAIN.MY_PATIENT.DETAILS.BASIC_DATA, component: PatientBasicDataComponent },
  { url: '/history?{criteria}', name: State.MAIN.MY_PATIENT.DETAILS.HISTORY, component: PatientHistoryComponent },
  { url: '/insurance', name: State.MAIN.MY_PATIENT.DETAILS.INSURANCE, component: PatientInsuranceComponent },

  {
    url: '^/patients',
    abstract: true,
    name: State.MAIN.PATIENT.PATIENT,
    component: AbstractStateComponent,
    data: { type: UserType.ADMIN }
  },
  { url: '/list?{criteria}', name: State.MAIN.PATIENT.LIST, component: PatientAdminListComponent },

  {
    url: '^/doctors',
    abstract: true,
    name: State.MAIN.DOCTOR.DOCTOR,
    component: AbstractStateComponent,
    data: { type: UserType.ADMIN }
  },
  { url: '/list?{criteria}', name: State.MAIN.DOCTOR.LIST, component: DoctorListComponent },
  {
    url: '/{doctorId:int}',
    name: State.MAIN.DOCTOR.DETAILS.DETAILS,
    component: DoctorDetailsComponent,
    redirectTo: State.MAIN.DOCTOR.DETAILS.BASIC_DATA
  },
  { url: '/basic-data', name: State.MAIN.DOCTOR.DETAILS.BASIC_DATA, component: DoctorBasicDataComponent },
  { url: '/license', name: State.MAIN.DOCTOR.DETAILS.LICENSE, component: DoctorLicenseComponent },
  { url: '/category', name: State.MAIN.DOCTOR.DETAILS.CATEGORY, component: DoctorCategoryComponent },
  { url: '/qualification', name: State.MAIN.DOCTOR.DETAILS.QUALIFICATION, component: DoctorQualificationComponent },
  { url: '/practice', name: State.MAIN.DOCTOR.DETAILS.PRACTICE, component: DoctorPracticeComponent },
  { url: '/scheduler', name: State.MAIN.DOCTOR.DETAILS.SCHEDULER, component: DoctorSchedulerComponent },
  {
    url: '/{doctorId:int}/edit',
    abstract: true,
    name: State.MAIN.DOCTOR.EDIT.EDIT,
    component: AbstractStateComponent,
    data: { type: UserType.ADMIN }
  },
  { url: '/basic-data', name: State.MAIN.DOCTOR.EDIT.BASIC_DATA, component: DoctorBasicDataEditComponent },
  { url: '/qualification', name: State.MAIN.DOCTOR.EDIT.QUALIFICATION, component: DoctorQualificationEditComponent },
  { url: '/license', name: State.MAIN.DOCTOR.EDIT.LICENSE, component: DoctorLicenseEditComponent },
  { url: '/practice', name: State.MAIN.DOCTOR.EDIT.PRACTICE, component: DoctorPracticeEditComponent },
  {
    url: '/{doctorId:int}/create',
    abstract: true,
    name: State.MAIN.DOCTOR.CREATE.CREATE,
    component: AbstractStateComponent,
    data: { type: UserType.ADMIN }
  },
  { url: '/practice', name: State.MAIN.DOCTOR.CREATE.PRACTICE, component: DoctorPracticeCreateComponent },


  { url: '^/events', name: State.MAIN.EVENT.EVENT, abstract: true, component: AbstractStateComponent },
  { url: '/{eventId:int}', name: State.MAIN.EVENT.DETAILS, component: EventComponent, data: { type: UserType.DOCTOR } },
  {
    url: '/list?{criteria}',
    name: State.MAIN.EVENT.LIST,
    component: EventListComponent,
    data: { type: UserType.ADMIN }
  },

  {
    url: '^/invite',
    name: State.MAIN.INVITE.INVITE,
    component: InviteComponent,
    data: { type: UserType.DOCTOR },
    redirectTo: State.MAIN.INVITE.INVITE_PATIENT
  },
  { url: '/doctor', name: State.MAIN.INVITE.INVITE_DOCTOR, component: InviteDoctorComponent },
  { url: '/patient', name: State.MAIN.INVITE.INVITE_PATIENT, component: InvitePatientComponent },

  {
    url: '^/profile',
    abstract: true,
    name: State.MAIN.PROFILE.PROFILE,
    component: ProfileComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/basic-data', name: State.MAIN.PROFILE.BASIC_DATA, component: ProfileBasicDataComponent },
  { url: '/license', name: State.MAIN.PROFILE.LICENSE, component: ProfileLicenseComponent },
  { url: '/practice', name: State.MAIN.PROFILE.PRACTICE, component: ProfilePracticeComponent },
  { url: '/qualification', name: State.MAIN.PROFILE.QUALIFICATION, component: ProfileQualificationComponent },

  {
    url: '^/profile/edit',
    abstract: true,
    name: State.MAIN.PROFILE_EDIT.PROFILE_EDIT,
    component: AbstractStateComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/basic-data', name: State.MAIN.PROFILE_EDIT.BASIC_DATA, component: ProfileBasicDataEditComponent },
  { url: '/license', name: State.MAIN.PROFILE_EDIT.LICENSE, component: ProfileLicenseEditComponent },
  { url: '/practice', name: State.MAIN.PROFILE_EDIT.PRACTICE, component: ProfilePracticeEditComponent },
  { url: '/qualification', name: State.MAIN.PROFILE_EDIT.QUALIFICATION, component: ProfileQualificationEditComponent },

  {
    url: '^/profile/create',
    abstract: true,
    name: State.MAIN.PROFILE_CREATE.PROFILE_CREATE,
    component: AbstractStateComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/practice', name: State.MAIN.PROFILE_CREATE.PRACTICE, component: ProfilePracticeCreateComponent },

  { url: '^/practice', name: State.MAIN.PRACTICE.PRACTICE, component: PracticeComponent, data: { type: UserType.DOCTOR } },
  { url: '/category', name: State.MAIN.PRACTICE.CATEGORY, component: PracticeCategoryComponent },
  { url: '^/settings', name: State.MAIN.SETTINGS.SETTINGS, component: SettingsComponent },

  {
    url: '^/statistics?{criteria}',
    name: State.MAIN.STATISTIC.STATISTIC,
    abstract: true,
    component: AbstractStateComponent,
    data: { type: UserType.ADMIN }
  },
  { url: '/list', name: State.MAIN.STATISTIC.LIST, component: StatisticListComponent },

  {
    url: '^/guest-video-call?{roomName,roomToken}',
    name: State.RAW.GUEST_VIDEO_CALL.GUEST_VIDEO_CALL,
    component: GuestVideoCallComponent
  },

  {
    url: '^/video-call/{eventId:int}',
    name: State.RAW.VIDEO_CALL.VIDEO_CALL,
    component: MeetingComponent,
    data: { type: UserType.DOCTOR }
  },
  { url: '/basic-data', name: State.RAW.VIDEO_CALL.BASIC_DATA, component: EventBasicDataComponent },
  { url: '/insurance', name: State.RAW.VIDEO_CALL.INSURANCE, component: EventInsuranceComponent },
  { url: '/prescription', name: State.RAW.VIDEO_CALL.PRESCRIPTION, component: EventPrescriptionComponent },
  { url: '/health-condition', name: State.RAW.VIDEO_CALL.HEALTH_CONDITION, component: EventHealthConditionComponent },
  { url: '/note', name: State.RAW.VIDEO_CALL.NOTES, component: EventNoteComponent },
  { url: '/sick-leaves', name: State.RAW.VIDEO_CALL.SICK_LEAVES, component: EventSickLeavesComponent },
  { url: '/uploaded-files', name: State.RAW.VIDEO_CALL.UPLOAD_IMAGES, component: EventUploadFilesComponent },

  {
    url: '^/child-decision?{token,lang}',
    name: State.RAW.ACCOUNT_MIGRATION.ACCOUNT_MIGRATION,
    component: MigratingAccountComponent
  },
  {
    url: '^/young-child-decision?{token,lang}',
    name: State.RAW.ACCOUNT_MIGRATION.PROCESSING_DATA_CONSENT,
    component: ProcessingDataConsentComponent
  },

  { url: '^/event/{token:.+}', abstract: true, name: State.RAW.EVENT.EVENT, component: AbstractStateComponent },
  { url: '/cancel?{lang}', name: State.RAW.EVENT.CANCEL, component: EventCancelComponent }
];
