export class BaseCriteriaDTO {

  public sortOrders?: string[];

  public searchPhrase?: string;

  public excludedIds?: number[];

  public includedIds?: number[];

}
