import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { ChildDecisionDTO } from '@dto/migratingAccount/ChildDecisionDTO';
import { YoungChildDecisionDTO } from '@dto/migratingAccount/YoungChildDecisionDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { ObjectUtil } from '@util/ObjectUtil';

@Injectable({
  providedIn: 'root'
})
export class UserDecision {

  constructor(private http: HttpClient) {
  }

  public getAccountByToken(token: string): Observable<PatientDTO> {
    return this.http.get<PatientDTO>(`${ ApplicationConfig.apiUrl }/patients/children/decision?token=${ token }`)
      .pipe(
        map((response: PatientDTO) => plainToClass(PatientDTO, response))
      );
  }

  public makeChildDecision(decision: ChildDecisionDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/patients/children/decision`, ObjectUtil.classToPlain(decision));
  }

  public makeYoungChildDecision(decision: YoungChildDecisionDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/patients/children/decision/16yo`, ObjectUtil.classToPlain(decision));
  }
}
