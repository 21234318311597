import { Layout } from 'app/common/Layout';

export class State {

  public static readonly RAW = {
    ACCOUNT_MIGRATION: {
      ACCOUNT_MIGRATION: `${ Layout.RAW }.accountMigration`,
      PROCESSING_DATA_CONSENT: `${ Layout.RAW }.processingDataConsent`
    },
    EVENT: {
      EVENT: `${ Layout.RAW }.event`,
      CANCEL: `${ Layout.RAW }.event.cancel`
    },
    GUEST_VIDEO_CALL: {
      GUEST_VIDEO_CALL: `${ Layout.RAW }.guestVideoCall`
    },
    VIDEO_CALL: {
      VIDEO_CALL: `${ Layout.RAW }.videoCall`,
      BASIC_DATA: `${ Layout.RAW }.videoCall.basicData`,
      INSURANCE: `${ Layout.RAW }.videoCall.insurance`,
      HEALTH_CONDITION: `${ Layout.RAW }.videoCall.healthCondition`,
      PRESCRIPTION: `${ Layout.RAW }.videoCall.prescription`,
      SICK_LEAVES: `${ Layout.RAW }.videoCall.sickLeaves`,
      REFERRALS: `${ Layout.RAW }.videoCall.referrals`,
      NOTES: `${ Layout.RAW }.videoCall.notes`,
      UPLOAD_IMAGES: `${ Layout.RAW }.videoCall.uploadImages`
    }
  };

  public static readonly PRELIMINARY = {
    LOGIN: `${ Layout.PRELIMINARY }.login`,
    RESET_PASSWORD_START: `${ Layout.PRELIMINARY }.resetPasswordStart`,
    STYLEGUIDE: `${ Layout.PRELIMINARY }.styleguide`,
    RESET_PASSWORD_COMPLETE: `${ Layout.PRELIMINARY }.resetPasswordComplete`,
    REGISTER: `${ Layout.PRELIMINARY }.register`,
    REGISTER_COMPLETE: `${ Layout.PRELIMINARY }.registerComplete`,
    REGISTER_COMPLETE_UNSUCCESSFUL: `${ Layout.PRELIMINARY }.registerCompleteUnsuccessful`,
    ERROR: {
      ERROR: `${ Layout.PRELIMINARY }.error`,
      NOT_FOUND: `${ Layout.PRELIMINARY }.error.notFound`,
      ACCESS_DENIED: `${ Layout.PRELIMINARY }.error.accessDenied`
    }
  };

  public static readonly MAIN = {
    HOME: `${ Layout.MAIN }.home`,
    ACCOUNT: {
      ACCOUNT: `${ Layout.MAIN }.account`,
      CHANGE_PASSWORD: `${ Layout.MAIN }.account.changePassword`,
      PASSWORD_CHANGE_REQUIRED: `${ Layout.MAIN }.account.passwordChangeRequired`
    },
    DASHBOARD: {
      DASHBOARD: `${ Layout.MAIN }.dashboard`,
      MY_SCHEDULER: `${ Layout.MAIN }.dashboard.scheduler`,
      VACATION: `${ Layout.MAIN }.dashboard.vacation`,
      NEW_DOCTORS: `${ Layout.MAIN }.dashboard.newDoctors`
    },
    STATISTIC: {
      STATISTIC: `${ Layout.MAIN }.statistic`,
      LIST: `${ Layout.MAIN }.statistic.list`
    },
    DOCTOR: {
      DOCTOR: `${ Layout.MAIN }.doctor`,
      LIST: `${ Layout.MAIN }.doctor.list`,
      EDIT: {
        EDIT: `${ Layout.MAIN }.doctor.edit`,
        BASIC_DATA: `${ Layout.MAIN }.doctor.edit.basicData`,
        LICENSE: `${ Layout.MAIN }.doctor.edit.license`,
        QUALIFICATION: `${ Layout.MAIN }.doctor.edit.qualification`,
        PRACTICE: `${ Layout.MAIN }.doctor.edit.practice`
      },
      CREATE: {
        CREATE: `${ Layout.MAIN }.doctor.create`,
        PRACTICE: `${ Layout.MAIN }.doctor.create.practice`
      },
      DETAILS: {
        DETAILS: `${ Layout.MAIN }.doctor.details`,
        BASIC_DATA: `${ Layout.MAIN }.doctor.details.basicData`,
        LICENSE: `${ Layout.MAIN }.doctor.details.license`,
        CATEGORY: `${ Layout.MAIN }.doctor.details.category`,
        QUALIFICATION: `${ Layout.MAIN }.doctor.details.qualification`,
        PRACTICE: `${ Layout.MAIN }.doctor.details.practice`,
        SCHEDULER: `${ Layout.MAIN }.doctor.details.scheduler`
      }
    },
    WORKDAYS: {
      WORKDAYS: `${ Layout.MAIN }.workdays`,
      WORKING_HOURS: `${ Layout.MAIN }.workdays.workingHours`,
      VACATIONS: `${ Layout.MAIN }.workdays.vacations`
    },
    INVITE: {
      INVITE: `${ Layout.MAIN }.invite`,
      INVITE_PATIENT: `${ Layout.MAIN }.invite.patient`,
      INVITE_DOCTOR: `${ Layout.MAIN }.invite.doctor`
    },
    PRESCRIPTION_REQUEST: {
      PRESCRIPTION_REQUEST: `${ Layout.MAIN }.prescriptionRequest`,
      LIST: `${ Layout.MAIN }.prescriptionRequest.list`,
      DETAILS: `${ Layout.MAIN }.prescriptionRequest.details`
    },
    PATIENT: {
      PATIENT: `${ Layout.MAIN }.patient`,
      LIST: `${ Layout.MAIN }.patient.list`
    },
    MY_PATIENT: {
      MY_PATIENT: `${ Layout.MAIN }.myPatient`,
      LIST: `${ Layout.MAIN }.myPatient.list`,
      PATIENT_CARD: `${ Layout.MAIN }.myPatient.patientCard`,
      INSURANCE: `${ Layout.MAIN }.myPatient.patientInsurance`,
      HISTORY: `${ Layout.MAIN }.myPatient.patientHistory`,
      EVENT_DETAILS_VIEW: `${ Layout.MAIN }.myPatient.detailsEventView`,
      DETAILS: {
        DETAILS: `${ Layout.MAIN }.myPatient.details`,
        BASIC_DATA: `${ Layout.MAIN }.myPatient.details.basicData`,
        HISTORY: `${ Layout.MAIN }.myPatient.details.history`,
        INSURANCE: `${ Layout.MAIN }.myPatient.details.insurance`
      }
    },
    EVENT: {
      EVENT: `${ Layout.MAIN }.event`,
      LIST: `${ Layout.MAIN }.event.list`,
      DETAILS: `${ Layout.MAIN }.event.details`
    },
    PROFILE: {
      PROFILE: `${ Layout.MAIN }.profile`,
      BASIC_DATA: `${ Layout.MAIN }.profile.basicData`,
      QUALIFICATION: `${ Layout.MAIN }.profile.qualification`,
      PRACTICE: `${ Layout.MAIN }.profile.practice`,
      LICENSE: `${ Layout.MAIN }.profile.license`
    },
    PROFILE_EDIT: {
      PROFILE_EDIT: `${ Layout.MAIN }.profileEdit`,
      BASIC_DATA: `${ Layout.MAIN }.profileEdit.basicData`,
      LICENSE: `${ Layout.MAIN }.profileEdit.license`,
      PRACTICE: `${ Layout.MAIN }.profileEdit.practice`,
      QUALIFICATION: `${ Layout.MAIN }.profileEdit.qualification`
    },
    PROFILE_CREATE: {
      PROFILE_CREATE: `${ Layout.MAIN }.profileCreate`,
      PRACTICE: `${ Layout.MAIN }.profileCreate.practice`
    },
    PRACTICE: {
      PRACTICE: `${ Layout.MAIN }.practice`,
      CATEGORY: `${ Layout.MAIN }.practice.category`
    },
    SETTINGS: {
      SETTINGS: `${ Layout.MAIN }.settings`
    }
  };

}