import { ApplicationConfigDefinition } from '../data/local/ApplicationConfigDefinition';
import { environment } from 'environment/environment';

export const ApplicationConfig: ApplicationConfigDefinition = {

  applicationOwner: 'TeleHealth',
  applicationName: 'TeleHealth',
  version: '1.0.5 (2024-04-03)',
  environmentName: environment.name,
  production: environment.production,
  apiUrl: environment.apiUrl,
  defaultLanguage: environment.defaultLanguage,

  userPoolId: environment.cognito.userPoolId,
  userPoolWebClientId: environment.cognito.userPoolWebClientId,
  region: environment.cognito.region,

  // optional
  websiteUrl: environment.websiteUrl,
};
