import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderModel {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public loading$: Observable<boolean> = this.loading.asObservable();

  private loadingMap: Map<string, { value: boolean, mode: string }> = new Map<string, { value: boolean, mode: string }>();

  constructor(private ngxUiLoaderService: NgxUiLoaderService) {
    this.startListener();
  }

  private startListener(): void {
    this.loading$
      .pipe(
        delay(0)
      )
      .subscribe((loading: boolean) => {
        if (loading) {
          this.ngxUiLoaderService.startLoader('master');
        } else {
          this.ngxUiLoaderService.stopLoader('master');
        }
      });
  }

  public setLoading(loading: boolean, url: string, mode: string = 'master'): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, { value: loading, mode });
      this.loading.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }

    if (this.loadingMap.size === 0) {
      this.loading.next(false);
    }
  }
}
