import { Inject, Injectable } from '@angular/core';
import { IVideoCallService, VIDEO_CALL_SERVICE } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { VideoCallRoomDTO } from '@dto/videocall/VideoCallRoomDTO';
import { BehaviorSubject, Observable } from 'rxjs';
import { createLocalAudioTrack, createLocalVideoTrack, LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { IVideoCallUser } from 'app/component/ui/videoCall/interface/IVideoCallUser';
import { ViewUtil } from '@util/ViewUtil';

@Injectable()
export class VideoCallStorage {
  public id: number | string;

  public availableVideoDevice: boolean = false;

  public availableAudioDevice: boolean = false;

  private _roomDetails: BehaviorSubject<VideoCallRoomDTO> = new BehaviorSubject(null);

  private videoLocalTrackSubject: BehaviorSubject<LocalVideoTrack> = new BehaviorSubject<LocalVideoTrack>(null);

  private audioLocalTrackSubject: BehaviorSubject<LocalAudioTrack> = new BehaviorSubject<LocalAudioTrack>(null);

  public videoLocalTrack$: Observable<LocalVideoTrack> = this.videoLocalTrackSubject.asObservable();

  public audioLocalTrack$: Observable<LocalAudioTrack> = this.audioLocalTrackSubject.asObservable();

  public roomDetails$: Observable<VideoCallRoomDTO> = this._roomDetails.asObservable();

  constructor(@Inject(VIDEO_CALL_SERVICE) private videoCallService: IVideoCallService,
              private viewUtil: ViewUtil) {
  }

  public get me(): IVideoCallUser {
    return this.videoCallService.me;
  }

  public get participant(): IVideoCallUser {
    return this.videoCallService.participant;
  }

  public get roomExists(): boolean {
    return this.videoCallService.roomExists;
  }

  public get participantIsWaiting(): boolean {
    return this.videoCallService.participantIsWaiting;
  }

  public get canJoin(): boolean {
    return this.videoCallService.canJoin;
  }

  public get roomDetails(): VideoCallRoomDTO {
    return this._roomDetails.value;
  }

  public joinRoom(): void {
    if (this.roomExists) {
      this.videoCallService.getRoom(this.id).toPromise()
        .then((roomDetails: VideoCallRoomDTO) => this.setRoom(roomDetails))
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    } else {
      this.videoCallService.createRoom(this.id).toPromise()
        .then((roomDetails: VideoCallRoomDTO) => this.setRoom(roomDetails))
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    }
  }

  public closeRoom(): Observable<unknown> {
    return this.videoCallService.closeRoom(this.id);
  }

  public isVideoDevice(): Promise<void> {
    return this.isDevice('videoinput');
  }

  public isAudioDevice(): Promise<void> {
    return this.isDevice('audioinput');
  }

  public getLocalVideoTrack(): LocalVideoTrack {
    return this.videoLocalTrackSubject.value;
  }

  public getLocalAudioTrack(): LocalAudioTrack {
    return this.audioLocalTrackSubject.value;
  }

  public generateLocalVideoTrack(status: boolean = false): Promise<LocalVideoTrack | null> {
    return this.isVideoDevice()
      .then(() => createLocalVideoTrack({ name: 'camera' }))
      .then((track: LocalVideoTrack) => {
        this.availableVideoDevice = true;

        if (status) {
          this.videoLocalTrackSubject.next(track);
        } else {
          track.disable();
          track.stop();
        }

        return track;
      })
      .catch((ex) => {
        this.availableVideoDevice = false;
        return null;
      });
  }

  public generateLocalAudioTrack(status: boolean = false): Promise<LocalAudioTrack | null> {
    return this.isAudioDevice()
      .then(() => createLocalAudioTrack({ name: 'microphone' }))
      .then((track: LocalAudioTrack) => {
        this.availableAudioDevice = true;

        if (status) {
          this.audioLocalTrackSubject.next(track);
        } else {
          track.disable();
          track.stop();
        }

        return track;
      })
      .catch((ex) => {
        this.availableAudioDevice = false;
        return null;
      });
  }

  public stopLocalVideoTrack(): void {
    this.getLocalVideoTrack()?.disable();
    this.getLocalVideoTrack()?.stop();
    this.videoLocalTrackSubject.next(null);
  }

  public stopLocalAudioTrack(): void {
    this.getLocalAudioTrack()?.disable();
    this.getLocalAudioTrack()?.stop();
    this.audioLocalTrackSubject.next(null);
  }

  private isDevice(kind: MediaDeviceKind): Promise<void> {
    return new Promise((resolve, reject) => {
      return navigator.mediaDevices.enumerateDevices()
        .then((devices: MediaDeviceInfo[]) => {
          if (devices.filter(s => s.kind === kind).length > 0) {
            return resolve();
          } else {
            return reject();
          }
        });
    });
  }

  public close(): void {
    this.videoCallService.close();
  }

  private setRoom(roomDetails: VideoCallRoomDTO): void {
    this._roomDetails.next(roomDetails);
  }
}
