import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Constant } from 'app/common/Constant';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Observable, throwError } from 'rxjs';
import { LoaderModel } from '@model/LoaderModel';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loaderModel: LoaderModel) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url, urlWithParams } = request;

    if (url.startsWith(ApplicationConfig.apiUrl)) {
      if (!request.headers.has(Constant.HTTP_SILENT_MODE)) {
        const loadingMode = request.headers.get(Constant.HTTP_LOADING_MODE) || 'master';

        this.loaderModel.setLoading(true, urlWithParams, loadingMode);

        return next.handle(request)
          .pipe(
            catchError((err: any) => {
              this.loaderModel.setLoading(false, urlWithParams);

              return throwError(err);
            }),
            map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                  this.loaderModel.setLoading(false, request.urlWithParams);
                }
                return evt;
              }
            )
          );
      }

      return next.handle(request);
    } else {
      return next.handle(request);
    }
  }
}
