<div class="page">
  <ng-container *ngIf="!verificationCodeRequired" [ngTemplateOutlet]="loginTemplate"></ng-container>
  <ng-container *ngIf="verificationCodeRequired" [ngTemplateOutlet]="confirmationCodeTemplate"></ng-container>

  <ng-template #loginTemplate>
    <div class="navbar-container">
      <div class="navbar-instance">
        <app-navbar></app-navbar>
      </div>
    </div>

    <div class="content">
      <!--      Form container-->
      <div class="form-container">
        <div class="title">{{ 'VIEW.PRELIMINARY.LOGIN.DOCTORS' | translate }}</div>

        <form #loginForm="ngForm" (ngSubmit)="submit()" novalidate>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <ng-container *ngIf="serverValidationError">
              <div class="font-size-sm text-danger mb-3">
                <p> {{ 'VIEW.PRELIMINARY.LOGIN.VALIDATION_ERROR' | translate }} </p>
              </div>
            </ng-container>

            <div class="form-group">
              <label for="email"> {{ 'VIEW.PRELIMINARY.LOGIN.EMAIL' | translate }}</label>
              <input id="email" type="text" name="email" #email="ngModel"
                     placeholder="{{ 'VIEW.PRELIMINARY.LOGIN.EMAIL' | translate }}" class="form-control form-control-sm"
                     ngvemail [ngClass]="{'is-invalid': loginForm.submitted && email.invalid}"
                     [(ngModel)]="formData.email"
                     required>

              <div class="invalid-feedback" *ngIf="loginForm.submitted && email.invalid">
                <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
              </div>
            </div>

            <div class="form-group">
              <label for="password"> {{ 'VIEW.PRELIMINARY.LOGIN.PASSWORD' | translate }}</label>
              <input id="password" type="password" name="password" appInputPasswordToggle #password="ngModel"
                     class="form-control form-control-sm"
                     [ngClass]="{'is-invalid': loginForm.submitted && password.invalid}"
                     [(ngModel)]="formData.password" required>
              <div class="invalid-feedback" *ngIf="loginForm.submitted && password.invalid">
                <div *ngIf="password.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
              </div>
            </div>

            <div class="w-100 d-flex justify-content-end">
              <button type="button" (click)="resetPassword()" class="btn btn-link btn-link--white btn-sm text-normal">
                <span>{{ 'VIEW.PRELIMINARY.LOGIN.FORGOT_PASSWORD' | translate }}</span>
              </button>
            </div>

            <div class="w-100">
              <button type="submit" class="btn btn-custom-1">{{ 'VIEW.PRELIMINARY.LOGIN.LOG_IN' | translate }}
              </button>
            </div>

            <div class="w-100 mt-3">
              <button type="button" (click)="register()"
                      class="btn btn-link btn-link--white btn-sm text-normal">
                <div class="d-flex align-items-center">
                  <span>{{ 'VIEW.PRELIMINARY.LOGIN.REGISTER' | translate }}</span>
                  <img src="asset/image/icon/right-arrow.png" alt="Logo"/>
                </div>
              </button>
            </div>

          </div>
        </form>
      </div>

      <div class="vertical-line"></div>

      <!--      Info container-->
      <div class="info-container">
        <div class="title">{{ 'VIEW.PRELIMINARY.LOGIN.PATIENTS' | translate }}</div>
        <div class="description mb-3">
          {{ 'VIEW.PRELIMINARY.LOGIN.DESCRIPTION' | translate }}
        </div>

        <div class="d-flex align-items-center gap-2">
          <a [href]="applicationModel.playGoogleStoreApplicationUrl" target="_blank">
            <img src="asset/image/icon/google-logo.png" alt="Google App"/>
          </a>

          <a [href]="applicationModel.appleStoreApplicationUrl" target="_blank">
            <img src="asset/image/icon/apple-logo.png" alt="Apple App"/>
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <!--Confirmation Code Template-->
  <ng-template #confirmationCodeTemplate>
    <form #confirmationCodeForm="ngForm" (ngSubmit)="submitCode()" novalidate>
      <div class="confirmation-container">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="mb-4">
            <img class="logo d-block img-fluid" src="asset/image/logo.png" alt="Logo"/>
          </div>

          <div class="form-group">
            <label for="confirmationCode"> {{ 'VIEW.PRELIMINARY.LOGIN.CONFIRMATION_CODE.LABEL' | translate }}</label>
            <input id="confirmationCode" type="text" name="confirmationCode" #confirmationCode="ngModel"
                   placeholder="{{ 'VIEW.PRELIMINARY.LOGIN.CONFIRMATION_CODE.PLACEHOLDER' | translate }}"
                   class="form-control form-control-sm"
                   [ngClass]="{'is-invalid': confirmationCodeForm.submitted && confirmationCode.invalid}"
                   [(ngModel)]="formData.confirmationCode" required>

            <div class="invalid-feedback" *ngIf="confirmationCodeForm.submitted && confirmationCode.invalid">
              <div *ngIf="confirmationCode.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
            </div>
          </div>

          <button type="submit" class="btn btn-custom-1">
            {{ 'COMMON.CONFIRM' | translate }}
          </button>

          <button type="button" class="btn btn-link btn-link--white btn-sm text-normal mt-4" (click)="backToLogin()">
            <span>{{ 'VIEW.PRELIMINARY.LOGIN.BACK_TO_LOGIN' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</div>