<div class="week-element"
     [ngClass]="{
               'today': eventTimeMoment === EventTimeMoment.TODAY,
               'future': eventTimeMoment === EventTimeMoment.FUTURE
                 }">
  <div class="week-day">
    <span>{{data?.date | date: 'EEEE'}} </span>
    <span *ngIf="eventTimeMoment === EventTimeMoment.TODAY">&nbsp;({{'COMMON.TODAY' | translate}})</span>
  </div>
  <div class="date">{{ data?.date | date: 'dd MMM YYYY' }} </div>
  <div class="visits-counter"
       [ngClass]="{'at-least-one': numberOfEvents > 0 }">
    <span *ngIf="numberOfEvents > 0">{{numberOfEventsText | translate:{number: numberOfEvents} }}</span>
    <span *ngIf="numberOfEvents === 0">{{noEventsText | translate}}</span>
  </div>
</div>