import { Component, OnInit } from '@angular/core';
import { State } from 'app/common/State';
import { ViewUtil } from 'app/util/ViewUtil';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { DictionaryModel } from '@model/DictionaryModel';
import { Observable } from 'rxjs';
import { QualificationDTO } from '@dto/doctor/QualificationDTO';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import * as _ from 'lodash';
import { plainToClass } from 'class-transformer';
import { ApplicationModel } from '@model/ApplicationModel';
import { StateUtil } from '@util/StateUtil';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-profile-qualification-edit',
  templateUrl: './ProfileQualificationEditComponent.html',
  styleUrls: [ './ProfileQualificationEditComponent.scss' ]
})
export class ProfileQualificationEditComponent implements OnInit {
  public availableQualifications$: Observable<QualificationDTO[]> = this.dictionaryModel.qualifications$;

  public qualifications: QualificationDTO[];

  public newQualification: QualificationDTO;

  public disabledSubmit: boolean = false;

  constructor(private stateUtil: StateUtil,
              private viewUtil: ViewUtil,
              private dictionaryModel: DictionaryModel,
              private applicationModel: ApplicationModel,
              private currentDoctorService: CurrentDoctorService) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public ngOnInit(): void {
    this.currentDoctorService.getQualifications().toPromise()
      .then((data: QualificationListDTO) => {
        this.qualifications = data.qualifications;

        this.setAvailableQualifications();
      });
  }

  public remove(index: number): void {
    this.qualifications.splice(index, 1);

    this.setAvailableQualifications();
  }

  public addNewQualification(): void {
    this.qualifications.push(this.newQualification);
    this.newQualification = null;

    this.setAvailableQualifications();
  }

  public onSelectedChanged(): void {
    this.setAvailableQualifications();
  }

  public save(): void {
    const qualifications = _.uniqBy(this.qualifications, 'id');

    this.disabledSubmit = true;

    this.currentDoctorService.updateQualifications(plainToClass(QualificationListDTO, { qualifications }))
      .toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.QUALIFICATIONS.MESSAGE.UPDATE_SUCCESS');
        this.backToProfile();
      })
      .catch((error) => {
        this.viewUtil.handleServerError(error);
      })
      .finally(() => {
        this.disabledSubmit = false;
      });
  }

  public backToProfile(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE.QUALIFICATION);
  }

  private setAvailableQualifications(): void {
    const selectedQualificationIds = this.qualifications.map(q => q.id);
    this.availableQualifications$ = this.dictionaryModel.qualifications$
      .pipe(
        map((qualifications: QualificationDTO[]) => qualifications
          .filter((qualification: QualificationDTO) => !selectedQualificationIds.includes(qualification.id)))
      );
  }
}
