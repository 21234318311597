import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';

@Component({
  selector: 'app-data-grid-template-cell-renderer-component',
  template: '<div class="template-cell" (click)="preventGridSelection()"><ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container></div>',
  styles: [ ':host {width: 100%; height:100%} .template-cell {text-overflow: ellipsis;white-space: nowrap;overflow: hidden; height: 100% }' ]
})
export class DataGridTemplateCellRendererComponent implements ICellRendererAngularComp {
  public template: TemplateRef<any>;

  public templateContext: { $implicit: any, params: any, value: any };

  public preventSelection: boolean = true;

  public params: any;


  public agInit(params: ICellRendererParams): void {
    this.template = params['ngTemplate'];

    if (_.isBoolean(params['preventSelection'])) {
      this.preventSelection = params['preventSelection'];
    }

    this.refresh(params);
  }

  public refresh(params: any): boolean {
    this.params = params;

    this.templateContext = {
      $implicit: params.data,
      params: params,
      value: params.value
    };

    return true;
  }

  public preventGridSelection(): void {
    if (this.preventSelection) {
      const previousValue = this.params.node.gridOptionsWrapper.gridOptions.suppressRowClickSelection;
      this.params.node.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;
      setTimeout(() => {
        this.params.node.gridOptionsWrapper.gridOptions.suppressRowClickSelection = previousValue;
      });
    }
  }
}
