<div *ngIf="firstSlotDate">
  <app-calendar-selector
    [startDate]="firstSlotDate"
    (changeDateRange)="onDateRangeChanged($event)"></app-calendar-selector>
</div>

<div class="conflicts-container" *ngIf="data">
  <section class="weeks-container">
    <div *ngFor="let dayItem of data">
      <app-scheduler-day-header [data]="dayItem"
                                noEventsText="VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.DAY_HEADER.NO_CONFLICTS"
                                numberOfEventsText="VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.DAY_HEADER.CONFLICTS"></app-scheduler-day-header>
    </div>
  </section>

  <section class="slots-container">
    <div *ngFor="let dayItem of data">
      <app-scheduler-slots-grid [data]=dayItem.slots
                                [slotTemplate]="conflictSlotTemplate"></app-scheduler-slots-grid>
    </div>
  </section>
</div>

<!--Templates-->
<ng-template #conflictSlotTemplate let-slot>
  <app-scheduler-conflict-slot [slot]="slot" (slotCancelled)="slotCancelled(slot)"></app-scheduler-conflict-slot>
</ng-template>
