import { Component, OnInit } from '@angular/core';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { ViewUtil } from 'app/util/ViewUtil';
import { Observable } from 'rxjs';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { Constant } from '@common/Constant';
import { State } from '@common/State';
import { StateUtil } from '@util/StateUtil';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { map } from 'rxjs/operators';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-profile-basic-data',
  templateUrl: './ProfileBasicDataComponent.html',
  styleUrls: [ './ProfileBasicDataComponent.scss' ]
})
export class ProfileBasicDataComponent implements OnInit {
  public doctor$: Observable<DoctorDTO> = this.currentDoctorService.getBasicData();

  public Constant: typeof Constant = Constant;

  public isEditable$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.NOT_ACCEPTED)
    .pipe(
      map(status => !status)
    );

  constructor(public viewUtil: ViewUtil,
              private userModel: UserModel,
              private stateUtil: StateUtil,
              private currentDoctorService: CurrentDoctorService) {
  }

  public ngOnInit(): void {
  }

  public editProfile(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE_EDIT.BASIC_DATA);
  }
}
