import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Transition } from '@uirouter/core';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { EventPrescriptionService } from '@service/event/EventPrescriptionService';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { Constant } from '@common/Constant';
import { EventPrescriptionEditPopupComponent } from 'app/component/view/raw/meeting/view/prescription/popup/EventPrescriptionEditPopupComponent';
import { cloneDeep } from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-event-prescription',
  templateUrl: './EventPrescriptionComponent.html',
  styleUrls: [ './EventPrescriptionComponent.scss' ]
})
export class EventPrescriptionComponent implements OnInit {
  @ViewChild('newPrescriptionForm') private form: NgForm;

  private eventId: number = this.transition.params().eventId;

  public Constant: typeof Constant = Constant;

  public prescriptions: PrescriptionDTO[] = [];

  public isProcessing: boolean = false;

  public newPrescription: PrescriptionDTO = new PrescriptionDTO();

  constructor(public viewUtil: ViewUtil, private modalService: BsModalService,
              private transition: Transition,
              private eventPrescriptionService: EventPrescriptionService) {
  }

  public ngOnInit(): void {
    this.getPrescribedMedications();
  }

  public addPrescription(): void {
    this.isProcessing = true;

    this.eventPrescriptionService.create(this.eventId, this.newPrescription).toPromise()
      .then((createdPrescription: PrescriptionDTO) => {
        this.prescriptions.push(createdPrescription);
        this.viewUtil.showToastSuccess('VIEW.MAIN.PRESCRIPTION.MESSAGE.CREATED_SUCCESS');
        this.form.resetForm();
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  public editPrescription(prescription: PrescriptionDTO): void {
    const modal: BsModalRef = this.modalService.show(EventPrescriptionEditPopupComponent, {
      initialState: {
        prescription: cloneDeep(prescription),
        eventId: this.eventId
      }
    });

    modal.content.close$.subscribe(() => {
      this.getPrescribedMedications();
    });
  }

  public removePrescription(prescription: PrescriptionDTO): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.VIDEO_CALL.PRESCRIPTION.ACTION.DELETE.TITLE',
        message: 'VIEW.VIDEO_CALL.PRESCRIPTION.ACTION.DELETE.DESCRIPTION',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => {
      this.eventPrescriptionService.remove(this.eventId, prescription.id).toPromise()
        .then(() => {
          this.prescriptions = this.prescriptions.filter(s => s.id !== prescription.id);
          this.viewUtil.showToastSuccess('VIEW.MAIN.PRESCRIPTION.MESSAGE.DELETED_SUCCESS');
        })
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    });
  }

  private getPrescribedMedications(): void {
    this.eventPrescriptionService.getList(this.eventId).toPromise()
      .then((prescriptions: PrescriptionDTO[]) => {
        this.prescriptions = prescriptions;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }
}
