import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Constant } from 'app/common/Constant';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {

  private timezone: string = momentTimezone.tz.guess();
  private utcOffset: string = moment().format('Z');

  constructor() {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const timezoneRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_TIMEZONE_HEADER]: this.timezone,
          [Constant.HTTP_UTC_OFFSET_HEADER]: this.utcOffset
        }
      });

      return next.handle(timezoneRequest);
    }
    else {
      return next.handle(request);
    }
  }
}
