import { LanguageVersion } from '@local/LanguageVersion';
import { Expose, Transform } from 'class-transformer';
import { localeParser } from '@util/parser/LocaleParser';

export class DoctorSettingsDTO {
  @Expose({ name: 'smsActive' })
  public smsNotificationEnabled: boolean;

  @Expose({ name: 'emailActive' })
  public emailNotificationEnabled: boolean;

  @Transform(({ value }) => localeParser(value))
  public locale: LanguageVersion;
}
