import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { ITab } from 'app/component/ui/tab/interface/ITab';
import { Transition, TransitionService } from '@uirouter/angular';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-tab',
  templateUrl: './TabComponent.html',
  styleUrls: [ './TabComponent.scss' ]
})
export class TabComponent implements OnInit, OnDestroy {
  @ViewChild(TabsetComponent) private tabsetComponent: TabsetComponent;

  @Input()
  public set tabs(value: ITab[]) {
    this._tabs = value.filter(s => !s.hidden);
    this.setActiveTab();
  }

  @Input() public type: 'tabs' | 'pills' = 'tabs';

  public transitionHook: Function;

  public _tabs: ITab[];

  public currentTab: ITab;

  constructor(private stateService: StateService,
              private transitionService: TransitionService) {
    this.transitionHook = this.transitionService.onSuccess({}, (transition: Transition) => {
      this.setActiveTab();
    });

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    if (this.transitionHook) {
      this.transitionHook();
    }
  }

  public onChangeTab(data: ITab): void {
    this.stateService.go(data.state, null, { location: 'replace' });
  }

  private setActiveTab(): void {
    this._tabs?.forEach((tab: ITab) => {
      const isActive = this.stateService.is(tab.state);

      if (isActive) {
        this.currentTab = tab;
      }

      tab.active = isActive;
    });
  }
}
