import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { DoctorSettingsDTO } from '@dto/doctor/DoctorSettingsDTO';
import { Constant } from '@common/Constant';
import { EventTimesDTO } from '@dto/events/EventTimesDTO';
import { LicenseType } from '@enum/doctor/LicenseType';

@Injectable({ providedIn: 'root' })
export class CurrentDoctorService {

  constructor(private http: HttpClient) {
  }

  public getBasicData(): Observable<DoctorDTO> {
    return this.http.get<DoctorDTO>(`${ ApplicationConfig.apiUrl }/doctors/basic-data`)
      .pipe(
        map((response: DoctorDTO) => ObjectUtil.plainToClass(DoctorDTO, response))
      );
  }

  public update(data: DoctorDTO): Observable<unknown> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/doctors/basic-data`, ObjectUtil.classToPlain(data));
  }

  public getPractice(): Observable<PracticeDTO> {
    return this.http.get<PracticeDTO>(`${ ApplicationConfig.apiUrl }/doctors/practice`)
      .pipe(
        map((response: PracticeDTO) => ObjectUtil.plainToClass(PracticeDTO, response))
      );
  }

  public updatePractice(practice: PracticeDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/practice`, ObjectUtil.classToPlain(practice));
  }

  public createPractice(practice: PracticeDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/practice`, ObjectUtil.classToPlain(practice));
  }

  public deletePractice(): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/practice`);
  }

  public getVisitCategories(): Observable<EventTimesDTO> {
    return this.http.get<EventTimesDTO>(`${ ApplicationConfig.apiUrl }/events/times`)
      .pipe(
        map((response: EventTimesDTO) => ObjectUtil.plainToClass(EventTimesDTO, response))
      );
  }

  public getQualifications(): Observable<QualificationListDTO> {
    return this.http.get<QualificationListDTO>(`${ ApplicationConfig.apiUrl }/doctors/qualifications`)
      .pipe(
        map((response: QualificationListDTO) => ObjectUtil.plainToClass(QualificationListDTO, response))
      );
  }

  public updateQualifications(qualification: QualificationListDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/qualifications`, ObjectUtil.classToPlain(qualification));
  }

  public getLicense(): Observable<LicenseDTO> {
    return this.http.get<LicenseDTO>(`${ ApplicationConfig.apiUrl }/doctors/license`)
      .pipe(
        map((result: LicenseDTO) => ObjectUtil.plainToClass(LicenseDTO, result))
      );
  }

  public updateLicense(license: LicenseDTO): Observable<any> {
    return this.http.patch(`${ ApplicationConfig.apiUrl }/doctors/license`, ObjectUtil.classToPlain(license));
  }

  public deleteCertificate(type: LicenseType): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/license-file/${ type }`);
  }

  public uploadAvatar(file: Blob): Observable<void> {
    const requestData: FormData = new FormData();
    let context: HttpContext = new HttpContext();

    requestData.append('photo', file, null);
    context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/profile-photo`, requestData);
  };

  public uploadCertificate(file: File, type: LicenseType): Observable<void> {
    const requestData: FormData = new FormData();

    requestData.append('file', file, file.name);
    requestData.append('licenseType', type);

    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/license-file`, requestData);
  };

  public deleteAvatar(): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/profile-photo`);
  };

  public getSettings(): Observable<DoctorSettingsDTO> {
    return this.http.get<DoctorSettingsDTO>(`${ ApplicationConfig.apiUrl }/doctors/settings`)
      .pipe(
        map((response: DoctorSettingsDTO) => ObjectUtil.plainToClass(DoctorSettingsDTO, response))
      );
  }

  public updateSettings(settings: DoctorSettingsDTO): Observable<any> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/doctors/settings`, ObjectUtil.classToPlain(settings));
  }

}