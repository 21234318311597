export class UserRegistrationDTO {

  public firstName: string;

  public lastName: string;

  public email: string;

  public password: string;
}

