import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UpdatePasswordDTO } from '@dto/user/UpdatePasswordDTO';
import { SecurityDataDTO } from '@dto/auth/SecurityDataDTO';
import { CurrentUserDTO } from '@dto/user/CurrentUserDTO';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {
  }

  public getCurrentUser(): Observable<{ user: CurrentUserDTO, securityData: SecurityDataDTO }> {
    return this.http.get(`${ ApplicationConfig.apiUrl }/users/me`)
      .pipe(
        map((response: CurrentUserDTO) => {
          const user: CurrentUserDTO = ObjectUtil.plainToClass(CurrentUserDTO, response);
          return { user: user, securityData: undefined };
        })
      );
  }

  public updateUserPassword(updatePassword: UpdatePasswordDTO): Observable<any> {
    return this.http.put(`${ ApplicationConfig.apiUrl }/users/password`, ObjectUtil.classToPlain(updatePassword));
  }
}
