<form #form="ngForm" novalidate (ngSubmit)="save()">
  <ng-container *ngIf="license">
    <section class="page">
      <div class="header-container separator">
        <button type="button" class="back" (click)="back()">
          <i-tabler name="chevron-left"></i-tabler>
          <div>{{ 'VIEW.MAIN.DOCTOR.LICENSE.EDIT_DATA' | translate }}</div>
        </button>

        <div class="actions">
          <button type="submit" class="btn btn-primary" [disabled]="disabledSubmit"> {{ 'COMMON.SAVE' | translate}}
          </button>
        </div>
      </div>

      <div class="content">
        <div class="container px-0">

          <div class="row">
            <div class="col-12 col-md-8">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.LICENSE.LICENSE_INFO' | translate }}</div>

                  <div class="form-group mt-4">
                    <label for="insuranceType">{{ 'VIEW.MAIN.DOCTOR.LICENSE.INSURANCE_TYPE' | translate }}</label>
                    <ng-select id="insuranceType" name="insuranceType" #insuranceType="ngModel"
                               [placeholder]="'COMMON.CHOOSE' | translate" [items]="insuranceTypeOptions"
                               [searchable]="false"
                               [clearable]="false" [ngClass]="{'is-invalid': form.submitted && insuranceType.invalid }"
                               [(ngModel)]="license.insuranceType" required>
                      <ng-template ng-label-tmp let-item="item">{{item | prefix: 'ENUM.INSURANCE_TYPE.' |
                        translate}}</ng-template>
                      <ng-template ng-option-tmp let-item="item">{{item | prefix: 'ENUM.INSURANCE_TYPE.' |
                        translate}}</ng-template>
                    </ng-select>

                    <div class="invalid-feedback" *ngIf="form.submitted && insuranceType.invalid">
                      <div *ngIf="insuranceType.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.KIDS' | translate }}</label>

                    <div [ngClass]="{'is-invalid': form.submitted && worksWithKids.invalid }">
                      <div class="custom-control custom-radio primary">
                        <input type="radio" class="custom-control-input" id="worksWithKids_yes" name="worksWithKids"
                               [(ngModel)]="license.worksWithKids" [value]="true"
                               [ngClass]="{'is-invalid': form.submitted && worksWithKids.invalid }"
                               #worksWithKids="ngModel"
                               required>
                        <label class="custom-control-label" for="worksWithKids_yes">{{'COMMON.YES' | translate}}</label>
                      </div>

                      <div class="custom-control custom-radio primary mt-1">
                        <input type="radio" class="custom-control-input" id="worksWithKids_no" name="worksWithKids"
                               [(ngModel)]="license.worksWithKids" [value]="false"
                               [ngClass]="{'is-invalid': form.submitted && worksWithKids.invalid }"
                               #worksWithKids="ngModel"
                               required>
                        <label class="custom-control-label" for="worksWithKids_no">{{'COMMON.NO' | translate}}</label>
                      </div>
                    </div>

                    <div class="invalid-feedback" *ngIf="form.submitted && worksWithKids.invalid">
                      <div *ngIf="worksWithKids.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                    </div>

                  </div>

                  <div class="form-group">
                    <label for="ban">{{ 'VIEW.MAIN.DOCTOR.LICENSE.BAN' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="ban" id="ban"
                           [ngClass]="{'is-invalid': form.submitted && ban.invalid }" #ban="ngModel"
                           [(ngModel)]="license.ban" [pattern]="Constant.BAN_PATTERN" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && ban.invalid">
                      <div *ngIf="ban.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="ban.errors['pattern']">{{ 'VALIDATION.BAN' | translate }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="lanrNumber">{{ 'VIEW.MAIN.DOCTOR.LICENSE.LANR' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="lanrNumber" id="lanrNumber"
                           [ngClass]="{'is-invalid': form.submitted && lanrNumber.invalid }" #lanrNumber="ngModel"
                           [(ngModel)]="license.lanrNumber" [pattern]="Constant.LANR_NUMBER_PATTERN" required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && lanrNumber.invalid">
                      <div *ngIf="lanrNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="lanrNumber.errors['pattern']">{{ 'VALIDATION.LANR_NUMBER' | translate }}</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="doctorOfficeNumber">{{ 'VIEW.MAIN.DOCTOR.LICENSE.OFFICE_NUMBER' | translate }}</label>
                    <input type="text" class="form-control form-control-sm" name="doctorOfficeNumber"
                           id="doctorOfficeNumber"
                           [ngClass]="{'is-invalid': form.submitted && doctorOfficeNumber.invalid }"
                           #doctorOfficeNumber="ngModel" [(ngModel)]="license.doctorOfficeNumber"
                           [pattern]="Constant.DOCTOR_OFFICE_NUMBER_PATTERN" appInputPositiveIntegerOnly required/>

                    <div class="invalid-feedback" *ngIf="form.submitted && doctorOfficeNumber.invalid">
                      <div *ngIf="doctorOfficeNumber.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                      <div *ngIf="doctorOfficeNumber.errors['pattern']">{{ 'VALIDATION.DOCTOR_OFFICE_NUMBER' | translate
                        }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mt-4 mt-sm-0">
                  <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATES' | translate }}</div>
                  <div class="certificates">
                    <div class="form-group mt-4">
                      <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.DOCTOR_CERTIFICATE_FRONT' | translate }}</label>

                      <ng-container *ngIf="license.doctorCertificateFrontFile">
                        <app-file-preview [file]="license.doctorCertificateFrontFile"></app-file-preview>
                      </ng-container>

                      <ng-container
                        *ngTemplateOutlet="certificateFileTemplate; context:{ $implicit: license?.doctorCertificateFrontFile, photoType: LicensePhotoType.DOCTOR_CERTIFICATE_FRONT_FILE}"></ng-container>
                    </div>

                    <div class="form-group mt-4">
                      <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.DOCTOR_CERTIFICATE_BACK' | translate }}</label>

                      <ng-container *ngIf="license.doctorCertificateBackFile">
                        <app-file-preview [file]="license.doctorCertificateBackFile"></app-file-preview>
                      </ng-container>

                      <ng-container
                        *ngTemplateOutlet="certificateFileTemplate; context:{ $implicit: license?.doctorCertificateBackFile , photoType: LicensePhotoType.DOCTOR_CERTIFICATE_BACK_FILE}"></ng-container>
                    </div>

                    <div class="form-group mt-4">
                      <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATE_OF_APPROBATION' | translate }}</label>

                      <ng-container *ngIf="license.approbationCertificateFile">
                        <app-file-preview [file]="license.approbationCertificateFile"></app-file-preview>
                      </ng-container>

                      <ng-container
                        *ngTemplateOutlet="certificateFileTemplate; context:{ $implicit: license?.approbationCertificateFile, photoType: LicensePhotoType.APPROBATION_CERTIFICATE_FILE}"></ng-container>
                    </div>

                    <div class="form-group mt-4">
                      <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATE_OF_SPECIALITY' | translate }}</label>

                      <ng-container *ngIf="license.specialtyCertificateFile">
                        <app-file-preview [file]="license.specialtyCertificateFile"></app-file-preview>
                      </ng-container>

                      <ng-container
                        *ngTemplateOutlet="certificateFileTemplate; context:{ $implicit: license?.specialtyCertificateFile, photoType: LicensePhotoType.SPECIALTY_CERTIFICATE_FILE}"></ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</form>


<!--Templates-->
<ng-template #certificateFileTemplate let-value let-photoType="photoType">
  <div class="certificate-file-manager">
    <ng-container *ngIf="value">
      <button type="button" class="btn btn-outline-primary" (click)="certificateUploader.click()">{{'COMMON.REPLACE' |
        translate}}</button>
      <button type="button" class="btn btn-danger" (click)="deleteCertificate(photoType)">{{'COMMON.DELETE' |
        translate}}</button>
    </ng-container>

    <ng-container *ngIf="!value">
      <button type="button" class="btn btn-outline-primary" (click)="certificateUploader.click()">{{'COMMON.UPLOAD' |
        translate}}</button>
    </ng-container>

    <input type="file" #certificateUploader accept="image/*,application/pdf" class="d-none"
           (change)="changeCertificate($event, photoType, value)">
  </div>
</ng-template>