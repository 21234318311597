import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { DoctorService } from '@service/DoctorService';
import { ViewUtil } from '@util/ViewUtil';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { Transition } from '@uirouter/angular';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { DoctorCategoryDTO } from '@dto/doctor/DoctorCategoryDTO';

@Injectable()
export class DoctorModel {
  public id: number;

  public needVerification: boolean = false;

  public wasDeclined: boolean = false;

  public doctor$: Observable<DoctorDTO>;

  public license$: Observable<LicenseDTO>;

  public categories$: Observable<DoctorCategoryDTO>;

  public qualifications$: Observable<QualificationListDTO>;

  public practice$: Observable<PracticeDTO>;

  private loadData: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(private doctorService: DoctorService,
              private transition: Transition,
              private viewUtil: ViewUtil) {
    this.setData(this.transition.params().doctorId);
    this.refreshData();
  }

  public decline(note: string): void {
    this.doctorService.decline(this.id, note).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.MESSAGE.DECLINE_SUCCESS');
        history.back();

        return;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public accept(): void {
    this.doctorService.accept(this.id).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.MESSAGE.ACCEPT_SUCCESS');
        this.refreshData();

        return;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public resendVerificationEmail(): void {
    this.doctorService.resendVerificationEmail(this.id).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.MESSAGE.RESEND_CONFIRMATION_LINK_SUCCESS');

        return;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public deletePractice(): void {
    this.doctorService.deletePractice(this.id).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.DELETE.SUCCESS');
        this.refreshData();

        return;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private setData(id: number): void {
    this.id = id;

    this.doctor$ = this.loadData.asObservable()
      .pipe(
        switchMap(() => this.doctorService.getById(id)),
        tap((doctor: DoctorDTO) => {
          this.needVerification = [ DoctorStatus.NOT_ACCEPTED, DoctorStatus.DECLINED ].includes(doctor.status);
          this.wasDeclined = doctor.status === DoctorStatus.DECLINED;
        }),
        shareReplay()
      );

    this.license$ = this.doctorService.getLicense(id)
      .pipe(
        shareReplay()
      );

    this.qualifications$ = this.doctorService.getQualifications(id)
      .pipe(
        shareReplay()
      );

    this.categories$ = this.doctorService.getCategories(id)
      .pipe(
        shareReplay()
      );

    this.practice$ = this.loadData.asObservable()
      .pipe(
        switchMap(() => this.doctorService.getPractice(id)),
        shareReplay()
      );
  }

  private refreshData(): void {
    this.loadData.next();
  }
}
