import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QualificationDTO } from '@dto/doctor/QualificationDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { HttpClient } from '@angular/common/http';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { SpecialityDTO } from '@dto/doctor/SpecialityDTO';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  constructor(private http: HttpClient) {
  }

  public getQualifications(): Observable<QualificationDTO[]> {
    return this.http.get<QualificationDTO[]>(`${ ApplicationConfig.apiUrl }/qualifications`)
      .pipe(
        map((response: QualificationDTO[]) => plainToClass(QualificationDTO, response))
      );
  }

  public getSpecialities(): Observable<SpecialityDTO[]> {
    return this.http.get<SpecialityDTO[]>(`${ ApplicationConfig.apiUrl }/specialities`)
      .pipe(
        map((response: SpecialityDTO[]) => plainToClass(SpecialityDTO, response))
      );
  }
}
