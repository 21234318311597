import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { Constant } from 'app/common/Constant';

@Injectable()
export class AppTypeInterceptor implements HttpInterceptor {

  constructor() {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method && (request.url?.indexOf(ApplicationConfig.apiUrl) > -1)) {
      const appTypeRequest: HttpRequest<any> = request.clone({
        setHeaders: {
          [Constant.HTTP_APP_TYPE_HEADER]: 'web-application'
        }
      });

      return next.handle(appTypeRequest);
    }
    else {
      return next.handle(request);
    }
  }
}
