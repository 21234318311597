import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { ObjectUtil } from '@util/ObjectUtil';
import { PageDTO } from '@dto/PageDTO';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { QualificationListDTO } from '@dto/doctor/QualificationsListDTO';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { DoctorPageCriteriaDTO } from '@dto/doctor/DoctorPageCriteriaDTO';
import { LicenseType } from '@enum/doctor/LicenseType';
import { Constant } from '@common/Constant';
import { DoctorCategoryDTO } from '@dto/doctor/DoctorCategoryDTO';
import { DoctorExportCriteriaDTO } from '@dto/doctor/DoctorExportCriteriaDTO';
import { HttpUtil } from '@util/HttpUtil';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private http: HttpClient) {
  }

  public getPage(criteria: DoctorPageCriteriaDTO): Observable<PageDTO<DoctorDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors`, { params })
      .pipe(
        map((response: PageDTO<DoctorDTO>) => ObjectUtil.plainToClassFromExist(new PageDTO<DoctorDTO>(DoctorDTO), response))
      );
  }

  public getExcelReport(criteria: DoctorExportCriteriaDTO): Observable<File> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/export`,
      {
        params: params,
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => new File([ response.body ], `doctors.xlsx`))
      );
  }

  public getDoctorsToVerifyPage(criteria: DoctorPageCriteriaDTO): Observable<PageDTO<DoctorDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/doctors/to-verify`, { params })
      .pipe(
        map((response: PageDTO<DoctorDTO>) => ObjectUtil.plainToClassFromExist(new PageDTO<DoctorDTO>(DoctorDTO), response))
      );
  }

  public getById(id: number): Observable<DoctorDTO> {
    return this.http.get<DoctorDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/basic-data`)
      .pipe(
        map((response: any) => ObjectUtil.plainToClass(DoctorDTO, response))
      );
  }

  public uploadAvatar(id: number, file: Blob): Observable<void> {
    const requestData: FormData = new FormData();
    let context: HttpContext = new HttpContext();

    requestData.append('photo', file, null);
    context.set(Constant.HTTP_SUPPRESS_LOADING_TOKEN, true);

    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/profile-photo`, requestData);
  };

  public deleteAvatar(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/profile-photo`);
  };

  public update(id: number, data: DoctorDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/basic-data`, ObjectUtil.classToPlain(data));
  }

  getLicense(id: number): Observable<LicenseDTO> {
    return this.http.get<LicenseDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/license`)
      .pipe(
        map((result: LicenseDTO) => ObjectUtil.plainToClass(LicenseDTO, result))
      );
  }

  public updateLicense(license: LicenseDTO, id?: number): Observable<LicenseDTO> {
    return this.http.patch<LicenseDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/license`, ObjectUtil.classToPlain(license));
  }

  public deleteCertificate(id: number, type: LicenseType): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/license-file/${ type }`);
  }

  public uploadCertificate(id: number, file: File, type: LicenseType): Observable<void> {
    const requestData: FormData = new FormData();

    requestData.append('file', file, file.name);
    requestData.append('licenseType', type);

    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/license-file`, requestData);
  };

  public getQualifications(id: number): Observable<QualificationListDTO> {
    return this.http.get<QualificationListDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/qualifications`)
      .pipe(
        map((response: QualificationListDTO) => ObjectUtil.plainToClass(QualificationListDTO, response))
      );
  }

  public getCategories(id: number): Observable<DoctorCategoryDTO> {
    return this.http.get<DoctorCategoryDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/categories`)
      .pipe(
        map((response: DoctorCategoryDTO) => ObjectUtil.plainToClass(DoctorCategoryDTO, response))
      );
  }

  public updateQualifications(id: number, qualification: QualificationListDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/qualifications`, ObjectUtil.classToPlain(qualification));
  }

  public getPractice(id: number): Observable<PracticeDTO> {
    return this.http.get<PracticeDTO>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/practice`)
      .pipe(
        map((response: PracticeDTO) => ObjectUtil.plainToClass(PracticeDTO, response))
      );
  }

  public deletePractice(id: number): Observable<void> {
    return this.http.delete<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/practice`);
  }

  public createPractice(id: number, practice: PracticeDTO): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/practice`, ObjectUtil.classToPlain(practice));
  }

  public updatePractice(id: number, practice: PracticeDTO): Observable<void> {
    return this.http.put<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/practice`, ObjectUtil.classToPlain(practice));
  }

  public decline(id: number, note: string): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/decline`, { note });
  }

  public accept(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/${ id }/accept`, null);
  }

  public resendVerificationEmail(id: number): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/doctors/to-verify/retry-email/${ id }`, null);
  }
}
