<section class="page">
  <div class="header-container separator">
    <div class="header">{{'VIEW.MAIN.MAIN.PATIENTS' | translate}}</div>
    <div class="actions">
<!--      <button type="button" class="btn btn-primary"-->
<!--              (click)="export()"> {{ 'COMMON.EXPORT' | translate}} </button>-->
    </div>
  </div>

  <div class="content">
    <app-data-grid *ngIf="data" [data]="data.content"
                   [criteria]="criteria"
                   [context]="{ host: this }"
                   [selectionEnabled]="false"
                   [showFilters]="true"
                   [showSearch]="true"
                   [showPagination]="true"
                   [pageTotal]="data.totalPages" [itemTotal]="data.totalElements"
                   (criteriaChanged)="onCriteriaChanged($event)"
                   [columns]="$any([
                    { colId: '1', headerName: ('VIEW.MAIN.PATIENT.DATA.CREATION_DATE' | translate), maxWidth: 110, field: 'creationDate', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: creationDateTemplate, preventSelection: false } },
                    { colId: '2', headerName: ('VIEW.MAIN.PATIENT.DATA.STATUS' | translate), maxWidth: 110, field: 'status', suppressSizeToFit: true, cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: statusTemplate, preventSelection: false } },
                    { colId: '3', headerName: ('VIEW.MAIN.PATIENT.DATA.FIRST_NAME' | translate), maxWidth: 100, field: 'firstName', suppressSizeToFit: true },
                    { colId: '4', headerName: ('VIEW.MAIN.PATIENT.DATA.LAST_NAME' | translate), maxWidth: 130, field: 'lastName', suppressSizeToFit: true },
                    { colId: '5', headerName: ('VIEW.MAIN.PATIENT.DATA.PHONE' | translate), maxWidth: 135, field: 'phoneNumber', suppressSizeToFit: true },
                    { colId: '6', headerName: ('VIEW.MAIN.PATIENT.DATA.EMAIL' | translate), minWidth: 150, flex: 1, field: 'email', cellRendererFramework: DataGridTemplateCellRendererComponent, cellRendererParams: { ngTemplate: emailTemplate, preventSelection: false } },
                    { colId: '7', headerName: ('VIEW.MAIN.PATIENT.DATA.VIDEO_CALLS' | translate), maxWidth: 120, field: 'videoCalls', suppressSizeToFit: true },
                    { colId: '8', headerName: ('VIEW.MAIN.PATIENT.DATA.E_PRESCRIPTIONS' | translate), maxWidth: 130, field: 'ePrescriptions', suppressSizeToFit: true }
                  ])">

      <app-data-grid-filters>
        <div class="filter">
          <ng-select name="filter-status"
                     class="sm"
                     [placeholder]="'VIEW.MAIN.PATIENT.FILTER.STATUS.PLACEHOLDER' | translate"
                     [searchable]="false"
                     [(ngModel)]="criteria.status" (ngModelChange)="onStatusFilterChanged($event)">
            <ng-option *ngFor="let status of statuses"
                       [value]="status">{{ status | prefix:'ENUM.PATIENT_STATUS.' | translate }}</ng-option>
          </ng-select>
        </div>

        <div class="filter">
          <ng-select name="filter-insurance"
                     class="sm"
                     [placeholder]="'VIEW.MAIN.PATIENT.FILTER.INSURANCE_TYPE.PLACEHOLDER' | translate"
                     [searchable]="false"
                     [(ngModel)]="criteria.insuranceType" (ngModelChange)="onInsuranceTypeFilterChanged($event)">
            <ng-option *ngFor="let insuranceType of insuranceTypes"
                       [value]="insuranceType">{{ insuranceType | prefix:'ENUM.PATIENT_INSURANCE_TYPE.' | translate }}</ng-option>
          </ng-select>
        </div>
      </app-data-grid-filters>
    </app-data-grid>
  </div>
</section>

<!--Templates-->
<ng-template #statusTemplate let-value="value">
  <ng-container [ngSwitch]="value">
    <ng-container *ngSwitchCase="PatientStatus.ACTIVE">
      <span class="color-primary">{{value | prefix: 'ENUM.PATIENT_STATUS.' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="PatientStatus.INACTIVE">
      <span class="color-gray-3">{{value | prefix: 'ENUM.PATIENT_STATUS.' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="PatientStatus.BLOCKED">
      <span class="color-danger">{{value | prefix: 'ENUM.PATIENT_STATUS.' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="PatientStatus.UNREGISTERED">
      <span class="color-gray-3">{{value | prefix: 'ENUM.PATIENT_STATUS.' | translate }}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>-</ng-container>
  </ng-container>
</ng-template>

<ng-template #creationDateTemplate let-value="value">
  <span>{{value | date:Constant.DATE_FORMAT}}</span>
</ng-template>

<ng-template #emailTemplate let-value="value">
  <a href="mailto:{{value}}">{{value}}</a>
</ng-template>

