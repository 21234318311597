import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './DatePickerComponent.html',
  styleUrls: [ './DatePickerComponent.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  public onChange: any = () => {
  };

  public onTouch: any = () => {
  };

  constructor() {
  }

  public originalValue: Date;

  public parsedValue: string;

  public ngOnInit(): void {
  }

  public writeValue(value: Date): void {
    if (value) {
      this.originalValue = value;
      this.parsedValue = value ? moment(value).format('YYYY-MM-DD') : null;

      this.onChange(value);
      this.onTouch(value);
    }
  }

  public update(): void {
    const date = moment(this.parsedValue);
    const changedOriginalValue = moment(this.originalValue || new Date()).toDate();

    changedOriginalValue.setFullYear(date.year());
    changedOriginalValue.setMonth(date.get('month'));
    changedOriginalValue.setDate(date.get('date'));

    this.originalValue = changedOriginalValue;

    this.onChange(this.originalValue);
    this.onTouch(this.originalValue);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}
