import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from '@enum/user/UserType';
import { UserModel } from '@model/UserModel';

@Pipe({
  name: 'isUserType'
})
export class IsUserTypePipe implements PipeTransform {
  constructor(private userModel: UserModel) {
  }

  public transform(userType: UserType): boolean {
    return this.userModel.isUserType(userType);
  }

}
