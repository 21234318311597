import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { DateUtil } from '@util/DateUtil';
import { Exclude, Transform } from 'class-transformer';
import { PrescriptionStatus } from '@enum/prescription/PrescriptionStatus';

export class PrescriptionDTO extends IdentifiableObjectDTO<number> {
  public doze: string;

  public medicamentGrammage: string;

  public medicamentName: string;

  public status: PrescriptionStatus;

  public justification: string;

  @Transform(DateUtil.dateTimeConversion)
  @Exclude({ toPlainOnly: true })
  public createdDate: Date;
}

