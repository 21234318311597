<section class="page">
  <div class="header-container">
    <div class="header">{{ 'VIEW.MAIN.PROFILE.PROFILE' | translate }}</div>
    <div class="d-flex gap-1">
      <button type="button" class="btn btn-primary" *ngIf="doctorIsIncompleteRegistration$ | async"
              [disabled]="!registrationStatus?.completed" (click)="openConfirmationModal()"> {{
        'VIEW.MAIN.DOCTOR.ACTION.VERIFICATION.VERIFY_REGISTRATION_BUTTON' | translate }} </button>
      <app-page-action-container name="profile"></app-page-action-container>
    </div>
  </div>
  <div class="content">

    <app-tab [tabs]="tabs"></app-tab>

    <div class="pt-4">
      <ui-view></ui-view>
    </div>
  </div>
</section>