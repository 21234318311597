<form #form="ngForm" novalidate (ngSubmit)="save()">
  <section class="page">
    <div class="header-container separator">
      <div class="header">{{'VIEW.MAIN.SETTINGS.SETTINGS' | translate}}</div>

      <div class="actions">
        <button type="submit" class="btn btn-primary"> {{ 'COMMON.SAVE' | translate}} </button>
      </div>
    </div>

    <div class="content">
      <ng-container *ngIf="settings">
        <div class="row">
          <div class="col-12 col-sm-5 col-lg-3">
            <div class="form-group">
              <div class="mb-1">{{ 'VIEW.MAIN.SETTINGS.NOTIFICATION' | translate }}</div>
              <div class="custom-control custom-switch primary">
                <input type="checkbox" class="custom-control-input"
                       id="emailNotificationEnabled"
                       (change)="onEmailNotificationChanged($event)"
                       [checked]="settings.emailNotificationEnabled">
                <label class="custom-control-label"
                       for="emailNotificationEnabled">{{'VIEW.MAIN.SETTINGS.EMAIL' | translate}}</label>
              </div>

              <div class="custom-control custom-switch primary mt-1">
                <input type="checkbox" class="custom-control-input"
                       id="smsNotificationEnabled"
                       (change)="onSmsNotificationChanged($event)"
                       [checked]="settings.smsNotificationEnabled">
                <label class="custom-control-label"
                       for="smsNotificationEnabled">{{'VIEW.MAIN.SETTINGS.SMS' | translate}}</label>
              </div>
            </div>

            <div class="language form-group mt-4">
              <label for="language">{{ 'VIEW.MAIN.SETTINGS.LANGUAGE' | translate }}</label>
              <ng-select id="language" name="language"
                         class="sm"
                         [placeholder]="'COMMON.CHOOSE' | translate"
                         [items]="languageOptions"
                         [(ngModel)]="settings.locale"
                         [searchable]="false"
                         [clearable]="false">
                <ng-template ng-label-tmp
                             let-item="item">{{item | uppercase | prefix: 'ENUM.LANGUAGE.' | translate}}</ng-template>
                <ng-template ng-option-tmp
                             let-item="item">{{item | uppercase | prefix: 'ENUM.LANGUAGE.' | translate}}</ng-template>
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <app-settings-global-logout></app-settings-global-logout>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="mb-2">{{'VIEW.MAIN.SETTINGS.DOCUMENTATION' | translate}}</div>

            <div>
              <a class="font-size-sm"
                 href="https://www.telehealth.de/datenschutzhinweise"
                 target="_blank">{{ 'VIEW.MAIN.SETTINGS.PRIVACY' | translate }} </a>
            </div>

            <div class="mt-2">
              <a class="font-size-sm"
                 href="https://www.telehealth.de/nutzungsbedingungen-aerzte"
                 target="_blank">{{ 'VIEW.MAIN.SETTINGS.TERMS' | translate }} </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</form>
