import { Component, OnDestroy } from '@angular/core';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { StateUtil } from '@util/StateUtil';
import { DoctorModel } from '@model/DoctorModel';
import { State } from '@common/State';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-doctor-practice',
  templateUrl: './DoctorPracticeComponent.html',
  styleUrls: [ './DoctorPracticeComponent.scss' ]
})
export class DoctorPracticeComponent implements OnDestroy {
  public practice: PracticeDTO | null;

  public viewReady: boolean = false;

  public subscription: Subscription = new Subscription();

  constructor(public doctorModel: DoctorModel,
              public modalService: BsModalService,
              private stateUtil: StateUtil) {
    this.subscription.add(
      this.doctorModel.practice$
        .subscribe(
          (practice: PracticeDTO) => {
            this.practice = practice;
            this.viewReady = true;
          },
          () => {
            this.practice = null;
            this.viewReady = true;
          })
    );
  }

  public delete(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.DELETE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.subscribe(() => this.doctorModel.deletePractice());
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.DOCTOR.EDIT.PRACTICE, { doctorId: this.doctorModel.id });
  }

  public create(): void {
    this.stateUtil.goToState(State.MAIN.DOCTOR.CREATE.PRACTICE, { doctorId: this.doctorModel.id });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
