import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationConfig } from 'app/config/ApplicationConfig';
import { ObjectUtil } from 'app/util/ObjectUtil';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserRegistrationDTO } from 'app/data/dto/user/UserRegistrationDTO';
import { UserRegistrationStatusDTO } from '@dto/user/UserRegistrationStatusDTO';

@Injectable({ providedIn: 'root' })
export class UserRegistrationService {

  constructor(private http: HttpClient) {
  }

  public register(userRegistration: UserRegistrationDTO): Observable<UserRegistrationDTO> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/doctors/registration`, ObjectUtil.classToPlain(userRegistration))
      .pipe(
        map((response: any) => {
          const registeredUser: UserRegistrationDTO = ObjectUtil.plainToClass(UserRegistrationDTO, response);
          return registeredUser;
        })
      );
  }

  public getRegistrationStatus(): Observable<UserRegistrationStatusDTO> {
    return this.http.get<UserRegistrationStatusDTO>(`${ ApplicationConfig.apiUrl }/doctors/registration/status`)
      .pipe(
        map((response: UserRegistrationStatusDTO) => {
          const status: UserRegistrationStatusDTO = ObjectUtil.plainToClass(UserRegistrationStatusDTO, response);
          return status;
        })
      );
  }

  public sendForVerification(): Observable<any> {
    return this.http.post(`${ ApplicationConfig.apiUrl }/doctors/registration/verification`, {});
  }

}
