import { InsuranceType } from '@enum/doctor/InsuranceType';
import { EventStatus } from '@enum/event/EventStatus';
import { Transform } from 'class-transformer';
import { DateUtil } from '@util/DateUtil';
import { PatientInsuranceType } from '@enum/patient/PatientInsuranceType';

export class EventPageEntityDTO {
  public eventId: number;

  public connectionRating: number;

  public insuranceType: PatientInsuranceType;

  public patientFirstname: string;

  public patientLastname: string;

  public doctorFirstname: string;

  public doctorLastname: string;

  public doctorRating: number;

  @Transform(DateUtil.dateTimeConversion)
  public dateTimeFrom: Date;

  public status: EventStatus | string;
}