import { Gender } from '@enum/common/Gender';

export class DoctorAvatarHelper {
  public static getUrl(url: string, gender: Gender = Gender.MALE): string {
    if (!url) {
      switch (gender) {
        case Gender.MALE:
          return 'asset/image/icon/patient/patient-male.svg';
        case Gender.FEMALE:
          return 'asset/image/icon/patient/patient-female.svg';
      }
    }

    return url;
  }
}