<form [formGroup]="form" novalidate (ngSubmit)="save()" #ngForm="ngForm" class="on-submit-verification">
  <section class="page prescription-details">
    <div class="header-container separator">
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{ 'VIEW.MAIN.PRESCRIPTION.REQUEST.TITLE' | translate }}</div>
      </button>
      <div class="actions">
        <button *ngIf="!isDone"
                type="submit"
                class="btn btn-primary">{{ 'VIEW.MAIN.PRESCRIPTION.REQUEST.ACTION.CONFIRMATION' | translate}} </button>

      </div>
    </div>

    <div class="content">
      <ng-container *ngIf="prescriptionRefill">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.PATIENT' | translate}}</div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.FIRST_NAME' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.firstName | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.LAST_NAME' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.lastName | replaceEmpty }}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.EMAIL' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.email | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.PHONE' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.phoneNumber | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.GENDER' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.gender | prefix: 'ENUM.PATIENT_GENDER.' | translate}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.PREGNANT' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{prescriptionRefill?.patient?.pregnant | prefix: 'ENUM.PREGNANT_STATUS.' | translate}}</div>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-2 mt-md-0">
            <div
              class="font-weight-bold mb-4">{{'VIEW.MAIN.PRESCRIPTION.REQUEST.MESSAGE_FROM_PATIENT' | translate}}</div>

            <div>{{prescriptionRefill?.additionalInformation | replaceEmpty}}</div>

            <div class="mt-4">
              <div class="errors-header">
                <div class="mb-4 color-danger">Please confirm or decline all requested medications.</div>
              </div>

              <div class="request request-header font-weight-bold p-2">
                <div class="details">{{'VIEW.MAIN.PRESCRIPTION.REQUEST.REQUEST' | translate}}</div>
                <div class="d-flex justify-content-around flex-grow-1 align-items-center actions">
                  <div>{{'VIEW.MAIN.PRESCRIPTION.REQUEST.ACTION.DECLINE' | translate}}</div>
                  <div>{{'VIEW.MAIN.PRESCRIPTION.REQUEST.ACTION.CONFIRM' | translate}}</div>
                  <div *ngIf="!isDone">{{'VIEW.MAIN.PRESCRIPTION.REQUEST.ACTION.EDIT' | translate}}</div>
                </div>
              </div>

              <div class="request request-header request-header--background p-2">
                <div
                  class="details font-weight-normal">{{'VIEW.MAIN.PRESCRIPTION.REQUEST.ALL_MEDICATIONS' | translate}}</div>
                <div class="d-flex justify-content-around flex-grow-1 align-items-center actions">
                  <div class="custom-control custom-radio primary">
                    <input type="radio" class="custom-control-input"
                           id="declineAll"
                           [value]="PrescriptionStatus.DECLINED"
                           (change)="changeAllStatus(PrescriptionStatus.DECLINED)"
                           formControlName="status">
                    <label class="custom-control-label"
                           for="declineAll"></label>
                  </div>

                  <div class="custom-control custom-radio primary">
                    <input type="radio" class="custom-control-input"
                           id="confirmAll"
                           [value]="PrescriptionStatus.CONFIRMED"
                           (change)="changeAllStatus(PrescriptionStatus.CONFIRMED)"
                           formControlName="status">
                    <label class="custom-control-label"
                           for="confirmAll"></label>
                  </div>
                  <div *ngIf="!isDone"></div>
                </div>
              </div>

              <ng-container formArrayName="requests">
                <ng-container *ngFor="let requestFrom of requests.controls; let i = index">
                  <div class="request flex-column p-2 mt-2" [formGroupName]="i">
                    <div class="d-flex w-100">
                      <div class="d-flex flex-column details">
                        <ng-container *ngIf="requests.controls[i].value as medicament">
                          <span>{{medicament.medicamentName | replaceEmpty}}</span>
                          <span>{{medicament.medicamentGrammage | replaceEmpty}}</span>
                          <span>{{medicament.doze | replaceEmpty}}</span>
                        </ng-container>

                      </div>
                      <div class="d-flex justify-content-around flex-grow-1 align-items-center actions">
                        <!--                  Declined-->
                        <div class="custom-control custom-radio primary">
                          <input type="radio" class="custom-control-input"
                                 id="declined_{{i}}"
                                 [value]="PrescriptionStatus.DECLINED"
                                 formControlName="status">
                          <label class="custom-control-label"
                                 for="declined_{{i}}"></label>
                        </div>

                        <!--                  Confirmed-->
                        <div class="custom-control custom-radio primary">
                          <input type="radio" class="custom-control-input"
                                 id="confirmed_{{i}}"
                                 [value]="PrescriptionStatus.CONFIRMED"
                                 formControlName="status">
                          <label class="custom-control-label"
                                 for="confirmed_{{i}}"></label>
                        </div>

                        <!--                  Edit-->
                        <div *ngIf="!isDone">
                          <button type="button" class="btn btn-link btn-link--black"
                                  (click)="editRequest(prescriptionRefill.requests[i], i)">
                            <i-tabler class="i-lg" name="pencil"></i-tabler>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex flex-column w-100 mt-3">
                      <label for="justification"
                             class="font-size-sm">{{'VIEW.MAIN.PRESCRIPTION.REQUEST.EXPLANATION' | translate}}</label>
                      <textarea id="justification"
                                rows="2"
                                formControlName="justification"
                                class="form-control form-control-sm"></textarea>

                      <div class="invalid-feedback" *ngIf="requestFrom.get('justification').errors as errors">
                        <div
                          *ngIf="errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: errors['maxlength'].requiredLength} }}</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
</form>
