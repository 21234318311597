<form #form="ngForm" novalidate>
  <div class="row">
    <div class="col-12 col-sm-4">

      <!--      First name-->
      <div class="form-group">
        <label for="firstName">{{ 'VIEW.MAIN.INVITE.DATA.FIRST_NAME' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="firstName" id="firstName"
               [ngClass]="{'is-invalid': form.submitted && firstName.invalid }" #firstName="ngModel"
               [(ngModel)]="data.firstName"
               required/>

        <div class="invalid-feedback" *ngIf="form.submitted && firstName.invalid">
          <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <!--      Last name-->
      <div class="form-group">
        <label for="lastName">{{ 'VIEW.MAIN.INVITE.DATA.LAST_NAME' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="lastName" id="lastName"
               [ngClass]="{'is-invalid': form.submitted && lastName.invalid }" #lastName="ngModel"
               [(ngModel)]="data.lastName"
               required/>

        <div class="invalid-feedback" *ngIf="form.submitted && lastName.invalid">
          <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <!--      Email-->
      <div class="form-group">
        <label for="email">{{ 'VIEW.MAIN.INVITE.DATA.EMAIL' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="email" id="email"
               [ngClass]="{'is-invalid': form.submitted && email.invalid }" #email="ngModel"
               [(ngModel)]="data.email" required ngvemail/>

        <div class="invalid-feedback" *ngIf="form.submitted && email.invalid">
          <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</form>

<!--Portal-->
<app-page-title-portal name="invite">
  <div class="header">{{'VIEW.MAIN.INVITE.PATIENT.TITLE' | translate}}</div>
</app-page-title-portal>

<app-page-action-portal name="invite">
  <button class="btn btn-primary" type="button"
          (click)="invite()">{{'VIEW.MAIN.INVITE.PATIENT.ACTION.INVITE' | translate}}</button>
</app-page-action-portal>