import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export const maxLengthValidator = (value: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    const length: number = control.value ? control.value.length : 0;
    return length > value ? { maxLength: { requiredLength: value, actualLength: length } } : null;
  };
};

// this is a more relaxed maxLength validator, since Angular's default aligns with HTML property (maxLength/maxlength)
// and limits html input directly (cannot type in more than specified maxlength)

@Directive({
  selector: '[appMaxLength][formControlName],[appMaxLength][formControl],[appMaxLength][ngModel]',
  providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: MaxLengthValidator,
        multi: true
      }
    ]
})
export class MaxLengthValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appMaxLength: number;

  private validator: ValidatorFn;

  private onChange: () => void;

  public ngOnInit():void {
    this.validator = maxLengthValidator(this.appMaxLength);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appMaxlength') {
        this.validator = maxLengthValidator(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appMaxLength !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}

