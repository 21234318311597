import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { ApplicationModel } from '@model/ApplicationModel';
import { ViewUtil } from '@util/ViewUtil';
import { StateUtil } from '@util/StateUtil';
import { State } from '@common/State';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-profile-practice-create',
  templateUrl: './ProfilePracticeCreateComponent.html',
  styleUrls: [ './ProfilePracticeCreateComponent.scss' ]
})
export class ProfilePracticeCreateComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public practice: PracticeDTO = plainToClass(PracticeDTO, {});

  constructor(private currentDoctorService: CurrentDoctorService,
              private applicationModel: ApplicationModel,
              private viewUtil: ViewUtil,
              private stateUtil: StateUtil) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.PROFILE.BASIC_DATA);
  }

  public ngOnInit(): void {
  }

  public save(): void {
    if (this.form.valid) {
      this.currentDoctorService.createPractice(this.practice).toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.CREATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public back(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE.PRACTICE);
  }
}
