<div id="layout">
  <div class="container-fluid px-0 position-relative">
    <div class="bg-white">
      <div class="container px-0">
        <app-header [showSidebar]="false"></app-header>
      </div>
    </div>
  </div>

  <div class="container px-0">
    <div class="content d-flex">
      <main>
        <ng-container *ngIf="simpleInfo$ | async as simpleInfo">
          <div class="info-panel mb-3">
            <div class="d-flex justify-content-between flex-column flex-sm-row">
              <div class="font-size-lg">{{simpleInfo?.patient?.firstName}} {{simpleInfo?.patient?.lastName}}</div>
              <div class="font-size-lg" *ngIf="onMeeting">{{'VIEW.VIDEO_CALL.VISIT_DURATION' | translate}}:
                <span [appTimeLeft]="simpleInfo.dateTime" [duration]="simpleInfo.duration"></span>
              </div>
            </div>

            <div
              class="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row mt-2">
              <div>{{simpleInfo?.visitTypes | collectionJoinPipe:'name'}}</div>

              <div class="tab-container mt-2 mt-md-0">
                <app-tab [tabs]="tabs" type="pills"></app-tab>
              </div>
            </div>

          </div>


          <div class="row">
            <div class="col">
              <div class="video-card">
                <app-video-call
                  [identifier]="eventId"
                  (joinedToRoom)="joinedToRoom($event)">
                </app-video-call>
              </div>
            </div>

            <div class="page-vew col-12 col-md-6 mt-2 mt-md-0" [class.is-hidden]="isMainState()">
              <div class="page-view-card">
                <ui-view></ui-view>
              </div>
            </div>
          </div>
        </ng-container>
      </main>
    </div>
  </div>
</div>




