<p> {{'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.TIME_ZONE' | translate:{offset: europeTimeZoneOffset} }} </p>

<div class="row">
  <div class="col-12 col-md-5">
    <div *ngFor="let day of data; index as dayIndex; let last = last"
         class="d-flex flex-column working-hours-container">

      <div class="d-flex flex-row justify-content-between align-items-center mb-2 w-100">
        <div class="font-weight-bold"> {{ day.dayOfWeek | prefix: 'ENUM.DAY_OF_WEEK.' | translate }} </div>

        <button class="btn btn-outline-primary btn-sm"
                type="button"
                [disabled]="isEditing"
                (click)="addWorkingHours(day)">
          <div class="d-flex justify-content-center align-items-center">
            <i-tabler name="plus" class="d-flex i-sm"></i-tabler>
            <div class="ml-1">{{  'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.ADD_HOURS' | translate}}</div>
          </div>
        </button>
      </div>

      <div class="d-flex w-100 flex-column">
        <ng-container *ngIf="day.hours.length === 0">
          <p>{{'VIEW.MAIN.WORKDAYS_SETTING.WORKING_HOURS.NOT_AVAILABLE' | translate }}</p>
        </ng-container>

        <ng-container *ngIf="day.hours.length > 0">
          <div class="d-flex flex-row justify-content-between align-items-center w-100"
               *ngFor="let hourRange of day.hours;let hourIndex = index">
            <div class="d-flex mb-2 w-100">
              <ng-container *ngIf="hourRange.isEditing">
                <ng-container [ngTemplateOutlet]="timeEditingTemplate"
                              [ngTemplateOutletContext]="{ $implicit: hourRange, hourIndex: hourIndex, day: day}"></ng-container>
              </ng-container>

              <ng-container *ngIf="!hourRange.isEditing">
                <ng-container [ngTemplateOutlet]="timeReadOnlyTemplate"
                              [ngTemplateOutletContext]="{ $implicit: hourRange,hourIndex: hourIndex, day: day}"></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!--Templates-->

<ng-template #timeReadOnlyTemplate let-item let-day="day" let-hourIndex="hourIndex">
  <div class="d-flex justify-content-between w-100">
    <div>
      <span>{{item.timeFrom | toDate | date: 'HH:mm'}}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{{item.timeTo | toDate | date: 'HH:mm'}}</span>
    </div>

    <div>
      <button type="button"
              class="btn btn-link btn-link--black btn-sm mr-1"
              [disabled]="isEditing"
              (click)="edit(item)">
        <i-tabler name="pencil"></i-tabler>
      </button>

      <button type="button" class="btn btn-link btn-link--black btn-sm"
              [disabled]="isEditing"
              (click)="remove(item, day, hourIndex)">
        <i-tabler name="trash"></i-tabler>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #timeEditingTemplate let-item let-day="day" let-hourIndex="hourIndex">
  <form #form="ngForm" novalidate class="w-100" (ngSubmit)="save(form, item, day)">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex justify-content-center">
        <div class="d-flex flex-column mr-1">
          <input type="time"
                 name="timeFrom"
                 class="form-control form-control-sm"
                 [ngClass]="{'is-invalid': form.submitted && timeFrom.invalid }" #timeFrom="ngModel"
                 [(ngModel)]="item.timeFrom" required>
          <div class="invalid-feedback" *ngIf="form.submitted && timeFrom.invalid">
            <div *ngIf="timeFrom.errors['required']">{{ 'VALIDATION.TIME_REQUIRED' | translate }}</div>
          </div>
        </div>

        <div class="d-flex align-self-baseline pt-1">
          <span>-</span>
        </div>

        <div class="d-flex flex-column ml-1">
          <input type="time"
                 name="timeTo"
                 [ngClass]="{'is-invalid': form.submitted && timeTo.invalid }" #timeTo="ngModel"
                 class="form-control form-control-sm "
                 [(ngModel)]="item.timeTo" required>
          <div class="invalid-feedback" *ngIf="form.submitted && timeTo.invalid">
            <div *ngIf="timeTo.errors['required']">{{ 'VALIDATION.TIME_REQUIRED' | translate }}</div>
          </div>
        </div>
      </div>

      <div class="d-block actions">
        <button type="submit" class="btn btn-primary btn-sm mr-2">{{'COMMON.SAVE' | translate}}</button>
        <button type="button" class="btn btn-link btn-link--black btn-sm"
                (click)="remove(item, day, hourIndex)">
          <i-tabler name="trash"></i-tabler>
        </button>
      </div>
    </div>
  </form>
</ng-template>


