import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoStorage
} from 'amazon-cognito-identity-js';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthLoginResult, AuthServiceInterface } from './interface/AuthServiceInterface';
import { Token } from '@local/auth/Token';
import { SecurityDataDTO } from '@dto/auth/SecurityDataDTO';
import { CognitoTokenDTO } from '@dto/auth/CognitoTokenDTO';
import { ObjectUtil } from '@util/ObjectUtil';
import { ServerErrorDTO } from '@dto/ServerErrorDTO';
import { ServerErrorCode } from '@enum/ServerErrorCode';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { forEach, has, keys, zipObject } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { catchError, mergeMap, tap } from 'rxjs/operators';

export type AttributeData = { [key: string]: any };

@Injectable({ providedIn: 'root' })
export class CognitoAuthService implements AuthServiceInterface {

  private cognitoUser: CognitoUser;

  private cognitoUserAttributes: AttributeData;

  private cognitoUserRequiredAttributeNames: string[];

  private rememberLogin: boolean = false;

  constructor(private http: HttpClient) {
  }

  public setRememberLogin(remember: boolean): Observable<void> {
    this.rememberLogin = remember;
    return of();
  }

  public login(email: string, password: string): Observable<AuthLoginResult> {
    const authenticationDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    let cognitoUser: CognitoUser = this.getCognitoUser(email);

    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

    return new Observable<AuthLoginResult>((observer) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session: CognitoUserSession) => {
          this.cognitoUser = cognitoUser;
          const token: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

          observer.next({ token: token, securityData: undefined });
          observer.complete();
        },
        newPasswordRequired: (userAttributes: AttributeData, requiredAttributes: string[]) => {
          this.cognitoUser = cognitoUser;
          this.cognitoUserAttributes = userAttributes;
          this.cognitoUserRequiredAttributeNames = requiredAttributes;

          let securityData: SecurityDataDTO = new SecurityDataDTO();
          securityData.requireNewPassword = true;

          observer.next({ token: null, securityData: securityData });
          observer.complete();
        },
        onFailure: (error: any) => {
          this.cognitoUser = null;
          this.cognitoUserAttributes = null;
          this.cognitoUserRequiredAttributeNames = null;

          if (error instanceof Error && (error.name === 'NotAuthorizedException')) {
            observer.error({
              error: ObjectUtil.plainToClass(ServerErrorDTO, {
                errorCode: ServerErrorCode.BAD_CREDENTIALS
              })
            });
          } else {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
            observer.complete();
          }
        },
        customChallenge: () => {
          this.cognitoUser = cognitoUser;

          observer.next({ token: null, securityData: null, verificationCodeRequired: true });
          observer.complete();
        }
      });
    });
  }

  public sendCustomChallengeAnswer(answer: string): Observable<AuthLoginResult> {
    return new Observable<AuthLoginResult>((observer) => {
      this.cognitoUser.sendCustomChallengeAnswer(answer, {
        onSuccess: (session: CognitoUserSession) => {
          const token: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);
          observer.next({ token: token, securityData: undefined });
          observer.complete();
        },
        onFailure: (error) => {
          observer.error(error);
          observer.complete();
        },
        customChallenge: () => {
          observer.error();
          observer.complete();
        }
      });
    });
  }

  public globalLogout(): Observable<void> {
    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/auth/global-logout`, null)
      .pipe(
        mergeMap(() => this.cognitoSignOut()),
        tap(() => {
          this.cognitoUser = null;
          this.cognitoUserAttributes = null;
          this.cognitoUserRequiredAttributeNames = null;
        })
      );
  }

  public logout(): Observable<void> {
    const refreshToken = this.cognitoUser.getSignInUserSession().getRefreshToken().getToken();
    const clientId = ApplicationConfig.userPoolWebClientId;

    return this.cognitoSignOut()
      .pipe(
        mergeMap(() => this.http.post<void>(`${ ApplicationConfig.apiUrl }/auth/logout`, {
          refreshToken,
          apiKeys: { clientId }
        })
          .pipe(
            catchError(() => EMPTY)
          )),
        tap(() => {
          this.cognitoUser = null;
          this.cognitoUserAttributes = null;
          this.cognitoUserRequiredAttributeNames = null;
        })
      );
  }

  public refresh(token: Token): Observable<AuthLoginResult> {
    return new Observable<AuthLoginResult>((observer) => {
      if (this.cognitoUser) {
        this.cognitoUser.refreshSession((token as CognitoTokenDTO).cognitoRefreshToken, (error: any, session: CognitoUserSession) => {
          if (!error) {
            const refreshedToken: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

            observer.next({ token: refreshedToken, securityData: undefined });
            observer.complete();
          } else {
            this.cognitoUser = null;
            this.cognitoUserAttributes = null;
            this.cognitoUserRequiredAttributeNames = null;

            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }

            observer.error(error);
          }
        });
      } else {
        this.cognitoUserAttributes = null;
        this.cognitoUserRequiredAttributeNames = null;

        observer.error();
      }
    });
  }

  public startPasswordReset(email: string): Observable<void> {
    let cognitoUser: CognitoUser = this.getCognitoUser(email);

    return new Observable<void>((observer) => {
      cognitoUser.forgotPassword({
        onSuccess: (data: any) => {
          observer.next();
          observer.complete();
        },
        inputVerificationCode: (data: any) => {
          observer.next();
          observer.complete();
        },
        onFailure: (error: any) => {
          if (error instanceof Error) {
            // for debugging
            console.error(`Cognito error: ${ error.name } - ${ error.message }`);
          }
          observer.error(error);
        }
      });
    });
  }

  public completePasswordReset(email: string, confirmationCode: string, password: string): Observable<void> {
    const clientId = ApplicationConfig.userPoolWebClientId;

    return this.http.post<void>(`${ ApplicationConfig.apiUrl }/auth/password-change-confirmation`, {
      password,
      confirmationCode,
      email,
      apiKeys: { clientId }
    });
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<void> {
    return new Observable<void>(observer => {
      if (this.cognitoUser) {
        this.cognitoUser.changePassword(currentPassword, newPassword, (error: any) => {
          if (!error) {
            observer.next();
            observer.complete();
          } else {
            if (error instanceof Error && (error.name === 'NotAuthorizedException')) {
              observer.error({
                error: ObjectUtil.plainToClass(ServerErrorDTO, {
                  errorCode: ServerErrorCode.INVALID_PASSWORD
                })
              });
            } else {
              if (error instanceof Error) {
                // for debugging
                console.error(`Cognito error: ${ error.name } - ${ error.message }`);
              }
              observer.error(error);
            }
          }
        });
      } else {
        observer.error();
      }
    });
  }

  public changePasswordForced(newPassword: string): Observable<void> {
    return new Observable<void>(observer => {
      if (this.cognitoUser) {
        // it copies required attributes from this.cognitoUserAttributes, but what if some are missing?
        // (is it even possible?) seems like every time cognitoUserRequiredAttributeNames is empty anyway...
        let requiredAttributeData: AttributeData = zipObject(this.cognitoUserRequiredAttributeNames);
        forEach(keys(requiredAttributeData), (key: string) => {
          if (has(this.cognitoUserAttributes, key)) {
            requiredAttributeData[key] = this.cognitoUserAttributes[key];
          }
        });

        this.cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributeData, {
          onSuccess: (session: CognitoUserSession, userConfirmationNecessary: boolean) => {
            this.cognitoUserAttributes = null;
            this.cognitoUserRequiredAttributeNames = null;
            observer.next();
            observer.complete();
          },
          onFailure: (error: any) => {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
          }
        });
      } else {
        observer.error();
      }
    });
  }

  public setToken(token: Token): Observable<Token> {
    // not needed, Cognito manages tokens automatically, using own storage
    return of(token);
  }

  public getToken(): Observable<Token> {
    return new Observable<Token>((observer) => {
      const userPool: CognitoUserPool = this.getPool();
      const cognitoUser: CognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((error: any, session: CognitoUserSession) => {
          if (error) {
            if (error instanceof Error) {
              // for debugging
              console.error(`Cognito error: ${ error.name } - ${ error.message }`);
            }
            observer.error(error);
          } else {
            this.cognitoUser = cognitoUser;

            let token: CognitoTokenDTO = CognitoTokenDTO.fromCognitoSession(session);

            observer.next(token);
            observer.complete();
          }
        });
      } else {
        observer.error();
      }
    });
  }

  public deleteToken(): Observable<void> {
    // not needed, Cognito manages tokens automatically, using own storage
    return of();
  }

  // --

  private getCognitoUser(email: string): CognitoUser {
    return new CognitoUser({
      Username: email,
      Pool: this.getPool(),
      Storage: this.getStorage()
    });
  }

  private cognitoSignOut(): Observable<void> {
    return new Observable((subscriber) => {
      this.cognitoUser.signOut(() => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private getPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: ApplicationConfig.userPoolId,
      ClientId: ApplicationConfig.userPoolWebClientId,
      Storage: this.getStorage()
    });
  }

  private getStorage(): ICognitoStorage {
    return this.rememberLogin ? localStorage : sessionStorage;
  }
}