import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'imageName' })
export class ImageNamePipe implements PipeTransform {

  constructor() {
  }

  public transform(value: string): string {
    if (value !== "") {
      let name = value.substring(0,value.indexOf('?'));
      const words = name.split('/');
      return words[words.length-1];
    }
    else
     return "";
  }
}