import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'replaceEmpty' })
export class ReplaceEmptyPipe implements PipeTransform {

  public transform(value: any, replacement: string = '-'): any {
    if ((value === undefined) || (value === null) || (value === '')) {
      return replacement;
    }
    else {
      return value;
    }
  }
}
