import { Component, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { Constant } from '@common/Constant';
import { ViewUtil } from '@util/ViewUtil';
import { IInsuranceService, INSURANCE_SERVICE } from '@local/interface/IInsuranceService';

@Component({
  selector: 'app-popup-report-problem',
  templateUrl: './PatientInsuranceReportProblemPopupComponent.html',
  styleUrls: [ './PatientInsuranceReportProblemPopupComponent.scss' ]
})
export class PatientInsuranceReportProblemPopupComponent extends BaseModalComponent<boolean> {
  public isProcessing: boolean = false;

  public reportText: string;

  public id: number;

  public Constant: typeof Constant = Constant;

  constructor(public bsModalRef: BsModalRef,
              private viewUtil: ViewUtil,
              @Inject(INSURANCE_SERVICE) private insuranceService: IInsuranceService) {
    super();
  }

  public send(): void {
    this.isProcessing = true;

    this.insuranceService.reportInsuranceProblem(this.id, this.reportText).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.PATIENT.INSURANCE.REPORT_PROBLEM.MESSAGE.REPORTED_SUCCESS');
        this.ok(true);
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
