<div class="waiting-room">
  <div class="text-center mb-3">{{ 'VIEW.VIDEO_CALL.WAITING_ROOM.TITLE' | translate }}</div>

  <div class="preview-container mb-3">
    <div class="microphone-indicator" *ngIf="videoCallStorage.audioLocalTrack$ | async as audio">
      <app-microphone-volume-indicator [audio]="audio"></app-microphone-volume-indicator>
    </div>

    <div class="preview">
      <div class="avatar-container">
        <div class="avatar">
          <img [src]="videoCallStorage?.me?.avatarUrl" alt="Avatar"/>
        </div>
      </div>
      <div id="video-container" #cameraPreview></div>
    </div>

    <div class="settings-container">
      <app-video-call-setting></app-video-call-setting>
    </div>
  </div>

  <div class="d-flex flex-column actions">
    <button type="button" class="btn btn-primary"
            [disabled]="!videoCallStorage.availableAudioDevice || !videoCallStorage.canJoin"
            (click)="startVideoCall()">{{'VIEW.VIDEO_CALL.WAITING_ROOM.ACTION.JOIN' | translate}}</button>
    <button type="button"
            class="btn btn-outline-primary mt-2"
            (click)="back()">{{'VIEW.VIDEO_CALL.WAITING_ROOM.ACTION.BACK' | translate}}</button>
  </div>

  <div class="participant mt-2" *ngIf="videoCallStorage.participantIsWaiting">
    <div class="avatar">
      <img [src]="videoCallStorage.participant.avatarUrl"/>
    </div>

    <div class="d-flex flex-column ml-2">
      <div
        class="font-weight-bold">{{videoCallStorage.participant.firstName}} {{videoCallStorage.participant.lastName}}</div>
      <div>{{'VIEW.VIDEO_CALL.WAITING_ROOM.PATIENT_IS_WAITING' | translate}}</div>
    </div>
  </div>
</div>

