import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileUtil } from '@util/FileUtil';
import { AvatarCropperPopupComponent } from 'app/component/ui/avatar/AvatarCropperPopupComponent';
import { AvatarManagerService } from '@service/AvatarManagerService';
import { AvatarUrl } from '@local/interface/AvatarUrl';

@Component({
  selector: 'app-avatar-manager',
  templateUrl: './AvatarManagerComponent.html',
  styleUrls: [ './AvatarManagerComponent.scss' ]
})
export class AvatarManagerComponent implements OnInit {
  @Input() public data: AvatarUrl;

  @Output() public uploadEvent: EventEmitter<Blob> = new EventEmitter<Blob>();

  @Output() public deleteEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private modalService: BsModalService,
              private fileUtil: FileUtil,
              private avatarManagerService: AvatarManagerService,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
  }

  public uploadAvatar(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files.length > 0) {
      const file = files[0];
      this.upload(file);
    }

    target.value = null;
  }

  public edit(): void {
    this.avatarManagerService.getAvatar(this.data).toPromise()
      .then((blob: Blob) => {
        const file: File = this.fileUtil.blobToFile(blob, 'avatar.jpg');
        this.upload(file);
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private upload(file: File): void {
    this.modalService.show(AvatarCropperPopupComponent, {
      initialState: {
        image: file
      }
    }).content.close$.toPromise()
      .then((image: Blob) => this.uploadEvent.emit(image));
  }

}
