import { EventSlot } from '@local/scheduler/EventSlot';
import { EventsSummaryDTO } from '@dto/events/EventsSummaryDTO';
import moment from 'moment';
import { FreeTimeSlotDTO } from '@dto/events/FreeTimeSlotDTO';
import { EventsTimetableDTO } from '@dto/events/EventsTimetableDTO';
import { SlotStatus } from '@local/scheduler/SlotStatus';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import { DayOfWeek } from '@enum/common/DayOfWeek';
import * as _ from 'lodash';
import { EventStatus } from '@enum/event/EventStatus';

export class EventSlotMapper {
  public static eventSlotsToList(startDate: Date, slots: EventSlot[]): EventSlotList[] {
    let result: EventSlotList[] = [];

    const days = Object.keys(DayOfWeek).map(key => DayOfWeek[key]).filter(value => typeof value === 'string') as string[];

    for (let i = 0; i < days.length; i++) {
      const eventSlotList = new EventSlotList;

      eventSlotList.date = moment(startDate).add(i, 'days').toDate();
      eventSlotList.slots = [];
      eventSlotList.slots = _.filter(slots, (item: EventSlot) => moment(item.startDate).weekday() === i);

      eventSlotList.slots = _.orderBy(eventSlotList.slots, ((item: EventSlot) => {
        return item.startDate.getTime();
      }), [ 'asc' ]);

      result.push(eventSlotList);
    }

    return result;
  }

  public static timeTableToEventSlots(timetable: EventsTimetableDTO): EventSlot[] {
    const events = (timetable.events || []).map((event: EventsSummaryDTO) => this.eventFromTimetableToEventSlot(event));
    const freeTimeSlots = (timetable.freeTimeSlots || []).map((event: FreeTimeSlotDTO) => this.freeSlotTimetableToEventSlot(event));

    return [ ...events, ...freeTimeSlots ];
  }

  private static setTimeStatus(startDate: Date, endDate: Date, status: EventStatus = null): SlotStatus {
    const currentTime: Date = new Date();
    const startActive: Date = moment(startDate).add(-15, 'minutes').toDate();   // start display visits as a active 15 minutes before real start

    if (currentTime.getTime() > endDate.getTime() || status === EventStatus.PAST || status === EventStatus.CANCELLED) {
      return SlotStatus.PAST;
    } else if (currentTime.getTime() < startActive.getTime()) {
      return SlotStatus.FUTURE;
    } else if ((startActive.getTime() < currentTime.getTime()) && (endDate.getTime() > currentTime.getTime())) {
      return SlotStatus.NOW;
    } else {
      return null;
    }
  }

  public static eventFromTimetableToEventSlot(eventSummary: EventsSummaryDTO): EventSlot {
    const data = new EventSlot();

    data.eventId = eventSummary.id;
    data.startDate = eventSummary.dateTime;
    data.endDate = moment(eventSummary.dateTime).add(eventSummary.duration, 'minutes').toDate();
    data.duration = eventSummary.duration;
    data.isVideoCall = true;
    data.patientFirstName = eventSummary.patientFirstName;
    data.noShow = eventSummary.noShow;
    data.newPatient = eventSummary.newPatient;
    data.isUnregisteredPatient = eventSummary.isUnregisteredPatient;
    data.patientLastName = eventSummary.patientLastName;
    data.patientId = eventSummary.patientId;
    data.visitType = eventSummary.selectedVisitTypes;
    data.activeStatus = this.setTimeStatus(data.startDate, data.endDate, eventSummary.eventStatus);

    return data;
  }

  public static freeSlotTimetableToEventSlot(freeSlot: FreeTimeSlotDTO): EventSlot {
    const data = new EventSlot;

    data.startDate = freeSlot.dateFrom;
    data.endDate = freeSlot.dateTo;
    data.isVideoCall = false;
    data.eventId = -1;    // event not exsist
    data.duration = moment(freeSlot.dateFrom).diff(freeSlot.dateTo, 'minutes');
    data.activeStatus = this.setTimeStatus(freeSlot.dateFrom, freeSlot.dateTo);

    return data;
  }
}