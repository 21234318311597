import { DateUtil } from '@util/DateUtil';
import { Transform } from 'class-transformer';
import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { PrescriptionRefillStatus } from '@enum/prescription/PrescriptionRefillStatus';

export class PrescriptionRefillPageEntityDTO extends IdentifiableObjectDTO<number> {
  @Transform(DateUtil.dateTimeConversion)
  public createdDate: string;

  public patientEmail: string;

  public patientFirstName: string;

  public patientLastName: string;

  public patientPhone: string;

  public status: PrescriptionRefillStatus;
}