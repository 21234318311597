<header id="header">
  <div class="d-flex align-items-center px-2 h-100">
    <div class="d-md-none mr-2" *ngIf="showSidebar">
      <button type="button" class="btn btn-link sidebar-button p-0 border-0" (click)="toggle()" *ngIf="collapsed">
        <div class="d-flex justify-content-center align-items-center">
          <i-tabler name="menu-2"></i-tabler>
        </div>
      </button>

      <div class="sidebar-container" #sidebar cdkTrapFocus *ngIf="showSidebar">
        <button type="button" class="btn btn-link sidebar-button p-0 border-0" (click)="toggle()" *ngIf="!collapsed">
          <div class="d-flex justify-content-center align-items-center">
            <i-tabler name="x"></i-tabler>
          </div>
        </button>

        <div class="sidebar-position">
          <div class="sidebar" [collapse]="collapsed" [isAnimated]="true"
               (collapsed)="onCollapsed(true)"
               (expanded)="onCollapsed(false)">
            <div class="container px-0 pt-3">
              <div class="sidebar-scrollable-view pr-2">
                <app-sidebar (changedState)="onChangedState()"></app-sidebar>
              </div>

              <div class="d-flex align-items-center contact-data mx-2">
                <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center w-100 h-100">
      <a href="" type="button" class="btn btn-link p-0 ml-1 ml-md-2">
        <svg-icon src="asset/image/logo.svg"></svg-icon>
      </a>

      <div class="d-flex align-items-center">
        <a class="font-size-sm d-none d-md-inline mr-1" href="mailto:{{supportEmail}}">{{supportEmail}}</a>

        <div class="btn-group user-actions mx-1" dropdown>
          <button id="button-animated" dropdownToggle
                  type="button"
                  class="btn btn-dropdown d-flex align-items-center dropdown-toggle p-0"
                  aria-controls="dropdown-animated">
            <div class="text d-none d-md-inline-block">{{currentUserFullName}}</div>
            <span class="caret ml-1"></span>
            <div class="d-flex d-md-none">
              <i-tabler name="user"></i-tabler>
            </div>
          </button>

          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-2"
              role="menu" aria-labelledby="button-animated">
            <li role="menuitem" *ngIf="UserType.DOCTOR | isUserType">
              <a class="dropdown-item" (click)="myProfile()">{{ 'VIEW.MAIN.MAIN.MY_PROFILE' | translate }}</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="changePassword()">{{ 'VIEW.MAIN.MAIN.CHANGE_PASSWORD' | translate }}</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="logout()">{{ 'VIEW.MAIN.MAIN.LOGOUT' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</header>


