import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environment/environment';
import { ApplicationModule } from 'app/ApplicationModule';
import 'hammerjs';

import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en-GB';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDE, 'de-DE');
registerLocaleData(localeEN, 'en-GB');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(ApplicationModule)
  .catch(err => console.error(err));
