<div class="row">
  <div class="col-12 col-md-6">
    <button type="button" class="btn btn-primary" (click)="addNew()">
      <div class="d-flex align-items-center">
        <i-tabler name="plus" class="d-flex"></i-tabler>
        <div class="ml-1">{{ 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.ACTION.ADD' | translate }}</div>
      </div>
    </button>

    <div *ngFor="let vacation of vacations;let index = index"
         class="d-flex vacation-container mt-4">
      <form #form="ngForm" (ngSubmit)="save(vacation)" novalidate class="w-100">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                class="col-4 font-weight-bold">{{'VIEW.MAIN.WORKDAYS_SETTING.VACATION.DATA.DATE_FROM' | translate}}</div>
              <div
                class="col-4 font-weight-bold">{{'VIEW.MAIN.WORKDAYS_SETTING.VACATION.DATA.DATE_TO' | translate}}</div>
              <div class="col-4 text-right">
                <ng-container *ngIf="vacation.isEditing">
                  <button type="submit" class="btn btn-primary btn-sm mr-1"
                          [disabled]="form.invalid">{{'COMMON.SAVE' | translate}}</button>
                  <button type="button" class="btn btn-link btn-link--black btn-sm"
                          (click)="remove(vacation)">
                    <i-tabler name="trash"></i-tabler>
                  </button>
                </ng-container>

                <ng-container *ngIf="!vacation.isEditing">
                  <button type="button"
                          class="btn btn-link btn-link--black btn-sm mr-1"
                          (click)="vacation.isEditing = true">
                    <i-tabler name="pencil"></i-tabler>
                  </button>

                  <button type="button" class="btn btn-link btn-link--black btn-sm"
                          (click)="remove(vacation)">
                    <i-tabler name="trash"></i-tabler>
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="row">
              <div class="col-4">
                <ng-container *ngIf="vacation.isEditing">
                  <div>
                    <app-date-picker name="dateFrom" [(ngModel)]="vacation.dateFrom"
                                     [appMinDateTime]="nowDate"
                                     required></app-date-picker>
                  </div>
                  <div class="mt-1" *ngIf="!vacation.wholeDay">
                    <app-time-picker name="timeFrom" [(ngModel)]="vacation.dateFrom"
                    ></app-time-picker>
                  </div>
                </ng-container>

                <ng-container *ngIf="!vacation.isEditing">
                  <div>{{vacation?.dateFrom | date: Constant.DATE_FORMAT}}</div>
                  <div *ngIf="!vacation.wholeDay">{{vacation?.dateFrom | date: Constant.TIME_FORMAT}}</div>
                </ng-container>
              </div>
              <div class="col-4">
                <ng-container *ngIf="vacation.isEditing">
                  <div>
                    <app-date-picker name="dateTo" [(ngModel)]="vacation.dateTo"
                                     [appMinDateTime]="vacation.dateFrom"
                                     required></app-date-picker>
                  </div>

                  <div class="mt-1" *ngIf="!vacation.wholeDay">
                    <app-time-picker name="timeTo" [(ngModel)]="vacation.dateTo"></app-time-picker>
                  </div>
                </ng-container>

                <ng-container *ngIf="!vacation.isEditing">
                  <div>{{vacation?.dateTo | date: Constant.DATE_FORMAT}}</div>
                  <div *ngIf="!vacation.wholeDay">{{vacation?.dateTo | date: Constant.TIME_FORMAT}}</div>
                </ng-container>
              </div>

              <div class="col-12 mt-1" *ngIf="vacation.isEditing && form.invalid">
                <ng-container *ngIf="form?.control?.get('dateFrom') as dateFrom">
                  <div class="invalid-feedback" *ngIf="dateFrom.touched && dateFrom.invalid">
                    <div
                      *ngIf="dateFrom?.errors['minDateTime']">{{ 'VALIDATION.DATE_MIN' | translate: {dateString: nowDate.toDateString()} }}</div>
                    <div *ngIf="dateFrom?.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="form?.control?.get('dateTo') as dateTo">
                  <div class="invalid-feedback" *ngIf="dateTo.touched && dateTo.invalid">
                    <div
                      *ngIf="dateTo?.errors['minDateTime']">{{ 'VALIDATION.DATE_MIN' | translate: {dateString: vacation?.dateFrom?.toDateString()} }}</div>
                    <div *ngIf="dateTo?.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="col-12 mt-1" *ngIf="vacation.isEditing">
            <div class="custom-control custom-checkbox primary">
              <input type="checkbox" class="custom-control-input"
                     id="whole-day-{{index}}" name="wholeDay"
                     (change)="vacation.wholeDay = !vacation.wholeDay"
                     [checked]="vacation.wholeDay">
              <label class="custom-control-label color-black"
                     for="whole-day-{{index}}">{{ 'VIEW.MAIN.WORKDAYS_SETTING.VACATION.DATA.WHOLE_DAY' | translate }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
