import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'nl2br' })
export class Nl2BrPipe implements PipeTransform {

  public transform(value: string): any {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    else {
      return value;
    }
  }
}
