<ng-container *ngIf="doctorModel.doctor$ | async as doctor">
  <div class="container px-0">
    <div class="row mb-4">
      <div class="col-12">
        <div class="d-flex justify-content-center justify-content-sm-start">
          <app-avatar-preview [url]="doctor.avatarUrl"></app-avatar-preview>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="font-weight-bold mb-4">{{'VIEW.MAIN.DOCTOR.BASIC_DATA.PERSONAL_INFO' | translate}}</div>

        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group" *ngIf="!doctorModel.needVerification">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.STATUS' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">
                <span
                  *ngIf="doctor.status; else noDataTemplate">{{doctor?.status | prefix: 'ENUM.DOCTOR_STATUS.' | translate }}</span>
              </div>
            </div>

            <div class="form-group" *ngIf="!doctorModel.needVerification">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.CUSTOMER_ID' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{doctor?.customerId | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.TITLE' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{doctor?.title | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.FIRST_NAME' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{doctor?.firstName | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.LAST_NAME' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{doctor?.lastName | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.GENDER' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">
                <span
                  *ngIf="doctor.gender; else noDataTemplate">{{doctor?.gender | prefix: 'ENUM.GENDER.' | translate}}</span>
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.DATE_APPLIED' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.dateApplied | date:Constant.DATE_FORMAT | replaceEmpty}}</div>
            </div>

            <div class="form-group" *ngIf="!doctorModel.needVerification">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.JOIN_DATE' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.joinDate | date:Constant.DATE_FORMAT | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.EMAIL' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.email | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.PHONE_NUMBER' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.phoneNumber | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.ADDRESS' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">
                <div>{{doctor?.address?.streetWithNumber}}</div>
                <div>{{ doctor?.address?.cityCode }} {{ doctor?.address?.city }}</div>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group" *ngIf="!doctorModel.needVerification">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.RATING' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">
                <span *ngIf="doctor.rating; else noDataTemplate">{{doctor?.rating | number : '1.1'}}</span>
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.SPECIALITY' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.speciality?.name}}</div>
            </div>

            <div class="form-group" *ngIf="!doctorModel.needVerification">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BIO' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.bio | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BUSINESS_OWNER' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.businessOwner | boolean | translate }}</div>
            </div>

            <div class="form-group" *ngIf="doctor?.businessOwner">
              <label>{{ 'VIEW.MAIN.DOCTOR.DATA.UST_ID' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{doctor?.ustId | uppercase | replaceEmpty}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 mt-2 mt-md-0" *ngIf="!doctorModel.needVerification">
        <div class="font-weight-bold mb-4">{{ 'VIEW.MAIN.DOCTOR.BASIC_DATA.BILLING_INFO' | translate }}</div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BILLING_NAME' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{doctor?.billingName | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BIC' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{doctor?.bic | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.DATA.IBAN' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{doctor?.iban | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <label>{{ 'VIEW.MAIN.DOCTOR.DATA.BILLING_EMAIL' | translate }}</label>
          <div
            class="form-control-plaintext form-control-sm">{{doctor?.billingEmail | replaceEmpty}}</div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox primary">
            <input type="checkbox" class="custom-control-input"
                   id="paymentAcceptation" name="paymentAcceptation"
                   disabled
                   [checked]="doctor.paymentAcceptation">
            <label class="custom-control-label color-black"
                   for="paymentAcceptation">{{ 'VIEW.MAIN.DOCTOR.BASIC_DATA.AGREEMENT_TO_PAYMENT' | translate }}</label>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>


<!--Portal-->
<app-page-action-portal name="doctor">
  <button class="btn btn-primary" type="button" (click)="edit()"
          *ngIf="!doctorModel.needVerification">{{'COMMON.EDIT' | translate}}</button>
</app-page-action-portal>

<ng-template #noDataTemplate>
  <span>-</span>
</ng-template>