import { Component, OnInit } from '@angular/core';
import { State } from '@common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { DoctorService } from '@service/DoctorService';
import { PageDTO } from '@dto/PageDTO';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { DoctorPageCriteriaDTO } from '@dto/doctor/DoctorPageCriteriaDTO';
import { PageCriteriaDTO } from '@dto/PageCriteriaDTO';
import { Constant } from '@common/Constant';
import { DataGridTemplateCellRendererComponent } from 'app/component/ui/dataGrid/cellRenderer/DataGridTemplateCellRendererComponent';
import { StateService } from '@uirouter/core';
import { Transition } from '@uirouter/angular';
import { SortDirection } from '@enum/SortDirection';
import { PageUtil } from '@util/PageUtil';
import { ViewUtil } from '@util/ViewUtil';

@Component({
  selector: 'app-new-doctors-list-component',
  templateUrl: './NewDoctorsListComponent.html',
  styleUrls: [ './NewDoctorsListComponent.scss' ]
})
export class NewDoctorsListComponent implements OnInit {
  public data: PageDTO<DoctorDTO>;

  public criteria: DoctorPageCriteriaDTO;

  public DataGridTemplateCellRendererComponent: typeof DataGridTemplateCellRendererComponent = DataGridTemplateCellRendererComponent;

  public Constant: typeof Constant = Constant;

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService,
              private transition: Transition,
              private viewUtil: ViewUtil,
              private doctorService: DoctorService) {
    this.criteria = PageUtil.prepareCriteria<DoctorPageCriteriaDTO>(DoctorPageCriteriaDTO, transition.params()?.criteria, { sortOrders: [ `dateApplied ${ SortDirection.DESC.toLowerCase() }` ] });
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DASHBOARD.NEW_DOCTORS);
  }

  public ngOnInit(): void {
    this.getData();
  }

  private getData(): Promise<void> {
    return this.doctorService.getDoctorsToVerifyPage(this.criteria).toPromise()
      .then((result: PageDTO<DoctorDTO>) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  public goDetails(doctor: DoctorDTO): void {
    this.stateService.go(State.MAIN.DOCTOR.DETAILS.BASIC_DATA, { doctorId: doctor.id });
  }

  public onCriteriaChanged(changedCriteria: PageCriteriaDTO): void {
    this.criteria = PageUtil.onCriteriaChanged<DoctorPageCriteriaDTO>(DoctorPageCriteriaDTO, this.criteria, changedCriteria as DoctorPageCriteriaDTO);

    this.getData()
      .then(() => PageUtil.updateURL(changedCriteria));
  }
}
