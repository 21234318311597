<ng-container *ngIf="insurance$ | async as insurance">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.INSURANCE.TITLE' | translate}}</div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.PROVIDER' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{insurance?.branchProvider?.providerName | replaceEmpty}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.BRANCH' | translate }}</label>
        <div class="form-control-plaintext form-control-sm">{{insurance?.branchProvider?.branch | replaceEmpty}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.MEMBER_TYPE' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{insurance?.memberType | prefix: 'ENUM.MEMBER_TYPE.' | translate}}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.MEMBER_NUMBER' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{insurance?.memberNumber | replaceEmpty }}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.COMPANY_NUMBER' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{insurance?.companyNumber | replaceEmpty }}</div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.VALID' | translate }}</label>
        <div
          class="form-control-plaintext form-control-sm">{{insurance?.validUntil | date:Constant.DATE_FORMAT }}</div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.INSURANCE.PHOTO_TITLE' | translate}}</div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.FRONT_IMAGE' | translate }}</label>
        <div class="image-container" *ngIf="insurance?.cardFrontFileUrl">
          <a [href]="insurance?.cardFrontFileUrl" target="_blank">
            <img [src]="insurance?.cardFrontFileUrl"/>
          </a>
        </div>
      </div>

      <div class="form-group">
        <label>{{ 'VIEW.MAIN.PATIENT.INSURANCE.BACK_IMAGE' | translate }}</label>
        <div class="image-container" *ngIf="insurance?.cardBackFileUrl">
          <a [href]="insurance?.cardBackFileUrl" target="_blank">
            <img [src]="insurance?.cardBackFileUrl"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Portal-->
<app-page-action-portal>
  <button type="button"
          class="btn btn-danger mt-3 mt-sm-0"
          (click)="reportProblem()">{{'VIEW.MAIN.PATIENT.INSURANCE.ACTION.REPORT_PROBLEM' | translate}}</button>
</app-page-action-portal>