import { HttpParams } from '@angular/common/http';

export class HttpUtil {
  public static createHttpParams(params: {}): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    Object.keys(params).forEach(param => {
      const value = params[param];

      if (value) {
        httpParams = httpParams.set(param, Array.isArray(value) ? value.filter(s => s) : value);
      }
    });

    return httpParams;
  }
}