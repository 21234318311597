import { PatientDTO } from '@dto/patient/PatientDTO';
import { Type } from 'class-transformer';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { PrescriptionRefillStatus } from '@enum/prescription/PrescriptionRefillStatus';
import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';

export class PrescriptionRefillDTO extends IdentifiableObjectDTO<number> {
  public additionalInformation: string;

  public justification: string;

  @Type(() => PatientDTO)
  public patient: PatientDTO;

  @Type(() => PrescriptionDTO)
  public requests: PrescriptionDTO[];

  public status: PrescriptionRefillStatus;
}