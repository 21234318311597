import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { DateUtil } from '@util/DateUtil';
import { Exclude, Transform } from 'class-transformer';

export class VacationDTO extends IdentifiableObjectDTO<number> {
  @Transform(DateUtil.dateTimeConversion)
  public dateFrom: Date;

  @Transform(DateUtil.dateTimeConversion)
  public dateTo: Date;

  @Exclude({ toPlainOnly: true })
  public isEditing: boolean;

  public wholeDay: boolean;
}