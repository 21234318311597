<div class="container px-0">
  <div class="d-flex align-items-center justify-content-center min-height-100-dvh">
    <div class="content">
      <form #form="ngForm" (ngSubmit)="submit()" novalidate>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="mb-5">
            <img class="logo d-block img-fluid" src="asset/image/logo.png" alt="Logo"/>
          </div>

          <!--            Step 1-->
          <ng-container *ngIf="!resetPasswordSuccess">
            <h5 class="mb-3"> {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD' | translate }}</h5>

            <div class="form-group">
              <label for="email"> {{ 'VIEW.PRELIMINARY.LOGIN.EMAIL' | translate }}</label>
              <input id="email" type="text"
                     name="email"
                     #email="ngModel"
                     placeholder="{{ 'VIEW.PRELIMINARY.LOGIN.EMAIL' | translate }}"
                     class="form-control form-control-sm"
                     ngvemail
                     [ngClass]="{'is-invalid': form.submitted && email.invalid}"
                     [(ngModel)]="formData.email" required>

              <div class="invalid-feedback" *ngIf="form.submitted && email.invalid">
                <div *ngIf="email.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
                <div *ngIf="email.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
              </div>
            </div>

            <button type="submit"
                    class="btn btn-custom-1">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD' | translate }}
            </button>

          </ng-container>


          <!--            Step 2-->
          <ng-container *ngIf="resetPasswordSuccess">
            <h5 class="mb-3"> {{ 'VIEW.PRELIMINARY.RESET_PASSWORD.RESET_PASSWORD_INFO' | translate }}</h5>
            <p class="mt-2 text-center">{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.SEND_MAIL_SUCCESS' | translate }} </p>
            <button type="button" class="btn btn-link btn-sm text-normal mt-3"
                    (click)="sendAgain()"
                    [disabled]="!sendAgainActive">
              <span>{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.SEND_AGAIN' | translate }}</span>
            </button>
          </ng-container>

          <button type="button" class="btn btn-link btn-sm text-normal mt-4"
                  (click)="backToLogin()">
            <span>{{ 'VIEW.PRELIMINARY.RESET_PASSWORD.BACK_TO_LOGIN' | translate }}</span>
          </button>
        </div>
      </form>
    </div>

  </div>
</div>

