<div id="registration-container">
  <div id="registration">
    <form #registrationForm="ngForm" novalidate (ngSubmit)="register()">

      <img class="logo d-block img-fluid mx-auto mb-5 mt-3" src="asset/image/logo.png">

      <div class="text-center mb-4 font-weight-bold title">{{
          'VIEW.PRELIMINARY.REGISTRATION.CREATE_ACCOUNT' | translate | uppercase
        }}
      </div>

      <div class="form-group">
        <label for="firstName">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.FIRST_NAME' | translate }}</label>
        <input type="text" id="firstName" name="firstName" #firstName="ngModel" class="form-control"
               placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.FIRST_NAME' | translate }}"
               [ngClass]="{'is-invalid': registrationForm.submitted && firstName.invalid, 'is-valid': registrationForm.submitted && firstName.valid }"
               [pattern]="Constant.STRING_NO_NUMBER_NO_SPECIAL_CHARACTERS"
               [(ngModel)]="userRegistration.firstName" required [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG">
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && firstName.invalid">
          <div *ngIf="firstName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="firstName.errors['pattern']">{{ 'VALIDATION.NO_SPECIAL_CHARACTERS' | translate }}</div>
          <div *ngIf="firstName.errors['maxLength']">{{
              'VALIDATION.CHAR_MAX' | translate: {
                max:
                Constant.TEXT_MAX_LENGTH_LG
              }
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="lastName">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.LAST_NAME' | translate }}</label>
        <input type="text" id="lastName" name="lastName" #lastName="ngModel" class="form-control"
               placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.LAST_NAME' | translate }}"
               [ngClass]="{'is-invalid': registrationForm.submitted && lastName.invalid, 'is-valid': registrationForm.submitted && lastName.valid }"
               [pattern]="Constant.STRING_NO_NUMBER_NO_SPECIAL_CHARACTERS"
               [(ngModel)]="userRegistration.lastName" required [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG">
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && lastName.invalid">
          <div *ngIf="lastName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="firstName.errors['pattern']">{{ 'VALIDATION.NO_SPECIAL_CHARACTERS' | translate }}</div>
          <div *ngIf="lastName.errors['maxLength']">{{
              'VALIDATION.CHAR_MAX' | translate: {
                max:
                Constant.TEXT_MAX_LENGTH_LG
              }
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="emailAddress">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.EMAIL' | translate }}</label>
        <input type="email" id="emailAddress" name="emailAddress" #emailAddress="ngModel" class="form-control"
               placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.EMAIL' | translate }}"
               [ngClass]="{'is-invalid': registrationForm.submitted && emailAddress.invalid, 'is-valid': registrationForm.submitted && emailAddress.valid }"
               [(ngModel)]="userRegistration.email" required ngvemail [appMaxLength]="Constant.TEXT_MAX_LENGTH_LG">
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && emailAddress.invalid">
          <div *ngIf="emailAddress.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="emailAddress.errors['email']">{{ 'VALIDATION.EMAIL' | translate }}</div>
          <div *ngIf="emailAddress.errors['maxLength']">{{
              'VALIDATION.CHAR_MAX' | translate: {
                max:
                Constant.TEXT_MAX_LENGTH_LG
              }
            }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="password">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.PASSWORD' | translate }}</label>
        <input type="password" id="password" name="password" #password="ngModel" class="form-control"
               placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.PASSWORD' | translate }}"
               [ngClass]="{'is-invalid': registrationForm.submitted && password.invalid, 'is-valid': registrationForm.submitted && password.valid }"
               [(ngModel)]="userRegistration.password" appPasswordRequirements required
               [pattern]="Constant.PASSWORD_PATTERN">
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && password.invalid">
          <div *ngIf="password.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="password.errors['pattern']">{{
              'VALIDATION.PASSWORD' | translate: {
                passwordLength:
                Constant.PASSWORD_MIN_LENGTH
              }
            }}
          </div>
        </div>
        <!-- If you want to remove the list of password requirements, delete the "appPasswordRequirements" validator and below <ul> -->
        <div class="mb-1 mt-1 w-100">
          <app-password-requirements [passwordControl]="password.control"></app-password-requirements>
        </div>
      </div>

      <div class="form-group">
        <label for="passwordRepeat">{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.REPEAT_PASSWORD' | translate }}</label>
        <input type="password" id="passwordRepeat" name="passwordRepeat" #passwordRepeat="ngModel" class="form-control"
               placeholder="{{ 'VIEW.PRELIMINARY.REGISTRATION.DATA.REPEAT_PASSWORD' | translate }}"
               [ngClass]="{'is-invalid': registrationForm.submitted && passwordRepeat.invalid, 'is-valid': registrationForm.submitted && passwordRepeat.valid }"
               [(ngModel)]="formData.passwordRepeat" required [pattern]="Constant.PASSWORD_PATTERN"
               [equalTo]="password.control">
        <div class="invalid-feedback" *ngIf="registrationForm.submitted && passwordRepeat.invalid">
          <div *ngIf="passwordRepeat.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div *ngIf="passwordRepeat.errors['pattern']">{{
              'VALIDATION.PASSWORD' | translate: {
                passwordLength:
                Constant.PASSWORD_MIN_LENGTH
              }
            }}
          </div>
          <div *ngIf="passwordRepeat.errors['equalTo']">{{ 'VALIDATION.PASSWORD_MATCH' | translate }}</div>
        </div>
      </div>

      <div class="form-group mb-2">
        <div class="custom-control custom-checkbox primary">
          <input type="checkbox" class="form-control custom-control-input"
                 name="termsAndConditions"
                 [ngClass]="{'is-invalid': registrationForm.submitted && acceptTermsAndConditions.invalid, 'is-valid': registrationForm.submitted && acceptTermsAndConditions.valid }"
                 [(ngModel)]="termsAndConditions" #acceptTermsAndConditions="ngModel"
                 id="acceptTermsAndConditions" required>
          <label class="custom-control-label"
                 for="acceptTermsAndConditions"
                 [innerHTML]="'VIEW.PRELIMINARY.REGISTRATION.CONSENTS.TERMS_AND_CONDITIONS' | translate">
          </label>
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && acceptTermsAndConditions.invalid">
            <div *ngIf="acceptTermsAndConditions.errors['required']">{{ 'VALIDATION.REQUIRED_CONSENT' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group mb-4">
        <div class="custom-control custom-checkbox primary">
          <input type="checkbox" class="form-control custom-control-input"
                 name="privacyPolicy"
                 [ngClass]="{'is-invalid': registrationForm.submitted && acceptPrivacyPolicy.invalid, 'is-valid': registrationForm.submitted && acceptPrivacyPolicy.valid }"
                 [ngModel]="privacyPolicy" #acceptPrivacyPolicy="ngModel"
                 id="acceptPrivacyPolicy" required>
          <label class="custom-control-label"
                 for="acceptPrivacyPolicy"
                 [innerHTML]="'VIEW.PRELIMINARY.REGISTRATION.CONSENTS.PRIVACY_POLICY' | translate">
          </label>
          <div class="invalid-feedback" *ngIf="registrationForm.submitted && acceptPrivacyPolicy.invalid">
            <div *ngIf="acceptPrivacyPolicy.errors['required']">{{ 'VALIDATION.REQUIRED_CONSENT' | translate }}</div>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-custom-1 btn-block mt-4 mx-auto">
        <span>{{ 'VIEW.PRELIMINARY.REGISTRATION.REGISTRATION_START_ACTION' | translate | uppercase }}</span>
      </button>

      <div class="d-flex justify-content-center">
        <button id="back-button" type="button" class="btn btn-link btn-sm text-normal mt-4" (click)="backToLogin()">
          <span>{{ 'VIEW.PRELIMINARY.REGISTRATION.BACK_TO_LOGIN' | translate }}</span>
        </button>
      </div>

    </form>
  </div>
</div>