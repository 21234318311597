<section class="page">
  <div class="header-container">
    <app-page-title-container name="invite"></app-page-title-container>
    <app-page-action-container name="invite"></app-page-action-container>
  </div>

  <div class="content">
    <app-tab [tabs]="tabs"></app-tab>

    <div class="pt-4">
      <ui-view></ui-view>
    </div>
  </div>
</section>
