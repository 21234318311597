import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionJoinPipe'
})
export class CollectionJoinPipe implements PipeTransform {

  public transform(value: any[], fieldName: string, separator: string = ', '): string {
    return value?.length > 0 ? value?.map((s: any) => s?.[fieldName]).join(separator) : '-';
  }

}
