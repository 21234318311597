import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-time-picker',
  templateUrl: './TimePickerComponent.html',
  styleUrls: [ './TimePickerComponent.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements ControlValueAccessor {
  public onChange: any = () => {
  };

  public onTouch: any = () => {
  };

  constructor() {
  }

  public originalValue: Date;

  public parsedValue: string;

  public ngOnInit(): void {
  }

  public writeValue(value: Date): void {
    if (value) {
      this.originalValue = value;
      this.parsedValue = value ? moment(value).format('HH:mm') : null;

      this.onChange(value);
      this.onTouch(value);
    }
  }

  public update(): void {
    const date = moment(this.parsedValue, 'HH:mm');
    const changedOriginalValue = moment(this.originalValue).toDate();

    changedOriginalValue.setHours(date.get('hour'));
    changedOriginalValue.setMinutes(date.get('minute'));
    changedOriginalValue.setSeconds(date.get('second'));

    this.originalValue = changedOriginalValue;

    this.onChange(changedOriginalValue);
    this.onTouch(changedOriginalValue);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

}