import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'toDate' })
export class CastDatePipe implements PipeTransform {

  public transform(value: string, format: string = 'HH:mm:ss'): Date {
    return moment(value, format).toDate();
  }
}