import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationModel } from 'app/model/ApplicationModel';
import { ViewUtil } from 'app/util/ViewUtil';

@Component({
  selector: 'app-preliminary-layout',
  templateUrl: 'PreliminaryLayoutComponent.html',
  styleUrls: [ 'PreliminaryLayoutComponent.scss' ]
})
export class PreliminaryLayoutComponent implements OnInit, OnDestroy {

  constructor(private applicationModel: ApplicationModel,
              public viewUtil: ViewUtil) {
  }

  public ngOnInit():void {
 
  }

  public ngOnDestroy():void {
  }

}
