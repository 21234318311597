<div>
  <tabset [type]="type" class="d-none d-md-block">
    <ng-container *ngFor="let tab of _tabs">
      <tab [active]="tab.active" (selectTab)="onChangeTab(tab)">
        <ng-template tabHeading>
          <ng-container *ngTemplateOutlet="valueTemplate; context:{$implicit: tab}">
          </ng-container>
        </ng-template>
      </tab>
    </ng-container>
  </tabset>

  <div class="select-as-tabs">
    <ng-select class="d-block d-md-none" [placeholder]="'COMMON.CHOOSE' | translate" [searchable]="false"
               [clearable]="false" [items]="_tabs" (change)="onChangeTab($event)" [(ngModel)]="currentTab">
      <ng-template ng-label-tmp let-item="item">
        <ng-container *ngTemplateOutlet="valueTemplate; context:{$implicit: item}">
        </ng-container>
      </ng-template>
      <ng-template ng-option-tmp class="test" let-item="item">
        <ng-container *ngTemplateOutlet="valueTemplate; context:{$implicit: item}">
        </ng-container>
      </ng-template>
    </ng-select>
  </div>
</div>

<!--Templates-->

<ng-template #valueTemplate let-item>
  <span>{{item.title | translate}}</span>
  <ng-container *ngTemplateOutlet="validationStatusTemplate; context:{$implicit: item?.validationStatus}">
  </ng-container>
</ng-template>

<ng-template #validationStatusTemplate let-status>
  <ng-container *ngIf="status === true || status === false">
    <div *ngIf="status" class="d-inline-block">
      <svg-icon *ngIf="status" class="d-flex completed"
                src="asset/image/icon/completed.svg"></svg-icon>
    </div>
    <div *ngIf="!status" class="d-inline-block">
      <svg-icon *ngIf="!status" class="d-flex not-completed"
                src="asset/image/icon/not-completed.svg"></svg-icon>
    </div>
  </ng-container>
</ng-template>