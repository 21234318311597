import { Component, OnInit } from '@angular/core';
import { Transition } from '@uirouter/angular';
import { State } from '@common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';
import { Observable } from 'rxjs';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { PatientModel } from '@model/PatientModel';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-patient-details',
  templateUrl: './PatientDetailsComponent.html',
  styleUrls: [ './PatientDetailsComponent.scss' ],
  providers: [ PatientModel ]
})
export class PatientDetailsComponent implements OnInit {
  public patientId: number = this.transition.params().patientId;

  public patient$: Observable<PatientDTO> = this.patientModel.getPatientById(this.patientId)
    .pipe(
      tap(() => this.setTabs())
    );

  public tabs: ITab[];

  constructor(public patientModel: PatientModel,
              private transition: Transition,
              private applicationModel: ApplicationModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.MY_PATIENT.LIST);
  }

  public ngOnInit(): void {
  }

  public back(): void {
    history.back();
  }

  private setTabs(): void {
    const result = [ {
      title: 'VIEW.MAIN.PATIENT.TAB.BASIC_DATA',
      state: State.MAIN.MY_PATIENT.DETAILS.BASIC_DATA
    },
      {
        title: 'VIEW.MAIN.PATIENT.TAB.INSURANCE',
        state: State.MAIN.MY_PATIENT.DETAILS.INSURANCE
      }
    ];

    if (this.patientModel.registeredPatient) {
      result.push({
        title: 'VIEW.MAIN.PATIENT.TAB.HISTORY',
        state: State.MAIN.MY_PATIENT.DETAILS.HISTORY
      });
    }

    this.tabs = result;
  }

}
