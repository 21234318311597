import { Component, OnInit } from '@angular/core';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { CurrentDoctorService } from '@service/CurrentDoctorService';
import { State } from '@common/State';
import { StateUtil } from '@util/StateUtil';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ViewUtil } from '@util/ViewUtil';
import { UserService } from '@service/UserService';
import { Observable } from 'rxjs';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';
import { map } from 'rxjs/operators';
import { UserModel } from '@model/UserModel';

@Component({
  selector: 'app-profile-practice',
  templateUrl: './ProfilePracticeComponent.html',
  styleUrls: [ './ProfilePracticeComponent.scss' ]
})
export class ProfilePracticeComponent {
  public practice: PracticeDTO;

  public viewReady: boolean = false;

  public isEditable$: Observable<boolean> = this.userModel.isStatus$(DoctorStatus.NOT_ACCEPTED)
    .pipe(
      map(status => !status)
    );

  constructor(private currentDoctorService: CurrentDoctorService,
              private modalService: BsModalService,
              private viewUtil: ViewUtil,
              private stateUtil: StateUtil,
              private userModel: UserModel) {
    this.getPractice();
  }

  public delete(): void {
    this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.DELETE.TITLE',
        okText: 'COMMON.DELETE',
        okButtonClass: 'btn-danger'
      }
    }).content.close$.subscribe(() => {
      this.currentDoctorService.deletePractice().toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.DELETE.SUCCESS');
          this.practice = null;
        })
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    });
  }

  public create(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE_CREATE.PRACTICE);
  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.PROFILE_EDIT.PRACTICE);
  }

  private getPractice(): void {
    this.currentDoctorService.getPractice().toPromise()
      .then((practice: PracticeDTO) => {
        this.practice = practice;
        this.viewReady = true;
      })
      .catch(() => {
        this.viewReady = true;
      });
  }
}
