import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

export const maxDateTimeValidator = (value: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
      return null;
    }
    else {
      const date: Date = control.value;
      return moment(date).isAfter(value, 'minute') ? { maxDateTime: true } : null;
    }
  };
};


@Directive({
  selector: '[appMaxDateTime][formControlName],[appMaxDateTime][formControl],[appMaxDateTime][ngModel]',
  providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: MaxDateTimeValidator,
        multi: true
      }
    ]
})
export class MaxDateTimeValidator implements Validator, OnInit, OnChanges {

  @Input()
  public appMaxDateTime: Date;

  private validator: ValidatorFn;

  private onChange: () => void;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit():void {
    this.validator = maxDateTimeValidator(this.appMaxDateTime);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    for (const key in changes) {
      if (key === 'appMaxDateTime') {
        this.validator = maxDateTimeValidator(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
          this.changeDetector.detectChanges();  //  there are some issues in angular with detecting valid/invalid state and binding to ngClass, for example. This fixes it.
        }
      }
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.appMaxDateTime !== null ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
