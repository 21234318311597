import { Component, OnInit } from '@angular/core';
import { State } from '@common/State';
import { ApplicationModel } from '@model/ApplicationModel';
import { ITab } from 'app/component/ui/tab/interface/ITab';

@Component({
  selector: 'app-invitation.component',
  templateUrl: './InviteComponent.html',
  styleUrls: [ './InviteComponent.scss' ]
})

export class InviteComponent implements OnInit {
  public tabs: ITab[] = [
    {
      title: 'VIEW.MAIN.INVITE.PATIENT.PATIENT',
      state: State.MAIN.INVITE.INVITE_PATIENT
    },
    {
      title: 'VIEW.MAIN.INVITE.DOCTOR.DOCTOR',
      state: State.MAIN.INVITE.INVITE_DOCTOR
    }
  ];

  constructor(private applicationModel: ApplicationModel) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.INVITE.INVITE);
  }

  public ngOnInit(): void {
  }
}
