<div id="layout-migrating">
  <div class="container-fluid px-0 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img id="logo" src="asset/image/logo.svg" alt="Logo"/>
        </div>


        <ng-container *ngIf="viewReady && linkExpired">
          <div class="col-12">
            <div class="col-12">
              <h5 class="mt-8">{{'VIEW.ACCOUNT_MIGRATION.LINK_EXPIRED.TITLE' | translate}}</h5>
              <p class="my-4">{{'VIEW.ACCOUNT_MIGRATION.LINK_EXPIRED.DESCRIPTION' | translate}}</p>

              <hr class="my-0">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="viewReady && !linkExpired">
          <ng-container [ngSwitch]="userDecision">
            <ng-container *ngSwitchCase="MigratingAccountDecision.DELETE_ACCOUNT">
              <div class="col-12">
                <h5
                  class="mt-8">{{'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.'+ userDecision + '.HEADER' | translate }}</h5>
                <p class="my-4"
                   [innerHTML]="'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.' + userDecision + '.DESCRIPTION' | translate"></p>

                <hr class="my-0">
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="MigratingAccountDecision.USE_PARENT_ACCOUNT">
              <div class="col-12">
                <h5
                  class="mt-8">{{'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.'+ userDecision + '.HEADER' | translate }}</h5>
                <p class="my-4"
                   [innerHTML]="'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.' + userDecision + '.DESCRIPTION' | translate"></p>

                <hr class="my-0">
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="MigratingAccountDecision.CREATE_ACCOUNT">
              <div class="col-12">
                <h5
                  class="mt-8">{{'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.'+ userDecision + '.HEADER' | translate }}</h5>
                <p class="my-4"
                   [innerHTML]="'VIEW.ACCOUNT_MIGRATION.MIGRATION_FINISHED.' + userDecision + '.DESCRIPTION' | translate"></p>

                <hr class="my-0">

                <div class="d-flex mt-4">
                  <a [href]="applicationModel.playGoogleStoreApplicationUrl" target="_blank">
                    <img src="asset/image/icon/google.png" alt="Google Play"/>
                  </a>

                  <a [href]="applicationModel.appleStoreApplicationUrl" target="_blank">
                    <img class="ml-3" src="asset/image/icon/apple.png" alt="App Store"/>
                  </a>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div class="col-12">
                <h5
                  class="mt-8">{{'VIEW.ACCOUNT_MIGRATION.WELCOME_TEXT.TITLE' | translate: { name: patient?.firstName || '' } }}</h5>
                <p class="my-4">{{'VIEW.ACCOUNT_MIGRATION.WELCOME_TEXT.DESCRIPTION' | translate}}</p>

                <hr class="my-0">
              </div>

              <div class="col-12 mt-4">
                <form #baseForm="ngForm" class="on-submit-verification" [formGroup]="form" novalidate>
                  <div class="validation-error mb-4">
                    <div>{{ 'VIEW.ACCOUNT_MIGRATION.MESSAGE.VALIDATION_ERROR' | translate }}</div>
                  </div>

                  <div class="form-group mb-4">
                    <div class="custom-control custom-checkbox primary">
                      <input type="checkbox" class="custom-control-input"
                             id="acceptTermsAndConditions"
                             formControlName="acceptTermsAndConditions"
                             [value]="true">
                      <label class="custom-control-label"
                             for="acceptTermsAndConditions"
                             [innerHTML]="'VIEW.ACCOUNT_MIGRATION.CONSENTS.TERMS_AND_CONDITIONS' | translate">
                      </label>
                    </div>
                  </div>

                  <div class="form-group mb-4">
                    <div class="custom-control custom-checkbox primary">
                      <input type="checkbox" class="custom-control-input"
                             id="confirmProcessingData"
                             formControlName="confirmProcessingData"
                             [value]="true">
                      <label class="custom-control-label"
                             for="confirmProcessingData"
                             [innerHTML]="'VIEW.ACCOUNT_MIGRATION.CONSENTS.PROCESSING_DATA' | translate">
                      </label>
                    </div>
                  </div>

                  <p class="my-4">
                    <span [innerHTML]="'VIEW.ACCOUNT_MIGRATION.PRIVACY' | translate"></span>
                  </p>

                  <div class="d-flex flex-column flex-sm-row">
                    <button type="submit" class="btn btn-outline-primary"
                            (click)="save(false)">{{'VIEW.ACCOUNT_MIGRATION.ACTION.USE_PARENT_ACCOUNT' | translate}}</button>
                    <button type="submit" class="btn btn-primary ml-sm-2 mt-2 mt-sm-0"
                            (click)="save(true)">{{'VIEW.ACCOUNT_MIGRATION.ACTION.CREATE_ACCOUNT' | translate}}</button>
                    <button type="button" class="btn btn-danger ml-sm-2 mt-2 mt-sm-0"
                            (click)="reject()">{{'VIEW.ACCOUNT_MIGRATION.ACTION.DELETE' | translate}}</button>
                  </div>
                </form>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
