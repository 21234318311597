import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LicenseDTO } from '@dto/doctor/LicenseDTO';
import { StateUtil } from '@util/StateUtil';
import { ViewUtil } from '@util/ViewUtil';
import { DoctorModel } from '@model/DoctorModel';
import { State } from '@common/State';

@Component({
  selector: 'app-doctor-license',
  templateUrl: './DoctorLicenseComponent.html',
  styleUrls: [ './DoctorLicenseComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoctorLicenseComponent {
  public license$: Observable<LicenseDTO> = this.doctorModel.license$;

  constructor(public doctorModel: DoctorModel,
              private stateUtil: StateUtil,
              public viewUtil: ViewUtil) {

  }

  public edit(): void {
    this.stateUtil.goToState(State.MAIN.DOCTOR.EDIT.LICENSE, { doctorId: this.doctorModel.id });
  }
}

