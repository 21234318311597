import { Injectable } from '@angular/core';
import { PatientService } from 'app/service/PatientService';
import { EventManager } from 'app/util/other/EventManager';
import { InsuranceDTO } from '@dto/insurance/InsuranceDTO';
import { BehaviorSubject, Observable } from 'rxjs';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { map, shareReplay, tap } from 'rxjs/operators';
import { PageDTO } from '@dto/PageDTO';
import { PatientEventsHistoryPageCriteriaDTO } from '@dto/patient/PatientEventsHistoryPageCriteriaDTO';
import { PatientEventHistoryDTO } from '@dto/patient/PatientEventHistoryDTO';
import { PatientStatus } from '@enum/patient/PatientStatus';

@Injectable()
export class PatientModel {
  public id: number;

  public patient$: Observable<PatientDTO>;

  private isUnregisteredPatient: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isUnregisteredPatient$: Observable<boolean> = this.isUnregisteredPatient.asObservable();

  public isRegisteredPatient$: Observable<boolean> = this.isUnregisteredPatient.asObservable()
    .pipe(
      map((result) => !result)
    );

  constructor(private eventManager: EventManager,
              private patientService: PatientService) {
  }

  public get unregisteredPatient(): boolean {
    return this.isUnregisteredPatient.value;
  }

  public get registeredPatient(): boolean {
    return !this.isUnregisteredPatient.value;
  }

  public getPatientById(id: number): Observable<PatientDTO> {
    if (!this.patient$) {
      this.id = id;
      this.patient$ = this.patientService.getById(id)
        .pipe(
          tap((patient: PatientDTO) => this.isUnregisteredPatient.next(patient.status === PatientStatus.UNREGISTERED)),
          shareReplay()
        );
    }

    return this.patient$;
  }

  public getInsurance(): Observable<InsuranceDTO> {
    return this.patientService.getInsurance(this.id);
  }

  public update(data: PatientDTO): Observable<void> {
    return this.patientService.update(data);
  }

  public getEventsHistoryPage(criteria: PatientEventsHistoryPageCriteriaDTO): Observable<PageDTO<PatientEventHistoryDTO>> {
    return this.patientService.getEventsHistoryPage(this.id, criteria);
  }

}