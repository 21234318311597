<div class="room">

  <div class="preview-container">
    <div class="microphone-indicator" *ngIf="videoCallStorage.audioLocalTrack$ | async as audio">
      <app-microphone-volume-indicator [audio]="audio"></app-microphone-volume-indicator>
    </div>

    <div id="participants" #participants></div>

    <div class="avatar-container" [class.show-avatar]="!isRemoteVideo">
      <div class="avatar">
        <img [src]="videoCallStorage.participant.avatarUrl" alt="Avatar"/>
      </div>
    </div>

    <div id="camera-preview" #cameraPreview cdkDrag cdkDragBoundary=".preview-container"></div>

    <div class="settings-container">
      <div class="settings">
        <ng-container *ngIf="videoCallStorage.audioLocalTrack$ | async">
          <button type="button" class="btn btn-link on" (click)="stopMicrophone()">
            <i-tabler name="microphone"></i-tabler>
          </button>
        </ng-container>

        <ng-container *ngIf="(videoCallStorage.audioLocalTrack$ | async) === null">
          <button type="button" class="btn btn-link off" (click)="startMicrophone()"
                  [disabled]="!videoCallStorage.availableAudioDevice">
            <i-tabler name="microphone-off"></i-tabler>
          </button>
        </ng-container>


        <ng-container *ngIf="videoCallStorage.videoLocalTrack$ | async">
          <button type="button" class="btn btn-link on" (click)="stopCamera()">
            <i-tabler name="video"></i-tabler>
          </button>
        </ng-container>

        <ng-container *ngIf="(videoCallStorage.videoLocalTrack$ | async) === null">
          <button type="button" class="btn btn-link off" (click)="startCamera()"
                  [disabled]="!videoCallStorage.availableVideoDevice">
            <i-tabler name="video-off"></i-tabler>
          </button>
        </ng-container>

        <button type="button" class="btn btn-link danger" (click)="disconnect()"
                [disabled]="!currentRoom">
          <i-tabler name="phone-off"></i-tabler>
        </button>
      </div>
    </div>
  </div>
</div>
