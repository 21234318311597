import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
export class TranslateUtil {

    constructor(private translate: TranslateService) {

    }

    public getTranslateMessage(key: string): string{
        let message = "";
        this.translate.get(key).subscribe(data =>
          message = data
        );
        return message;
    }
}
