import { Component } from '@angular/core';
import { WorkingDaysTimetableDTO } from '@dto/workingDay/WorkingDaysTimetableDTO';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-doctor-working-hours-popup',
  templateUrl: './DoctorWorkingHoursPopupComponent.html',
  styleUrls: [ './DoctorWorkingHoursPopupComponent.scss' ]
})
export class DoctorWorkingHoursPopupComponent extends BaseModalComponent<boolean> {
  public data: WorkingDaysTimetableDTO[];

  constructor(public bsModalRef: BsModalRef) {
    super();
  }

}
