import { Component } from '@angular/core';
import { StateService } from '@uirouter/core';
import { State } from 'app/common/State';

@Component({
  selector: 'app-registration-complete',
  templateUrl: './RegistrationCompleteComponent.html',
  styleUrls: [ './RegistrationCompleteComponent.scss' ]
})
export class RegistrationCompleteComponent {

  constructor(private stateService: StateService) {
  }

  public backToLogin(): void {
    this.stateService.go(State.PRELIMINARY.LOGIN);
  }

}
