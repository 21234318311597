import { SpecialityDTO } from '../doctor/SpecialityDTO';
import { VisitTypeDTO } from '../doctor/VisitTypeDTO';
import { DateUtil } from '@util/DateUtil';
import { Transform, Type } from 'class-transformer';
import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { DoctorDTO } from '@dto/doctor/DoctorDTO';
import { EventStatus } from '@enum/event/EventStatus';

export class EventsSummaryDTO extends IdentifiableObjectDTO<number> {

  @Transform(DateUtil.dateTimeConversion)
  public dateTime: Date;

  @Type(() => DoctorDTO)
  public doctor: DoctorDTO;

  public duration: number;

  public eventStatus: EventStatus;

  public patientId: number;

  public patientFirstName: string;

  public patientLastName: string;

  public noShow: boolean;

  public newPatient: boolean;

  public isUnregisteredPatient: boolean;

  @Type(() => SpecialityDTO)
  public selectedSpeciality: SpecialityDTO;

  @Type(() => VisitTypeDTO)
  public selectedVisitTypes: VisitTypeDTO[];
}