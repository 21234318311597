<ng-container *ngIf="data$ | async as data">
  <div class="container px-0">
    <div class="font-weight-bold mb-4">{{'VIEW.MAIN.DOCTOR.QUALIFICATIONS.QUALIFICATIONS' | translate }}</div>

    <ng-container *ngFor="let qualification of data?.qualifications">
      <div class="mb-1">{{qualification?.name | replaceEmpty}}</div>
    </ng-container>

    <div class="row mt-4">
      <div class="col-12 col-md-7">
        <span class="font-size-sm" innerHTML="{{'MESSAGE.FEEDBACK' | translate}}"></span>
      </div>
    </div>

  </div>
</ng-container>

<!--Portal-->
<app-page-action-portal name="profile" *ngIf="isEditable$ | async">
  <button class="btn btn-primary" type="button" (click)="edit()">{{'COMMON.EDIT' | translate}}</button>
</app-page-action-portal>