<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <div class="modal-control">
    <button type="button" class="close-button" (click)="close()">
      <svg-icon src="asset/image/icon/point.svg"></svg-icon>
    </button>
  </div>
</div>

<div class="modal-body">
  <app-internal-url-browser *ngIf="url" [url]="url"></app-internal-url-browser>
</div>

<div class="modal-control">
  <button type="button" class="close-button" (click)="close()">
    <svg-icon src="asset/image/icon/point.svg"></svg-icon>
  </button>
</div>
