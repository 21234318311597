<div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.SICK_LEAVES.TITLE' | translate}}</div>

<div class="row">
  <div class="col-12">
    <span>{{'VIEW.VIDEO_CALL.SICK_LEAVES.DESCRIPTION' | translate}}</span>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12 col-sm-6">
    <div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.SICK_LEAVES.NEW_SICK_LEAVES' | translate}}</div>

    <form #form="ngForm" novalidate (ngSubmit)="add()">

      <div class="form-group">
        <label for="from">{{ 'VIEW.VIDEO_CALL.SICK_LEAVES.DATA.FROM' | translate }}</label>
        <input type="date"
               placeholder="{{'VIEW.VIDEO_CALL.SICK_LEAVES.PLACEHOLDER' | translate}}"
               class="form-control form-control-sm" name="from" id="from"
               [ngClass]="{'is-invalid': from.touched && from.invalid }"
               #from="ngModel"
               [(ngModel)]="data.dateFrom" required/>

        <div class="invalid-feedback" *ngIf="from.touched && from.invalid">
          <div *ngIf="from.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        </div>
      </div>

      <div class="form-group">
        <label for="to">{{ 'VIEW.VIDEO_CALL.SICK_LEAVES.DATA.TO' | translate }}</label>
        <input type="date"
               placeholder="{{'VIEW.VIDEO_CALL.SICK_LEAVES.PLACEHOLDER' | translate}}"
               class="form-control form-control-sm" name="to" id="to"
               [ngClass]="{'is-invalid': to.touched && to.invalid }"
               [appMinDateTime]="data.dateFrom"
               #to="ngModel"
               [(ngModel)]="data.dateTo" required/>

        <div class="invalid-feedback" *ngIf="to.touched && to.invalid">
          <div *ngIf="to.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="to.errors['minDateTime']">{{ 'VALIDATION.DATE_MIN' | translate:{dateString: data?.dateFrom} }}</div>
        </div>
      </div>

      <button type="submit"
              [disabled]="form.invalid || isProcessing"
              class="btn btn-primary mt-4">{{'VIEW.VIDEO_CALL.SICK_LEAVES.ACTION.ADD_SICK_LEAVE' | translate}}</button>
    </form>
  </div>
  <div class="col-12 col-sm-6 mt-4 mt-sm-0">
    <div
      class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.SICK_LEAVES.LIST_SICK_LEAVES' | translate}}</div>

    <ng-container *ngFor="let sickLeave of list">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <div class="d-flex">
          {{sickLeave?.dateFrom | date:Constant.DATE_FORMAT}} - {{sickLeave?.dateTo | date:Constant.DATE_FORMAT}}
        </div>

        <div class="d-flex align-items-start ml-2">
          <button type="button" class="btn btn-link btn-link--black"
                  (click)="remove(sickLeave)">
            <i-tabler class="i-lg" name="trash"></i-tabler>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
