import { Component, Input, OnInit } from '@angular/core';
import { EventSlotList } from '@local/scheduler/EventSlotList';
import moment from 'moment';
import * as _ from 'lodash';
import { EventTimeMoment } from '@local/scheduler/EventTimeMoment';

@Component({
  selector: 'app-scheduler-day-header',
  templateUrl: './SchedulerDayHeaderComponent.html',
  styleUrls: [ './SchedulerDayHeaderComponent.scss' ]
})
export class SchedulerDayHeaderComponent implements OnInit {
  @Input() public data: EventSlotList;

  @Input() public numberOfEventsText: string = 'VIEW.MAIN.DASHBOARD.SCHEDULER.NUMBER_OF_VIDEO_CALLS';

  @Input() public noEventsText: string = 'VIEW.MAIN.DASHBOARD.SCHEDULER.NO_CALLS';

  public eventTimeMoment: EventTimeMoment;

  public EventTimeMoment: typeof EventTimeMoment = EventTimeMoment;

  public numberOfEvents: number = 0;

  constructor() {
  }

  public ngOnInit(): void {
    const date = moment(this.data.date);

    if (date.isSame(new Date(), 'day')) {
      this.eventTimeMoment = EventTimeMoment.TODAY;
    } else if (date.isAfter(new Date(), 'day')) {
      this.eventTimeMoment = EventTimeMoment.FUTURE;
    } else {
      this.eventTimeMoment = EventTimeMoment.PAST;
    }

    this.numberOfEvents = _.filter(this.data.slots, { 'isVideoCall': true }).length;
  }

}
