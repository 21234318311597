<form #form="ngForm" novalidate (ngSubmit)="save()">
  <section class="page">
    <div class="header-container separator">
      <button type="button"
              class="back" (click)="back()">
        <i-tabler name="chevron-left"></i-tabler>
        <div>{{ 'VIEW.MAIN.DOCTOR.PRACTICE.EDIT_DATA' | translate }}</div>
      </button>

      <div class="actions">
        <button type="submit" class="btn btn-primary">{{ 'COMMON.SAVE' | translate}} </button>
      </div>
    </div>

    <div class="content">
      <app-doctor-practice-form [practice]="practice" *ngIf="practice"
                                [submitted]="form.submitted"></app-doctor-practice-form>
    </div>
  </section>
</form>
