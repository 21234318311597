import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-action-container',
  template: '<div id="{{name}}-action-portal" style="display:flex;gap: 1rem;"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageActionContainerComponent implements OnInit {
  @Input() public name: string = 'page';

  constructor() {
  }

  public ngOnInit(): void {
  }

}
