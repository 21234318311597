<div class="slot visit">
  <div class="d-flex flex-column">
    <div class="time">
      <i-tabler name="clock-hour-4" class="i-sm"></i-tabler>
      &nbsp;<span>{{slot.startDate | date: 'HH:mm'}} ({{slot.duration}}min)</span>
    </div>

    <div class="patient">
      <button type="button"
              class="btn btn-link btn-sm btn-link--black text-normal text-left">{{slot.patientFirstName}} {{slot.patientLastName}}</button>
    </div>

    <div class="visit-types">
      <div *ngFor="let item of slot.visitType">{{item.name}}</div>
    </div>
  </div>

  <div class="actions">
    <div class="badges mb-1">
          <span class="th-badge th-badge--secondary"
                *ngIf="slot?.newPatient">{{'COMPONENT.SLOT.NEW_PATIENT' | translate}}</span>
      <span class="th-badge th-badge--black"
            *ngIf="slot?.isUnregisteredPatient">{{'COMPONENT.SLOT.UNREGISTERED' | translate}}</span>
    </div>

    <button type="button"
            class="btn btn-outline-danger btn-sm btn-block"
            (click)="cancel(slot.eventId)">{{'VIEW.MAIN.DASHBOARD.SCHEDULER.CONFLICTS.ACTION.CANCEL' | translate}}</button>
  </div>
</div>
