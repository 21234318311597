import { VisitTypeTimeDTO } from '@dto/doctor/VistiTypeTimeDTO';
import { Type } from 'class-transformer';

export class EventTimesDTO {
  @Type(() => VisitTypeTimeDTO)
  public visitTypes: VisitTypeTimeDTO[];

  public maxVideoCallTime: number;

  public newPatientAdditionalTime: number;
}