import { Component, Input, OnInit } from '@angular/core';
import { LocalAudioTrack } from 'twilio-video';

@Component({
  selector: 'app-microphone-volume-indicator',
  templateUrl: './MicrophoneVolumeIndicatorComponent.html',
  styleUrls: [ './MicrophoneVolumeIndicatorComponent.scss' ]
})
export class MicrophoneVolumeIndicatorComponent implements OnInit {
  @Input()
  public set audio(value: LocalAudioTrack) {
    this.audioStream = value ? new MediaStream([ value.mediaStreamTrack ]) : null;

    if (value) {
      this.initialize();
    }
  }

  public microphoneVolume: number = 0;

  public volumeInterval: NodeJS.Timeout;

  public volumeCallback = null;

  public audioStream: MediaStream;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public initialize(): void {
    try {
      const audioContext = new AudioContext();
      const audioSource = audioContext.createMediaStreamSource(this.audioStream);
      const analyser = audioContext.createAnalyser();

      analyser.fftSize = 1024;
      analyser.smoothingTimeConstant = 0.8; //0.4;
      audioSource.connect(analyser);

      const volumes = new Uint8Array(analyser.frequencyBinCount);

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      this.volumeCallback = () => {
        analyser.getByteFrequencyData(volumes);
        let volumeSum = 0;

        for (const volume of volumes) {
          volumeSum += volume;
        }

        const averageVolume = volumeSum / volumes.length;
        this.microphoneVolume = Math.round(averageVolume * 100 / 127);
      };

      this.volumeInterval = setInterval(this.volumeCallback, 100);

    } catch (e) {
      console.error('Failed to initialize volume visualizer, simulating instead...', e);
      this.microphoneVolume = 0;
    }
  }
}

