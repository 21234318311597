import { Component, Input, OnInit } from '@angular/core';
import { EventSlot } from '@local/scheduler/EventSlot';
import { SlotStatus } from '@local/scheduler/SlotStatus';
import { State } from '@common/State';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-scheduler-slot',
  templateUrl: './SchedulerSlotComponent.html',
  styleUrls: [ './SchedulerSlotComponent.scss' ]
})
export class SchedulerSlotComponent implements OnInit {
  @Input() public slot: EventSlot;

  public SlotStatus: typeof SlotStatus = SlotStatus;

  constructor(private stateService: StateService) {
  }

  public ngOnInit(): void {
  }

  public toWaitingRoom(eventId: number): void {
    this.stateService.go(State.RAW.VIDEO_CALL.VIDEO_CALL, { eventId });
  }

  public showDetails(eventId: number): void {
    this.stateService.go(State.MAIN.EVENT.DETAILS, { eventId });
  }

  public showPatient(patientId: number): void {
    this.stateService.go(State.MAIN.MY_PATIENT.DETAILS.BASIC_DATA, { patientId });
  }
}
