import { Component, OnInit } from '@angular/core';
import { EventService } from '@service/EventService';
import { Transition } from '@uirouter/core';
import { EventDetailsDTO } from '@dto/events/EventDetailsDTO';
import { Constant } from '@common/Constant';
import { ViewUtil } from '@util/ViewUtil';
import { State } from '@common/State';
import { EventStatus } from '@enum/event/EventStatus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';
import { EventNoteDTO } from '@dto/events/EventNoteDTO';
import { ApplicationModel } from '@model/ApplicationModel';

@Component({
  selector: 'app-event',
  templateUrl: './EventComponent.html',
  styleUrls: [ './EventComponent.scss' ]
})
export class EventComponent implements OnInit {
  public id: number = this.transition.params().eventId;

  public data: EventDetailsDTO;

  public Constant: typeof Constant = Constant;

  public State: typeof State = State;

  public EventStatus: typeof EventStatus = EventStatus;

  public isEditing: boolean = false;

  constructor(private eventService: EventService,
              private viewUtil: ViewUtil,
              private modalService: BsModalService,
              private applicationModel: ApplicationModel,
              private transition: Transition) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DASHBOARD.DASHBOARD);
  }

  public ngOnInit(): void {
    this.getData();
  }

  public back(): void {
    history.back();
  }

  public cancelEvent(): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.TITLE',
        message: 'VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.DESCRIPTION',
        okText: 'COMMON.CONFIRM',
        okButtonClass: 'btn-danger'
      }
    });

    modal.content.close$.subscribe(() => {
      this.eventService.deleteEvent(this.id).toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.SUCCESS');
          this.back();
        })
        .catch((ex) => {
          this.viewUtil.handleServerError(ex);
        });
    }, () => {
      //
    });
  }

  public saveNote(): void {
    const note = new EventNoteDTO();
    note.text = this.data.note;

    this.eventService.updateNote(this.id, note).toPromise()
      .then(() => {
        this.isEditing = false;
        this.viewUtil.showToastSuccess('VIEW.MAIN.EVENT_DETAILS.MESSAGE.NOTE_UPDATE_SUCCESS');
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }

  private getData(): void {
    this.eventService.getEventDetails(this.id).toPromise()
      .then((result: EventDetailsDTO) => {
        this.data = result;
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      });
  }
}