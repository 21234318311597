import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'prefix' })
export class PrefixPipe implements PipeTransform {

  public transform(value: string, prefix: string): any {
    if (value === null) {
      return '-';
    }
    return prefix + value;
  }

}
