<form #form="ngForm" novalidate>
  <div class="modal-header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <div class="modal-control">
      <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
        <i-tabler name="x"></i-tabler>
      </button>
    </div>
  </div>
  <div class="modal-body" *ngIf="message">
    <p class="lead mb-1">{{ message | translate: messageVariables }}</p>

    <textarea class="form-control form-control-sm"
              rows="3"
              [(ngModel)]="answer" name="answer"
              [required]="!answerIsOptional"></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()">{{ cancelText | translate }}</button>
    <button type="button" class="btn"
            [disabled]="form.invalid"
            [ngClass]="okButtonClass"
            (click)="ok(answer)">{{ okText | translate }}</button>
  </div>
</form>