import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';

@Component({
  selector: 'app-popup-alert',
  templateUrl: 'PopupAlertComponent.html'
})
export class PopupAlertComponent extends BaseModalComponent<boolean> {
  public title: string;

  public message: string;

  public messageVariables: { [key: string]: any };

  public okText: string = 'COMMON.OK';

  constructor(public bsModalRef: BsModalRef) {
    super();
  }
}
