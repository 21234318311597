import { Component, EventEmitter, Output } from '@angular/core';
import { ApplicationModel } from '@model/ApplicationModel';
import { Observable } from 'rxjs';
import { SideBarItem } from '@local/ui/SideBarItem';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { ApplicationConfig } from '@config/ApplicationConfig';

@Component({
  selector: 'app-sidebar',
  templateUrl: './SidebarComponent.html',
  styleUrls: [ './SidebarComponent.scss' ]
})
export class SidebarComponent {
  public sideBarItems$: Observable<SideBarItem[]> = this.applicationModel.sideBarItems$;

  public applicationVersion: string = ApplicationConfig.version;

  public environmentName: string = ApplicationConfig.environmentName;

  public versionMouseOver: boolean = false;

  @Output() public changedState: EventEmitter<void> = new EventEmitter<void>();

  constructor(private applicationModel: ApplicationModel,
              private stateService: StateService) {
  }

  public trackBy(index: number, sidebarItem: SideBarItem): string {
    return sidebarItem.label;
  }

  public goToSideBarItem(sideBarItem: SideBarItem): void {
    this.changedState.next();

    setTimeout(() => {
      if (sideBarItem.callback && _.isFunction(sideBarItem.callback)) {
        sideBarItem.callback();
      }

      if (sideBarItem.stateName) {
        this.stateService.go(sideBarItem.stateName, sideBarItem.stateParameters);
      }

      this.applicationModel.deselectSideBarItems();
      this.applicationModel.selectSideBarItemWithState(sideBarItem.stateName);
    });
  }
}