import { Component, OnInit, ViewChild } from '@angular/core';
import { PracticeDTO } from '@dto/doctor/PracticeDTO';
import { NgForm } from '@angular/forms';
import { DoctorService } from '@service/DoctorService';
import { ApplicationModel } from '@model/ApplicationModel';
import { ViewUtil } from '@util/ViewUtil';
import { Transition } from '@uirouter/core';
import { State } from '@common/State';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-doctor-practice-create',
  templateUrl: './DoctorPracticeCreateComponent.html',
  styleUrls: [ './DoctorPracticeCreateComponent.scss' ]
})
export class DoctorPracticeCreateComponent implements OnInit {
  @ViewChild('form') public form: NgForm;

  public practice: PracticeDTO = plainToClass(PracticeDTO, {});

  public id: number = this.transition.params().doctorId;

  constructor(private doctorService: DoctorService,
              private applicationModel: ApplicationModel,
              private viewUtil: ViewUtil,
              private transition: Transition) {
    this.applicationModel.selectSideBarItemWithState(State.MAIN.DOCTOR.LIST);
  }

  public ngOnInit(): void {
  }

  public save(): void {
    if (this.form.valid) {
      this.doctorService.createPractice(this.id, this.practice).toPromise()
        .then(() => {
          this.viewUtil.showToastSuccess('VIEW.MAIN.DOCTOR.PRACTICE.MESSAGE.CREATE_SUCCESS');
          this.back();
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    } else {
      this.viewUtil.showToastError('VALIDATION.FORM');
    }
  }

  public back(): void {
    history.back();
  }
}
