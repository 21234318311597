import { Component, Inject, OnInit } from '@angular/core';
import { ViewUtil } from '@util/ViewUtil';
import { Transition } from '@uirouter/core';
import { Observable } from 'rxjs';
import { PatientDTO } from '@dto/patient/PatientDTO';
import { Constant } from '@common/Constant';
import { EventPatientService } from '@service/event/EventPatientService';
import { VIDEO_CALL_SERVICE } from 'app/component/ui/videoCall/interface/IVideoCallService';
import { DoctorVideoCallService } from '@service/event/DoctorVideoCallService';

@Component({
  selector: 'app-event-basic-data',
  templateUrl: './EventBasicDataComponent.html'
})
export class EventBasicDataComponent implements OnInit {
  private eventId: number = this.transition.params().eventId;

  public patient$: Observable<PatientDTO> = this.eventPatientService.getBasicData(this.eventId);

  public Constant: typeof Constant = Constant;

  constructor(public viewUtil: ViewUtil,
              private eventPatientService: EventPatientService,
              @Inject(VIDEO_CALL_SERVICE) public videoCallService: DoctorVideoCallService,
              private transition: Transition) {
  }

  public ngOnInit(): void {
  }
}
