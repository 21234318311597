import { WorkingDaysTimeRangeDTO } from './WorkingDaysTimeRangeDTO';
import { DayOfWeek } from '@enum/common/DayOfWeek';
import { Type } from 'class-transformer';

export class WorkingDaysCreateDTO {
  public dayOfWeek: DayOfWeek;

  public zone: string = 'Europe/Berlin';

  @Type(() => WorkingDaysTimeRangeDTO)
  public timeRange: WorkingDaysTimeRangeDTO;
}