import { LanguageVersion } from '@local/LanguageVersion';
import { UserType } from '@enum/user/UserType';
import { Expose, Transform } from 'class-transformer';
import { localeParser } from '@util/parser/LocaleParser';
import { DoctorStatus } from '@enum/doctor/DoctorStatus';

export class CurrentUserDTO {
  public email: string;

  public firstName: string;

  public lastName: string;

  @Transform(({ value }) => localeParser(value))
  public locale: LanguageVersion;

  public title: string;

  @Expose({ name: 'role' })
  public type: UserType;

  public status: DoctorStatus;
}