<div id="layout-migrating">
  <div class="container-fluid px-0 position-relative">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <img id="logo" src="asset/image/logo.svg" alt="Logo"/>
        </div>


        <ng-container *ngIf="viewReady && linkExpired">
          <div class="col-12">
            <div class="col-12">
              <h5 class="mt-8">{{'VIEW.PROCESSING_DATA_CONSENT.LINK_EXPIRED.TITLE' | translate}}</h5>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="viewReady && !linkExpired">
          <ng-container *ngIf="decision">
            <div class="col-12">
              <h5
                class="mt-8"
                [innerHtml]="('VIEW.PROCESSING_DATA_CONSENT.DECISION.' + decision + '.TITLE') | translate:{ name: patient?.firstName || '' }"></h5>
            </div>
          </ng-container>

          <ng-container *ngIf="!decision">
            <div class="col-12">
              <h5
                class="mt-8">{{'VIEW.PROCESSING_DATA_CONSENT.WELCOME_TEXT' | translate: { name: patient?.firstName || '' } }}</h5>

              <hr class="mt-4 mb-0">
            </div>

            <div class="col-12 mt-4">
              <form #baseForm="ngForm" class="on-submit-verification" [formGroup]="form" novalidate>
                <div class="validation-error mb-4">
                  <div>{{ 'VIEW.PROCESSING_DATA_CONSENT.MESSAGE.VALIDATION_ERROR' | translate }}</div>
                </div>

                <div class="form-group mb-4">
                  <div class="custom-control custom-radio primary">
                    <input type="radio" class="custom-control-input"
                           id="personalDataUsage_allow"
                           formControlName="personalDataUsage"
                           [value]="true">
                    <label class="custom-control-label"
                           for="personalDataUsage_allow"
                           [innerHTML]="'VIEW.PROCESSING_DATA_CONSENT.CONSENTS.ALLOW' | translate">
                    </label>
                  </div>
                </div>

                <div class="form-group mb-4">
                  <div class="custom-control custom-radio primary">
                    <input type="radio" class="custom-control-input"
                           id="personalDataUsage_disallow"
                           formControlName="personalDataUsage"
                           [value]="false">
                    <label class="custom-control-label"
                           for="personalDataUsage_disallow"
                           [innerHTML]="'VIEW.PROCESSING_DATA_CONSENT.CONSENTS.DISALLOW' | translate">
                    </label>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row mt-4">
                  <button type="submit" class="btn btn-primary ml-sm-2 mt-2 mt-sm-0"
                          (click)="save()">{{'COMMON.SAVE' | translate}}</button>
                </div>
              </form>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
