<form #form="ngForm" novalidate (ngSubmit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'VIEW.MAIN.PRESCRIPTION.EDIT.TITLE' | translate }}</h4>
    <div class="modal-control">
      <button type="button" class="btn btn-link btn-link--black close-button" (click)="close()">
        <i-tabler name="x"></i-tabler>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="medicationName">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.NAME' | translate }}</label>
      <input type="text" class="form-control form-control-sm" name="medicationName" id="medicationName"
             [ngClass]="{'is-invalid': medicationName.touched && medicationName.invalid }"
             [maxlength]="Constant.MAX_MEDICATION_NAME_LENGTH"
             #medicationName="ngModel"
             [(ngModel)]="prescription.medicamentName" required/>
      <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{prescription?.medicamentName?.length || 0}}/{{Constant.MAX_MEDICATION_NAME_LENGTH}}
          </span>
      </div>

      <div class="invalid-feedback" *ngIf="medicationName.touched && medicationName.invalid">
        <div *ngIf="medicationName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div
          *ngIf="medicationName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: medicationName.errors['maxlength'].requiredLength} }}</div>
      </div>
    </div>

    <div class="form-group mt-4">
      <label for="medicamentGrammage">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.GRAMMAGE' | translate }}</label>
      <input type="text" class="form-control form-control-sm" name="medicamentGrammage" id="medicamentGrammage"
             [ngClass]="{'is-invalid': medicamentGrammage.touched && medicamentGrammage.invalid }"
             #medicamentGrammage="ngModel"
             [maxlength]="Constant.MAX_GRAMMAGE_LENGTH"
             [(ngModel)]="prescription.medicamentGrammage" required/>
      <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{prescription?.medicamentGrammage?.length || 0}}/{{Constant.MAX_GRAMMAGE_LENGTH}}
          </span>
      </div>

      <div class="invalid-feedback" *ngIf="medicamentGrammage.touched && medicamentGrammage.invalid">
        <div *ngIf="medicamentGrammage.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div
          *ngIf="medicamentGrammage.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: medicamentGrammage.errors['maxlength'].requiredLength} }}</div>
      </div>
    </div>

    <div class="form-group mt-4">
      <label for="doze">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.DOZE' | translate }}</label>
      <input type="text" class="form-control form-control-sm" name="doze" id="doze"
             [ngClass]="{'is-invalid': doze.touched && doze.invalid }" #doze="ngModel"
             [maxlength]="Constant.MAX_DOZE_LENGTH"
             [(ngModel)]="prescription.doze" required/>
      <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{prescription?.doze?.length || 0}}/{{Constant.MAX_DOZE_LENGTH}}
          </span>
      </div>

      <div class="invalid-feedback" *ngIf="doze.touched && doze.invalid">
        <div *ngIf="doze.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
        <div
          *ngIf="doze.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: doze.errors['maxlength'].requiredLength} }}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="cancel()"
            [disabled]="isProcessing">{{ 'COMMON.CANCEL' | translate }}</button>
    <button type="submit" class="btn btn-primary"
            [disabled]="isProcessing || form.invalid">{{ 'COMMON.CONFIRM' | translate }}</button>
  </div>
</form>