import { PatientStatus } from '@enum/patient/PatientStatus';
import { IdentifiableObjectDTO } from '@dto/IdentifiableObjectDTO';
import { PatientAddressDTO } from '@dto/patient/PatientAddressDTO';
import { Exclude, Transform, Type } from 'class-transformer';
import { DateUtil } from '@util/DateUtil';
import { PregnantStatus } from '@enum/patient/PregnantStatus';
import { TransformFnParams } from 'class-transformer/types/interfaces';
import { PatientGender } from '@enum/common/PatientGender';
import { PatientAvatarHelper } from 'app/data/helper/PatientAvatarHelper';
import { PatientInsuranceType } from '@enum/patient/PatientInsuranceType';

export class PatientDTO extends IdentifiableObjectDTO<number> {
  public externalIdNumber: number;

  public firstName: string;

  public lastName: string;

  public email: string;

  public phoneNumber: string;

  @Transform(DateUtil.dateTimeConversion)
  public lastVideoCall: Date;

  @Type(() => PatientAddressDTO)
  public address: PatientAddressDTO;

  public status: PatientStatus;

  @Transform(DateUtil.dateConversion)
  public dateOfBirth: Date;

  public gender: PatientGender;

  public pregnant: PregnantStatus;

  public insuranceType: PatientInsuranceType;

  @Transform((params: TransformFnParams) => PatientAvatarHelper.getUrl(params.value, params.obj?.gender, params.obj?.isAdult))
  @Exclude({ toPlainOnly: true })
  public avatarUrl: string;

  public isPhoneConfirmed: boolean;

  public isUnregisteredPatient: boolean;

  public isAdult: boolean;

  @Transform(DateUtil.dateTimeConversion)
  public creationDate: Date;
}
