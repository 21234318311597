<div *ngIf="slot.isVideoCall" class="slot visit"
     [ngClass]="{
             'past': slot.activeStatus === SlotStatus.PAST,
             'today': slot.activeStatus === SlotStatus.NOW,
             'future': slot.activeStatus === SlotStatus.FUTURE
             }">
  <div class="d-flex flex-column">
    <div class="time">
      <i-tabler name="clock-hour-4" class="i-sm"></i-tabler>
      &nbsp;<span>{{slot.startDate | date:'HH:mm'}} ({{slot.duration}}min)</span>
    </div>

    <div class="patient">
      <button type="button"
              class="btn btn-link btn-sm btn-link--black text-normal text-left"
              (click)="showPatient(slot.patientId)">{{slot.patientFirstName}} {{slot.patientLastName}}</button>
    </div>

    <div class="visit-types">
      <div *ngFor="let item of slot.visitType">{{item.name}}</div>
    </div>
  </div>

  <div class="actions">
    <div class="badges mb-1">
      <div *ngIf="slot.newPatient">
        <span class="th-badge th-badge--secondary">{{'COMPONENT.SLOT.NEW_PATIENT' | translate}}</span>
      </div>

      <div *ngIf="slot.noShow">
        <span class="th-badge th-badge--danger">{{'COMPONENT.SLOT.NO_SHOW' | translate}}</span>
      </div>

      <div *ngIf="slot.isUnregisteredPatient">
        <span class="th-badge th-badge--black">{{'COMPONENT.SLOT.UNREGISTERED' | translate}}</span>
      </div>
    </div>

    <button type="button"
            class="btn btn-sm btn-block"
            [ngClass]="{
                'btn-outline-primary': slot.activeStatus !== SlotStatus.NOW,
                'btn-outline-secondary': slot.activeStatus === SlotStatus.NOW
                }"
            (click)="showDetails(slot.eventId)">{{'COMPONENT.SLOT.SHOW_DETAILS' | translate}}</button>
  </div>
</div>

<div *ngIf="!slot.isVideoCall" class="slot free">
  <div class="d-flex flex-column">
    <div class="time">
      <i-tabler name="clock-hour-4" class="i-sm"></i-tabler>
      &nbsp;<span>{{slot.startDate | date:'HH:mm'}} - {{slot.endDate | date:'HH:mm' }}</span>
    </div>
  </div>

  <div class="mt-2">
    <span class="th-badge th-badge--yellow">{{ 'COMPONENT.SLOT.FREE_TIME_SLOT' | translate }}</span>
  </div>
</div>
