import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-title-container',
  template: '<div id="{{name}}-title-portal"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleContainerComponent implements OnInit {
  @Input() public name: string = 'page';

  constructor() { }

  public ngOnInit(): void {
  }

}
