import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { ICalendarDateRange } from '@local/scheduler/ICalendarDateRange';

@Component({
  selector: 'app-calendar-selector',
  templateUrl: './CalendarSelectorComponent.html',
  styleUrls: [ './CalendarSelectorComponent.scss' ]
})
export class CalendarSelectorComponent implements OnInit {
  @Input()
  public set startDate(value: Date) {
    this._startDate = value || new Date();
    this.setCalendar(this._startDate);
  }

  @Output() public changeDateRange: EventEmitter<ICalendarDateRange> = new EventEmitter<ICalendarDateRange>();

  public start: Date;

  public end: Date;

  public weekNumber: number;

  private _startDate: Date;

  constructor() {
  }

  public ngOnInit(): void {
  }

  public next(): void {
    this.setCalendar(moment(this.start).add('7', 'days').toDate());
  }

  public previous(): void {
    this.setCalendar(moment(this.start).add('-7', 'days').toDate());
  }

  private setCalendar(date: Date): void {
    this.start = moment(date).weekday(0).startOf('day').toDate();
    this.end = moment(date).weekday(6).endOf('day').toDate();
    this.weekNumber = moment(this.start).week();

    this.changeDateRange.emit({
      startDate: this.start,
      endDate: this.end
    } as ICalendarDateRange);
  }
}
