// import 'zone.js/plugins/zone-error';

import { LanguageVersion } from 'app/data/local/LanguageVersion';
import { EnvironmentType } from 'app/data/local/EnvironmentType';

export const environment = {
  name: EnvironmentType.LOCAL,
  production: false,
  mock: false,
  apiUrl: 'https://telehealth-de-dev.eu-central-1.elasticbeanstalk.com',
  defaultLanguage: LanguageVersion.de,
  // optional
  websiteUrl: 'https://telehealth.de',
  cognito: {
    userPoolId: 'eu-central-1_Lkg5BCHaA',
    userPoolWebClientId: '2bifa376d0p01h9agsfvs8gok3',
    region: 'eu-central-1',
  }
};
