import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

// this is to change Angular's default custom 'required' validator for checkboxes, that only checks for their "true" value
// when we're using checkboxes combined with "array" directive (InputCheckBoxArrayValueAccessorDirective), we're using custom values
// in array, and thus we want the "required" to mean "at least one value present in array"

// note: this solution (combined with ngModelGroup, wrapping multiple checkboxes) is somewhat less preferred to another input type="hidden"
// with "required" validator. ngModelGroup preserves the validation status and values of each control, but it doesn't preserve errors of its
// subcontrols and as such is far less flexible for precise error reporting

export const checkboxArrayRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value || (control.value && !_.isArray(control.value))) {
      return { arrayRequired: true };
    }
    else {
      return ((control.value as Array<any>).length > 0) ? null : { arrayRequired: true };
    }
  };
};
@Directive({
  selector: 'input[type=checkbox][formControlName][array][arrayRequired],' +
            'input[type=checkbox][formControl][array][arrayRequired],' +
            'input[type=checkbox][ngModel][array][arrayRequired])',
  providers: [
      {
        provide: NG_VALIDATORS,
        useExisting: CheckboxArrayRequiredValidator,
        multi: true
      }
    ]
})
export class CheckboxArrayRequiredValidator implements Validator, OnInit {

  private validator: ValidatorFn;
  private onChange: () => void;

  @HostBinding('attr.arrayRequired')  // implementation from RequiredValidator, modernized
  public get attrArrayRequired(): boolean | string {
    return this.arrayRequired ? '' : null;
  }

  private _arrayRequired: boolean = false;

  @Input()
  public get arrayRequired(): boolean {
    return this._arrayRequired;
  }

  public set arrayRequired(value: boolean | string) {
    this._arrayRequired = (value !== null) && (value !== false) && (`${value}` !== 'false');  // implementation from RequiredValidator
    if (this.onChange) {
      this.onChange();
    }
  }

  public ngOnInit(): void {
    this.validator = checkboxArrayRequiredValidator();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.arrayRequired ? this.validator(control) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }
}
