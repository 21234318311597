import { Component } from '@angular/core';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';
import { PrescriptionDTO } from '@dto/prescription/PrescriptionDTO';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ViewUtil } from '@util/ViewUtil';
import { Constant } from '@common/Constant';
import { PrescriptionService } from '@service/PrescriptionService';

@Component({
  selector: 'app-prescription-edit-popup',
  templateUrl: './PrescriptionEditPopupComponent.html'
})
export class PrescriptionEditPopupComponent extends BaseModalComponent<PrescriptionDTO> {
  public prescription: PrescriptionDTO;

  public isProcessing: boolean = false;

  public prescriptionRequestId: number;

  public Constant: typeof Constant = Constant;

  constructor(public bsModalRef: BsModalRef,
              private viewUtil: ViewUtil,
              private prescriptionService: PrescriptionService) {
    super();
  }

  public save(): void {
    this.isProcessing = true;

    this.prescriptionService.updatePatientPrescription(this.prescriptionRequestId, this.prescription).toPromise()
      .then(() => {
        this.viewUtil.showToastSuccess('VIEW.MAIN.PRESCRIPTION.MESSAGE.UPDATED_SUCCESS');
        this.ok(this.prescription);
      })
      .catch((ex) => {
        this.viewUtil.handleServerError(ex);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
