import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from '@dto/PageDTO';
import { ObjectUtil } from '@util/ObjectUtil';
import { ApplicationConfig } from '@config/ApplicationConfig';
import { map } from 'rxjs/operators';
import { PatientAdminPageCriteriaDTO } from '@dto/patient/PatientAdminPageCriteriaDTO';
import { PatientAdminPageEntityDTO } from '@dto/patient/PatientAdminPageEntityDTO';
import { PatientAdminExportCriteriaDTO } from '@dto/patient/PatientAdminExportCriteriaDTO';
import { HttpUtil } from '@util/HttpUtil';

@Injectable({
  providedIn: 'root'
})
export class PatientAdminService {

  constructor(private http: HttpClient) {
  }

  public getPage(criteria: PatientAdminPageCriteriaDTO): Observable<PageDTO<PatientAdminPageEntityDTO>> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/patients/admin/all`, { params })
      .pipe(
        map((response: any) => ObjectUtil.plainToClassFromExist(new PageDTO<PatientAdminPageEntityDTO>(PatientAdminPageEntityDTO), response))
      );
  }

  public getExcelReport(criteria: PatientAdminExportCriteriaDTO): Observable<File> {
    const params = HttpUtil.createHttpParams(criteria);

    return this.http.get(`${ ApplicationConfig.apiUrl }/patients/admin/export`,
      {
        params: params,
        responseType: 'blob' as 'json',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => new File([ response.body ], `patients.xlsx`))
      );
  }
}
