<ng-container *ngIf="data$ | async as data">
  <div class="container px-0">

    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.LICENSE.LICENSE_INFO' | translate }}</div>

            <div class="form-group mt-4">
              <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.INSURANCE_TYPE' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{data?.insuranceType | prefix: 'ENUM.INSURANCE_TYPE.' | translate}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.KIDS' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{data?.worksWithKids | boolean | translate}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.BAN' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{data?.ban}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.LANR' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{data?.lanrNumber}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.OFFICE_NUMBER' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{data?.doctorOfficeNumber}}</div>
            </div>
          </div>
          <div class="col-12 col-sm-6 mt-4 mt-sm-0">
            <div class="font-weight-bold">{{'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATES' | translate }}</div>
            <div class="certificates">
              <ng-container *ngIf="data.doctorCertificateFrontFile">
                <div class="form-group mt-4">
                  <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.DOCTOR_CERTIFICATE_FRONT' | translate }}</label>
                  <app-file-preview [file]="data.doctorCertificateFrontFile"></app-file-preview>
                </div>
              </ng-container>

              <ng-container *ngIf="data.doctorCertificateBackFile">
                <div class="form-group mt-4">
                  <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.DOCTOR_CERTIFICATE_BACK' | translate }}</label>
                  <app-file-preview [file]="data.doctorCertificateBackFile"></app-file-preview>
                </div>
              </ng-container>

              <ng-container *ngIf="data.approbationCertificateFile">
                <div class="form-group mt-4">
                  <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATE_OF_APPROBATION' | translate }}</label>
                  <app-file-preview [file]="data.approbationCertificateFile"></app-file-preview>
                </div>
              </ng-container>

              <ng-container *ngIf="data.specialtyCertificateFile">
                <div class="form-group mt-4">
                  <label>{{ 'VIEW.MAIN.DOCTOR.LICENSE.CERTIFICATE_OF_SPECIALITY' | translate }}</label>
                  <app-file-preview [file]="data.specialtyCertificateFile"></app-file-preview>
                </div>
              </ng-container>
            </div>

            <p class="d-none mt-4 no-data">{{'COMMON.NO_DATA' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!--Portal-->
<app-page-action-portal name="profile" *ngIf="isEditable$ | async">
  <button class="btn btn-primary" type="button" (click)="edit()">{{'COMMON.EDIT' | translate}}</button>
</app-page-action-portal>
