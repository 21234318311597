<div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.PRESCRIPTION.TITLE' | translate}}</div>

<div class="row">
  <div class="col-12">
    <span>{{'VIEW.VIDEO_CALL.PRESCRIPTION.DESCRIPTION' | translate}}</span>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12 col-sm-6">
    <div class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.PRESCRIPTION.NEW_PRESCRIPTION' | translate}}</div>

    <form #newPrescriptionForm="ngForm" novalidate (ngSubmit)="addPrescription()">

      <div class="form-group">
        <label for="medicationName">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.NAME' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="medicationName" id="medicationName"
               [ngClass]="{'is-invalid': medicationName.touched && medicationName.invalid }"
               [maxlength]="Constant.MAX_MEDICATION_NAME_LENGTH"
               #medicationName="ngModel"
               [(ngModel)]="newPrescription.medicamentName" required/>
        <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{newPrescription?.medicamentName?.length || 0}}/{{Constant.MAX_MEDICATION_NAME_LENGTH}}
          </span>
        </div>

        <div class="invalid-feedback" *ngIf="medicationName.touched && medicationName.invalid">
          <div *ngIf="medicationName.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="medicationName.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: medicationName.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <div class="form-group mt-4">
        <label for="medicamentGrammage">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.GRAMMAGE' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="medicamentGrammage" id="medicamentGrammage"
               [ngClass]="{'is-invalid': medicamentGrammage.touched && medicamentGrammage.invalid }"
               #medicamentGrammage="ngModel"
               [maxlength]="Constant.MAX_GRAMMAGE_LENGTH"
               [(ngModel)]="newPrescription.medicamentGrammage" required/>
        <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{newPrescription?.medicamentGrammage?.length || 0}}/{{Constant.MAX_GRAMMAGE_LENGTH}}
          </span>
        </div>

        <div class="invalid-feedback" *ngIf="medicamentGrammage.touched && medicamentGrammage.invalid">
          <div *ngIf="medicamentGrammage.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="medicamentGrammage.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: medicamentGrammage.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <div class="form-group mt-4">
        <label for="doze">{{ 'VIEW.MAIN.PRESCRIPTION.DATA.DOZE' | translate }}</label>
        <input type="text" class="form-control form-control-sm" name="doze" id="doze"
               [ngClass]="{'is-invalid': doze.touched && doze.invalid }" #doze="ngModel"
               [maxlength]="Constant.MAX_DOZE_LENGTH"
               [(ngModel)]="newPrescription.doze" required/>
        <div class="d-flex justify-content-end">
          <span class="font-size-sm color-gray-3">
            {{newPrescription?.doze?.length || 0}}/{{Constant.MAX_DOZE_LENGTH}}
          </span>
        </div>

        <div class="invalid-feedback" *ngIf="doze.touched && doze.invalid">
          <div *ngIf="doze.errors['required']">{{ 'VALIDATION.REQUIRED' | translate }}</div>
          <div
            *ngIf="doze.errors['maxlength']">{{ 'VALIDATION.MAXLENGTH' | translate:{requiredLength: doze.errors['maxlength'].requiredLength} }}</div>
        </div>
      </div>

      <button type="submit"
              [disabled]="newPrescriptionForm.invalid || isProcessing"
              class="btn btn-primary mt-4">{{'VIEW.VIDEO_CALL.PRESCRIPTION.ACTION.ADD_PRESCRIPTION' | translate}}</button>
    </form>
  </div>
  <div class="col-12 col-sm-6 mt-4 mt-sm-0">
    <div
      class="font-weight-bold mb-4">{{'VIEW.VIDEO_CALL.PRESCRIPTION.PRESCRIBED_MEDICATIONS' | translate}}</div>

    <ng-container *ngFor="let prescribedMedication of prescriptions">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex flex-column medication-descriptions">
          <span>{{prescribedMedication.medicamentName}}</span>
          <span>{{prescribedMedication.medicamentGrammage}}</span>
          <span>{{prescribedMedication.doze}}</span>
        </div>
        <div class="d-flex align-items-start mt-1 ml-2">
          <button type="button" class="btn btn-link btn-link--black" (click)="editPrescription(prescribedMedication)">
            <i-tabler class="i-lg" name="pencil"></i-tabler>
          </button>

          <button type="button" class="btn btn-link btn-link--black ml-1"
                  (click)="removePrescription(prescribedMedication)">
            <i-tabler class="i-lg" name="trash"></i-tabler>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
