import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventSlot } from '@local/scheduler/EventSlot';
import { ViewUtil } from '@util/ViewUtil';
import { StateService } from '@uirouter/core';
import { EventService } from '@service/EventService';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupConfirmationComponent } from 'app/component/ui/popup/PopupConfirmationComponent';

@Component({
  selector: 'app-scheduler-conflict-slot',
  templateUrl: './SchedulerConflictSlotComponent.html',
  styleUrls: [ './SchedulerConflictSlotComponent.scss' ]
})
export class SchedulerConflictSlotComponent implements OnInit {

  @Input() public slot: EventSlot;

  @Output() public slotCancelled: EventEmitter<void> = new EventEmitter();

  constructor(private eventService: EventService,
              private stateService: StateService,
              private modalService: BsModalService,
              private viewUtil: ViewUtil) {
  }

  public ngOnInit(): void {
  }

  public cancel(eventId: number): void {
    const modal: BsModalRef = this.modalService.show(PopupConfirmationComponent, {
      initialState: {
        title: 'VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.TITLE',
        message: 'VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.DESCRIPTION',
        okText: 'COMMON.CONFIRM'
      }
    });

    modal.content.close$.subscribe(() => {
      this.eventService.deleteEvent(eventId).toPromise()
        .then(() => {
          this.slotCancelled.emit();
          this.viewUtil.showToastSuccess('VIEW.MAIN.EVENT_DETAILS.MESSAGE.CANCEL_EVENT.SUCCESS');
        })
        .catch((error) => {
          this.viewUtil.handleServerError(error);
        });
    }, () => {
      //
    });
  }

}
