import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OptionItem } from 'app/data/local/generic/OptionItem';

@Component({
  selector: 'app-styleguide',
  templateUrl: './StyleguideComponent.html',
  styleUrls: [ './StyleguideComponent.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleguideComponent implements OnInit {
  public options: OptionItem<number>[] = [
    { label: 'Option 1', value: 1 },
    { label: 'Option 2', value: 2 },
    { label: 'Option 3', value: 3 },
    { label: 'Option 4', value: 4 },
    { label: 'Option 5', value: 5 }
  ];

  public selectedValue: number = 5;

  constructor() {
  }

  public ngOnInit(): void {
  }

}
