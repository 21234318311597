<ng-container *ngIf="patient$ | async as patient">
  <div class="container px-0">
    <div class="row mb-4">
      <div class="col-12">
        <div class="d-flex justify-content-center justify-content-sm-start">
          <img class="avatar rounded-circle"
               [src]="patient?.avatarUrl "/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="font-weight-bold mb-4">{{'VIEW.MAIN.PATIENT.BASIC_DATA' | translate}}</div>

        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.FIRST_NAME' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{patient?.firstName | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.LAST_NAME' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{patient?.lastName | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.BIRTHDAY_AND_AGE' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">
                <span>{{patient?.dateOfBirth | date: Constant.DATE_FORMAT}}</span>
                <span *ngIf="patient?.dateOfBirth">&nbsp;({{patient.dateOfBirth | birthDateToAge}})</span>
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.STATUS' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{patient?.status ? (patient.status | prefix: 'ENUM.PATIENT_STATUS.' | translate) : '-'}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.EMAIL' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{patient?.email | replaceEmpty}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.PHONE' | translate }}</label>
              <div class="form-control-plaintext form-control-sm">{{patient?.phoneNumber | replaceEmpty}}</div>
            </div>

            <div class="form-group" *ngIf="patient.status !== PatientStatus.UNREGISTERED">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.GENDER' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">{{patient?.gender | prefix: 'ENUM.PATIENT_GENDER.' | translate}}</div>
            </div>

            <div class="form-group">
              <label>{{ 'VIEW.MAIN.PATIENT.DATA.ADDRESS' | translate }}</label>
              <div
                class="form-control-plaintext form-control-sm">
                <div>{{patient?.address?.fullStreetName}}</div>
                <div>{{ patient?.address?.cityCode }} {{ patient?.address?.city }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3" *ngIf="patient.status !== PatientStatus.UNREGISTERED">
        <div class="font-weight-bold mb-md-4">&nbsp;</div>

        <form novalidate #form="ngForm" (ngSubmit)="save(patient)">
          <div class="form-group">
            <div class="d-flex align-items-center mb-1">
              <label for="externalIdNumber" class="mb-0">{{ 'VIEW.MAIN.PATIENT.DATA.EXTERNAL_ID' | translate }}</label>
              <button type="button"
                      class="btn btn-link btn-link--black btn-sm ml-2"
                      *ngIf="!isEditing"
                      (click)="isEditing = true">
                <i-tabler name="pencil"></i-tabler>
              </button>
            </div>

            <div class="d-flex" *ngIf="isEditing">
              <input type="text" id="externalIdNumber" class="form-control form-control-sm"
                     name="externalIdNumber"
                     [(ngModel)]="patient.externalIdNumber"/>
              <button type="submit" class="btn btn-primary ml-1"
                      [disabled]="form.invalid">{{'COMMON.SAVE' | translate}}</button>
            </div>
            <div *ngIf="!isEditing"
                 class="form-control-plaintext form-control-sm">{{patient?.externalIdNumber | replaceEmpty}}</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
