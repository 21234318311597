import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseModalComponent } from '@util/extender/BaseModalComponent';

@Component({
  selector: 'app-popup-confirmation',
  templateUrl: 'PopupConfirmationComponent.html'
})
export class PopupConfirmationComponent extends BaseModalComponent<boolean> {

  public title: string = 'COMPONENT.POPUP.CONFIRMATION.TITLE';

  public message: string;

  public messageVariables: { [key: string]: any };

  public okText: string = 'COMMON.OK';

  public cancelText: string = 'COMMON.CANCEL';

  public okButtonClass: string = 'btn-primary';

  constructor(public bsModalRef: BsModalRef) {
    super();
  }
}
