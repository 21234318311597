import { Component, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/core';
import { AuthModel, LoginResult } from 'app/model/AuthModel';
import { ViewUtil } from 'app/util/ViewUtil';
import { State } from 'app/common/State';
import { NgForm } from '@angular/forms';
import { ApplicationModel } from '@model/ApplicationModel';

@Component({
  selector: 'app-login',
  templateUrl: 'LoginComponent.html',
  styleUrls: [ 'LoginComponent.scss' ]
})
export class LoginComponent {
  @ViewChild('loginForm') public loginForm: NgForm;

  @ViewChild('confirmationCodeForm') public confirmationCodeForm: NgForm;

  public serverValidationError: boolean = false;

  public verificationCodeRequired: boolean = false;

  public formData: { email: string; password: string; confirmationCode?: string; } = {
    email: null,
    password: null,
    confirmationCode: null
  };

  constructor(public applicationModel: ApplicationModel,
              private stateService: StateService,
              public viewUtil: ViewUtil,
              private authModel: AuthModel) {
  }

  public async submit(): Promise<void> {
    this.serverValidationError = false;

    if (this.loginForm.valid) {
      this.login();
    }
  }

  public async submitCode(): Promise<void> {
    if (this.confirmationCodeForm.valid) {
      this.authModel.sendVerificationCode(this.formData.confirmationCode)
        .catch(() => {
          this.verificationCodeRequired = false;
          this.viewUtil.showToastError('VIEW.PRELIMINARY.LOGIN.CONFIRMATION_CODE.VALIDATION.INVALID');

          this.formData.password = null;
          this.formData.confirmationCode = null;
        });
    }
  }

  public resetPassword(): void {
    this.stateService.go(State.PRELIMINARY.RESET_PASSWORD_START);
  }

  public register(): void {
    this.stateService.go(State.PRELIMINARY.REGISTER);
  }

  public login(): void {
    const { email, password } = this.formData;

    this.authModel.login(email, password)
      .then((result: LoginResult) => {
        if (result.verificationCodeRequired) {
          this.verificationCodeRequired = true;
        }
      })
      .catch(() => {
        this.serverValidationError = true;
      });
  }

  public backToLogin(): void {
    this.verificationCodeRequired = false;
  }
}
